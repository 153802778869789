import axios from 'axios';

import { USER_API_BASE_COLLECTION_URL, getApiUrl } from '../../Constants/Api';

export class CollectionService {
    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        return { headers: { "Authorization": this.getUserInfo() } };
    }

    emailCollectionData(data) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_COLLECTION_URL}/send`, data, this.getAuthHeader())
    }
}

export default new CollectionService();