import axios from 'axios';
import { USER_API_BASE_USERS_URL, USER_API_BASE_ROLES_URL, getApiUrl } from '../../Constants/Api';

export class ManageGlobalUsersService {
    getUsers = (clientKey, filter = '') => {
        let query = `${getApiUrl()}${USER_API_BASE_USERS_URL}/${clientKey}`;
        if (filter) query += `?filter=${filter}`

        return axios.get(query);
    }
    addUser = (clientKey, user) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_USERS_URL}/${clientKey}`, { user });
    }
    updateUser = (clientKey, user) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_USERS_URL}/${clientKey}/${user.key}`, { user });
    }
    deleteUser = (clientKey, userKey) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_USERS_URL}/${clientKey}/${userKey}`)
    }
    undeleteUser = (clientKey, userKey) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_USERS_URL}/${clientKey}/${userKey}/undelete`)
    }

    getRoles = (clientKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_ROLES_URL}/${clientKey}`);
    }

    addUserRole = (clientKey, userKey, roleKey) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_USERS_URL}/${clientKey}/${userKey}/roles/${roleKey}`);
    }
    deleteUserRole = (clientKey, userKey, roleKey) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_USERS_URL}/${clientKey}/${userKey}/roles/${roleKey}`);
    }
}

export default new ManageGlobalUsersService();