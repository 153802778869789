import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** VERLADUNG IMAGES */
import Verladung_Einfuehrung1 from '../../assets/img_en/verladung/Einfuehrung/1.png'
import Verladung_Einfuehrung2 from '../../assets/img_en/verladung/Einfuehrung/2.png'
import Verladung_Einfuehrung3 from '../../assets/img_en/verladung/Einfuehrung/3.png'

import Verladung_WA_Kontrolle_Ohne_HU1 from '../../assets/img_en/verladung/WA_Kontrolle_Ohne_HU/1.png'
import Verladung_WA_Kontrolle_Ohne_HU2 from '../../assets/img_en/verladung/WA_Kontrolle_Ohne_HU/2.png'
import Verladung_WA_Kontrolle_Ohne_HU3 from '../../assets/img_en/verladung/WA_Kontrolle_Ohne_HU/3.png'
import Verladung_WA_Kontrolle_Ohne_HU4 from '../../assets/img_en/verladung/WA_Kontrolle_Ohne_HU/4.png'
import Verladung_WA_Kontrolle_Ohne_HU5 from '../../assets/img_en/verladung/WA_Kontrolle_Ohne_HU/5.png'
import Verladung_WA_Kontrolle_Ohne_HU6 from '../../assets/img_en/verladung/WA_Kontrolle_Ohne_HU/6.png'

import Verladung_WA_Kontrolle_Mit_HU1 from '../../assets/img_en/verladung/WA_Kontrolle_Mit_HU/1.png'
import Verladung_WA_Kontrolle_Mit_HU2 from '../../assets/img_en/verladung/WA_Kontrolle_Mit_HU/2.png'
import Verladung_WA_Kontrolle_Mit_HU3 from '../../assets/img_en/verladung/WA_Kontrolle_Mit_HU/3.png'

import Verladung_Transport1 from '../../assets/img_en/verladung/Transport/1.png'
import Verladung_Transport2 from '../../assets/img_en/verladung/Transport/2.png'

import Verladung_Gruppe1 from '../../assets/img_en/verladung/Gruppe/1.png'
import Verladung_Gruppe2 from '../../assets/img_en/verladung/Gruppe/2.png'

export const VerladungImagesEN = [
    new ProcessImageContainer(
        `P_VERLADUNG_EINFUEHRUNG`,
        [
            new ProcessImage('Deliveries', Verladung_Einfuehrung1),
            new ProcessImage('Positions Incomplete', Verladung_Einfuehrung2),
            new ProcessImage('Positions Complete', Verladung_Einfuehrung3)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_FUER_VERSANDELEMENTE`,
        [
            new ProcessImage('Delivery', Verladung_WA_Kontrolle_Ohne_HU1),
            new ProcessImage('Search', Verladung_WA_Kontrolle_Ohne_HU2),
            new ProcessImage('Deliveries', Verladung_WA_Kontrolle_Ohne_HU3),
            new ProcessImage('Positions Incomplete', Verladung_WA_Kontrolle_Ohne_HU4),
            new ProcessImage('Detail', Verladung_WA_Kontrolle_Ohne_HU5),
            new ProcessImage('Positions Complete', Verladung_WA_Kontrolle_Ohne_HU6)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_MIT_HANDLING_UNITS`,
        [
            new ProcessImage('Delivery', Verladung_WA_Kontrolle_Mit_HU1),
            new ProcessImage('Positions Incomplete', Verladung_WA_Kontrolle_Mit_HU2),
            new ProcessImage('Positions Complete', Verladung_WA_Kontrolle_Mit_HU3)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_ZUM_TRANSPORT`,
        [
            new ProcessImage('Transport', Verladung_Transport1),
            new ProcessImage('Deliveries Transport', Verladung_Transport2)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_ZUR_GRUPPE`,
        [
            new ProcessImage('Group', Verladung_Gruppe1),
            new ProcessImage('Deliveries Group', Verladung_Gruppe2)
        ]
    )
]