import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** KOMMISSIONIERUNG IMAGES */
import Kommissionierung_Einfuehrung1 from '../../assets/img_en/kommissionierung/Einfuehrung/1.png'
import Kommissionierung_Einfuehrung2 from '../../assets/img_en/kommissionierung/Einfuehrung/2.png'

import Kommissionierung_Auslieferung_WM1 from '../../assets/img_en/kommissionierung/Auslieferung_WM/1.png'
import Kommissionierung_Auslieferung_WM2 from '../../assets/img_en/kommissionierung/Auslieferung_WM/2.png'
import Kommissionierung_Auslieferung_WM3 from '../../assets/img_en/kommissionierung/Auslieferung_WM/3.png'
import Kommissionierung_Auslieferung_WM4 from '../../assets/img_en/kommissionierung/Auslieferung_WM/4.png'
import Kommissionierung_Auslieferung_WM5 from '../../assets/img_en/kommissionierung/Auslieferung_WM/5.png'

import Kommissionierung_Auslieferung_EWM1 from '../../assets/img_en/kommissionierung/Auslieferung_EWM/1.png'
import Kommissionierung_Auslieferung_EWM2 from '../../assets/img_en/kommissionierung/Auslieferung_EWM/2.png'
import Kommissionierung_Auslieferung_EWM3 from '../../assets/img_en/kommissionierung/Auslieferung_EWM/3.png'
import Kommissionierung_Auslieferung_EWM4 from '../../assets/img_en/kommissionierung/Auslieferung_EWM/4.png'
import Kommissionierung_Auslieferung_EWM5 from '../../assets/img_en/kommissionierung/Auslieferung_EWM/5.png'

import Kommissionierung_Transport1 from '../../assets/img_en/kommissionierung/Transport/1.png'
import Kommissionierung_Transport2 from '../../assets/img_en/kommissionierung/Transport/2.png'

import Kommissionierung_Sammellieferung1 from '../../assets/img_en/kommissionierung/Sammellieferung/1.png'
import Kommissionierung_Sammellieferung2 from '../../assets/img_en/kommissionierung/Sammellieferung/2.png'

import Kommissionierung_Queues_WM1 from '../../assets/img_en/kommissionierung/Queues_WM/1.png'
import Kommissionierung_Queues_WM2 from '../../assets/img_en/kommissionierung/Queues_WM/2.png'
import Kommissionierung_Queues_WM3 from '../../assets/img_en/kommissionierung/Queues_WM/3.png'
import Kommissionierung_Queues_WM4 from '../../assets/img_en/kommissionierung/Queues_WM/4.png'

import Kommissionierung_Queues_EWM1 from '../../assets/img_en/kommissionierung/Queues_EWM/1.png'
import Kommissionierung_Queues_EWM2 from '../../assets/img_en/kommissionierung/Queues_EWM/2.png'
import Kommissionierung_Queues_EWM3 from '../../assets/img_en/kommissionierung/Queues_EWM/3.png'
import Kommissionierung_Queues_EWM4 from '../../assets/img_en/kommissionierung/Queues_EWM/4.png'

import Kommissionierung_Wellen_WM1 from '../../assets/img_en/kommissionierung/Wellen_WM/1.png'
import Kommissionierung_Wellen_WM2 from '../../assets/img_en/kommissionierung/Wellen_WM/2.png'
import Kommissionierung_Wellen_WM3 from '../../assets/img_en/kommissionierung/Wellen_WM/3.png'
import Kommissionierung_Wellen_WM4 from '../../assets/img_en/kommissionierung/Wellen_WM/4.png'

import Kommissionierung_Wellen_EWM1 from '../../assets/img_en/kommissionierung/Wellen_EWM/1.png'
import Kommissionierung_Wellen_EWM2 from '../../assets/img_en/kommissionierung/Wellen_EWM/2.png'
import Kommissionierung_Wellen_EWM3 from '../../assets/img_en/kommissionierung/Wellen_EWM/3.png'
import Kommissionierung_Wellen_EWM4 from '../../assets/img_en/kommissionierung/Wellen_EWM/4.png'

import Kommissionierung_NullKontrolle1 from '../../assets/img_en/kommissionierung/NullKontrolle/1.png'
import Kommissionierung_NullKontrolle2 from '../../assets/img_en/kommissionierung/NullKontrolle/2.png'
import Kommissionierung_NullKontrolle3 from '../../assets/img_en/kommissionierung/NullKontrolle/3.png'

export const KommissionierungImagesEN = [
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_EINFUEHRUNG_WM`,
        [
            new ProcessImage('Queues', Kommissionierung_Einfuehrung1),
            new ProcessImage('Direct Deliveries', Kommissionierung_Einfuehrung2),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_EINFUEHRUNG_EWM`,
        [
            new ProcessImage('Queues', Kommissionierung_Einfuehrung1),
            new ProcessImage('Direct Deliveries', Kommissionierung_Einfuehrung2),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUR_AUSLIEFERUNG_WM`,
        [
            new ProcessImage('Direct Delivery', Kommissionierung_Auslieferung_WM1),
            new ProcessImage('Direct Deliveries', Kommissionierung_Auslieferung_WM2),
            new ProcessImage('Optional Positions', Kommissionierung_Auslieferung_WM3),
            new ProcessImage('Optional Details', Kommissionierung_Auslieferung_WM4),
            new ProcessImage('Optional Storage Bin', Kommissionierung_Auslieferung_WM5),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUR_AUSLIEFERUNG_EWM`,
        [
            new ProcessImage('Direct Delivery', Kommissionierung_Auslieferung_EWM1),
            new ProcessImage('Direct Deliveries', Kommissionierung_Auslieferung_EWM2),
            new ProcessImage('Optional Positions', Kommissionierung_Auslieferung_EWM3),
            new ProcessImage('Optional Details', Kommissionierung_Auslieferung_EWM4),
            new ProcessImage('Optional Storage Bin', Kommissionierung_Auslieferung_EWM5),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUM_TRANSPORT`,
        [
            new ProcessImage('Transport', Kommissionierung_Transport1),
            new ProcessImage('Deliveries for Transport', Kommissionierung_Transport2)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUR_GRUPPE`,
        [
            new ProcessImage('Group', Kommissionierung_Sammellieferung1),
            new ProcessImage('Deliveries Group', Kommissionierung_Sammellieferung2)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERLISTEN_UND_QUEUES_WM`,
        [
            new ProcessImage('Queues', Kommissionierung_Queues_WM1),
            new ProcessImage('Positions', Kommissionierung_Queues_WM2),
            new ProcessImage('Optional Details', Kommissionierung_Queues_WM3),
            new ProcessImage('Optional Storage Bin', Kommissionierung_Queues_WM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERLISTEN_UND_QUEUES_EWM`,
        [
            new ProcessImage('Queues', Kommissionierung_Queues_EWM1),
            new ProcessImage('Positions', Kommissionierung_Queues_EWM2),
            new ProcessImage('Optional Details', Kommissionierung_Queues_EWM3),
            new ProcessImage('Optional Storage Bin', Kommissionierung_Queues_EWM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERWELLEN_WM`,
        [
            new ProcessImage('Picking Waves', Kommissionierung_Wellen_WM1),
            new ProcessImage('Positions', Kommissionierung_Wellen_WM2),
            new ProcessImage('Optional Details', Kommissionierung_Wellen_WM3),
            new ProcessImage('Optional Storage Bin', Kommissionierung_Wellen_WM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERWELLEN_EWM`,
        [
            new ProcessImage('Picking Waves', Kommissionierung_Wellen_EWM1),
            new ProcessImage('Positions', Kommissionierung_Wellen_EWM2),
            new ProcessImage('Optional Details', Kommissionierung_Wellen_EWM3),
            new ProcessImage('Optional Storage Bin', Kommissionierung_Wellen_EWM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_NULL_KONTROLLE_UND_RESTMENGENPRUEFUNG`,
        [
            new ProcessImage('Residual Quantity Check', Kommissionierung_NullKontrolle1),
            new ProcessImage('Zero Control', Kommissionierung_NullKontrolle2),
            new ProcessImage('Zero Control Correction', Kommissionierung_NullKontrolle3)
        ]
    )
]