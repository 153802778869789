import React from 'react';

import { Typography, FONT_FAMILY, ColorSAP, Warning } from '@commsult/ontego-ui';

import Backdrop from '@material-ui/core/Backdrop';
import { useTranslation } from 'react-i18next';

const ScreenSizeWarningBackdrop = props => {
    const { open } = props

    const { t } = useTranslation()

    return (
        <Backdrop
            style={{
                zIndex: 100000,
                background: `rgba(255, 255, 255, .15)`,
                backdropFilter: 'blur(15px)',
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                overflow: "hidden"
            }}
            open={open}
            onClick={() => { }}
        >
            <div style={{ marginTop: 320, textAlign: 'center' }}>
                <Warning style={{ width: 134, height: 134, color: ColorSAP.secondary[40] }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={32}
                        color={ColorSAP.neutral[100]}
                        style={{ marginBottom: 10, marginTop: 30, textAlign: 'center' }}
                    >
                        {t('error.displayTooSmall')}
                    </Typography>
                </div>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={24}
                    color={ColorSAP.neutral[100]}
                >
                    {t('error.pleaseUseBiggerDisplay')}
                </Typography>
            </div>
        </Backdrop>
    )
}

export default ScreenSizeWarningBackdrop