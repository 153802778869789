// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        rootContainer: {
            width: '100%',
            height: '100%',
            marginTop: 80,
            // border: '1px solid red',
            display: 'flex',
            // alignItems: 'center',
            justifyContent: 'center'
            // boxSizing: 'border-box',
        },
        rootContainerChildWrapper: {
            width: 1200,
            maxWidth: 1200,
            width: '100%'
            // [theme.breakpoints.between('1280', '1440')]: {
            //     maxWidth: 1200,
            // },
            // [theme.breakpoints.up('1440')]: {
            //     maxWidth: 1200,
            // },
        },
        paddedContainer: {
            width: 1200,
            maxWidth: 1200,
            width: '100%'
            // [theme.breakpoints.between('1280', '1440')]: {
            //     paddingLeft: 40,
            //     paddingRight: 40,
            // },
            // [theme.breakpoints.up('1440')]: {
            //     paddingLeft: 120,
            //     paddingRight: 120,
            // },
        },
        paddedContainerChildWrapper: {
            // [theme.breakpoints.between('1280', '1440')]: {
            //     maxWidth: 1200,
            // },
            // [theme.breakpoints.up('1440')]: {
            //     maxWidth: 1200,
            // },
        },
        buttonUp: {
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 16,
            borderRadius: 60,
            cursor: 'pointer',
            visibility: 'hidden',
            opacity: 0,
            transition: '0.5s all ease',
            background: ColorSAP.primary[70],
            [theme.breakpoints.between('1280', '1440')]: {
                right: 40,
                width: 56.6,
                height: 56.6,
                bottom: 91.43,
            },
            [theme.breakpoints.up('1440')]: {
                right: 120,
                width: 56.6,
                height: 56.6,
                bottom: 91.43,
            },
            // "&:hover": {
            //     background: Color.primary[400],
            // }
        }
    })
);  