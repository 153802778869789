import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** RÜCKMELDUNGPRODUKTION IMAGES */
import Rueckmeldungproduktion_Einfuehrung1 from '../../assets/img/rueckmeldungproduktion/Einfuehrung/1.png'
import Rueckmeldungproduktion_Einfuehrung2 from '../../assets/img/rueckmeldungproduktion/Einfuehrung/2.png'

import Rueckmeldungproduktion_Fertigungsauftrag1 from '../../assets/img/rueckmeldungproduktion/Fertigungsauftrag/1.png'
import Rueckmeldungproduktion_Fertigungsauftrag2 from '../../assets/img/rueckmeldungproduktion/Fertigungsauftrag/2.png'
import Rueckmeldungproduktion_Fertigungsauftrag3 from '../../assets/img/rueckmeldungproduktion/Fertigungsauftrag/3.png'

import Rueckmeldungproduktion_Serienfertigung1 from '../../assets/img/rueckmeldungproduktion/Serienfertigung/1.png'
import Rueckmeldungproduktion_Serienfertigung2 from '../../assets/img/rueckmeldungproduktion/Serienfertigung/2.png'
import Rueckmeldungproduktion_Serienfertigung3 from '../../assets/img/rueckmeldungproduktion/Serienfertigung/3.png'

import Rueckmeldungproduktion_Einlagerung_WM1 from '../../assets/img/rueckmeldungproduktion/Einlagerung_WM/1.png'

import Rueckmeldungproduktion_Einlagerung_EWM1 from '../../assets/img/rueckmeldungproduktion/Einlagerung_EWM/1.png'

export const RueckmeldungproduktionImages = [
    new ProcessImageContainer(
        `P_RUECKMEDLUNG_PRODUKTION_EINFUEHRUNG`,
        [
            new ProcessImage('Details Seriennummern', Rueckmeldungproduktion_Einfuehrung1),
            new ProcessImage('Details (mit Version und Ausschuss)', Rueckmeldungproduktion_Einfuehrung2)
        ]
    ),
    new ProcessImageContainer(
        `P_RUECKMEDLUNG_PRODUKTION_RUECKMELDUNG_ZUM_FERTIGUNGSAUFTRAG`,
        [
            new ProcessImage('FAUF', Rueckmeldungproduktion_Fertigungsauftrag1),
            new ProcessImage('Details (mit Charge)', Rueckmeldungproduktion_Fertigungsauftrag2),
            new ProcessImage('Details Seriennummern', Rueckmeldungproduktion_Fertigungsauftrag3)
        ]
    ),
    new ProcessImageContainer(
        `P_RUECKMEDLUNG_PRODUKTION_RUECKMELDUNG_IN_DER_SERIENFERTIGUNG`,
        [
            new ProcessImage('Material', Rueckmeldungproduktion_Serienfertigung1),
            new ProcessImage('Details (mit Version und Ausschuss)', Rueckmeldungproduktion_Serienfertigung2),
            new ProcessImage('Details Seriennummern', Rueckmeldungproduktion_Serienfertigung3)
        ]
    ),
    new ProcessImageContainer(
        `P_RUECKMEDLUNG_PRODUKTION_RUECKMELDUNG_ANSCHLIESSENDE_EINLAGERUNG_WM`,
        [
            new ProcessImage('Einlagerung', Rueckmeldungproduktion_Einlagerung_WM1),
        ]
    ),
    new ProcessImageContainer(
        `P_RUECKMEDLUNG_PRODUKTION_RUECKMELDUNG_ANSCHLIESSENDE_EINLAGERUNG_EWM`,
        [
            new ProcessImage('Einlagerung', Rueckmeldungproduktion_Einlagerung_EWM1),
        ]
    )
]