import { Color, ColorSAP } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        systemMessageContainer: {
            boxSizing: "border-box",
            padding: "32px 40px 32px 40px",
            width: "100%",
            height: 475,
            backgroundColor: ColorSAP.neutral[15],
            borderRadius: 18
        },
        systemMessageListContainer: {
            maxHeight: "calc(100% - 48px)",
            marginTop: "24px",
            overflowY: "scroll"
        },
        systemMessageList: {
            padding: '12px 16px 12px 16px',
            boxSizing: 'border-box',
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            height: 65,
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: ColorSAP.neutral[10]
            }
        },
        systemMessageHeaderContainer: {
            display: "flex",
            justifyContent: "space-between"
        }
    })
);