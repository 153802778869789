import React from 'react';

import { useStyles } from './BackdropStyles';

import { Typography, FONT_FAMILY, Color, ColorSAP, Loading } from '@commsult/ontego-ui';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const GeneralBackdrop = props => {
    const classes = useStyles(props)
    const { open } = props

    return (
        <Backdrop
            style={{
                zIndex: 100000,
                background: `rgba(42, 46, 52, 1, 0.65)`,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                overflow: "hidden"
            }}
            open={open}
        >
            <div>
                <div
                    className={classes.loading}
                    style={{ marginTop: 320 }}
                >
                    <Loading />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                        size={18}
                        color={ColorSAP.neutral[0]}
                    >
                        {`Loading...`}
                    </Typography>
                </div>
            </div>
        </Backdrop>
    )
}

export default GeneralBackdrop