import React from 'react';
import { useHistory } from 'react-router-dom';
import Catch from "./functional-error-boundary"
import { Button } from '@commsult/ontego-ui';
import { SCENARIO_PATH } from '../../Constants/Path';

type Props = {
    children: React.ReactNode
}

const ErrorBoundary = Catch(function MyErrorBoundary(props: Props, error?: Error) {
    if (error) {
        return (
            <div style={{ width: "100%", textAlign: "center", marginTop: 46 }}>
                <div>
                    <svg width="874px" height="371px" viewBox="0 0 874 371" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <desc>Created with sketchtool.</desc>
                        <g id="404" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(-259.000000, -110.000000)" id="Group-2">
                                <g transform="translate(259.000000, 110.000000)">
                                    <g id="Group" transform="translate(0.000000, 166.500000)">
                                        <path d="M0,93 C1.53965225,63.4265836 25.8424726,40.2413118 55.3022805,40.2407571 C56.9853583,40.2407571 58.6473509,40.3289978 60.2921825,40.476766 C73.4572493,17.7092983 97.6720327,3.69562598 123.869293,3.68316832 C150.066554,3.67072726 174.294507,17.6613788 187.480983,40.4163238 C188.868654,40.3115469 190.265638,40.2407571 191.679926,40.2407571 C221.139734,40.2413118 245.442554,63.4265836 246.982206,93" id="Path" fill="#F2F2F2" fillRule="nonzero"></path>
                                        <path d="M11.0177936,89.3168317 C12.5574602,59.7434177 36.8602743,36.5581531 66.320074,36.5575916 C68.0031519,36.5575916 69.6651445,36.6458181 71.3099903,36.7936006 C84.4750564,14.0261333 108.689834,0.0124606125 134.88709,0 C161.084345,-0.0124440017 185.312296,13.9782025 198.498776,36.7331441 C199.886448,36.6283814 201.283432,36.5575916 202.697734,36.5575916 C232.157531,36.5581535 256.460341,59.74342 258,89.3168317" id="Path" stroke="#3F3D56"></path>
                                    </g>
                                    <g id="Group-Copy" transform="translate(687.000000, 100.000000)">
                                        <path d="M0,67 C1.10809147,45.7275176 18.5988904,29.0500977 39.8011857,29.0496987 C41.0125009,29.0496987 42.2086411,29.1131712 43.3924303,29.2194625 C52.867361,12.8425736 70.2947995,2.76238559 89.1490316,2.75342466 C108.003264,2.74447567 125.440181,12.8081046 134.930519,29.1759858 C135.92923,29.1006186 136.934643,29.0496987 137.952509,29.0496987 C159.154804,29.0500977 176.645603,45.7275176 177.753695,67" id="Path" fill="#F2F2F2" fillRule="nonzero"></path>
                                        <path d="M8.24630542,64.2465753 C9.35440719,42.9740947 26.8452016,26.2966799 48.0474912,26.2962761 C49.2588064,26.2962761 50.4549465,26.3597383 51.638746,26.4660399 C61.1136761,10.0891513 78.5411107,0.00896305508 97.3953392,0 C116.249568,-0.00895110674 133.686483,10.0546742 143.176824,26.4225529 C144.175535,26.347196 145.180948,26.2962761 146.198825,26.2962761 C167.401112,26.2966802 184.891903,42.9740964 186,64.2465753" id="Path" stroke="#3F3D56"></path>
                                    </g>
                                    <g id="undraw_page_not_found_su7k" transform="translate(108.000000, 0.500000)">
                                        <ellipse id="Oval" fill="#F2F2F2" fillRule="nonzero" cx="344.795576" cy="347.506203" rx="336.531501" ry="22.0347395"></ellipse>
                                        <path d="M332.14355,103.287841 C277.241096,103.287841 237.821716,138.484767 237.821716,220.610913 C237.821716,313.687211 277.241083,338.325062 332.14355,338.325062 C387.046018,338.325062 428.813673,311.340755 428.813673,220.610913 C428.813673,123.623844 387.046005,103.287841 332.14355,103.287841 Z M332.50445,307.42999 C294.477734,307.42999 274.611154,285.137792 274.611154,220.671018 C274.611154,163.788565 295.290966,133.791842 333.317714,133.791842 C371.344461,133.791842 392.024273,153.495538 392.024273,220.671018 C392.024273,283.512574 370.531166,307.42999 332.50445,307.42999 Z" id="Shape" fill="#005481"></path>
                                        <path d="M204.863142,273.042539 L182.200396,273.042539 L182.200396,206.376953 C182.200389,199.003442 176.216671,193.026033 168.835379,193.026026 L163.525057,193.026026 C159.98043,193.026024 156.58098,194.432636 154.07455,196.936423 C151.568119,199.44021 150.160021,202.836075 150.160021,206.376965 L150.160021,273.042539 L86.2362011,273.042539 C83.561407,273.042543 81.0819331,271.643346 79.7013095,269.354822 C78.3206858,267.066298 78.2405476,264.222678 79.4900974,261.860187 L147.146356,133.943344 C148.850849,130.720662 149.162286,126.941952 148.008388,123.484181 C146.85449,120.02641 144.335588,117.190227 141.036269,115.633855 L137.042351,113.749827 C130.601352,110.711451 122.906769,113.253137 119.548744,119.528342 L37.0330182,273.726904 C35.6278892,276.352689 34.8927614,279.28414 34.8927614,282.261551 L34.8927614,282.261551 C34.8927614,287.062947 36.8021219,291.66769 40.2008051,295.062789 C43.5994883,298.457888 48.2090918,300.365234 53.0155548,300.365232 L150.160021,300.365232 L150.160021,342.520297 C150.160021,346.76463 151.847855,350.835126 154.852219,353.836322 C157.856582,356.837519 161.931374,358.523573 166.180186,358.523573 L166.180218,358.523573 C170.42903,358.523573 174.503822,356.837519 177.508185,353.836322 C180.512549,350.835126 182.200383,346.76463 182.200383,342.520297 L182.200383,300.365258 L204.86311,300.365258 C212.416026,300.365258 218.538874,294.248865 218.538874,286.703911 L218.538874,286.703911 C218.538874,279.15896 212.416055,273.042557 204.863142,273.042539 Z" id="Path" fill="#00A7DD"></path>
                                        <path d="M624.494538,273.042539 L601.831808,273.042539 L601.831808,206.376953 C601.831801,199.003435 595.84807,193.026023 588.46677,193.026026 L583.156446,193.026026 C579.611819,193.026024 576.212369,194.432637 573.705938,196.936424 C571.199507,199.440211 569.791408,202.836075 569.791408,206.376966 L569.791408,273.042539 L505.867577,273.042539 C503.192781,273.042546 500.713304,271.643351 499.332679,269.354825 C497.952055,267.0663 497.871918,264.222678 499.121472,261.860187 L566.777737,133.94337 C568.482237,130.720686 568.793678,126.941971 567.639781,123.484195 C566.485884,120.026418 563.966978,117.19023 560.667655,115.633855 L556.673736,113.749828 C550.232734,110.711451 542.538148,113.253137 539.180119,119.528343 L456.664386,273.726904 C455.259257,276.352689 454.524129,279.284141 454.524129,282.261551 L454.524129,282.261551 C454.524129,292.259938 462.637978,300.365232 472.646919,300.365232 L569.791408,300.365232 L569.791408,342.520297 C569.791408,351.358663 576.963882,358.523573 585.811576,358.523573 L585.811576,358.523573 C594.659271,358.523573 601.831744,351.358663 601.831744,342.520297 L601.831744,300.365258 L624.494475,300.365258 C632.047392,300.365258 638.170241,294.248865 638.170241,286.703911 L638.170241,286.703911 C638.170241,279.158973 632.047441,273.042574 624.494538,273.042539 L624.494538,273.042539 Z" id="Path" fill="#00A7DD"></path>
                                        <path d="M211.29075,265.69762 L188.628017,265.69762 L188.628017,199.032052 C188.628019,195.491161 187.219923,192.095296 184.713493,189.591508 C182.207064,187.08772 178.807616,185.681107 175.262988,185.681107 L169.952666,185.681107 C162.571361,185.681107 156.587631,191.658528 156.587631,199.032052 L156.587631,265.69762 L92.6638133,265.69762 C89.9890192,265.697624 87.5095454,264.298427 86.1289218,262.009903 C84.7482982,259.72138 84.66816,256.877759 85.9177098,254.515269 L153.57396,126.598475 C155.278464,123.375789 155.589907,119.597072 154.43601,116.139292 C153.282113,112.681513 150.763206,109.845322 147.46388,108.288948 L143.469961,106.404921 C137.028969,103.366539 129.334387,105.90821 125.976348,112.183403 L43.4606321,266.381991 C42.0555031,269.007778 41.3203753,271.939232 41.3203753,274.916644 L41.3203753,274.916644 C41.3203753,284.91503 49.4342232,293.020324 59.4431618,293.020324 L156.587631,293.020324 L156.587631,335.175385 C156.587631,339.419718 158.275465,343.490213 161.279828,346.491409 C164.284192,349.492606 168.358984,351.17866 172.607795,351.17866 L172.607827,351.17866 C181.45552,351.17866 188.627992,344.01375 188.627992,335.175385 L188.627992,293.020349 L211.290724,293.020349 C214.917762,293.020349 218.396246,291.581033 220.960949,289.019034 C223.525653,286.457035 224.966488,282.982218 224.966488,279.359004 L224.966488,279.359004 C224.966488,275.735787 223.525671,272.260965 220.960972,269.698958 C218.396273,267.136951 214.91779,265.697627 211.29075,265.69762 L211.29075,265.69762 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M636.431536,265.69762 L613.768805,265.69762 L613.768805,199.032052 C613.768805,191.658528 607.785074,185.681107 600.403767,185.681107 L595.093444,185.681107 C591.548815,185.681107 588.149366,187.08772 585.642935,189.591508 C583.136504,192.095296 581.728406,195.491161 581.728406,199.032052 L581.728406,265.69762 L517.804575,265.69762 C515.12978,265.697624 512.650306,264.298427 511.269682,262.009903 C509.889058,259.72138 509.80892,256.877759 511.05847,254.515269 L578.714734,126.598475 C580.419238,123.375789 580.730682,119.597072 579.576785,116.139292 C578.422887,112.681513 575.903979,109.845322 572.604652,108.288948 L568.610733,106.404921 C562.16974,103.366539 554.475156,105.90821 551.117117,112.183403 L468.601383,266.381991 C467.196254,269.007778 466.461126,271.939232 466.461126,274.916644 L466.461126,274.916644 C466.461126,284.91503 474.574976,293.020324 484.583916,293.020324 L581.728406,293.020324 L581.728406,335.175385 C581.728406,344.01375 588.900879,351.17866 597.748573,351.17866 L597.748573,351.17866 C606.596268,351.17866 613.768741,344.01375 613.768741,335.175385 L613.768741,293.020349 L636.431472,293.020349 C640.058511,293.020349 643.536996,291.581033 646.101699,289.019034 C648.666403,286.457035 650.107239,282.982218 650.107239,279.359004 L650.107239,279.359004 C650.107239,271.814061 643.984443,265.697655 636.431536,265.69762 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M344.080548,96.8610422 C289.178093,96.8610422 249.758713,132.057962 249.758713,214.184114 C249.758713,307.260412 289.17808,331.898263 344.080548,331.898263 C398.983015,331.898263 440.75067,304.913956 440.75067,214.184114 C440.75067,117.197038 398.983003,96.8610422 344.080548,96.8610422 Z M344.441479,301.003185 C306.414763,301.003185 286.548183,278.710993 286.548183,214.244219 C286.548183,157.361766 307.227995,127.365043 345.254743,127.365043 C383.281491,127.365043 403.961303,147.068739 403.961303,214.244219 C403.961303,277.085769 382.468163,301.003185 344.441447,301.003185 L344.441479,301.003185 Z" id="Shape" stroke="#3F3D56"></path>
                                        <path d="M340.036985,0.459057072 C339.939193,0.459057072 339.842297,0.463079327 339.745308,0.466698075 C344.031657,0.628209419 347.426027,4.27654415 347.426027,8.72208437 C347.426027,13.1676246 344.031657,16.8159593 339.745308,16.9774707 C339.842291,16.9810894 339.939187,16.9851117 340.036985,16.9851117 C344.440019,16.9851117 348.009383,13.2856283 348.009383,8.72208437 C348.009383,4.1585404 344.440019,0.459057072 340.036985,0.459057072 L340.036985,0.459057072 Z" id="Path" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                                        <path d="M642.347496,264.992257 C647.660793,266.923823 653.614579,265.28867 657.195968,260.91426 C660.777358,256.53985 661.20505,250.380484 658.262614,245.553082 C655.320177,240.725679 649.649521,238.283365 644.120255,239.462062 L644.45345,246.313694 L641.211789,240.441784 C638.030277,241.936671 635.572423,244.633519 634.378347,247.939686 C633.954284,249.097792 633.696487,250.310151 633.612645,251.540601 C633.192195,257.472329 636.757712,262.963218 642.347496,264.992257 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M658.112323,343.833747 C656.973526,338.01331 661.908164,332.853316 666.782459,329.499342 C671.656754,326.145368 677.3521,322.849935 679.015204,317.158055 C681.405388,308.977823 674.285759,301.486267 668.744326,295.025868 C664.633113,290.231519 661.102545,284.964982 658.226504,279.336466 C657.073697,277.079596 656.014181,274.720325 655.71079,272.202482 C655.273867,268.576502 656.434484,264.968302 657.603777,261.509595 C661.499045,249.987605 665.663764,238.562157 670.097934,227.233251" id="Path" stroke="#3F3D56"></path>
                                        <path d="M642.347497,263.156028 C647.660793,265.087595 653.614579,263.452442 657.195968,259.078032 C660.777358,254.703621 661.20505,248.544256 658.262614,243.716853 C655.320177,238.889451 649.649521,236.447137 644.120255,237.625833 L644.45345,244.477466 L641.211783,238.605556 C638.030274,240.100444 635.572422,242.797291 634.378348,246.103457 C633.954285,247.261564 633.696487,248.473923 633.612645,249.704373 C633.192193,255.636102 636.757711,261.126992 642.347497,263.156028 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M656.415916,224.986667 C657.96152,220.722061 661.574117,217.539299 665.9995,216.543361 L667.095802,223.058524 L669.128324,216.215881 C674.918848,216.301752 679.989683,220.120993 681.67095,225.662709 C683.352218,231.204425 681.258232,237.197338 676.491511,240.48608 C671.724791,243.774821 665.378828,243.604949 660.794879,240.065903 C656.210931,236.526858 654.44056,230.430501 656.415897,224.986667 L656.415916,224.986667 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M657.334147,223.150439 C658.879753,218.885836 662.49235,215.703077 666.91773,214.707139 L668.014032,221.222302 L670.046555,214.379653 C675.837078,214.465524 680.907914,218.284765 682.589181,223.82648 C684.270448,229.368196 682.176462,235.36111 677.409742,238.649851 C672.643021,241.938593 666.297059,241.76872 661.71311,238.229675 C657.129161,234.690629 655.35879,228.594273 657.334128,223.150439 L657.334147,223.150439 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M666.232132,295.291837 C672.593102,297.599186 679.672912,294.766645 682.686901,288.708496 C685.70089,282.650346 683.689659,275.294993 678.012432,271.613313 L676.451099,276.870091 L675.343966,270.280095 C675.33302,270.27493 675.321686,270.270632 675.310068,270.26724 C670.83901,268.642317 665.840631,269.527123 662.199083,272.58813 C658.557535,275.649137 656.826514,280.420917 657.658532,285.104739 C658.49055,289.78856 661.7591,293.672248 666.232094,295.291837 L666.232132,295.291837 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M667.150363,292.537495 C673.511333,294.844844 680.591143,292.012303 683.605132,285.954153 C686.619121,279.896004 684.607889,272.54065 678.930662,268.858971 L677.36933,274.115749 L676.262196,267.525753 C676.25125,267.520588 676.239917,267.51629 676.228299,267.512897 C671.75724,265.887974 666.758862,266.772781 663.117313,269.833788 C659.475765,272.894794 657.744744,277.666575 658.576763,282.350397 C659.408781,287.034218 662.677331,290.917906 667.150324,292.537495 L667.150363,292.537495 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M653.066524,317.217783 C654.156,323.417245 659.438595,328.006326 665.729334,328.218182 C672.020072,328.430037 677.59943,324.206759 679.103506,318.094641 C680.607583,311.982522 677.625252,305.652264 671.954616,302.920497 C666.283979,300.18873 659.475063,301.802161 655.63309,306.788023 L661.567126,315.719732 L653.459011,310.97907 C652.830334,312.997176 652.695727,315.136795 653.066524,317.217783 L653.066524,317.217783 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M653.066526,315.381555 C654.156002,321.581017 659.438597,326.170097 665.729335,326.381953 C672.020073,326.593809 677.59943,322.370531 679.103506,316.258413 C680.607583,310.146294 677.625252,303.816036 671.954616,301.084269 C666.283981,298.352501 659.475065,299.965933 655.633092,304.951795 L661.567127,313.88351 L653.459013,309.142842 C652.830333,311.160947 652.695726,313.300567 653.066526,315.381555 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M5.74479214,296.484595 C9.22591294,297.750133 13.1266813,296.67884 15.473119,293.812843 C17.8195566,290.946845 18.099771,286.911381 16.17196,283.748592 C14.244149,280.585803 10.5288787,278.985674 6.90625642,279.757948 L7.12456195,284.246946 L5.00071514,280.399836 C2.9162808,281.379251 1.30596833,283.146158 0.523649873,285.312273 C0.245815684,286.071034 0.0769135774,286.86534 0.021981499,287.671499 C-0.253461435,291.557781 2.08255248,295.15523 5.74479214,296.484595 L5.74479214,296.484595 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M16.3234967,348.424318 C15.5788959,344.620412 18.8053937,341.248156 21.992427,339.056161 C25.1794602,336.864165 28.9033383,334.71049 29.9907614,330.990599 C31.5535773,325.64446 26.8984178,320.748408 23.2751838,316.526255 C20.587085,313.39294 18.2786404,309.951029 16.3981563,306.272549 C15.5806065,304.827666 15.0237617,303.249319 14.7532629,301.610186 C14.4675724,299.240451 15.2264577,296.88234 15.9909871,294.621919 C18.5378898,287.091796 21.2609726,279.624771 24.1602354,272.220844" id="Path" stroke="#3F3D56"></path>
                                        <path d="M5.74480794,295.566489 C9.22592836,296.832016 13.12669,295.760718 15.4731228,292.894723 C17.8195556,290.028727 18.0997696,285.99327 16.1719633,282.830484 C14.244157,279.667698 10.5288941,278.067565 6.90627357,278.839829 L7.12457936,283.328837 L5.00072369,279.481693 C2.91628649,280.461107 1.30597195,282.228016 0.523653197,284.394132 C0.24582167,285.152893 0.0769194481,285.947197 0.0219842373,286.753354 C-0.253477288,290.639653 2.08254662,294.237125 5.74480794,295.566489 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M15.2153462,270.622324 C16.2279856,267.828265 18.5948656,265.743002 21.494261,265.09049 L22.2125273,269.359055 L23.5441976,264.875931 C27.337986,264.932197 30.6602516,267.43446 31.7617681,271.065237 C32.8632845,274.696015 31.4913628,278.622403 28.3683417,280.777094 C25.2453207,282.931786 21.0876247,282.820493 18.0843477,280.501813 C15.0810707,278.183133 13.9211677,274.188973 15.2153462,270.622324 L15.2153462,270.622324 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M15.2153485,269.704203 C16.2279897,266.910147 18.5948694,264.824887 21.4942633,264.172376 L22.2125296,268.440941 L23.5441999,263.957816 C27.3379887,264.014084 30.6602539,266.516348 31.7617691,270.147127 C32.8632842,273.777905 31.4913605,277.704293 28.368338,279.858983 C25.2453155,282.013673 21.0876191,281.902378 18.0843433,279.583695 C15.0810674,277.265013 13.9211671,273.270852 15.2153485,269.704203 L15.2153485,269.704203 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M21.3626705,316.684321 C25.5298535,318.196004 30.1679732,316.340264 32.1424957,312.371247 C34.1170182,308.402231 32.799419,303.583344 29.0801579,301.171287 L28.0573021,304.615273 L27.3320012,300.297828 C27.3248347,300.294447 27.3174145,300.291633 27.3098085,300.28941 C24.3805833,299.224183 21.1055529,299.80355 18.7195064,301.809071 C16.3334598,303.814592 15.199286,306.941253 15.7446038,310.010203 C16.2899217,313.079153 18.431795,315.623644 21.3626705,316.684321 L21.3626705,316.684321 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M22.2780974,315.766205 C26.4462796,317.277893 31.0855113,315.422147 33.0605072,311.453117 C35.0355031,307.484088 33.717588,302.665185 29.9974351,300.25312 L28.9743341,303.697118 L28.2488594,299.379653 C28.2416911,299.376271 28.2342691,299.373457 28.2266613,299.371234 C23.6984517,297.728897 18.6959812,300.067659 17.0533307,304.595005 C15.4106801,309.122351 17.7498879,314.123868 22.2780974,315.766205 L22.2780974,315.766205 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M12.9892783,331.112925 C13.703081,335.174641 17.1640973,338.181273 21.2856154,338.320067 C25.4071336,338.458861 29.0625682,335.691877 30.0479891,331.687384 C31.0334099,327.68289 29.0794598,323.535483 25.3642119,321.745713 C21.6489641,319.955942 17.187953,321.013029 14.6708061,324.279634 L18.5586195,330.131449 L13.2464084,327.025486 C12.8345214,328.347696 12.7463364,329.749519 12.9892783,331.112925 L12.9892783,331.112925 Z" id="Path" fill="#57B894" fillRule="nonzero"></path>
                                        <path d="M12.9892806,330.194811 C13.7030832,334.256527 17.164099,337.263159 21.2856166,337.401953 C25.4071342,337.540746 29.0625684,334.773763 30.0479891,330.76927 C31.0334098,326.764776 29.0794599,322.617369 25.3642126,320.827599 C21.6489652,319.037828 17.1879547,320.094915 14.6708081,323.36152 L18.5586211,329.213334 L13.2464043,326.107371 C12.8345195,327.429583 12.7463367,328.831405 12.9892806,330.194811 L12.9892806,330.194811 Z" id="Path" stroke="#3F3D56"></path>
                                        <path d="M345.254692,232.191174 C345.259717,233.057059 344.963134,233.892431 344.425416,234.526781 C343.911996,235.141127 343.187854,235.492568 342.427771,235.496278 L336.145868,235.496278 C334.534435,235.434791 333.271136,233.957766 333.319011,232.191174 C333.315733,231.519844 333.495072,230.862676 333.834127,230.303588 C335.224274,230.089145 336.626599,229.983606 338.030439,229.987771 C340.38453,229.979272 342.731323,230.275102 345.022197,230.869132 C345.177254,231.28909 345.256201,231.738131 345.254692,232.191174 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
                                        <ellipse id="Oval" fill="#FFFFFF" fillRule="nonzero" cx="308.984584" cy="193.722084" rx="3.21380697" ry="3.2133995"></ellipse>
                                        <ellipse id="Oval" fill="#FFFFFF" fillRule="nonzero" cx="368.669571" cy="193.722084" rx="3.21380697" ry="3.2133995"></ellipse>
                                    </g>
                                    <g id="Group-Copy-2" transform="translate(450.000000, 77.500000)">
                                        <path d="M0,46 C0.763490761,31.3783207 12.8148996,19.9150659 27.4235823,19.9147916 C28.2581956,19.9147916 29.0823532,19.9584196 29.8980008,20.0314791 C36.4263626,8.7747948 48.4341152,1.84615931 61.4249489,1.84 C74.4157826,1.83384889 86.4300661,8.75110246 92.969044,20.0015953 C93.6571698,19.9497915 94.3499136,19.9147916 95.0512379,19.9147916 C109.659921,19.9150659 121.711329,31.3783207 122.47482,46" id="Path" fill="#F2F2F2" fillRule="nonzero"></path>
                                        <path d="M5.52517986,44.16 C6.28867771,29.5383219 18.3400835,18.0750706 32.9487621,18.074793 C33.7833755,18.074793 34.607533,18.1184139 35.4231877,18.1914805 C41.9515491,6.93479646 53.9592991,0.00616077215 66.9501303,0 C79.9409615,-0.00615255944 91.9552438,6.91109851 98.4942239,18.1615896 C99.1823496,18.1097929 99.8750935,18.074793 100.576425,18.074793 C115.185102,18.0750708 127.236506,29.538323 128,44.16" id="Path" stroke="#3F3D56"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

                <a href="./" style={{ textDecoration: "none" }}>
                    <Button
                        style={{ marginTop: 56 }}
                    >
                        {`Go Back`}
                    </Button>
                </a>
            </div>
        )
    } else {
        return <React.Fragment>{props.children}</React.Fragment>
    }
})

export default ErrorBoundary;