import React, { useState, useContext, useCallback, useEffect } from 'react'
import { OntegoSAPLogoDefault, Typography, FONT_FAMILY, Color, ColorSAP } from '@commsult/ontego-ui'
import { useHistory } from "react-router-dom";

import { AppContext } from '../../App'
import { useStyles } from './LoginStyles';

import Grid from '@material-ui/core/Grid';

import LoginImage from "../../assets/img/login/login.jpg";

import moment from 'moment';
import { SCENARIO_PATH, ADMIN_DASHBOARD } from '../../Constants/Path';
import { timeout } from '../../Utils/Utils';
import { ROLE_ADMIN } from '../../Constants/ClientRoles';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import Button from '../../ReusableComponents/Button/Button';
import Form from '../../ReusableComponents/Form/Form';
import { LogoDark } from '../../assets/img/logo/logoDark';
import Play from '../../Icons/Play';
import VideoDialog from '../../Dialogs/VideoDialog/VideoDialog';

const Login = props => {
    const classes = useStyles(props);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(" ");

    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

    const { t } = useTranslation();

    /** Automated greetings based on local system time */
    const getGreetingTime = date => {

        let greetings = "";

        if (!date || !date.isValid()) {
            return;
        }

        const split_afternoon = 12;
        const split_evening = 17;
        const currentHour = parseFloat(date.format("HH"));

        if (currentHour >= split_afternoon
            && currentHour <= split_evening
        ) {
            greetings = t("login.greetAfternoon");
        }
        else if (currentHour >= split_evening) {
            greetings = t("login.greetEvening");
        }
        else {
            greetings = t("login.greetMorning");
        }

        return greetings;
    };

    const {
        setIsLoginLoading,
        AuthService,
        setUserInfo,
        handleShowErrorSnackbar
    } = useContext(AppContext);

    const handleChangeEmail = e => {
        setEmail(e.currentTarget.value)
        setEmailError(false)
        setErrorMessage(" ")
    };

    const handleChangePassword = e => {
        setPassword(e.currentTarget.value)
        setPasswordError(false)
        setErrorMessage(" ")
    };

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;

        if (keyCode === 13) {
            if (document.getElementById('loginButton')) document.getElementById('loginButton').click()
        }
    }, []);

    const handlePlayVideo = () => {
        setIsVideoDialogOpen(true)
    }

    const handleCloseVideo = () => {
        setIsVideoDialogOpen(false)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const login = async e => {
        e.preventDefault();
        setIsLoginLoading(true)

        if (email === "" || password === "") {
            setEmailError(true)
            setPasswordError(true)
            setErrorMessage(t("error.emailPasswordCantEmpty"))
            setIsLoginLoading(false)
            return;
        }

        const credentials = { email: email, password: password, name: "NAME TEST" };
        AuthService.login(credentials).then(res => {
            if (res.data.login) {
                localStorage.setItem("userInfo", JSON.stringify(res.data.token));

                AuthService.setUserToken(res.data.token);

                const decoded = jwtDecode(res.data.token);

                // Set current user
                setUserInfo(decoded);
                setIsLoginLoading(false)

                if (decoded.role === ROLE_ADMIN) {
                    history.push(ADMIN_DASHBOARD)
                } else {
                    history.push(SCENARIO_PATH)
                }
            } else {
                if (res.data.msg) {
                    console.log(res.data.msg)
                    setErrorMessage(res.data.msg);
                } else {
                    setErrorMessage(t("error.emailPasswordWrong"));
                }

                setEmail("")
                setPassword("")
                setIsLoginLoading(false)
            }
        }, error => {
            console.log(error)
            setEmail("")
            setPassword("")
            setErrorMessage(t("error.internalErrorLogin"));
            setIsLoginLoading(false)
        }).catch(err => {
            console.log(err);
            handleShowErrorSnackbar(err);
        });
        await timeout(2000)
        setIsLoginLoading(false)
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                // border: '1px solid red',
                display: 'flex',
                // alignItems: 'center',
                justifyContent: 'center'
                // boxSizing: 'border-box',
            }}
        >
            <Grid container style={{ flexGrow: 1, width: "100%", height: "100%" }}>
                <Grid item md={6}>
                    <div id="loginContainer" className={classes.loginContainer}>
                        <Grid container style={{ flexGrow: 0 }}>
                            <Grid item md={12}>
                                <LogoDark
                                    style={{
                                        width: 158,
                                        height: 64,
                                        marginTop: 80.5,
                                        marginBottom: 63.5
                                    }}
                                />
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                    size={56}
                                    color={ColorSAP.neutral[100]}
                                    style={{ marginBottom: 40 }}
                                >
                                    {/* {getGreetingTime(moment())} */}
                                    {`Hallo!`}
                                </Typography>

                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                    size={14}
                                    color={ColorSAP.neutral[100]}
                                    style={{ marginBottom: 20, width: 408 }}
                                >
                                    {/* {getGreetingTime(moment())} */}
                                    {`Entdecken Sie das Ontego Cockpit mit Ihrem Zugang zu über 120 Vorlagen und Funktionen für mobile Apps mit SAP.`}
                                </Typography>

                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                    size={14}
                                    color={ColorSAP.neutral[100]}
                                    style={{ marginBottom: 40, width: 408 }}
                                >
                                    {/* {getGreetingTime(moment())} */}
                                    <span>
                                        {`Bevor Sie starten,`}
                                    </span>
                                    <span
                                        style={{ color: ColorSAP.primary[70], cursor: 'pointer' }}
                                        onClick={handlePlayVideo}
                                    >
                                        {` informieren Sie sich gern im Video über unsere Arbeitsweise.`}
                                    </span>
                                </Typography>
                            </Grid>

                            <Grid item md={12} style={{ marginBottom: 24 }}>
                                <div tabIndex={1}>
                                    <Form
                                        placeholder={`${t('common.email')}`}
                                        label={`${t('common.email')} *`}
                                        onChange={handleChangeEmail}
                                        value={email}
                                        error={emailError}
                                    />
                                </div>
                            </Grid>

                            <Grid item md={12} style={{ marginBottom: 48 }}>
                                <div tabIndex={2}>
                                    <Form
                                        placeholder={`${t('common.password')}`}
                                        label={`${t('common.password')} *`}
                                        onChange={handleChangePassword}
                                        isPassword
                                        value={password}
                                        error={passwordError}
                                    />
                                </div>
                            </Grid>

                            <Grid item md={12}>
                                {errorMessage !== "" && (
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                        size={12}
                                        color={ColorSAP.danger[50]}
                                        style={{ marginBottom: 40, width: 408 }}
                                    >
                                        {/* {getGreetingTime(moment())} */}
                                        {errorMessage}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item md={12}>
                                <div tabIndex={3} style={{ marginBottom: 40 }}>
                                    <Button
                                        id="loginButton"
                                        // alignIcon="right"
                                        type="primary"
                                        theme="dark"
                                        size="medium"
                                        onClick={login}
                                    >
                                        {t("login.login")}
                                    </Button>
                                </div>
                            </Grid>
                            {/*<div className={classes.loginBottomAction}>
                                <div style={{ display: "flex", marginBottom: 15 }}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                                        size={14}
                                        color={Color.neutral[600]}
                                    >
                                        {`Don't have an account yet?`}
                                    </Typography>
                                    <Link
                                        to={REGISTER_PATH} style={{ textDecoration: "none" }}
                                    >
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                            size={14}
                                            color={Color.primary[600]}
                                            style={{ marginLeft: 10, cursor: "pointer" }}
                                        >
                                            {`Register here`}
                                        </Typography>
                                    </Link>
                                </div>
                            </div>*/}
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url(${LoginImage})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: 'center'
                        }}
                    >
                        <div
                            style={{
                                padding: 24,
                                width: 80,
                                height: 80,
                                borderRadius: 240,
                                backgroundColor: ColorSAP.primary[70],
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }}
                            onClick={handlePlayVideo}
                        >
                            <Play style={{ width: 80, height: 80, color: ColorSAP.neutral[0] }} />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <VideoDialog
                open={isVideoDialogOpen}
                onClose={handleCloseVideo}
            />
        </div>
    )
}

export default Login;