import React from 'react';

import { useStyles } from './BackdropStyles';

import { Typography, FONT_FAMILY, Color, ColorSAP, Loading } from '@commsult/ontego-ui';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginBackdrop = props => {
    const classes = useStyles(props)
    const { open } = props

    return (
        <Backdrop
            style={{
                zIndex: 100000,
                background: `rgba(42, 46, 52, 1, 0.65)`,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                overflow: "hidden"
            }}
            open={open}
        >
            <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={56}
                        color={ColorSAP.neutral[0]}
                        style={{ marginBottom: 40, marginTop: 200 }}
                    >
                        {`Welcome`}
                    </Typography>
                </div>
                <div className={classes.loading}>
                    <Loading />
                </div>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={18}
                    color={ColorSAP.neutral[0]}
                    // style={{ marginBottom: 24 }}
                >
                    {`Logging In`}
                </Typography>
            </div>
        </Backdrop>
    )
}

export default LoginBackdrop