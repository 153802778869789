// React libraries
import React from 'react';
import classnames from 'classnames';

// Component Style
import { useStyles } from "./RadioStyles";

export const Radio = (props) => {
    const classes = useStyles(props);
    const { id, type, name, value, label, disabled, style, checked, onChange } = props;

    const labelClasses = classnames({
        [classes.label]: true,
        [classes[type]]: type,
        [classes.disabled]: disabled
    });

    return (
        <div style={style} id={id}>
            <input type="radio" className={classes.input} name={name} id={`${name}-${value}`} value={value} disabled={disabled} onChange={onChange} checked={checked} />
            <label className={labelClasses} htmlFor={`${name}-${value}`}>{label}</label>
        </div>
    );
};

Radio.defaultProps = {
    type: "n900",
    disabled: false,
    onChange: () => { }
};

export default React.memo(Radio);