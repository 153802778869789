// React Lib
import React from "react";

import classNames from "classnames";

// Component Styles
import { useStyles } from "./TertiaryButtonStyles";

import { FONT_FAMILY } from "@commsult/ontego-ui";
import ArrowHeadSpecial from "../../Icons/ArrowHeadSpecial";
import { fontSizeTertiaryButtonIcon, iconSizeTertiaryButtonIcon } from "../../Utils/Utils";

const TertiaryButton = props => {
    const { id, style, size, theme, onClick, noIcon, isIconBeforeText = false, icon = (<ArrowHeadSpecial style={{ marginLeft: 8, width: iconSizeTertiaryButtonIcon(size), height: iconSizeTertiaryButtonIcon(size) }} />) } = props

    const classes = useStyles(props)

    // const fontSize = size => {
    //     switch (size) {
    //         case 'xsmall':
    //             return 14
    //         case 'small':
    //             return 14
    //         case 'medium':
    //             return 18
    //         case 'large':
    //             return 24
    //     }
    // }

    // const iconSize = size => {
    //     switch (size) {
    //         case 'xsmall':
    //             return 14
    //         case 'small':
    //             return 16
    //         case 'medium':
    //             return 22
    //         case 'large':
    //             return 38
    //     }
    // }

    const buttonClasses = classNames({
        [classes.container]: true,
        [classes.container__dark]: theme === 'dark',
        [classes.container__light]: theme === 'light',
        [classes.container__primary]: theme === 'primary'
    })

    const arrowContainerClasses = classNames({
        [classes.arrowContainer]: true,
        [classes.arrowContainer__small]: size === "small" || size === "xsmall",
        [classes.arrowContainer__medium]: size === "medium",
        [classes.arrowContainer__large]: size === "large",
        [classes.arrowContainer__dark]: theme === 'dark',
        [classes.arrowContainer__light]: theme === 'light'
    })

    return (
        <div
            id={id}
            className={buttonClasses}
            style={{
                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD,
                fontSize: fontSizeTertiaryButtonIcon(size),
                ...style
            }}
            onClick={onClick}
        >
            {!noIcon && isIconBeforeText && (
                <div
                    className={arrowContainerClasses}
                >
                    {icon}
                </div>
            )}

            {props.children}

            {!noIcon && !isIconBeforeText && (
                <div
                    className={arrowContainerClasses}
                >
                    {icon}
                </div>
            )}

        </div>
    )
}

export default TertiaryButton