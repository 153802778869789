import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import ErrorBoundary from './Pages/Error/ErrorBoundary'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';

import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
    disableGlobal: true
});

ReactDOM.render(
    <StylesProvider generateClassName={generateClassName}>
        <Router>
            {/* <ErrorBoundary> */}
                <App />
            {/* </ErrorBoundary> */}
        </Router>
    </StylesProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
