// Material-UI Styles
// Constants
import { ColorSAP } from "@commsult/ontego-ui";
import { createStyles, makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        carouselCompoundContainer: {
            paddingLeft: 16,
            backgroundColor: 'transparent',
            borderRadius: 16,
            display: "inline-flex",
            alignItems: "center",
            height: 8,
        },
        carouselContainer: {
            border: `1px solid ${ColorSAP.primary[40]}`,
            borderRadius: "50%",
            width: 8,
            height: 8,
            marginRight: 16,
            transition: "background-color 0.2s ease",
            "&:hover": {
                backgroundColor: ColorSAP.primary[60],
                transition: "background-color 0.2s ease",
            },
            cursor: "pointer"
        },

        carouselActive: {
            cursor: "pointer",
            border: `1px solid ${ColorSAP.primary[70]}`,
            backgroundColor: ColorSAP.primary[70],
            "&:hover": {
                backgroundColor: ColorSAP.primary[70],
                transition: "none"
            }
        },
    })
);