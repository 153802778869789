// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from "@commsult/ontego-ui";

export const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            width: 57,
            height: 22,
            border: `1px solid ${ColorSAP.neutral[0]}`,
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center'
        }
    })
);