import axios from 'axios';

import { USER_API_BASE_FUNCTION_NOTES_URL, USER_API_BASE_FUNCTION_URL, getApiUrl } from '../../Constants/Api';

export class FunctionService {
    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        return { headers: { "Authorization": this.getUserInfo() } };
    }

    getFunctionData(id) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_FUNCTION_URL}/${id}`, this.getAuthHeader())
    }

    getFunctionNotes(key) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_FUNCTION_NOTES_URL}/${key}`, this.getAuthHeader())
    }

    getFunctionNotesFromClientKeyAndFunctionId(key, functionId) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_FUNCTION_NOTES_URL}/${key}?functionId=${functionId}`, this.getAuthHeader())
    }

    createNewFunctionNotes(key, functionNotes) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_FUNCTION_NOTES_URL}/${key}`, { functionNotes }, this.getAuthHeader())
    }

    deleteFunctionNote(key, functionNotes) {
        console.log(functionNotes)
        return axios.delete(`${getApiUrl()}${USER_API_BASE_FUNCTION_NOTES_URL}/${key}`, { data: functionNotes });
    }
}

export default new FunctionService();