import React, { useState, useEffect, useRef } from 'react';
// import draft js
import { EditorState, ContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin, { Separator } from "@draft-js-plugins/static-toolbar";
//import styles
import 'draft-js/dist/Draft.css';
import './editorStyles.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import buttonStyles from './buttonStyles.module.css';
import toolbarStyles from './toolbarStyles.module.css';
// import icons
// import buttons
import {
  BoldButton,
  ItalicButton,
  UnorderedListButton,
  OrderedListButton,
  UnderlineButton
} from '@draft-js-plugins/buttons';
import { Color, ColorSAP, FONT_FAMILY, Typography } from '@commsult/ontego-ui';
import TertiaryButton from '../TertiaryButton/TertiaryButton';
import Button from '../Button/Button';
import { Delete, Edit } from '@commsult/ontego-ui/dist/sap_icons';
import Tooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

// import { FONT_FAMILY } from '@commsult/ontego-ui';

const EditorComponent = (props) => {
  const {
    handleSaveChange,
    handleDeleteNotes,
    editorState,
    setEditorState,
    isEdit,
    setIsEdit,
    setShowEditor
  } = props
  const editor = useRef(null);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { t } = useTranslation()

  const [oldEditorState, setOldEditorState] = useState(EditorState.createEmpty())
  const [tempState, setTempState] = useState(EditorState.createEmpty())

  const [{ plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin];
    return {
      plugins,
      Toolbar
    };
  });

  const editorRef = useRef(null);

  useEffect(() => {
    setTempState(editorState)
    setOldEditorState(editorState)
  }, [editorState])

  const onChange = (tempState) => {
    setTempState(tempState);
  };

  const handleCancel = () => {
    setIsEdit(false);

    // console.log("Old Editor has text: " + oldEditorState.getCurrentContent().hasText())
    // console.log("Old Editor has white space: " + oldEditorState.getCurrentContent().getPlainText() === '')

    // console.log("New Editor has text: " + tempState.getCurrentContent().hasText())
    // console.log("New Editor has white space: " + tempState.getCurrentContent().getPlainText() === '')

    if (!oldEditorState.getCurrentContent().hasText()) {
      setShowEditor(false)
    }
  }

  const handleSave = () => {
    setEditorState(tempState);
    const newEditorState = EditorState.push(
      tempState,
      ContentState.createFromText(""),
      'remove-range'
    );
    setTempState(newEditorState);
    setIsEdit(false);

    handleSaveChange(tempState)
  }

  return (
    <div>
      {isEdit ? (
        <>
          <Toolbar className="toolbar">
            {
              (externalProps) => (
                <div style={{ backgroundColor: 'transparent !important' }}>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                </div>
              )
            }
          </Toolbar>
          <div style={{ padding: "8px 16px 8px 16px", backgroundColor: ColorSAP.neutral[15], borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
            <div className='editor' onClick={() => editorRef.current && editorRef.current.focus()}>
              <Editor
                editorState={tempState}
                onChange={onChange}
                plugins={plugins}
                ref={editor}
                readOnly={!isEdit}
                placeholder={t('dialogs.functionNote')}
                style={{ fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR, fontSize: 14, color: ColorSAP.neutral[100] }}
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                <div style={{ marginBottom: "10px" }}>
                  <TertiaryButton
                    theme="light"
                    size="small"
                    onClick={handleCancel}
                    noIcon={true}
                  >
                    {t('manage.cancel')}
                  </TertiaryButton>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <Button
                    type="primary"
                    theme="dark"
                    size="small"
                    onClick={handleSave}
                  >
                    {t('manage.saveChanges')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ padding: 16, backgroundColor: ColorSAP.neutral[15], borderRadius: 8 }}>
          <div className="editorReadOnly" style={{ backgroundColor: ColorSAP.neutral[15], fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR, fontSize: 14, color: ColorSAP.neutral[100] }}>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 16 }}>
              <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                size={14}
                color={ColorSAP.neutral[100]}
              >
                {t('dialogs.functionNote')}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={t('dialogs.editNote')} placement="bottom">
                  <div
                    onClick={() => {
                      setIsEdit(true)
                      setTempState(editorState)
                    }}
                  >
                    <Edit style={{ cursor: "pointer", color: ColorSAP.primary[40], marginRight: 8 }} />
                  </div>
                </Tooltip>
                <Tooltip title={t('dialogs.deleteNote')} placement="bottom">
                  <div onClick={() => {
                    const newEditorState = EditorState.push(
                      tempState,
                      ContentState.createFromText(""),
                      'remove-range'
                    );
                    setEditorState(newEditorState);
                    setTempState(newEditorState);
                    handleDeleteNotes();
                  }}>
                    <Delete style={{ cursor: "pointer", color: ColorSAP.danger[50] }} />
                  </div>
                </Tooltip>
              </div>
            </div>
            <Editor
              editorState={editorState}
              readOnly={true}
              style={{ fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR, fontSize: 14, color: ColorSAP.neutral[100] }}
              onChange={onChange}
            />

          </div>
        </div>
      )
      }

    </div>
  );
};

export default EditorComponent