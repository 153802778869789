// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// Constants
import { Color } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        errorText: {
            marginTop: 3,
            fontFamily: "roboto !important",
            fontSize: 12,
            letterSpacing: 0.15,
            color: `${Color.danger[400]} !important`
        },
    })
);