class Function {
    constructor(systemID, name, type, typeOf, data) {
        this.systemID = systemID
        this.name = name
        this.type = type
        this.typeOf = typeOf
        this.data = data
    }
}

export default Function