// React Lib
import React from "react";
import classnames from 'classnames';

// Component Styles
import { useStyles } from "./BreadcrumbStyles";
import { ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { useTranslation } from "react-i18next";

export const Breadcrumb = (props) => {
    const classes = useStyles(props)
    const { t } = useTranslation()

    const { id, stacks, style, handleChangeBreadcrumb } = props

    const handleStackClick = (index) => () => {
        if (handleChangeBreadcrumb) {
            handleChangeBreadcrumb("POP", stacks[index])
        } else {
            stacks[index].onClick()
        }
    }

    return (
        <div
            className={classes.breadcrumbContainer}
            style={style}
            id={id}
        >
            {stacks.map((stack, index) => {
                const breadcrumbStackContainerClasses = classnames({
                    [classes.breadcrumbStackContainer]: index !== stacks.length - 1,
                    [classes.breadcrumbStackContainerLastOfType]: index === stacks.length - 1
                })

                const typeOf = stack.typeOf

                return (
                    <React.Fragment
                        key={`stack${index}`}
                    >
                        {index !== 0 && (
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={18}
                                color={ColorSAP.neutral[30]}
                                style={{
                                    cursor: index !== stacks.length - 1 && "pointer",
                                    marginLeft: 12,
                                    marginRight: 8,
                                    marginBottom: -5
                                }}
                                className={classes.breadcrumbLabel}
                            >
                                {` / `}
                            </Typography>
                        )}
                        <div
                            className={index !== stacks?.length - 1 && classes.breadcrumbTextContiner}
                            onClick={handleStackClick(index)}
                            style={{
                                marginTop: stack.typeOf !== "" ? -6 : 'auto'
                            }}
                        >
                            {(stack.typeOf !== "" || stack.typeof !== undefined) && (
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                    size={12}
                                    color={ColorSAP.neutral[30]}
                                    className={classes.breadcrumbTypographyHeader}
                                >
                                    {typeOf ? typeOf : stack.type}
                                </Typography>
                            )}
                            <div
                                // style={{ paddingTop: index === stacks?.length - 1 && 6 }}
                                className={breadcrumbStackContainerClasses}>
                                {stack.icon}
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                    size={18}
                                    color={index === stacks.length - 1 ? ColorSAP.neutral[100] : ColorSAP.neutral[30]}
                                    style={{
                                        cursor: index !== stacks.length - 1 && "pointer"
                                    }}
                                    className={classes.breadcrumbLabel}
                                >
                                    {index === 0 ? stack.label : (stack.label === "Sonderfunktionen & Erweiterungen" ? t('scenario.sonderfunktionen') : t(`scenario.${stack.label}`))}
                                </Typography>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    )
}

Breadcrumb.defaultProps = {
    stacks: [],
}

export default React.memo(Breadcrumb)