import { convertToRaw, EditorState } from 'draft-js'
class CartProcess {
    constructor(systemID, name, type) {
        this.systemID = systemID
        this.name = name
        this.type = type
        this.notes = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()))
        this.isEditMode = false
    }
}

export default CartProcess