import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './ManageMainDashboardStyles';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    FilterableChip,
    Edit,
    EditPassword,
    Plus,
    Delete,
    Breadcrumb,
    Dropdown,
    Typography,
    FONT_FAMILY,
    Color,
    Global,
    User,
    Separator,
    Button,
    Form,
    Close,
    // Switch,
    DeletableChip,
    USER_TYPE,
    OntegoLogoDefault,
    SAPLogoDefault,
    IconButton
} from '@commsult/ontego-ui';
import { MANAGE_MAIN_DASHBOARD_PATH } from '../../Constants/Path';
import { formatStringUppercaseFirstLetterAndLowercase } from '../../Utils/Utils';
import { AppContext, PaddedContainer } from '../../App';

import UserDialog from '../../Dialogs/UserDialog/UserDialog';
import DeleteDialog from '../../Dialogs/DeleteDialog/DeleteDialog';
import ManageUserService from './ManageGlobalUsersService';

import Grid from '@material-ui/core/Grid';
import Menu from "@material-ui/core/Menu";

const statusFilter = [
    {
        id: 'active',
        label: 'manage.active'
    },
    {
        id: 'inactive',
        label: 'manage.inactive'
    }
]

const typeFilter = [
    {
        id: USER_TYPE.SAP,
        label: 'SAP'
    },
    {
        id: USER_TYPE.SAP,
        label: 'Ontego'
    }
]

const getRoleKeyByName = (roles, roleName) => {
    const filteredRole = roles.filter(role => role.name === roleName);
    return typeof filteredRole[0] !== undefined ? filteredRole[0].key : 'key';
}

const CompleteRoles = props => {
    const { user, roles, handleChangeChip, handleClose } = props;

    const { t } = useTranslation();

    const FILTERED_ROLE_MENU = () => {
        const menus = [];
        roles.forEach(role => menus.push({
            key: role.key,
            text: role.name,
            onClick: handleChangeChip(user.id, role.name)
        }))

        const filteredRoleMenu = menus.filter(r => user.roles.indexOf(r.text) === -1);
        return filteredRoleMenu;
    }

    return (
        <div style={{ width: '16vw', minHeight: '10vh', padding: '10px 20px' }}>
            <Grid container spacing={2}>
                <Grid item md={12} style={{ display: 'flex', aligntItems: 'center', justifyContent: 'space-between' }}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={16}
                        color={Color.neutral[600]}
                    >
                        {`${user.username} - ` + t("manage.roles")}
                    </Typography>
                    <Close style={{ color: Color.danger[100], cursor: 'pointer' }} onClick={handleClose} />
                </Grid>
                <Grid item md={12} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }} >
                    {user.roles.map((role, index) => (
                        <DeletableChip
                            key={`userRole${index}`}
                            style={{ margin: '2px 4px 2px 0px' }}
                            onDeleteClick={handleChangeChip(user.id, role)}
                        >
                            {role}
                        </DeletableChip>
                    ))}
                </Grid>
                <Grid item md={12} style={{ marginTop: 5 }}>
                    <Dropdown
                        menu={FILTERED_ROLE_MENU()}
                        type="SELECTION"
                        placeholder={t("manage.assignNewRole")}
                        selected={null}
                        withCheckMark={false}
                        style={{ width: '100%' }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

const TableColumn = props => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12} sm={12} md={12}>
            <PaddedContainer>
                <div style={{ marginTop: 36 }}>
                    <Grid container spacing={4}>
                        <Grid item md={1}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.status`)}
                            </Typography>
                        </Grid>
                        <Grid item md={2} style={{ display: "flex", justifyContent: "center" }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.type`)}
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.name`)}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.latestActivity`)}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.roles`)}
                            </Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.actions`)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Separator type="n400" width={2} marginTop="10px" />
            </PaddedContainer>
        </Grid>
    )
}

const TableRow = props => {
    const {
        user,
        roles,
        handleChangeChip,
        handleChangeStatus,
        handleOpenDeleteDialog,
        handleOpenUserDialog,
        handleClickShowRoles,
        setUserAction,
        setInspectedUserData
    } = props

    const { t } = useTranslation();

    const FILTERED_ROLE_MENU = () => {
        const menus = [];
        roles.forEach(role => menus.push({
            key: role.key,
            text: role.name,
            onClick: handleChangeChip(user.id, role.name)
        }))

        const filteredRoleMenu = menus.filter(r => user.roles.indexOf(r.text) === -1);
        return filteredRoleMenu;
    }

    const handleChangePassword = () => {
        setInspectedUserData(user)
        setUserAction("CHANGE_PASSWORD")
        handleOpenUserDialog()
    }

    const handleEditUser = () => {
        setInspectedUserData(user)
        setUserAction("EDIT")
        handleOpenUserDialog()
    }

    const handleDeleteUser = () => {
        setInspectedUserData(user);
        handleOpenDeleteDialog();
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <PaddedContainer>
                <div style={{ position: "relative" }}>
                    <Grid container spacing={4} style={{ display: "flex", alignItems: "center" }}>
                        <Grid item md={1}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                {/* <Switch checked={user.status} handleChange={handleChangeStatus(user.id, !user.status)} /> */}
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={14}
                                    color={Color.neutral[800]}
                                    style={{ marginLeft: 10 }}
                                >
                                    {user.status ? t("manage.active") : t("manage.inactive")}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item md={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {user.types.includes(USER_TYPE.SAP) && (
                                <SAPLogoDefault key={`userType${user.key}`} style={{ width: "50px", marginRight: 10 }} />
                            )}
                            {user.types.includes(USER_TYPE.ONTEGO) && (
                                <OntegoLogoDefault key={`userType${user.key}`} />
                            )}
                        </Grid>
                        <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={20}
                                color={Color.neutral[800]}
                            >
                                {user.username}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {user.latestActivity ? user.latestActivity : t('manage.noLatestActivity')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Grid container spacing={1} style={{ display: "flex", alignItems: "center" }}>
                                <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid container spacing={1} style={{ display: "flex", alignItems: "center" }}>
                                        {user.roles.length > 0 && (
                                            <Grid item md={3} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                                <Button type="secondary" onClick={(e) => handleClickShowRoles(e, user)}>
                                                    <Typography
                                                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                        size={14}
                                                        color={Color.primary[600]}
                                                    >
                                                        {t(`manage.rolesCount`, {count: user.roles.length})}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        )}
                                        <Grid item md={9} style={{ overflowX: "scroll", display: 'flex', alignItems: 'center', padding: "10px 0px 15px 0px" }}>
                                            {user.roles.map((role, index) => (
                                                <DeletableChip style={{ marginRight: 5 }} key={`userRole${index}`} onDeleteClick={handleChangeChip(user.id, role)}>{role}</DeletableChip>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Dropdown
                                        key={user.id + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}
                                        menu={FILTERED_ROLE_MENU()}
                                        type="SELECTION"
                                        placeholder={t("manage.assignNewRole")}
                                        selected={null}
                                        withCheckMark={false}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1}>
                            <Grid item md={12}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        color="primary"
                                        icon={<EditPassword />}
                                        onClick={handleChangePassword}
                                        tooltip={t("manage.changePassword")}
                                        disabled={!user.types.includes(USER_TYPE.ONTEGO)}
                                    />
                                    <IconButton
                                        color="primary"
                                        icon={<Edit />}
                                        onClick={handleEditUser}
                                        tooltip={t("manage.editUser")}
                                    />
                                    <IconButton
                                        color="danger"
                                        icon={<Delete />}
                                        onClick={handleDeleteUser}
                                        tooltip={t("manage.deleteUser")}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Separator type="n400" width={1} marginTop="15px" />
            </PaddedContainer>
        </Grid>
    )
}

const FilterBar = props => {
    const { data, activeFilter, onClick, onClear } = props

    return (
        <Grid item xs={12} sm={12} md={12}>
            <FilterableChip
                data={data}
                activeFilter={activeFilter}
                onClick={onClick}
                onClear={onClear}
            />
        </Grid>
    )
}

const ManageGlobalUsers = props => {
    const classes = useStyles(props)
    const history = useHistory()
    const { t } = useTranslation()

    const {
        users,
        setUsers,
        roles,
        setRoles,
        userInfo,
        handleShowSnackbar,
        handleShowErrorSnackbar
    } = useContext(AppContext)

    const [userDialogOpen, setUserDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [userAction, setUserAction] = useState("CREATE")
    const [inspectedUserData, setInspectedUserData] = useState(null)

    const [searchableUsers, setSearchableUsers] = useState([])
    const [search, setSearch] = useState("")

    const [showFilter, setShowFilter] = useState(false)

    const [filters, setFilters] = useState([])
    const [activeFilter, setActiveFilter] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);

    const handleUserDialogPersistence = (action, user, users) => {
        user.types = JSON.stringify(user.types);

        if (action === "EDIT")
            ManageUserService.updateUser(userInfo.key, user)
                .then(res => {
                    setUsers(users);
                    handleShowSnackbar({
                        message: t("manage.objectEdited", {object: user.username}),
                        autoHideDuration: 3000,
                        icon: <Edit />,
                        showButton: true,
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));

        if (action === "CHANGE_PASSWORD")
            ManageUserService.updateUser(userInfo.key, user)
                .then(res => {
                    setUsers(users);
                    handleShowSnackbar({
                        message: user.username + " Password Changed",
                        autoHideDuration: 3000,
                        icon: <EditPassword />,
                        showButton: true,
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));

        if (action === "CREATE")
            ManageUserService.addUser(userInfo.key, user)
                .then(res => {
                    fetchUsersData();
                    handleShowSnackbar({
                        message: t("manage.objectCreated", {object: user.username}),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));
    }

    const handleDeleteUser = () => {
        const newUsers = JSON.parse(JSON.stringify(searchableUsers));
        const tempUsers = JSON.parse(JSON.stringify(searchableUsers));

        newUsers.forEach((p, j) => {
            if (p.id === inspectedUserData.id) {
                newUsers.splice(j, 1)
            }
        })

        ManageUserService.deleteUser(userInfo.key, inspectedUserData.key)
            .then(res => {
                setUsers(newUsers);
                handleShowSnackbar({
                    message: t("manage.objectDeleted", {object: inspectedUserData.username}),
                    autoHideDuration: 5000,
                    icon: <Delete />,
                    showButton: true,
                    buttonLabel: t("manage.undo"),
                    onUndo: () => {
                        ManageUserService.undeleteUser(userInfo.key, inspectedUserData.key)
                            .then(res => setUsers(tempUsers))
                            .catch(error => handleShowErrorSnackbar(error));
                    }
                });
            })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const handleOpenUserDialog = () => {
        setUserDialogOpen(true)
    }
    const handleCloseUserDialog = () => {
        setUserDialogOpen(false)
    }

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
    }

    const handleChangeChip = (rowId, text) => () => {
        const newUsers = JSON.parse(JSON.stringify(users));
        const roleKey = getRoleKeyByName(roles, text);

        newUsers.forEach(row => {
            if (row.id === rowId) {
                setInspectedUserData(row)
                if (row.roles.includes(text)) {
                    row.roles = row.roles.filter((event) => (event !== text));

                    ManageUserService.deleteUserRole(userInfo.key, row.key, roleKey)
                        .then(response => setUsers(newUsers))
                        .catch(error => handleShowErrorSnackbar(error));
                } else {
                    row.roles = [...row.roles, text];

                    ManageUserService.addUserRole(userInfo.key, row.key, roleKey)
                        .then(response => setUsers(newUsers))
                        .catch(error => handleShowErrorSnackbar(error));
                }
            }
        })

    }

    const handleChangeStatus = (rowId, status) => () => {
        const newUsers = JSON.parse(JSON.stringify(users));
        let editedUser = null;

        newUsers.forEach(row => {
            if (row.id === rowId) {
                row.status = status;
                editedUser = JSON.parse(JSON.stringify(row));
            }
        })

        editedUser.types = JSON.stringify(editedUser.types);
        ManageUserService.updateUser(userInfo.key, editedUser)
            .then(res => setUsers(newUsers))
            .catch(error => handleShowErrorSnackbar(error));
    }

    const handleChangeProcessSearch = e => {
        setSearch(e.target.value)

        let searchKey = e.target.value;
        let filter = ""

        let newSearchableUsers = [...searchableUsers]

        let filteredUsersData = [];

        if (searchKey !== "") {
            filteredUsersData = newSearchableUsers.filter(inspectedUser => {
                let temp = ""

                temp += ` ${inspectedUser.username.toLowerCase()}`
                temp += ` ${inspectedUser.latestActivity.toLowerCase()}`
                inspectedUser.roles.forEach(role => {
                    temp += ` ${role.toLowerCase()}`
                })

                // const lc = inspectedFunc.name.toLowerCase();
                filter = searchKey.toLowerCase();
                return temp.includes(filter)
            })

            newSearchableUsers = filteredUsersData
        }
        else if (searchKey === "") {
            newSearchableUsers = users
        }

        setSearchableUsers(newSearchableUsers)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (userInfo) {
            fetchUsersData();
            fetchRolesData();
        }
    }, []);

    useEffect(() => {
        setSearchableUsers(users);
    }, [users])

    useEffect(() => {
        initializeFilter();
    }, [roles]);

    const fetchRolesData = () => {
        ManageUserService.getRoles(userInfo.key)
            .then(response => setRoles(response.data))
            .catch(error => handleShowErrorSnackbar(error));
    }

    const fetchUsersData = () => {
        ManageUserService.getUsers(userInfo.key)
            .then(response => setUsers(response.data))
            .catch(error => handleShowErrorSnackbar(error));
    }

    const initializeFilter = () => {
        const filterData = []
        const roleFilter = []

        roles.forEach(role => {
            roleFilter.push({
                key: role.key,
                id: role.name,
                label: formatStringUppercaseFirstLetterAndLowercase(role.name)
            })
        });

        filterData.push({
            parameterName: t("manage.status"),
            filters: statusFilter
        })

        filterData.push({
            parameterName: t("manage.type"),
            filters: typeFilter
        })

        filterData.push({
            parameterName: t("manage.roles"),
            filters: roleFilter
        })

        setFilters(filterData)
        setActiveFilter([])
    }

    const handleToggleFilter = () => {
        setShowFilter(!showFilter)
    }

    const handleOnFilterClick = id => () => {
        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)

        const translateStatus = status => {
            switch (status) {
                case false:
                    return 'inactive'
                default:
                    return 'active'
            }
        }

        const filterCollection = (arr, filters, type) => {
            let shouldDuplicate = false

            switch (type) {
                case 'TYPES':
                    arr.forEach(el => {
                        if (newActiveFilter.some(filter => el.types.indexOf(filter) >= 0)) {
                            temp.push(el)
                            shouldDuplicate = true
                        }
                    })
                    break
                    case 'ROLES':
                        arr.forEach(el => {
                            if (newActiveFilter.some(filter => el.roles.indexOf(filter) >= 0)) {
                                temp.push(el)
                                shouldDuplicate = true
                            }
                        })
                        break
                    default:
                        arr.forEach(el => {
                            if (filters.includes(translateStatus(el.status))) {
                                temp.push(el)
                            }
                        })
                        break
            }

            return shouldDuplicate ? [...new Set(findDuplicates(arr))] : temp
        }

        const newActiveFilter = [...activeFilter]
        let newSearchableUsers = []

        let temp = []

        if (newActiveFilter.includes(id)) {
            newActiveFilter.splice(newActiveFilter.indexOf(id), 1)
        }
        else {
            if (id === 'active') {
                newActiveFilter.splice(newActiveFilter.indexOf('inactive'), 1)
            }

            if (id === 'inactive') {
                newActiveFilter.splice(newActiveFilter.indexOf('active'), 1)
            }

            newActiveFilter.push(id)
        }


        if (newActiveFilter.length > 0) {
            temp = filterCollection(users, newActiveFilter, 'STATUS')
            temp = filterCollection(temp, newActiveFilter, 'TYPES')
            temp = filterCollection(temp, newActiveFilter, 'ROLES')

            newSearchableUsers = temp
        }
        else {
            newSearchableUsers = [...users]
        }

        setSearchableUsers(newSearchableUsers)
        setActiveFilter(newActiveFilter)
    }

    const handleClearFilter = () => {
        setSearchableUsers([...users])
        setActiveFilter([])
    }

    const handleClickShowRoles = (e, user) => {
        e.stopPropagation();
        setInspectedUserData(user)

        if (!anchorEl) {
            setAnchorEl(e.currentTarget);
        };
    }

    const handleCloseRolesMenu = () => setAnchorEl(null);

    const BREADCRUMB_STACK = [
        {
            icon: <Global />,
            label: t("breadcrumb.appManagement"),
            typeOf: "",
            onClick: () => {
                history.push(MANAGE_MAIN_DASHBOARD_PATH)
            }
        },
        {
            icon: <User />,
            label: t("manage.users"),
            typeOf: "",
            onClick: () => { }
        }
    ]

    return (
        <div style={{ paddingBottom: 200 }}>
            <Grid container spacing={2}>
                <div style={{ height: 70, width: '100%' }} />
                <Grid item xs={12} sm={12} md={12}>
                    <div id='breadcrumb-container' style={{ width: '100%', position: 'fixed', top: 64, paddingTop: 24, paddingBottom: 24, background: Color.neutral[0], zIndex: 1200 }}>
                        <PaddedContainer>
                            <Breadcrumb stacks={BREADCRUMB_STACK} />
                        </PaddedContainer>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <PaddedContainer>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 12 }}>
                            <div style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                                <Form
                                    placeholder={t("manage.searchUser")}
                                    search
                                    value={search}
                                    style={{ width: '25%' }}
                                    onChange={handleChangeProcessSearch}
                                />
                                <Checkbox
                                    name="showFilter"
                                    label={t(`manage.showFilter`)}
                                    value={showFilter}
                                    checked={showFilter}
                                    onChange={handleToggleFilter}
                                    style={{ marginLeft: 25 }}
                                />
                            </div>
                            <Button
                                style={{ outline: "none" }}
                                icon={<Plus />}
                                alignIcon="left"
                                onClick={() => {
                                    setUserAction("CREATE");
                                    handleOpenUserDialog();
                                }}
                            >
                                {t("manage.addNewUsers")}
                            </Button>
                        </div>
                        <div style={{ width: '40%' }}>
                            {showFilter && (
                                <FilterBar
                                    data={filters}
                                    activeFilter={activeFilter}
                                    label={t("manage.status")}
                                    onClick={handleOnFilterClick}
                                    onClear={handleClearFilter}
                                />
                            )}
                        </div>
                    </PaddedContainer>
                </Grid>
                <TableColumn />
                {searchableUsers.length > 0 ? (
                    <>
                        {searchableUsers.map((user, index) => (
                            <TableRow
                                key={`user${index}`}
                                user={user}
                                roles={roles}
                                handleChangeChip={handleChangeChip}
                                handleChangeStatus={handleChangeStatus}
                                handleOpenUserDialog={handleOpenUserDialog}
                                handleOpenDeleteDialog={handleOpenDeleteDialog}
                                handleClickShowRoles={handleClickShowRoles}
                                setUserAction={setUserAction}
                                setInspectedUserData={setInspectedUserData}
                            />
                        ))}
                    </>
                ) : (
                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                            <User style={{ width: 200, height: 200, color: Color.neutral[100] }} />
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                size={36}
                                color={Color.neutral[400]}
                                style={{ paddingTop: 15 }}
                            >
                                {t("manage.noUsersFound")}
                            </Typography>
                        </Grid>
                    )}

            </Grid>
            <UserDialog
                open={userDialogOpen}
                onClose={handleCloseUserDialog}
                userAction={userAction}
                handlePersistence={handleUserDialogPersistence}
                user={inspectedUserData}
            />
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                onDelete={handleDeleteUser}
                title={t("manage.user")}
                dataName={inspectedUserData && inspectedUserData.username}
            />
            <Menu
                anchorEl={anchorEl}
                PaperProps={{
                    style: {
                        transform: 'translateY(35%)',
                    }
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseRolesMenu}
            >
                <CompleteRoles
                    user={inspectedUserData}
                    roles={roles}
                    handleChangeChip={handleChangeChip}
                    handleClose={handleCloseRolesMenu}
                />
            </Menu>
        </div>
    )
}

export default ManageGlobalUsers