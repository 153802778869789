// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// Constants
import { Color } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        errorText: {
            position: "absolute",
            bottom: 0,
            fontFamily: "roboto",
            fontSize: 12,
            letterSpacing: 0.15,
            color: Color.danger[400]
        },
    })
);