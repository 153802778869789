// Constants
import { ColorSAP } from "@commsult/ontego-ui";
import { createStyles, makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        breadcrumbTextContiner: {
            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -5,
                left: 0,
                width: 0,
                height: 1,
                backgroundColor: 'black',
                transition: 'width 600ms ease',
            },
            '&:hover::after': {
                width: '100%',
                left: 0,
            },
        },
        breadcrumbContainer: {
            width: "fit-content",
            display: "inline-flex",
            alignItems: "center"
        },
        breadcrumbStackContainer: {
            display: 'flex',
            alignItems: 'center',
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.neutral[30],
                marginRight: 8,
            }
        },
        breadcrumbStackContainerLastOfType: {
            display: 'flex',
            alignItems: 'center',
            "& svg": {
                width: 20,
                height: 20,
                color: ColorSAP.primary[70],
                marginRight: 6
            }
        },
        breadcrumbLabel: {
            userSelect: "none",
            color: ColorSAP.primary[100],
            transition: 'all 0.3s ease',
            "&:hover": {
                color: ColorSAP.primary[70],
                transition: 'all 0.3s ease'
            }
        },
        breadcrumbTypographyHeader: {
            userSelect: "none",
            color: ColorSAP.neutral[100],
            transition: 'all 0.3s ease',
            "&:hover": {
                color: ColorSAP.primary[70],
                transition: 'all 0.3s ease'
            },
            marginBottom: -6,
            marginLeft: 32,
        }
    })
);