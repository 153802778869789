import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** WA EXTERN IMAGES */
import WA_Extern_Einfuehrung1 from '../../assets/img_en/warenausgang_extern/Einfuehrung/1.png'

import WA_Extern_Auslieferung1 from '../../assets/img_en/warenausgang_extern/Auslieferung/1.png'
import WA_Extern_Auslieferung2 from '../../assets/img_en/warenausgang_extern/Auslieferung/2.png'
import WA_Extern_Auslieferung3 from '../../assets/img_en/warenausgang_extern/Auslieferung/3.png'
import WA_Extern_Auslieferung4 from '../../assets/img_en/warenausgang_extern/Auslieferung/4.png'

import WA_Extern_Transport1 from '../../assets/img_en/warenausgang_extern/Transport/1.png'

import WA_Extern_Sammellieferung1 from '../../assets/img_en/warenausgang_extern/Sammellieferung/1.png'

export const WarenausgangExternImagesEN = [
    new ProcessImageContainer(
        `P_WA_EXTERN_EINFUEHRUNG`,
        [
            new ProcessImage('List of Deliveries', WA_Extern_Einfuehrung1)
        ]
    ),
    new ProcessImageContainer(
        `P_WA_EXTERN_WARENAUSGANG_ZUR_AUSLIEFERUNG`,
        [
            new ProcessImage('Delivery', WA_Extern_Auslieferung1),
            new ProcessImage('Search', WA_Extern_Auslieferung2),
            new ProcessImage('Deliveries', WA_Extern_Auslieferung3),
            new ProcessImage('List of Deliveries', WA_Extern_Auslieferung4)
        ]
    ),
    new ProcessImageContainer(
        `P_WA_EXTERN_WARENAUSGANG_ZUM_TRANSPORT`,
        [
            new ProcessImage('Transport', WA_Extern_Transport1)
        ]
    ),
    new ProcessImageContainer(
        `P_WA_EXTERN_WARENAUSGANG_ZUR_GRUPPE`,
        [
            new ProcessImage('Group - Collecting Delivery', WA_Extern_Sammellieferung1)
        ]
    )
]