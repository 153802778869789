// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Color, ColorSAP } from '@commsult/ontego-ui';

// Constants

export const useStyles = makeStyles(theme =>
    createStyles({
        addNoteIconButton: {
            width: 32,
            height: 32,
            cursor: 'pointer',
            borderRadius: '50%',
            background: "transparent",
            transition: 'all 0.3s ease',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 16,
            "& svg": {
                width: 24,
                height: 24,
                color: Color.primary[100],
                transition: 'all 0.3s ease'
            },
            // "&:hover": {
            //     background: Color.primary[100],
            //     transition: 'all 0.3s ease',
            //     "& svg": {
            //         color: Color.danger[400],
            //         transition: 'all 0.3s ease'
            //     }
            // }
        },
        deleteIconButton: {
            width: 32,
            height: 32,
            cursor: 'pointer',
            borderRadius: '50%',
            background: "transparent",
            transition: 'all 0.15s ease',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.danger[50],
                transition: 'all 0.15s ease'
            },
            // "&:hover": {
            //     background: Color.primary[100],
            //     transition: 'all 0.3s ease',
            //     "& svg": {
            //         color: Color.danger[400],
            //         transition: 'all 0.3s ease'
            //     }
            // }
        }
    })
);