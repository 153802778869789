import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** BESTELLUNG IMAGES */
import Bestellung_Einfuehrung1 from '../../assets/img_en/bestellung/Einfuehrung/1.png';
import Bestellung_Einfuehrung2 from '../../assets/img_en/bestellung/Einfuehrung/2.png';

import Bestellung_Bestellerfassung1 from '../../assets/img_en/bestellung/Bestellerfassung/1.png';
import Bestellung_Bestellerfassung2 from '../../assets/img_en/bestellung/Bestellerfassung/2.png';
import Bestellung_Bestellerfassung3 from '../../assets/img_en/bestellung/Bestellerfassung/3.png';
import Bestellung_Bestellerfassung4 from '../../assets/img_en/bestellung/Bestellerfassung/4.png';
import Bestellung_Bestellerfassung5 from '../../assets/img_en/bestellung/Bestellerfassung/5.png';

import Bestellung_Bestelleraenderung1 from '../../assets/img_en/bestellung/Bestelleraenderung/1.png';
import Bestellung_Bestelleraenderung2 from '../../assets/img_en/bestellung/Bestelleraenderung/2.png';
import Bestellung_Bestelleraenderung3 from '../../assets/img_en/bestellung/Bestelleraenderung/3.png';
import Bestellung_Bestelleraenderung4 from '../../assets/img_en/bestellung/Bestelleraenderung/4.png';

import Bestellung_Freigabe1 from '../../assets/img_en/bestellung/Freigabe/1.png';
import Bestellung_Freigabe2 from '../../assets/img_en/bestellung/Freigabe/2.png';
import Bestellung_Freigabe3 from '../../assets/img_en/bestellung/Freigabe/3.png';

export const BestellerfassungImagesEN = [
    new ProcessImageContainer(
        `P_BESTELLERFASSUNG_EINFUEHRUNG`,
        [
            new ProcessImage('Positions', Bestellung_Einfuehrung1),
            new ProcessImage('Detail', Bestellung_Einfuehrung2)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTELLERFASSUNG`,
        [
            new ProcessImage('Material', Bestellung_Bestellerfassung1),
            new ProcessImage('Supplier', Bestellung_Bestellerfassung2),
            new ProcessImage('Details', Bestellung_Bestellerfassung3),
            new ProcessImage('Positions', Bestellung_Bestellerfassung4),
            new ProcessImage('Material to the Supplier', Bestellung_Bestellerfassung5)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTELLAENDERUNG`,
        [
            new ProcessImage('Search', Bestellung_Bestelleraenderung1),
            new ProcessImage('Orders', Bestellung_Bestelleraenderung2),
            new ProcessImage('Positions', Bestellung_Bestelleraenderung3),
            new ProcessImage('Detail', Bestellung_Bestelleraenderung4)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTELLFREIGABE`,
        [
            new ProcessImage('Orders', Bestellung_Freigabe1),
            new ProcessImage('Specify Order', Bestellung_Freigabe2),
            new ProcessImage('Detail', Bestellung_Freigabe3)
        ]
    )
]