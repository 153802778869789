import axios from 'axios';
import { USER_API_BASE_APPS_URL, getApiUrl } from '../../Constants/Api';

export class ManageDevicePermitDialogService {
    getDevicesData = (clientKey, appKey, versionKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/appversions/${versionKey}/devicerestrictions`);
    }
    updateDeviceRestriction = async (clientKey, appKey, versionKey, deviceRestriction) => {
        return await axios.put(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/appversions/${versionKey}/devicerestrictions`, { deviceRestriction });
    }
}

export default new ManageDevicePermitDialogService();