import React, { useState, useEffect, createContext } from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";

import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStyles } from './AppStyles';

import { Directory, Search, Edit, Manage, Color, ChevronUp, Checkmark, Close, Plus, USER_TYPE, CopyToClipboard, ColorSAP, Info } from '@commsult/ontego-ui';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { LOGIN_PATH, REGISTER_PATH, SCENARIO_PATH, FUNCTION_DETAILS_PATH, SPECIAL_FUNCTIONS_PATH, MANAGE_MAIN_DASHBOARD_PATH, MANAGE_PERMISSION_ASSIGN_PATH, MANAGE_GLOBAL_PERMISSION_PATH, MANAGE_GLOBAL_DEVICE_PATH, MANAGE_GLOBAL_USERS_PATH, CUSTOMIZE_MAIN_DASHBOARD_PATH, UPDATE_MANAGEMENT, MANAGE_GLOBAL_SIGNING_KEYS_PATH, ADMIN_DASHBOARD, BLACKLISTED_DEVICE } from './Constants/Path';
import { FUNCTION_SONDERFUNKTIONEN_ERWEITERUNGEN } from './Constants/FunctionCollection';
import { ROLE_ADMIN } from './Constants/ClientRoles';

import AuthService from './Pages/Login/AuthService';
import AppService from './AppService';

import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';

import AdminDashboard from './Pages/AdminDashboard/AdminDashboard';
import BlacklistedDevice from './Pages/BlacklistedDevice/BlacklistedDevice';

import Scenario from './Pages/Scenario/Scenario';
import FunctionDetailsContainer from './Pages/FunctionDetails/FunctionDetailsContainer';

import ManageMainDashboard from './Pages/Manage/ManageMainDashboard';
import ManagePermissionAssign from './Pages/Manage/ManagePermissionAssign';
import ManageGlobalPermission from './Pages/Manage/ManageGlobalPermission';
import ManageGlobalDevice from './Pages/Manage/ManageGlobalDevice';
import ManageGlobalUsers from './Pages/Manage/ManageGlobalUsers';
import ManageGlobalSigningKeys from './Pages/Manage/ManageGlobalSigningKeys';

import PricingAndLicenseDialog from "./Dialogs/PricingAndLicense/PricingAndLicense";
import UpAndRunningDialog from "./Dialogs/UpAndRunning/UpAndRunning";
import CollectionCartDialog from "./Dialogs/CollectionCart/CollectionCart";

import Cart from "./Models/Cart";
import { usePersistedState, calculateCollectionSize, convertNotesToJSON, convertNotesToEditorState, convertUpAndRunningNotesToEditorState } from "./Utils/Utils";
import { redirectToLogin } from "./Utils/Auth";
import GeneralBackdrop from "./ReusableComponents/Backdrop/GeneralBackdrop";
import LoginBackdrop from "./ReusableComponents/Backdrop/LoginBackdrop";
import ScreenSizeWarningBackdrop from "./ReusableComponents/Backdrop/ScreenSizeWarningBackdrop";
import CustomizeMainDashboard from "./Pages/Customize/CustomizeMainDashboard";
import UpdateManagement from "./Pages/Manage/UpdateManagement";

import PrivateRoute from "./Utils/Route/PrivateRoute";
import PublicRoute from "./Utils/Route/PublicRoute";

import { useTranslation } from "react-i18next";
import { jwtDecode } from 'jwt-decode';
import Navbar from './ReusableComponents/Navbar/Navbar';
import Snackbar from './ReusableComponents/Snackbar/Snackbar';

import { ContentState, convertFromRaw, EditorState } from 'draft-js'
import { ArrowUp, Check } from '@commsult/ontego-ui/dist/sap_icons';
import ArrowHeadSpecial from './Icons/ArrowHeadSpecial';

export const AppContext = createContext({});
const AppContextProvider = AppContext.Provider;

const directoryStart = require(`./directoryStart.svg`);
const directoryNext = require(`./directoryNext.svg`);

export const PaddedContainer = (props) => {
  const classes = useStyles(props);
  const { children, style } = props;

  return (
    <div className={classes.paddedContainer} style={style}>
      {children}
    </div>
  );
};

export const RootWrapperContainer = (props) => {
  const classes = useStyles(props);
  const { children } = props;
  return (
    <div className={classes.rootContainer}>
      <div className={classes.rootContainerChildWrapper}>
        {children}
      </div>
    </div>
  )
}

const RootContainer = (props) => {
  return <div style={{ width: "100%", height: "100%" }}>{props.children}</div>;
};

const useBackdropStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: 2000,
      background: `rgba(0, 82, 127, 0.8)`,
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
    },
  })
);

const goToTop = () => {
  window.scrollTo(0, 0);
};

let snackbarTimeout = null;
let errorSnackbarTimeout = null;

const LoginPage = (props) => {
  return <Login />;
};

const discover = "Discover";
const manage = "Manage";

const getDirectories = (isPurchased) => {
  return [
    {
      icon: <Search />,
      label: discover,
      isActive:
        window.location.pathname !== CUSTOMIZE_MAIN_DASHBOARD_PATH &&
        window.location.pathname !== MANAGE_MAIN_DASHBOARD_PATH,
      path: SCENARIO_PATH,
      disabled: false,
    },
    {
      icon: <Edit />,
      label: "Customize",
      isActive: window.location.pathname === CUSTOMIZE_MAIN_DASHBOARD_PATH,
      path: CUSTOMIZE_MAIN_DASHBOARD_PATH,
      disabled: false,
    },
    {
      icon: <Manage style={{ width: 36, height: 36 }} />,
      label: manage,
      isActive: window.location.pathname === MANAGE_MAIN_DASHBOARD_PATH,
      path: MANAGE_MAIN_DASHBOARD_PATH,
      disabled: false,
    },
  ];
};

const App = (props) => {
  const theme = useTheme();
  const isScreenSizeLimit = useMediaQuery(theme.breakpoints.down('1280'));
  // const isScreenSizeLimit = useMediaQuery('(min-width:1440px)');

  const classes = useStyles(props);
  const backdropClasses = useBackdropStyles(props);

  const history = useHistory();

  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isFunctionLoading, setIsFunctionLoading] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  const [client, setClient] = useState({});

  const [inspectedSystem, setInspectedSystem] = useState(null);
  const [inspectedVersion, setInspectedVersion] = useState(null);

  const [specialFunctions, setSpecialFunctions] = useState(
    FUNCTION_SONDERFUNKTIONEN_ERWEITERUNGEN
  );

  const [users, setUsers] = useState([]);
  const [signingKeys, setSigningKeys] = useState([]);
  const [devices, setDevices] = useState([]);
  const [devicesWithoutClient, setDevicesWithoutClient] = useState([]);
  const [deviceGroups, setDeviceGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [properties, setProperties] = useState([]);
  const [roles2properties, setRoles2properties] = useState([]);

  const [directories, setDirectories] = useState(getDirectories(false));

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const elem = document.getElementById("breadcrumb-container");

  //     if (elem) {
  //       const top =
  //         (window.scrollY || elem.scrollTop) - (elem.clientTop || 0);

  //       if (top > 20) {
  //         elem.style.boxShadow = "0px 2px 4px 0px rgba(56, 61, 71, 0.05)";
  //         elem.style.transition = "all 0.3s ease-in-out";
  //       } else if (top < 20) {
  //         elem.style.boxShadow = "none";
  //         elem.style.transition = "all 0.3s ease-in-out";
  //       }
  //     }

  //     const buttonUpEl = document.getElementById("button-up");

  //     if (buttonUpEl) {
  //       const top = window.scrollY;

  //       if (top > window.innerHeight * 1.5) {
  //         buttonUpEl.style.visibility = "visible";
  //         buttonUpEl.style.opacity = 1;
  //         buttonUpEl.style.transition = "0.5s all ease";
  //       } else {
  //         buttonUpEl.style.visibility = "hidden";
  //         buttonUpEl.style.opacity = 0;
  //         buttonUpEl.style.transition = "0.5s all ease";
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // });

  useEffect(() => {
    const info = AuthService.getUserInfo();
    if (info) {
      const userInformation = jwtDecode(info);

      AppService.getClientInfo(userInformation.key)
        .then((response) => {
          setClient(response.data);
          setDirectories(getDirectories(response.data.hasPurchased));
          window.gtag("config", "G-9CF4SB048S", {
            user_id: response.data.name,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            logoutAction();
          }
        });

      AppService.getFunctionCollection(userInformation.key)
        .then((res) => {
          if (
            Object.keys(res.data).length === 0 &&
            res.data.constructor === Object
          ) {
            setCollection([]);
          } else {
            const newCollection = [...res.data.functionCollection]

            setCollection(convertNotesToEditorState(newCollection));
          }
        })
        .catch((error) => {
          setCollection([]);
          console.log(error)
          // handleShowErrorSnackbar();
        });

      AppService.getUpAndRunningCollection(userInformation.key)
        .then((res) => {
          if (
            Object.keys(res.data).length === 0 &&
            res.data.constructor === Object
          ) {
            setUpAndRunningCollection({});
          } else {
            setUpAndRunningCollection(convertUpAndRunningNotesToEditorState(res.data.upAndRunningCollection));
          }
        })
        .catch((error) => {
          setUpAndRunningCollection({});
          // handleShowErrorSnackbar();
          console.log(error)
        });
    }
  }, [userInfo]);

  const [inspectedFunctionId, setInspectedFunctionId] = usePersistedState(
    "inspectedFunctionId",
    ``
  );
  const handleChangeInspectedFunctionId = (val) => setInspectedFunctionId(val);

  const [collection, setCollection] = usePersistedState("collection", []);
  const [upAndRunningCollection, setUpAndRunningCollection] = useState({});

  const [editorStateList, setEditorStateList] = useState([])

  const handleAddEditorStateList = (editorStateData) => {
    setEditorStateList(editorStateData)
  }

  const handleChangeCollection = (func) => (e) => {
    e.stopPropagation();

    const newCollection = [...collection];

    let alreadyExists = false;

    newCollection.forEach((c, index) => {
      if (c.name === func.name) {
        alreadyExists = true;
      }
    });

    if (!alreadyExists) {
      newCollection.push(
        new Cart(func.systemID, func.name, func.icon, func.typeOf)
      );
    } else {
      newCollection.forEach((c, i) => {
        if (c.name === func.name) {
          newCollection.splice(i, 1);
        }
      });
    }

    AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection))
      .then((res) => {
        setCollection(convertNotesToEditorState(newCollection));
        if (!alreadyExists) {
          handleShowSnackbar({
            message: t('common.addedToCollection'),
            autoHideDuration: 5000,
            icon: <CopyToClipboard />,
            showButton: true,
            buttonLabel: "Look Collection",
            onUndo: () => {
              handleOpenCollectionCartDialog();
            },
          });
        } else {
          handleShowSnackbar({
            message: t('common.removedFromCollection'),
            autoHideDuration: 5000,
            icon: <CopyToClipboard />,
            showButton: true,
            buttonLabel: "Look Collection",
            onUndo: () => {
              handleOpenCollectionCartDialog();
            },
          });
        }
      })
      .catch((error) => handleShowErrorSnackbar(error));
  };

  const [upAndRunningDialogOpen, setUpAndRunningDialogOpen] = useState(false);
  const handleOpenUpAndRunningDialog = () => setUpAndRunningDialogOpen(true);
  const handleCloseUpAndRunningDialog = () => setUpAndRunningDialogOpen(false);

  const [pricingAndLicenseDialogOpen, setPricingAndLicenseDialogOpen] =
    useState(false);
  const handleOpenPricingAndLicenseDialog = () =>
    setPricingAndLicenseDialogOpen(true);
  const handleClosePricingAndLicenseDialog = () =>
    setPricingAndLicenseDialogOpen(false);

  const [collectionCartDialogOpen, setCollectionCartDialogOpen] =
    useState(false);
  const handleOpenCollectionCartDialog = () =>
    setCollectionCartDialogOpen(true);
  const handleCloseCollectionCartDialog = () =>
    setCollectionCartDialogOpen(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(false);

  const { t, i18n } = useTranslation();

  const [snackbarData, setSnackbarData] = useState({
    key: "snackbar",
    message: "",
    icon: <Check />,
    showButton: false,
    buttonLabel: t("manage.close"),
    onClick: () => { },
  });
  const [errorSnackbarData, setErrorSnackbarData] = useState({
    key: "errorSnackbar",
  });

  const [showDirectory, setShowDirectory] = useState(false);

  const handleShowSnackbar = ({
    message,
    autoHideDuration = 1000,
    icon = <Check />,
    showButton = false,
    buttonLabel = t("manage.close"),
    onUndo = () => { },
  }) => {
    setShowSnackbar(true);
    setSnackbarData({
      key: new Date().getTime(),
      message: message,
      autoHideDuration: autoHideDuration,
      icon: icon,
      showButton: showButton,
      buttonLabel: buttonLabel,
      onClick: () => {
        handleCloseSnackbar();
        onUndo();
      },
    });
    clearTimeout(snackbarTimeout);
    snackbarTimeout = setTimeout(() => {
      handleCloseSnackbar();
    }, autoHideDuration);
  };

  const handleShowErrorSnackbar = (error) => {
    if (error && error.response && error.response.status === 401) {
      logoutAction();
      setSnackbarErrorMessage("Your session has expired.");
    }
    setShowErrorSnackbar(true);
    setErrorSnackbarData({
      key: new Date().getTime(),
    });
    clearTimeout(errorSnackbarTimeout);
    errorSnackbarTimeout = setTimeout(() => {
      handleCloseErrorSnackbar();
    }, 3000);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    clearTimeout(snackbarTimeout);
  };

  const handleCloseErrorSnackbar = () => {
    setShowErrorSnackbar(false);
    setTimeout(() => {
      setSnackbarErrorMessage(null);
    }, 100);
    clearTimeout(errorSnackbarTimeout);
  };

  const topNavbarLogoClick = () => {
    if (userInfo && userInfo.role === ROLE_ADMIN) {
      history.push(ADMIN_DASHBOARD);
    } else {
      history.push(SCENARIO_PATH);
    }
  };

  const handleDirectoryOnClick = (label) => () => {
    const newDirectories = [...directories];
    newDirectories.forEach((dir) => (dir.isActive = false));
    newDirectories.filter((dir) => dir.label === label)[0].isActive = true;
    setDirectories(newDirectories);

    history.push(newDirectories.filter((dir) => dir.label === label)[0].path);
  };

  const AppContextValue = {
    setIsLoginLoading: setIsLoginLoading,
    setIsFunctionLoading: setIsFunctionLoading,

    AuthService: AuthService,

    inspectedFunctionId: inspectedFunctionId,
    handleChangeInspectedFunctionId: handleChangeInspectedFunctionId,

    collection: collection,
    setCollection: setCollection,
    handleChangeCollection: handleChangeCollection,

    upAndRunningCollection: upAndRunningCollection,
    setUpAndRunningCollection: setUpAndRunningCollection,

    client: client,
    setClient: setClient,

    userInfo: userInfo,
    setUserInfo: setUserInfo,

    inspectedSystem: inspectedSystem,
    handleChangeInspectedSystem: setInspectedSystem,

    inspectedVersion: inspectedVersion,
    handleChangeInspectedVersion: setInspectedVersion,

    specialFunctions: specialFunctions,
    setSpecialFunctions: setSpecialFunctions,

    handleShowSnackbar: handleShowSnackbar,
    handleShowErrorSnackbar: handleShowErrorSnackbar,

    users: users,
    setUsers: setUsers,

    signingKeys: signingKeys,
    setSigningKeys: setSigningKeys,

    devices: devices,
    setDevices: setDevices,

    deviceGroups: deviceGroups,
    setDeviceGroups: setDeviceGroups,

    roles: roles,
    setRoles: setRoles,

    properties: properties,
    setProperties: setProperties,

    roles2properties: roles2properties,
    setRoles2properties: setRoles2properties,

    devicesWithoutClient: devicesWithoutClient,
    setDevicesWithoutClient: setDevicesWithoutClient,

    editorStateList: editorStateList,
    setEditorStateList: setEditorStateList,
    handleAddEditorStateList: handleAddEditorStateList
  };

  const logout = (e) => {
    e.preventDefault();
    logoutAction();
  };

  const logoutAction = () => {
    redirectToLogin(history);
    AuthService.logOut();

    setUserInfo(null);
    setInspectedFunctionId(``);
    setCollection([]);
  };

  const updateDirectories = (location) => {
    const newDirectories = [...directories];
    newDirectories.forEach((dir) => (dir.isActive = false));
    switch (location.pathname) {
      case SCENARIO_PATH:
        newDirectories[0].isActive = true;
        setShowDirectory(true);
        break;
      case CUSTOMIZE_MAIN_DASHBOARD_PATH:
        newDirectories[1].isActive = true;
        setShowDirectory(true);
        break;
      case MANAGE_MAIN_DASHBOARD_PATH:
        newDirectories[2].isActive = true;
        setShowDirectory(true);
        break;
      default:
        setShowDirectory(false);
        break;
    }

    setDirectories(newDirectories);
  };

  const onChangeLanguageClick = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang)
  };

  // useEffect(() => {
  //   /** DETECT EVERY ROUTE CHANGE */
  //   history.listen((location) => {
  //     console.log("history listen: " + JSON.stringify(location));
  //     updateDirectories(location);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [history, client]);

  useEffect(() => {
    updateDirectories(history.location);
  }, []);

  return (
    <RootContainer>
      <AppContextProvider value={AppContextValue}>
        {userInfo && (
          <Navbar
            userInfo={userInfo}
            onLogoClick={topNavbarLogoClick}
            onShoppingCartClick={handleOpenCollectionCartDialog}
            collectionSize={calculateCollectionSize(collection)}
            onProcessesClick={handleDirectoryOnClick(discover)}
            onManagementClick={handleDirectoryOnClick(manage)}
            onPriceModelClick={handleOpenPricingAndLicenseDialog}
            onUpAndRunningClick={handleOpenUpAndRunningDialog}
            onLogoutClick={logout}
            onChangeLanguageClick={onChangeLanguageClick}
          />
        )}

        {/* <div style={{ display: showDirectory ? 'block' : 'none', width: '100%', position: 'fixed', top: 64, paddingTop: 12, paddingBottom: 12, zIndex: 1200, background: Color.neutral[0] }}>
                        <PaddedContainer>
                            <Grid container spacing={0}>
                                {directories.map((dir, index) => (
                                    <Grid key={`directory${index}`} item xs={4} sm={4} md={4}>
                                        <Directory
                                            last={index === directories.length - 1}
                                            directory={dir}
                                            onClick={handleDirectoryOnClick(dir.label)}
                                            disabled={dir.disabled}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </PaddedContainer>
                    </div> */}

        <Switch>
          <PublicRoute component={LoginPage} restricted={true} exact path={LOGIN_PATH} />
          <PublicRoute component={Register} restricted={true} exact path={REGISTER_PATH} />

          <PrivateRoute component={Scenario} exact path={SCENARIO_PATH} />

          <PrivateRoute component={FunctionDetailsContainer} exact path={FUNCTION_DETAILS_PATH} />
          <PrivateRoute component={FunctionDetailsContainer} exact path={SPECIAL_FUNCTIONS_PATH} />

          {userInfo && userInfo.role !== 'PROSPECT' && (
            <PrivateRoute component={ManageMainDashboard} exact path={MANAGE_MAIN_DASHBOARD_PATH} />
          )}
          
          {userInfo && userInfo.role === 'ADMIN' && (
            <>
              <PrivateRoute component={AdminDashboard} exact path={ADMIN_DASHBOARD} />
              <PrivateRoute component={BlacklistedDevice} exact path={BLACKLISTED_DEVICE} />
            </>
          )}

          {/* <PrivateRoute component={CustomizeMainDashboard} exact path={CUSTOMIZE_MAIN_DASHBOARD_PATH} /> */}
          {/* <PrivateRoute component={ManagePermissionAssign} exact path={MANAGE_PERMISSION_ASSIGN_PATH} /> */}
          {/* <PrivateRoute component={ManageGlobalPermission} exact path={MANAGE_GLOBAL_PERMISSION_PATH} /> */}
          {/* <PrivateRoute component={ManageGlobalDevice} exact path={MANAGE_GLOBAL_DEVICE_PATH} /> */}
          {/* <PrivateRoute component={ManageGlobalUsers} exact path={MANAGE_GLOBAL_USERS_PATH} /> */}
          {/* <PrivateRoute component={ManageGlobalSigningKeys} exact path={MANAGE_GLOBAL_SIGNING_KEYS_PATH} /> */}
          {/* <PrivateRoute component={UpdateManagement} exact path={UPDATE_MANAGEMENT} /> */}

          <PrivateRoute component={Scenario} exact path={"*"} />
        </Switch>

        {/* <div
          id="button-up"
          className={classes.buttonUp}
          onClick={goToTop}
        >
          <ArrowUp style={{ width: 40, height: 40, color: ColorSAP.neutral[0] }} />
        </div> */}

        {/** Backdrop for login loading */}
        <LoginBackdrop open={isLoginLoading} />
        {/** Backdrop for function loading */}
        <GeneralBackdrop open={isFunctionLoading} />

        <CollectionCartDialog
          open={collectionCartDialogOpen}
          onClose={handleCloseCollectionCartDialog}
        />

        <PricingAndLicenseDialog
          open={pricingAndLicenseDialogOpen}
          onClose={handleClosePricingAndLicenseDialog}
          country={userInfo?.country}
        />

        <UpAndRunningDialog
          open={upAndRunningDialogOpen}
          onClose={handleCloseUpAndRunningDialog}
          country={userInfo?.country}
        />

        {/** Snackbar */}
        <Snackbar
          key={snackbarData.key}
          show={showSnackbar}
          onClick={() => { handleCloseSnackbar(); }}
          icon={<Info style={{ color: ColorSAP.primary[40] }} />}
          showButton={snackbarData.showButton}
          buttonLabel={snackbarData.buttonLabel}
          animationDuration={snackbarData.autoHideDuration}
          backgroundColor={ColorSAP.primary[60]}
          meterColor={ColorSAP.primary[80]}
        >
          {snackbarData.message}
        </Snackbar>
        <Snackbar
          key={errorSnackbarData.key}
          show={showErrorSnackbar}
          onClick={() => { handleCloseErrorSnackbar(); }}
          icon={<Info style={{ color: ColorSAP.danger[10] }} />}
          showButton={true}
          animationDuration={3000}
          backgroundColor={ColorSAP.danger[60]}
          meterColor={ColorSAP.danger[20]}
        >
          {snackbarErrorMessage ? snackbarErrorMessage : "Something went wrong!"}
        </Snackbar>

        <ScreenSizeWarningBackdrop open={isScreenSizeLimit} />
      </AppContextProvider>
    </RootContainer>
  )
}

export default App
