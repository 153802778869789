import React, { useEffect, useContext, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN_PATH } from '../../Constants/Path';
import { AppContext } from '../../App';
import AuthService from '../../Pages/Login/AuthService';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { userInfo, setUserInfo } = useContext(AppContext);
    const [ userInfoLoaded, setUserInfoLoaded ] = useState(false);

    useEffect(() => {
        if (!AuthService.checkIfTokenExpired()) {
            const info = AuthService.getUserInfo();

            AuthService.setUserToken(info);
            const decoded = jwtDecode(info);
            setUserInfo(decoded);
            setUserInfoLoaded(true);
        } else {
            setUserInfoLoaded(true);
            AuthService.logOut();
        }
    }, []);

    return (
        userInfoLoaded ?
            <Route {...rest} render={props => (
                userInfo ?
                    <Component {...props} /> : <Redirect to={LOGIN_PATH} />
            )} />
        : <span/>
    );
};

export default PrivateRoute;