// React Lib
import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../../App'

// @otg-one/ui_components
import { Typography, FONT_FAMILY, Color, ColorSAP } from '@commsult/ontego-ui'

// Reusable Components
import FunctionTypeChips from '../../ReusableComponents/FunctionTypeChips';

// Utils
import { isProcessAlreadyAddedToCollection, isProcessDataTypeAlreadyAddedToCollection, isProcessTypeAlreadyAddedToCollection, convertNotesToJSON, convertNotesToEditorState } from '../../Utils/Utils';
import { F_BESTELLERFASSUNG, F_EINLAGERUNG, F_AUSLAGERUNG, F_BESTANDSINFO, F_VERPACKUNG, F_KOMMISSIONIERUNG, F_UMBUCHUNG, F_UMLAGERUNG, F_VERLADUNG, F_WARENAUSGANG_EXTERN, F_WARENAUSGANG_INTERN, F_WARENEINGANG, F_INVENTUR, F_KANBAN, F_PRODUKTIONSVERSORGUNG, F_QUALITAETSKONTROLLE, F_RUECKMELDUNG_PRODUKTION, F_SONDERFUNKTIONEN_ERWEITERUNGEN } from '../../Constants/FunctionIds';

// Services
import AppService from '../../AppService';
import { useTranslation } from 'react-i18next';

//import TextEditor
import EditorComponent from '../../ReusableComponents/TextEditor/TextEditor';
import { EditorState } from 'draft-js'
import { Assignment, CollectionAdded, Delete, Edit } from '@commsult/ontego-ui/dist/sap_icons';
import Chip from '../../ReusableComponents/Chip/Chip';
import { Tabs } from '../../ReusableComponents/Tab/Tabs';
import Tooltip from '../../ReusableComponents/Tooltip/Tooltip';


const TabLabelChip = props => {
    const { type, isAdded } = props

    return (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', boxSizing: 'border-box', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
            {isAdded && (
                <Tooltip title="Zur Sammlung hinzugefügt" placement="right">
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: 8,
                            height: 8,
                            background: Color.alert[700],
                            borderRadius: "50%"
                        }}
                    />
                </Tooltip>
            )}
            <FunctionTypeChips type={type} />
        </div>
    )
}

const FunctionLists = props => {
    const { handleChangeInspectedFunctionId, collection, setCollection, handleShowErrorSnackbar, handleAddEditorStateList, editorStateList, setEditorStateList } = useContext(AppContext)
    const { func, search, inspectedFunction, setInspectedFunction, inspectedFunctionImage, appPreviewNavIndex, setAppPreviewNavIndex, handleOpenSpecialFunctionDialog, setIsEditingSpecialFunction, setInspectedSpecialFunctionData, handleAddToCollection, handleRemoveFromCollection, handleOpenDeleteDialog, userInfo, inspectedFunctionId, activeOption } = props
    const { t } = useTranslation()

    const [tabItems, setTabItems] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [showEditor, setShowEditor] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    window.xx = val => {
        let functionID = ""
        let toTop = true

        switch (val) {
            case "AUSLAGERUNG":
                functionID = F_AUSLAGERUNG
                break
            case "BESTANDSINFO":
                functionID = F_BESTANDSINFO
                break
            case "BESTELLUNG":
                functionID = F_BESTELLERFASSUNG
                break
            case "EINLAGERUNG":
                functionID = F_EINLAGERUNG
                break
            case "VERPACKUNG":
                functionID = F_VERPACKUNG
                break
            case "KOMMISSIONIERUNG":
                functionID = F_KOMMISSIONIERUNG
                break
            case "UMBUCHUNG":
                functionID = F_UMBUCHUNG
                break
            case "UMLAGERUNG":
                functionID = F_UMLAGERUNG
                break
            case "VERLADUNG":
                functionID = F_VERLADUNG
                break
            case "WARENAUSGANG_EXTERN":
                functionID = F_WARENAUSGANG_EXTERN
                break
            case "WARENAUSGANG_INTERN":
                functionID = F_WARENAUSGANG_INTERN
                break
            case "WARENEINGANG":
                functionID = F_WARENEINGANG
                break
            case "INVENTUR":
                functionID = F_INVENTUR
                break
            case "KANBAN":
                functionID = F_KANBAN
                break
            case "PRODUKTIONSVERSORGUNG":
                functionID = F_PRODUKTIONSVERSORGUNG
                break
            case "QUALITAETSKONTROLLE":
                functionID = F_QUALITAETSKONTROLLE
                break
            case "RUECKMELDUNG_PRODUKTION":
                functionID = F_RUECKMELDUNG_PRODUKTION
                break
            case "KANBAN_STATUSWECHSEL":
                functionID = F_KANBAN
                // toTop = false
                break
        }

        setAppPreviewNavIndex(0)
        handleChangeInspectedFunctionId(functionID)

        if (toTop) {
            window.scrollTo(0, 0)
        }
    }

    const handleChangeIndex = (index) => {
        const newInspectedFunction = { ...inspectedFunction }

        newInspectedFunction.data.forEach(inspectedFunc => {
            if (inspectedFunc.name === func.name) {
                inspectedFunc.activeIndex = index

                const currentImageLength = inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === inspectedFunc.dataType[index].systemID)[0].images.length

                if (appPreviewNavIndex >= currentImageLength) {
                    setAppPreviewNavIndex(currentImageLength - 1)
                }
                // setAppPreviewNavIndex(0)
            }
        })

        setInspectedFunction(newInspectedFunction)
    }

    useEffect(() => {
        const newTabItems = []

        func.dataType.forEach((dataType, index) => {
            newTabItems.push({
                id: `${func.name}-${index}`,
                label: <TabLabelChip type={dataType.type} isAdded={isProcessDataTypeAlreadyAddedToCollection(collection, dataType)} />,
                typeAmount: dataType.type.length
            })
        })

        setTabItems(newTabItems)
    }, [func, collection])

    useEffect(() => {
        setShowEditor(false)
        editorStateList.forEach(esl => {
            if (esl.function_id === "F_SONDERFUNKTIONEN_ERWEITERUNGEN") {
                if (esl.sub_function_id === func.dataType[0].systemID) {
                    setEditorState(esl.notes)

                    if (esl.notes.getCurrentContent().hasText()) {
                        setShowEditor(true)
                        setIsEdit(false)
                    }
                    else {
                        setShowEditor(false)
                    }
                }
            }
            else {
                if (esl.sub_function_id === func.name) {
                    setEditorState(esl.notes)

                    if (esl.notes.getCurrentContent().hasText()) {
                        setShowEditor(true)
                        setIsEdit(false)
                    }
                    else {
                        setShowEditor(false)
                    }
                }
            }
        })

    }, [editorStateList])

    const handleSaveChange = (editorState) => {
        const newData = {
            function_id: inspectedFunctionId,
            sub_function_id: (inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN' ? func.dataType[0].systemID : func.name),
            notes: editorState,
        }

        const isAvailable = editorStateList.some((data) => data.sub_function_id === newData.sub_function_id);

        if (!isAvailable) {
            const addValue = editorStateList;
            addValue.push(newData)

            handleAddEditorStateList(addValue);

        } else {
            const newState = editorStateList.map((data) => {
                if (data.sub_function_id === newData.sub_function_id) {
                    return newData
                }
                return data
            })

            const arr = [...collection]

            arr.forEach(collection => {
                collection.processes.forEach(process => {
                    if (process.systemID === (inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN' ? func.dataType[0].systemID : func.name)) {
                        process.notes = editorState
                    }
                })
            })

            AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(arr)).then(res => {
                handleAddEditorStateList(newState);
                setCollection(convertNotesToEditorState(arr))
            }).catch(error => handleShowErrorSnackbar(error));
        }
    }


    const handleOpenTextEditor = () => {
        return editorStateList.some((data) => data.sub_function_id === (inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN' ? func.dataType[0].systemID : func.name));
    }

    const handleDeleteNotes = () => {
        const removedData = editorStateList.filter(function (value) {
            return value.sub_function_id !== (inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN' ? func.dataType[0].systemID : func.name);
        })

        const isAvailable = removedData.some((data) => data.sub_function_id === (inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN' ? func.dataType[0].systemID : func.name));

        if (!isAvailable) {
            const arr = [...collection]

            arr.forEach(collection => {
                collection.processes.forEach(process => {
                    if (process.systemID === (inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN' ? func.dataType[0].systemID : func.name)) {
                        process.notes = EditorState.createEmpty()
                    }
                })
            })

            AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(arr)).then(res => {
                handleAddEditorStateList(removedData);
                setCollection(convertNotesToEditorState(arr))
            }).catch(error => handleShowErrorSnackbar(error));
        }

        setShowEditor(isAvailable);
    }

    const addEditorStateToList = () => {
        const newData = {
            function_id: inspectedFunctionId,
            sub_function_id: (inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN' ? func.dataType[0].systemID : func.name),
            notes: EditorState.createEmpty()
        }

        const isAvailable = editorStateList.some((data) => data.sub_function_id === newData.sub_function_id);

        if (!isAvailable) {
            const value = editorStateList;
            value.push(newData);
            handleAddEditorStateList(value);
        }

        setShowEditor(handleOpenTextEditor());
        setIsEdit(true)
    }

    return (
        <div style={{ position: 'relative' }}>
            <div id={func.name} style={{ userSelect: 'none', pointerEvents: 'none', color: 'transparent' }}>
                {`Anchor`}
            </div>
            <div
                // id={func.name}
                style={{ maxWidth: 450, marginTop: 185, marginLeft: 64 }}
            >
                <div
                // style={{ opacity: activeOption !== '' ? (activeOption === func.name ? 1 : 0.3) : 1, transition: "all 0.25s ease-in" }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Chip
                            style={{ visibility: isProcessAlreadyAddedToCollection(collection, func) ? 'visible' : 'hidden' }}
                            backgroundColor={ColorSAP.secondary[30]}
                            color={ColorSAP.neutral[90]}
                            icon={<CollectionAdded style={{ color: ColorSAP.secondary[60] }} />}
                        >
                            {t("functionDetails.added")}
                        </Chip>
                        {!showEditor && (
                            <Tooltip title={t('dialogs.addNote')} placement="top">
                                <div>
                                    <Assignment style={{ visibility: isProcessAlreadyAddedToCollection(collection, func) ? 'visible' : 'hidden', color: ColorSAP.primary[40], cursor: 'pointer' }} onClick={addEditorStateToList} />
                                </div>
                            </Tooltip>
                        )}
                    </div>

                    <div style={{ position: "relative", display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: inspectedFunction.systemID === F_SONDERFUNKTIONEN_ERWEITERUNGEN ? 5 : 0, marginTop: 12, wordBreak: 'break-word' }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={30}
                            color={ColorSAP.neutral[100]}
                            style={{ width: 350 }}
                        >

                            {inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN ? func.name : t(`processFunction.${inspectedFunction.systemID}.${func.name}.title`)}
                        </Typography>

                        {inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={t('common.edit')} placement="top">
                                    <div>
                                        <Edit
                                            style={{ color: ColorSAP.primary[50], marginRight: 24, cursor: 'pointer' }}
                                            onClick={() => {
                                                handleOpenSpecialFunctionDialog()
                                                setIsEditingSpecialFunction(true)
                                                setInspectedSpecialFunctionData(func)
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip title={t('common.delete')} placement="top">
                                    <div>
                                        <Delete
                                            style={{ color: ColorSAP.danger[50], cursor: 'pointer' }}
                                            onClick={() => {
                                                handleOpenDeleteDialog()
                                                setInspectedSpecialFunctionData(func)
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </div>

                    {func.dataType[0].type.length > 0 && inspectedFunction.systemID === F_SONDERFUNKTIONEN_ERWEITERUNGEN && (
                        func.dataType.map((dataType, index) => {
                            return <FunctionTypeChips key={`functionType${index}`} type={dataType.type} />
                        })
                    )}

                    {showEditor && (
                        <div style={{ marginTop: "16px" }}>
                            <EditorComponent
                                handleSaveChange={handleSaveChange}
                                handleDeleteNotes={handleDeleteNotes}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                                setShowEditor={setShowEditor}
                            />
                        </div>
                    )}

                    {func.dataType[0].type.length > 0 && inspectedFunction.systemID !== F_SONDERFUNKTIONEN_ERWEITERUNGEN && (
                        <Tabs
                            tabItems={tabItems}
                            activeIndex={func.activeIndex}
                            handleChangeIndex={handleChangeIndex}
                            style={{
                                marginTop: 24
                            }}
                        />
                    )}

                    {func.dataType[func.activeIndex].info.length > 0 && (
                        <>
                            {func.dataType[func.activeIndex].info.map((info, index) => (
                                <Typography
                                    key={`dataTypeInfo${index}`}
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                    size={14}
                                    // color={inspectedFunction.systemID === F_SONDERFUNKTIONEN_ERWEITERUNGEN ? ColorSAP.neutral[60] : ColorSAP.neutral[100]}
                                    color={ColorSAP.neutral[100]}
                                    style={{ width: 450, marginTop: 24 }}
                                >
                                    {<span dangerouslySetInnerHTML={{ __html: inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN ? info : t(`processFunction.${inspectedFunction.systemID}.${func.dataType[func.activeIndex].systemID}.info.${info}`) }} />}
                                </Typography>
                            ))}
                        </>
                    )}
                    {func.dataType[func.activeIndex].subInfo && (
                        <div style={{ width: 415, boxSizing: 'border-box', borderRadius: 8, padding: '16px 24px 16px 24px', marginTop: 40, background: ColorSAP.neutral[15] }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                size={20}
                                color={ColorSAP.neutral[100]}
                            >
                                {t(`processFunction.${inspectedFunction.systemID}.${func.dataType[func.activeIndex].systemID}.subInfo.${func.dataType[func.activeIndex].subInfo.name}`)}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={14}
                                color={ColorSAP.neutral[100]}
                                style={{ marginTop: 16 }}
                            >
                                {<span dangerouslySetInnerHTML={{ __html: t(`processFunction.${inspectedFunction.systemID}.${func.dataType[func.activeIndex].systemID}.subInfo.${func.dataType[func.activeIndex].subInfo.info}`) }} />}
                            </Typography>
                        </div>
                    )}
                    {inspectedFunction.systemID === F_SONDERFUNKTIONEN_ERWEITERUNGEN && (
                        <>
                            <div style={{ width: '100%', height: 50, display: 'flex', alignItems: "center", marginTop: 24 }}>

                                {(func && func.dataType[func.activeIndex].isCollectible) && (
                                    <>
                                        {isProcessTypeAlreadyAddedToCollection(collection, func) ? (
                                            <div style={{ width: inspectedFunction.systemID === F_SONDERFUNKTIONEN_ERWEITERUNGEN ? '100%' : 230, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                <props.removeCollectionButton activeData={func} handleRemoveFromCollection={handleRemoveFromCollection} />
                                            </div>
                                        ) : (
                                            <props.addCollectionButton activeData={func} handleAddToCollection={handleAddToCollection} />
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FunctionLists