import { Color, ColorSAP } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        totalCostContainer: {
            display: 'flex',
            flexDirection: 'column',
            boxSizing: "border-box",
            padding: '32px 40px 32px 40px',
            borderRadius: 16,
            width: "100%",
            height: "100%",
            backgroundColor: ColorSAP.primary[70],
            color: Color.neutral[0],
            // [theme.breakpoints.down('sm')]: {
            //     padding: 15,
            // }
        },
        totalCost: {
            textAlign: "center",
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginTop: 0,
        // }
    })
);