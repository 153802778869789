// React Lib
import React from "react";

// Component Styles
import { useStyles } from "./LanguageSwitcherStyles";

import { Typography, FONT_FAMILY, ColorSAP } from "@commsult/ontego-ui";


export const LanguageSwitcher = props => {
    const { i18n, onChangeLanguageClick } = props
    const classes = useStyles(props)
    return (
        <div
            className={classes.container}
        >
            <div
                style={{ width: '50%', height: '100%', borderTopLeftRadius: 2, borderBottomLeftRadius: 2, background: i18n.language === 'en' ? ColorSAP.neutral[0] : 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', transition: '0.3s all ease-in-out' }}
                onClick={() => onChangeLanguageClick('en')}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    size={14}
                    color={i18n.language === 'en' ? ColorSAP.primary[70] : ColorSAP.neutral[0]}
                    className={classes.languageDe}
                    style={{
                        fontStyle: i18n.language === 'en' && 'italic'
                    }}
                >
                    {`EN`}
                </Typography>
            </div>
            <div
                style={{ width: '50%', height: '100%', borderTopRightRadius: 2, borderBottomRightRadius: 2, background: i18n.language === 'de' ? ColorSAP.neutral[0] : 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', transition: '0.3s all ease-in-out' }}
                onClick={() => onChangeLanguageClick('de')}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    size={14}
                    color={i18n.language === 'de' ? ColorSAP.primary[70] : ColorSAP.neutral[0]}
                    className={classes.languageDe}
                    style={{
                        fontStyle: i18n.language === 'de' && 'italic'
                    }}
                >
                    {`DE`}
                </Typography>
            </div>
        </div >
    )
}