// Material-UI Styles
import { ColorSAP } from '@commsult/ontego-ui';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
export const useStyles = makeStyles(theme =>
    createStyles({
        backdrop: {
            zIndex: 100000,
            background: `rgba(0, 82, 127, 0.8)`,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            overflow: "hidden"
        },
        "@keyframes infinite-rotate": {
            "0%": {
                transform: 'rotate(0deg)',
            },
            "100%": {
                transform: 'rotate(360deg)'
            }
        },
        loading: {
            "& svg": {
                width: 104,
                height: 104,
                color: ColorSAP.primary[40],
                animation: '$infinite-rotate 1s linear infinite'
            }
        }
    })
);