// React Lib
import React, { useContext, useEffect, useState } from "react";
import classnames from 'classnames';

import { CopyToClipboard as CopyFunction } from 'react-copy-to-clipboard';

// Component Styles
import { useStyles } from "./FunctionCardCollectionStyles";
import Button from "../Button/Button";
import AddNoteIconButton from "../AddNoteIconButton";
import { Chip, Color, ColorSAP, CopyToClipboard, FONT_FAMILY, FUNCTION_TYPE, IconButton, Separator, Typography, getFunctionTypeBackgroundColor, getFunctionTypeLabelColor } from "@commsult/ontego-ui";
import { AddCollection, Delete, Edit, GoodsReceipt, ShowMoreVertical, Copy } from "@commsult/ontego-ui/dist/sap_icons";
import Form from "../Form/Form";
import TertiaryButton from "../TertiaryButton/TertiaryButton";
import { useTranslation } from "react-i18next";
import { F_SONDERFUNKTIONEN_ERWEITERUNGEN } from "../../Constants/FunctionIds";
import { OverflowMenu } from "../OverflowMenu/OverflowMenu";
import EditorComponent from "../TextEditor/TextEditor";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { AppContext } from "../../App";
import { convertJSONtoEditorState, iconSizeTertiaryButtonIcon } from "../../Utils/Utils";
import PlusIcon from "../../Icons/PlusIcon";

const NoteRenderer = (props) => {
    const classes = useStyles(props)
    const { t } = useTranslation()

    const { editorStateList, setEditorStateList } = useContext(AppContext)

    const { data, type, isDragging, isHover, hasUpAndRunning, notes, setNotes, onEditNote, onSaveNote, onDeleteNote, onCopyNote,
        addNote, functionNote, edit, copyToClipboard, deleteText, addFunctionNote, cancel, saveNote, text } = props

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [showEditor, setShowEditor] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        if (typeof data.notes === 'string') {
            data.notes = convertJSONtoEditorState(data.notes)
        }

        setShowEditor(false)
        setEditorState(data.notes)

        if (data.notes.getCurrentContent().hasText()) {
            setShowEditor(true)
            setIsEdit(false)
        }
    }, [])

    const handleAddNewNote = () => {
        setShowEditor(true)
        setIsEdit(true)
    }

    const handleSaveNote = (editorState) => {
        onSaveNote(data, type, editorState)

        const newEditorStateList = [...editorStateList]

        newEditorStateList.forEach(editorStateData => {
            if (editorStateData.sub_function_id === data.systemID) {
                editorStateData.notes = editorState
            }
        })

        setEditorStateList(newEditorStateList)
    }

    const handleDeleteNote = () => {
        onDeleteNote(data, type)

        const newEditorStateList = [...editorStateList]

        newEditorStateList.forEach(editorStateData => {
            if (editorStateData.sub_function_id === data.systemID) {
                editorStateData.notes = EditorState.createEmpty()
            }
        })

        setEditorStateList(newEditorStateList)
        setShowEditor(false)
    }

    return (
        <>
            {(!showEditor) && (
                <TertiaryButton
                    theme={(isHover || isDragging) ? 'dark' : 'primary'}
                    size="small"
                    onClick={handleAddNewNote}
                    style={{ color: ColorSAP.neutral[0] }}
                    icon={<PlusIcon style={{ marginLeft: 8, width: iconSizeTertiaryButtonIcon("small"), height: iconSizeTertiaryButtonIcon("small") }} />}

                >
                    {t('dialogs.addNote')}
                </TertiaryButton>
            )}
            {showEditor && (
                <div>
                    {/* <div className={classes.functionBoxCollectionNoteActionsContainer}> */}
                    <EditorComponent
                        handleSaveChange={handleSaveNote}
                        handleDeleteNotes={handleDeleteNote}
                        editorState={editorState}
                        setEditorState={setEditorState}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        setShowEditor={setShowEditor}
                    />
                    {/* <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {functionNote}
                    </Typography> */}
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Edit
                            onClick={onEditNote(data, type)}
                            style={{
                                color: ColorSAP.primary[40],
                                cursor: 'pointer',
                                marginRight: 8
                            }}
                        />
                        <CopyFunction
                            onCopy={onCopyNote}
                            text={data.notes}>
                            <Copy
                                onClick={copyToClipboard}
                                style={{
                                    color: ColorSAP.primary[40],
                                    cursor: 'pointer',
                                    marginRight: 8
                                }}
                            />
                        </CopyFunction>
                        <Delete
                            onClick={onDeleteNote(data, type)}
                            style={{
                                color: ColorSAP.danger[50],
                                cursor: 'pointer'
                            }}
                        />
                    </div> */}
                    {/* </div> */}
                    {/* 
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={12}
                    color={ColorSAP.neutral[100]}
                    style={{ marginTop: 20 }}
                >
                    {data.notes}
                </Typography> */}
                </div>
            )}
        </>
    )
}

NoteRenderer.defaultProps = {
    addNote: 'Add Note',
    functionNote: 'Function Note',
    edit: 'Edit',
    copyToClipboard: 'Copy to Clipboard',
    deleteText: 'Delete Text',
    addFunctionNote: 'Add Function Note',
    cancel: 'Cancel',
    saveNote: 'Save Note'
}

const ProcessRenderer = (props) => {
    const classes = useStyles(props)

    const { func, process, icon, isDragging, isHover, hasUpAndRunning, onEditNote,
        onSaveNote, onDeleteNote, onCopyNote, handleRemoveProcess, addNote, functionNote,
        edit, copyToClipboard, deleteText, addFunctionNote, cancel, saveNote, menu, text } = props

    const [anchorEl, setAnchorEl] = useState(null)
    const [notes, setNotes] = useState(process.notes)

    const { t } = useTranslation();

    const handleClickShowMoreIcon = (event) => {
        event.stopPropagation();

        if (!anchorEl) {
            setAnchorEl(event.currentTarget)
        }
    }

    const primaryMenu = [
        // {
        //     text: [t(`${menu[0]}`)],
        //     // text: { functionDetail },
        //     onClick: () => { }
        // },
        {
            text: [t(`${menu[1]}`)],
            // text: { removeFromCollection },
            onClick: () => handleRemoveProcess(func, process),
            destructive: true
        }
    ]

    return (
        <>
            <Separator width={2} type="n200" marginTop="10px" />
            <div className={classes.functionCardCollectionTextIconContainer}>
                <div className={classes.functionCardCollectionTextIcon}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={14}
                        color={(isHover || isDragging) ? Color.neutral[0] : ColorSAP.neutral[100]}
                    >
                        {func.systemID === F_SONDERFUNKTIONEN_ERWEITERUNGEN ? process.name : t(`processFunction.${func.systemID}.${process.name}.title`)}
                    </Typography>
                </div>
                <ShowMoreVertical
                    style={{
                        position: 'absolute',
                        top: 16,
                        right: 0,
                        cursor: 'pointer',
                        color: ColorSAP.primary[40]
                    }}
                    onClick={handleClickShowMoreIcon}
                />
                <OverflowMenu
                    anchorElement={anchorEl}
                    setAnchorEl={setAnchorEl}
                    menuItems={primaryMenu}
                />
            </div>

            <div className={classes.functionCollectionChipContainer}>
                {process.type.includes(FUNCTION_TYPE.MM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.MM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.MM)}
                    >
                        {FUNCTION_TYPE.MM}
                    </Chip>
                )}
                {process.type.includes(FUNCTION_TYPE.SD) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.SD)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.SD)}
                        style={{ marginLeft: process.type[0] !== FUNCTION_TYPE.SD && 8 }}
                    >
                        {FUNCTION_TYPE.SD}
                    </Chip>
                )}
                {process.type.includes(FUNCTION_TYPE.PP) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.PP)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.PP)}
                        style={{ marginLeft: process.type[0] !== FUNCTION_TYPE.PP && 8 }}
                    >
                        {FUNCTION_TYPE.PP}
                    </Chip>
                )}
                {process.type.includes(FUNCTION_TYPE.WM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.WM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.WM)}
                        style={{ marginLeft: process.type[0] !== FUNCTION_TYPE.WM && 8 }}
                    >
                        {FUNCTION_TYPE.WM}
                    </Chip>
                )}
                {process.type.includes(FUNCTION_TYPE.EWM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.EWM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.EWM)}
                        style={{ marginLeft: process.type[0] !== FUNCTION_TYPE.EWM && 8 }}
                    >
                        {FUNCTION_TYPE.EWM}
                    </Chip>
                )}
                {process.type.includes(FUNCTION_TYPE.QM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.QM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.QM)}
                        style={{ marginLeft: process.type[0] !== FUNCTION_TYPE.QM && 8 }}
                    >
                        {FUNCTION_TYPE.QM}
                    </Chip>
                )}
            </div>

            <div style={{ marginTop: 6 }} />

            <NoteRenderer
                data={process}
                icon={icon}
                isDragging={isDragging}
                isHover={isHover}
                type="PROCESS"
                onEditNote={onEditNote}
                onSaveNote={onSaveNote}
                onDeleteNote={onDeleteNote}
                onCopyNote={onCopyNote}
                hasUpAndRunning={hasUpAndRunning}
                notes={notes}
                setNotes={setNotes}
                addNote={addNote}
                functionNote={functionNote}
                edit={edit}
                copyToClipboard={copyToClipboard}
                deleteText={deleteText}
                addFunctionNote={addFunctionNote}
                cancel={cancel}
                saveNote={saveNote}
                text={text}
            />
        </>
    )
}

ProcessRenderer.defaultProps = {
    handleRemoveFunction: () => { },
    menu: ['Function Detail', 'functionDetails.removeFromCollection']
}

const FunctionRenderer = (props) => {
    const classes = useStyles(props)

    const { id, func, icon, isDragging, isHover, onEditNote, onSaveNote, onDeleteNote, onCopyNote, handleRemoveFunction,
        handleRemoveProcess, addNote, functionNote, edit, copyToClipboard, deleteText,
        addFunctionNote, cancel, saveNote, menu, text } = props

    const [anchorEl, setAnchorEl] = useState(null)
    const [notes, setNotes] = useState(func.notes)

    const { t } = useTranslation()

    const handleClickShowMoreIcon = (event) => {
        if (!anchorEl) {
            document.getElementById(id).focus()
            setAnchorEl(event.currentTarget)
        }
    }

    const primaryMenu = [
        // {
        //     text: [menu[0]],
        //     onClick: () => { }
        // },
        {
            text: t(`${menu[1]}`),
            onClick: () => handleRemoveFunction(func),
            destructive: true
        }
    ]

    return <>
        <Typography
            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
            size={10}
            color={(isHover || isDragging) ? Color.neutral[0] : Color.neutral[400]}
        >
            {t(`scenario.${func.typeOf}`)}
        </Typography>
        <div className={classes.functionCardCollectionTextIconContainer} style={{ marginBottom: 16 }}>
            <div className={classes.functionCardCollectionTextIcon}>
                {icon}
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={18}
                    color={(isHover || isDragging) ? Color.neutral[0] : ColorSAP.neutral[100]}
                >
                    {t(`scenario.${func.name}`)}
                </Typography>
            </div>
            <ShowMoreVertical
                style={{
                    position: 'absolute',
                    top: 16,
                    right: 0,
                    cursor: 'pointer',
                    color: ColorSAP.primary[40]
                }}
                onClick={handleClickShowMoreIcon}
            />
            <OverflowMenu
                anchorElement={anchorEl}
                setAnchorEl={setAnchorEl}
                menuItems={primaryMenu}
            />
        </div>
        <NoteRenderer
            data={func}
            type="FUNCTION"
            icon={icon}
            isDragging={isDragging}
            isHover={isHover}
            onEditNote={onEditNote}
            onSaveNote={onSaveNote}
            onDeleteNote={onDeleteNote}
            onCopyNote={onCopyNote}
            hasUpAndRunning={func.upAndRunningId !== ''}
            notes={notes}
            setNotes={setNotes}
            addNote={addNote}
            functionNote={functionNote}
            edit={edit}
            copyToClipboard={copyToClipboard}
            deleteText={deleteText}
            addFunctionNote={addFunctionNote}
            cancel={cancel}
            saveNote={saveNote}
            text={text}
        />
        {func.processes.map(process => (
            <ProcessRenderer
                func={func}
                process={process}
                icon={icon}
                isDragging={isDragging}
                isHover={isHover}
                onEditNote={onEditNote}
                onSaveNote={onSaveNote}
                onDeleteNote={onDeleteNote}
                onCopyNote={onCopyNote}
                hasUpAndRunning={func.upAndRunningId !== ''}
                handleRemoveFunction={handleRemoveFunction}
                handleRemoveProcess={handleRemoveProcess}
                addNote={addNote}
                functionNote={functionNote}
                edit={edit}
                copyToClipboard={copyToClipboard}
                deleteText={deleteText}
                addFunctionNote={addFunctionNote}
                cancel={cancel}
                saveNote={saveNote}
                menu={menu}
                text={text}
            />
        ))}
    </>;
}

FunctionRenderer.defaultProps = {
    menu: ['functionDetails.functionDetail', 'functionDetails.removeFromCollection']
}

export const FunctionCardCollection = (props) => {
    const classes = useStyles(props)

    const { id, func, icon, isDragging, onEditNote, onSaveNote, onDeleteNote,
        onCopyNote, handleRemoveFunction, handleRemoveProcess, addNote, functionNote,
        edit, copyToClipboard, deleteText, addFunctionNote, cancel, saveNote, menu, text
    } = props

    const [isHover, setIsHover] = useState(false)

    const handleOnMouseOver = () => setIsHover(true)
    const handleOnMouseOut = () => setIsHover(false)

    const functionBoxCollectionIconClasses = classnames({
        [classes.functionCardCollectionContainer]: true,
        [classes.functionCardCollectionContainerDragged]: isDragging,
    })

    const functionBoxCollectionIconClassesActive = classnames({
        [classes.functionCardCollectionContainerActive]: true,
        [classes.functionCardCollectionContainerDragged]: isDragging,
    })

    return (
        <div
            id={id}
            className={isHover ? functionBoxCollectionIconClassesActive : functionBoxCollectionIconClasses}
            onMouseEnter={handleOnMouseOver}
            onMouseLeave={handleOnMouseOut}
        >
            <FunctionRenderer
                id={id}
                func={func}
                icon={icon}
                isDragging={isDragging}
                isHover={isHover}
                onEditNote={onEditNote}
                onSaveNote={onSaveNote}
                onDeleteNote={onDeleteNote}
                onCopyNote={onCopyNote}
                handleRemoveFunction={handleRemoveFunction}
                handleRemoveProcess={handleRemoveProcess}
                addNote={addNote}
                functionNote={functionNote}
                edit={edit}
                copyToClipboard={copyToClipboard}
                deleteText={deleteText}
                addFunctionNote={addFunctionNote}
                cancel={cancel}
                saveNote={saveNote}
                menu={menu}
                text={text}
            />
        </div>
    )
}

FunctionCardCollection.defaultProps = {
    func: {
        systemID: `12345`,
        name: `Einlagerung`,
        typeOf: `WAREHOUSE_LOGISTICS`,
        processes: [
            {
                systemID: "TEST_1",
                name: "Einlagerung zum Transportbedarf",
                type: [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM],
                notes: ""
            },
            {
                systemID: "TEST_2",
                name: "Lagereinheit einlagern",
                type: [FUNCTION_TYPE.EWM],
                notes: ""
            }
        ],
        notes: "THIS IS A VERY LONG NOTE",
        edit: 'Edit',
        isEditMode: false
    },
    text: {
        addNote: 'Add Note!',
        functionNote: 'Function Note',
        edit: 'Edit',
        copyToClipboard: 'Copy to Clipboard',
        deleteText: 'Delete Text',
        addFunctionNote: 'Add Function Note',
        cancel: 'Cancel',
        saveNote: 'Save Note'
    },
    icon: <GoodsReceipt />,
    isDragging: false,
    onEditNote: () => { },
    onSaveNote: () => { },
    onDeleteNote: () => { },
    onCopyNote: () => { }
}

export default React.memo(FunctionCardCollection)