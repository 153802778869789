import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: window.localStorage.lang,
        backend: {
            /* translation file path */
            loadPath: './assets/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'de',
        debug: true,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            useSuspense: false,
            wait: true
        }
    });

export default i18n