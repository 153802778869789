import React from 'react';
import { Chip, getFunctionTypeBackgroundColor, getFunctionTypeLabelColor, FUNCTION_TYPE, FONT_FAMILY } from '@commsult/ontego-ui';

const FunctionTypeChips = props => {
    const { type } = props
    return (
        <>
            {type.includes(FUNCTION_TYPE.MM) && (
                <Chip
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.MM)}
                    color={getFunctionTypeLabelColor(FUNCTION_TYPE.MM)}
                >
                    {FUNCTION_TYPE.MM}
                </Chip>
            )}
            {type.includes(FUNCTION_TYPE.SD) && (
                <Chip
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.SD)}
                    color={getFunctionTypeLabelColor(FUNCTION_TYPE.SD)}
                    style={{ marginLeft: type[0] !== FUNCTION_TYPE.SD && 8 }}
                >
                    {FUNCTION_TYPE.SD}
                </Chip>
            )}
            {type.includes(FUNCTION_TYPE.PP) && (
                <Chip
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.PP)}
                    color={getFunctionTypeLabelColor(FUNCTION_TYPE.PP)}
                    style={{ marginLeft: type[0] !== FUNCTION_TYPE.PP && 8 }}
                >
                    {FUNCTION_TYPE.PP}
                </Chip>
            )}
            {type.includes(FUNCTION_TYPE.WM) && (
                <Chip
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.WM)}
                    color={getFunctionTypeLabelColor(FUNCTION_TYPE.WM)}
                    style={{ marginLeft: type[0] !== FUNCTION_TYPE.WM && 8 }}
                >
                    {FUNCTION_TYPE.WM}
                </Chip>
            )}
            {type.includes(FUNCTION_TYPE.EWM) && (
                <Chip
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.EWM)}
                    color={getFunctionTypeLabelColor(FUNCTION_TYPE.EWM)}
                    style={{ marginLeft: type[0] !== FUNCTION_TYPE.EWM && 8 }}
                >
                    {FUNCTION_TYPE.EWM}
                </Chip>
            )}
            {type.includes(FUNCTION_TYPE.QM) && (
                <Chip
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.QM)}
                    color={getFunctionTypeLabelColor(FUNCTION_TYPE.QM)}
                    style={{ marginLeft: type[0] !== FUNCTION_TYPE.QM && 8 }}
                >
                    {FUNCTION_TYPE.QM}
                </Chip>
            )}
        </>
    )
}

export default React.memo(FunctionTypeChips)