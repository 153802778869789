// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color, ColorSAP, FONT_FAMILY } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        configurationHeader: {
            boxSizing: "border-box",
            width: "100%",
            background: Color.neutral[0],
            position: "sticky",
            top: 64,
            zIndex: 9000,
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            alignItems: "flex-end"
        },

        sidebarContainer: {
            position: "fixed",
            top: 170,
            width: 340,
            // height: "80%",
            // background: 'gray'
        },
        sidebarDrawerContainer: {
            marginTop: 50,
            borderRight: `2px solid ${Color.neutral[100]}`,
            paddingRight: 24,
            overflow: "auto",
            "&::-webkit-scrollbar": {
                webkitAppearance: 'none',
                width: 7,
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: 100,
                backgroundColor: ColorSAP.secondary[15],
                paddingRight: 9
            }
        },
        sidebarTitle: {
            display: "inline-flex",
            alignItems: "center",
            "& svg": {
                width: 24,
                height: 24,
                color: Color.primary[500],
                marginRight: 8
            }
        },

        addedToCollection: {
            transform: "translateX(40px)",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: "fixed",
            top: 139,
            width: 220,
        },
        addToCollection: {
            transform: "translateX(22px)",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: "fixed",
            top: 129,
            width: 258,
        },

        disabledCarouselArrow: {
            "& svg": {
                color: ColorSAP.primary[70],
                opacity: 0.5,
                transition: '0.15s all ease-in-out',
            },
        },

        carouselArrow: {
            "& svg": {
                color: ColorSAP.primary[70],
                opacity: 0.5,
                transition: '0.15s all ease-in-out',
            },
            "&:hover": {
                "& svg": {
                    color: ColorSAP.primary[70],
                    opacity: 1,
                    transition: '0.15s all ease-in-out',
                },
            }
        },

        appPreviewContainer: {
            position: "fixed",
            transform: "translateX(42px)",
            top: 191.65,
            width: 220,
            height: 390.347,
            zIndex: 1000,
            textAlign: "center",
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM,
            borderRadius: 8
        },

        appPreviewWrapper: {
            position: 'relative',
        },

        appPreviewImageContainer: {
            position: 'relative',
            boxShadow: '0px 2px 29px 0px rgba(0, 0, 0, 0.12);',
            borderRadius: 8,
            background: '',
            userSelect: 'none'
        },

        appPreviewTextContainer: {
            width: '100%',
            textWrap: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: "absolute",
            bottom: -36
        },

        carouselContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: "absolute",
            bottom: -66,
        },

        noDataLayout: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "-webkit-center"
        }
    })
) 