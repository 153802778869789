import axios from 'axios';

import { USER_API_BASE_CLIENTS_URL, getApiUrl } from './Constants/Api';

export class AppService {
    getClientInfo = (clientKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/info`);
    }
    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }
    getAuthHeader() {
        return { headers: { "Authorization": this.getUserInfo() } };
    }

    getFunctionCollection(clientKey) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/functionCollection`);
    }
    updateFunctionCollection(clientKey, collection) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/functionCollection`, { functionCollection: collection }, this.getAuthHeader());
    }

    getUpAndRunningCollection(clientKey) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/upAndRunning`);
    }
    updateUpAndRunningCollection(clientKey, collection) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/upAndRunning`, { upAndRunningCollection: collection }, this.getAuthHeader());
    }

    getSpecialFunctions = (clientKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/specialfunctions`);
    }
    updateSpecialFunctions = (clientKey, functions) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/specialfunctions`, functions);
    }
}

export default new AppService();