import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';

import { useStyles } from './DeleteDialogStyles';

import { useTranslation } from 'react-i18next';

// Components
import {
    Checkbox,
    Typography,
    Separator,
    FONT_FAMILY,
    Color,
    Form,
    Warehouse,
    ProductionEHP,
    USER_TYPE,
    Edit,
    Plus,
    Password,
    ColorSAP,
    Info
} from '@commsult/ontego-ui';

import Grid from '@material-ui/core/Grid';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Button from '../../ReusableComponents/Button/Button';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';

const DeleteDialog = props => {
    const classes = useStyles(props)
    const { title, dataName, open, onClose, onDelete } = props

    const { t } = useTranslation();

    const handleClose = () => {
        onClose()
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={t("manage.deleteObject", { object: t(title) })}
            content={
                <>
                    <div
                        style={{
                            width: 508,
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 40,
                            boxSizing: 'border-box'
                        }}
                    >
                        <Grid container spacing={0} style={{ marginBottom: 24, marginTop: 24 }}>
                            <Grid item md={12}>
                                <div style={{ padding: "16px 24px 16px 24px", boxSizing: 'border-box', background: ColorSAP.secondary[30], borderRadius: 8, display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}>
                                    <Info
                                        style={{
                                            width: 16,
                                            height: 16,
                                            color: ColorSAP.secondary[60],
                                            marginRight: 8
                                        }}
                                    />
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                        size={14}
                                        color={ColorSAP.neutral[100]}
                                    >
                                        {t("manage.deleteObject", { object: dataName })}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item md={12}>
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                    size={14}
                                    color={ColorSAP.neutral[100]}
                                    style={{ marginTop: 8 }}
                                >
                                    {t("manage.deleteConfirmDesc")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 30 }}>
                            <Grid item md={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <TertiaryButton
                                        theme="light"
                                        size="small"
                                        onClick={handleClose}
                                        noIcon={true}
                                    >
                                        {t(`manage.cancel`)}
                                    </TertiaryButton>

                                    <Button
                                        type="primary"
                                        theme="danger"
                                        size="small"
                                        onClick={() => {
                                            onDelete()
                                            handleClose()
                                        }}
                                    >
                                        {t(`common.delete`)}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            }
        />
    )
};

export default DeleteDialog;