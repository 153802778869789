import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SCENARIO_PATH } from '../../Constants/Path';
import AuthService from '../../Pages/Login/AuthService';

const PublicRoute = ({ component: Component, restricted = false, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            (AuthService.getUserInfo() != null && restricted) ?
                <Redirect to={SCENARIO_PATH} />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;