// React libraries
import React from 'react';

import { Tooltip as MUITooltip } from '@material-ui/core';

// Etc
import { useStyles } from './TooltipStyles';
import { ColorSAP, FONT_FAMILY, Typography } from '@commsult/ontego-ui';


export const Tooltip = (props) => {
    const { enterNextDelay, leaveDelay } = props;

    const { ...classes } = useStyles(props);

    return (
        <MUITooltip
            classes={classes}
            arrow
            {...props}
            title={
                <React.Fragment>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[0]}
                    >
                        {props.title}
                    </Typography>
                    {props?.subTitle}
                </React.Fragment>
            }
            enterNextDelay={enterNextDelay}
            leaveDelay={leaveDelay}
        />
    );
}

export default Tooltip