// React Lib
import React, { useState, useEffect } from "react";

// Component Styles
import { useStyles } from "./OverflowMenuSelectionStyles";
import { Menu, MenuItem } from "@material-ui/core";
import { Check, Color } from "@commsult/ontego-ui";

export const OverflowMenuSelection = (props) => {
    const classes = useStyles(props);
    const [activeItem, setActiveItem] = useState("");

    const { id, setValue, anchorElement, menuItems, setAnchorEl, selectedItem, withCheckMark, menuWidth } = props;

    useEffect(() => {
        setActiveItem(selectedItem);
    }, []);

    const closeAllMenus = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (menuItem) => () => {
        setActiveItem(menuItem.text);
        setValue(menuItem.text);
        menuItem.onClick();
        closeAllMenus();
    };

    const renderMenuItem = (menuItem) => {
        const isSelected = activeItem === menuItem.text;

        return (
            <MenuItem
                key={menuItem.text}
                className={classes.menuItem}
                onClick={handleItemClick(menuItem)}
            >
                {withCheckMark && (
                    <div
                        className={classes.menuItemCheckIcon}
                        style={{
                            color: isSelected ? Color.primary[500] : "transparent"
                        }}
                    >
                        <Check />
                    </div>
                )}
                <div className={classes.menuItemText}>
                    {menuItem.text}
                </div>
            </MenuItem>
        );
    };

    return (
        <Menu
            id={id}
            anchorEl={anchorElement}
            classes={{
                paper: classes.menuRoot,
                list: classes.menuList
            }}
            PopoverClasses={{
                root: classes.popoverRoot
            }}
            keepMounted
            open={Boolean(anchorElement)}
            onClose={closeAllMenus}
        >
            {menuItems.map(menuItem => renderMenuItem(menuItem))}
        </Menu>
    );
}