import axios from 'axios';
import { USER_API_BASE_AUTH_URL, getApiUrl } from '../../Constants/Api';
import { jwtDecode } from 'jwt-decode';

export class AuthService {
    login(credentials) {
        return axios.post(getApiUrl() + USER_API_BASE_AUTH_URL, credentials);
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        return { headers: { "Authorization": this.getUserInfo() } };
    }

    logOut() {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("inspectedFunction");
        localStorage.removeItem("inspectedFunctionId");
        localStorage.removeItem("collection");
        this.setUserToken("");
    }

    setUserToken(token) {
        axios.defaults.headers.common['Authorization'] = token;
    }

    checkIfTokenExpired = () => {
        const userInfo = this.getUserInfo();
        if (!userInfo) return true;

        const decoded = jwtDecode(userInfo);
        return decoded.exp < Date.now() / 1000;
    };
}

export default new AuthService();