import React from 'react';
import { Typography, FONT_FAMILY, ColorSAP, Mobile } from '@commsult/ontego-ui';
import { useTranslation } from 'react-i18next';

import { PaddedContainer, RootWrapperContainer } from '../../App'
import ManageBlacklistedDevice from '../Manage/ManageBlacklistedDevice';


const BlacklistedDevice = () => {
    const { t } = useTranslation();
    return (
        <RootWrapperContainer>
            <div style={{ width: '100%', marginTop: 24, boxSizing: 'border-box', paddingBottom: 90 }}>
                <PaddedContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Mobile style={{ width: 24, height: 24, color: ColorSAP.primary[70], marginRight: 12 }} />
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={18}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.blacklistedDevice`)}
                        </Typography>
                    </div>
                    <ManageBlacklistedDevice />
                </PaddedContainer>
            </div>
        </RootWrapperContainer>
    );
}

export default BlacklistedDevice
