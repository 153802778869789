import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** INVENTUR IMAGES */
import Inventur_Einfuehrung_MM1 from '../../assets/img/inventur/Einfuehrung_MM/1.png';
import Inventur_Einfuehrung_MM2 from '../../assets/img/inventur/Einfuehrung_MM/2.png';

import Inventur_Einfuehrung_WM_EWM1 from '../../assets/img/inventur/Einfuehrung_WM_EWM/1.png';
import Inventur_Einfuehrung_WM_EWM2 from '../../assets/img/inventur/Einfuehrung_WM_EWM/2.png';
import Inventur_Einfuehrung_WM_EWM3 from '../../assets/img/inventur/Einfuehrung_WM_EWM/3.png';
import Inventur_Einfuehrung_WM_EWM4 from '../../assets/img/inventur/Einfuehrung_WM_EWM/4.png';
import Inventur_Einfuehrung_WM_EWM5 from '../../assets/img/inventur/Einfuehrung_WM_EWM/5.png';

import Inventur_PermanenteInventurImLagerort_MM1 from '../../assets/img/inventur/PermanenteInventurImLagerort_MM/1.png';
import Inventur_PermanenteInventurImLagerort_MM2 from '../../assets/img/inventur/PermanenteInventurImLagerort_MM/2.png';
import Inventur_PermanenteInventurImLagerort_MM3 from '../../assets/img/inventur/PermanenteInventurImLagerort_MM/3.png';
import Inventur_PermanenteInventurImLagerort_MM4 from '../../assets/img/inventur/PermanenteInventurImLagerort_MM/4.png';
import Inventur_PermanenteInventurImLagerort_MM5 from '../../assets/img/inventur/PermanenteInventurImLagerort_MM/5.png';

import Inventur_PermanenteInventurImLagerort_WM_EWM1 from '../../assets/img/inventur/PermanenteInventurImLagerort_WM_EWM/1.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM2 from '../../assets/img/inventur/PermanenteInventurImLagerort_WM_EWM/2.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM3 from '../../assets/img/inventur/PermanenteInventurImLagerort_WM_EWM/3.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM4 from '../../assets/img/inventur/PermanenteInventurImLagerort_WM_EWM/4.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM5 from '../../assets/img/inventur/PermanenteInventurImLagerort_WM_EWM/5.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM6 from '../../assets/img/inventur/PermanenteInventurImLagerort_WM_EWM/6.png';

import Inventur_AdhocInventurAnwenderMaterial1 from '../../assets/img/inventur/AdhocInventurAnwenderMaterial/1.png';
import Inventur_AdhocInventurAnwenderMaterial2 from '../../assets/img/inventur/AdhocInventurAnwenderMaterial/2.png';
import Inventur_AdhocInventurAnwenderMaterial3 from '../../assets/img/inventur/AdhocInventurAnwenderMaterial/3.png';

import Inventur_AdhocInventurAndwenderLagerplatz1 from '../../assets/img/inventur/AdhocInventurAndwenderLagerplatz/1.png';
import Inventur_AdhocInventurAndwenderLagerplatz2 from '../../assets/img/inventur/AdhocInventurAndwenderLagerplatz/2.png';
import Inventur_AdhocInventurAndwenderLagerplatz3 from '../../assets/img/inventur/AdhocInventurAndwenderLagerplatz/3.png';
import Inventur_AdhocInventurAndwenderLagerplatz4 from '../../assets/img/inventur/AdhocInventurAndwenderLagerplatz/4.png';
import Inventur_AdhocInventurAndwenderLagerplatz5 from '../../assets/img/inventur/AdhocInventurAndwenderLagerplatz/5.png';
import Inventur_AdhocInventurAndwenderLagerplatz6 from '../../assets/img/inventur/AdhocInventurAndwenderLagerplatz/6.png';

import Inventur_AdhocInventurSystem_MM1 from '../../assets/img/inventur/AdhocInventurSystem_MM/1.png';
import Inventur_AdhocInventurSystem_MM2 from '../../assets/img/inventur/AdhocInventurSystem_MM/2.png';
import Inventur_AdhocInventurSystem_MM3 from '../../assets/img/inventur/AdhocInventurSystem_MM/3.png';
import Inventur_AdhocInventurSystem_MM4 from '../../assets/img/inventur/AdhocInventurSystem_MM/4.png';

import Inventur_AdhocInventurSystem_WM_EWM1 from '../../assets/img/inventur/AdhocInventurSystem_WM_EWM/1.png';
import Inventur_AdhocInventurSystem_WM_EWM2 from '../../assets/img/inventur/AdhocInventurSystem_WM_EWM/2.png';
import Inventur_AdhocInventurSystem_WM_EWM3 from '../../assets/img/inventur/AdhocInventurSystem_WM_EWM/3.png';
import Inventur_AdhocInventurSystem_WM_EWM4 from '../../assets/img/inventur/AdhocInventurSystem_WM_EWM/4.png';
import Inventur_AdhocInventurSystem_WM_EWM5 from '../../assets/img/inventur/AdhocInventurSystem_WM_EWM/5.png';
import Inventur_AdhocInventurSystem_WM_EWM6 from '../../assets/img/inventur/AdhocInventurSystem_WM_EWM/6.png';
import Inventur_AdhocInventurSystem_WM_EWM7 from '../../assets/img/inventur/AdhocInventurSystem_WM_EWM/7.png';

import Inventur_NullKontrolle1 from '../../assets/img/inventur/NullKontrolle/1.png';
import Inventur_NullKontrolle2 from '../../assets/img/inventur/NullKontrolle/2.png';
import Inventur_NullKontrolle3 from '../../assets/img/inventur/NullKontrolle/3.png';
import Inventur_NullKontrolle4 from '../../assets/img/inventur/NullKontrolle/4.png';

import Inventur_Bestandskorrektur_MM1 from '../../assets/img/inventur/Bestandskorrektur_MM/1.png';
import Inventur_Bestandskorrektur_MM2 from '../../assets/img/inventur/Bestandskorrektur_MM/2.png';

import Inventur_Bestandskorrektur_WM_EWM1 from '../../assets/img/inventur/Bestandskorrektur_WM_EWM/1.png';
import Inventur_Bestandskorrektur_WM_EWM2 from '../../assets/img/inventur/Bestandskorrektur_WM_EWM/2.png';

export const InventurImages = [
    new ProcessImageContainer(
        `P_INVENTUR_EINFUEHRUNG_MM`,
        [
            new ProcessImage('Detail mit Bestandsart', Inventur_Einfuehrung_MM1),
            new ProcessImage('Detail mit Zähllnummer', Inventur_Einfuehrung_MM2)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_EINFUEHRUNG_WM_EWM`,
        [
            new ProcessImage('Lager', Inventur_Einfuehrung_WM_EWM1),
            new ProcessImage('Positionen unvollständig', Inventur_Einfuehrung_WM_EWM2),
            new ProcessImage('Detail', Inventur_Einfuehrung_WM_EWM3),
            new ProcessImage('Detail mit Zählnummer', Inventur_Einfuehrung_WM_EWM4),
            new ProcessImage('Quants unvollständig', Inventur_Einfuehrung_WM_EWM5)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_STICHTAGS_PERMANENTE_INVENTUR_IM_LAGERORT_MM`,
        [
            new ProcessImage('Lagerort', Inventur_PermanenteInventurImLagerort_MM1),
            new ProcessImage('Optional Material', Inventur_PermanenteInventurImLagerort_MM2),
            new ProcessImage('Detail mit Bestandsart', Inventur_PermanenteInventurImLagerort_MM3),
            new ProcessImage('Details', Inventur_PermanenteInventurImLagerort_MM4),
            new ProcessImage('Detail mit Zähllnummer', Inventur_PermanenteInventurImLagerort_MM5)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_STICHTAGS_PERMANENTE_INVENTUR_AM_LAGERPLATZ_WM`,
        [
            new ProcessImage('Lager', Inventur_PermanenteInventurImLagerort_WM_EWM1),
            new ProcessImage('Optional Lagerplatz', Inventur_PermanenteInventurImLagerort_WM_EWM2),
            new ProcessImage('Optional Quant', Inventur_PermanenteInventurImLagerort_WM_EWM3),
            new ProcessImage('Detail mit Bestandsart', Inventur_PermanenteInventurImLagerort_WM_EWM4),
            new ProcessImage('Detail', Inventur_PermanenteInventurImLagerort_WM_EWM5),
            new ProcessImage('Detail mit Zählnummer', Inventur_PermanenteInventurImLagerort_WM_EWM6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_STICHTAGS_PERMANENTE_INVENTUR_AM_LAGERPLATZ_EWM`,
        [
            new ProcessImage('Lager', Inventur_PermanenteInventurImLagerort_WM_EWM1),
            new ProcessImage('Optional Lagerplatz', Inventur_PermanenteInventurImLagerort_WM_EWM2),
            new ProcessImage('Optional Quant', Inventur_PermanenteInventurImLagerort_WM_EWM3),
            new ProcessImage('Detail mit Bestandsart', Inventur_PermanenteInventurImLagerort_WM_EWM4),
            new ProcessImage('Detail', Inventur_PermanenteInventurImLagerort_WM_EWM5),
            new ProcessImage('Detail mit Zählnummer', Inventur_PermanenteInventurImLagerort_WM_EWM6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_ANWENDER_GEFUEHRT_FUER_MATERIAL`,
        [
            new ProcessImage('Lagerort', Inventur_AdhocInventurAnwenderMaterial1),
            new ProcessImage('Material', Inventur_AdhocInventurAnwenderMaterial2),
            new ProcessImage('Details', Inventur_AdhocInventurAnwenderMaterial3)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_ANWENDER_GEFUEHRT_AM_LAGERPLATZ_WM`,
        [
            new ProcessImage('Lager', Inventur_AdhocInventurAndwenderLagerplatz1),
            new ProcessImage('Optional Lagerplatz', Inventur_AdhocInventurAndwenderLagerplatz2),
            new ProcessImage('Quants unvollständig', Inventur_AdhocInventurAndwenderLagerplatz3),
            new ProcessImage('Material', Inventur_AdhocInventurAndwenderLagerplatz4),
            new ProcessImage('Details', Inventur_AdhocInventurAndwenderLagerplatz5),
            new ProcessImage('Quants vollständig', Inventur_AdhocInventurAndwenderLagerplatz6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_ANWENDER_GEFUEHRT_AM_LAGERPLATZ_EWM`,
        [
            new ProcessImage('Lager', Inventur_AdhocInventurAndwenderLagerplatz1),
            new ProcessImage('Optional Lagerplatz', Inventur_AdhocInventurAndwenderLagerplatz2),
            new ProcessImage('Quants unvollständig', Inventur_AdhocInventurAndwenderLagerplatz3),
            new ProcessImage('Material', Inventur_AdhocInventurAndwenderLagerplatz4),
            new ProcessImage('Details', Inventur_AdhocInventurAndwenderLagerplatz5),
            new ProcessImage('Quants vollständig', Inventur_AdhocInventurAndwenderLagerplatz6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_SYSTEM_GEFUEHRT_FUER_MATERIAL_MM`,
        [
            new ProcessImage('Lagerort', Inventur_AdhocInventurSystem_MM1),
            new ProcessImage('Modal start ad hoc', Inventur_AdhocInventurSystem_MM2),
            new ProcessImage('Positionen', Inventur_AdhocInventurSystem_MM3),
            new ProcessImage('Detail', Inventur_AdhocInventurSystem_MM4),
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_SYSTEM_GEFUEHRT_AM_LAGERPLATZ_WM`,
        [
            new ProcessImage('Lager', Inventur_AdhocInventurSystem_WM_EWM1),
            new ProcessImage('Lagerplatz', Inventur_AdhocInventurSystem_WM_EWM2),
            new ProcessImage('Modal Start Ad-Hoc', Inventur_AdhocInventurSystem_WM_EWM3),
            new ProcessImage('Positionen unvollständig', Inventur_AdhocInventurSystem_WM_EWM4),
            new ProcessImage('Material', Inventur_AdhocInventurSystem_WM_EWM5),
            new ProcessImage('Details', Inventur_AdhocInventurSystem_WM_EWM6),
            new ProcessImage('Positionen vollständig', Inventur_AdhocInventurSystem_WM_EWM7)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_SYSTEM_GEFUEHRT_AM_LAGERPLATZ_EWM`,
        [
            new ProcessImage('Lager', Inventur_AdhocInventurSystem_WM_EWM1),
            new ProcessImage('Lagerplatz', Inventur_AdhocInventurSystem_WM_EWM2),
            new ProcessImage('Modal Start Ad-Hoc', Inventur_AdhocInventurSystem_WM_EWM3),
            new ProcessImage('Positionen unvollständig', Inventur_AdhocInventurSystem_WM_EWM4),
            new ProcessImage('Material', Inventur_AdhocInventurSystem_WM_EWM5),
            new ProcessImage('Details', Inventur_AdhocInventurSystem_WM_EWM6),
            new ProcessImage('Positionen vollständig', Inventur_AdhocInventurSystem_WM_EWM7)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_NULL_KONTROLLE_UND_ODER_RESTMENGENPRUEFUNG_WM`,
        [
            new ProcessImage('Restmengenprüfung', Inventur_NullKontrolle1),
            new ProcessImage('Nullkontrolle', Inventur_NullKontrolle2),
            new ProcessImage('Nullkontrolle Korrektur', Inventur_NullKontrolle3),
            new ProcessImage('Nullkontrolle Korrektur (mit Charge)', Inventur_NullKontrolle4),
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_NULL_KONTROLLE_UND_ODER_RESTMENGENPRUEFUNG_EWM`,
        [
            new ProcessImage('Restmengenprüfung', Inventur_NullKontrolle1),
            new ProcessImage('Nullkontrolle', Inventur_NullKontrolle2),
            new ProcessImage('Nullkontrolle Korrektur', Inventur_NullKontrolle3),
            new ProcessImage('Nullkontrolle Korrektur (mit Charge)', Inventur_NullKontrolle4),
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_BESTANDSKORREKTUR_MM`,
        [
            new ProcessImage('Bestandsinfo Detail MM Options', Inventur_Bestandskorrektur_MM1),
            new ProcessImage('AdHocInv', Inventur_Bestandskorrektur_MM2)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_BESTANDSKORREKTUR_WM_EWM`,
        [
            new ProcessImage('Bestandsinfo Detail WM Options', Inventur_Bestandskorrektur_WM_EWM1),
            new ProcessImage('AdHocInv WM', Inventur_Bestandskorrektur_WM_EWM2)
        ]
    )
]