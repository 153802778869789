import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** QUALITÄTSKONTROLLE IMAGES */
import Qualitaetskontrolle_Einfuehrung1 from '../../assets/img_en/qualitaetskontrolle/Einfuehrung/1.png'
import Qualitaetskontrolle_Einfuehrung2 from '../../assets/img_en/qualitaetskontrolle/Einfuehrung/2.png'
import Qualitaetskontrolle_Einfuehrung3 from '../../assets/img_en/qualitaetskontrolle/Einfuehrung/3.png'
import Qualitaetskontrolle_Einfuehrung4 from '../../assets/img_en/qualitaetskontrolle/Einfuehrung/4.png'
import Qualitaetskontrolle_Einfuehrung5 from '../../assets/img_en/qualitaetskontrolle/Einfuehrung/5.png'

import Qualitaetskontrolle_Prueflosauswahl1 from '../../assets/img_en/qualitaetskontrolle/Prueflosauswahl/1.png'
import Qualitaetskontrolle_Prueflosauswahl2 from '../../assets/img_en/qualitaetskontrolle/Prueflosauswahl/2.png'
import Qualitaetskontrolle_Prueflosauswahl3 from '../../assets/img_en/qualitaetskontrolle/Prueflosauswahl/3.png'

import Qualitaetskontrolle_Ergebniserfassung1 from '../../assets/img_en/qualitaetskontrolle/Ergebniserfassung/1.png'
import Qualitaetskontrolle_Ergebniserfassung2 from '../../assets/img_en/qualitaetskontrolle/Ergebniserfassung/2.png'
import Qualitaetskontrolle_Ergebniserfassung3 from '../../assets/img_en/qualitaetskontrolle/Ergebniserfassung/3.png'
import Qualitaetskontrolle_Ergebniserfassung4 from '../../assets/img_en/qualitaetskontrolle/Ergebniserfassung/4.png'

import Qualitaetskontrolle_Fehlererfassung1 from '../../assets/img_en/qualitaetskontrolle/Fehlererfassung/1.png'
import Qualitaetskontrolle_Fehlererfassung2 from '../../assets/img_en/qualitaetskontrolle/Fehlererfassung/2.png'
import Qualitaetskontrolle_Fehlererfassung3 from '../../assets/img_en/qualitaetskontrolle/Fehlererfassung/3.png'

import Qualitaetskontrolle_Verwendungsentscheid1 from '../../assets/img_en/qualitaetskontrolle/Verwendungsentscheid/1.png'
import Qualitaetskontrolle_Verwendungsentscheid2 from '../../assets/img_en/qualitaetskontrolle/Verwendungsentscheid/2.png'

export const QualitaetskontrolleImagesEN = [
    new ProcessImageContainer(
        `P_QUALITAETSKONTROLLE_EINFUEHRUNG`,
        [
            new ProcessImage('Characteristics', Qualitaetskontrolle_Einfuehrung1),
            new ProcessImage('Single Meter Level Detail', Qualitaetskontrolle_Einfuehrung2),
            new ProcessImage('Individual Value Level Evaluation', Qualitaetskontrolle_Einfuehrung3),
            new ProcessImage('Error Recording', Qualitaetskontrolle_Einfuehrung4),
            new ProcessImage('Test Stock', Qualitaetskontrolle_Einfuehrung5)
        ]
    ),
    new ProcessImageContainer(
        `P_QUALITAETSKONTROLLE_PRUEFLOSAUSWAHL`,
        [
            new ProcessImage('Search', Qualitaetskontrolle_Prueflosauswahl1),
            new ProcessImage('Inspection', Qualitaetskontrolle_Prueflosauswahl2),
            new ProcessImage('Use Decision', Qualitaetskontrolle_Prueflosauswahl3)
        ]
    ),
    new ProcessImageContainer(
        `P_QUALITAETSKONTROLLE_ERGEBNISERFASSUNG`,
        [
            new ProcessImage('Characteristics', Qualitaetskontrolle_Ergebniserfassung1),
            new ProcessImage('Feature Level Detail', Qualitaetskontrolle_Ergebniserfassung2),
            new ProcessImage('Single Meter Level Detail', Qualitaetskontrolle_Ergebniserfassung3),
            new ProcessImage('Individual Value Level Evaluation', Qualitaetskontrolle_Ergebniserfassung4)
        ]
    ),
    new ProcessImageContainer(
        `P_QUALITAETSKONTROLLE_FEHLERERFASSUNG`,
        [
            new ProcessImage('Error Recording', Qualitaetskontrolle_Fehlererfassung1),
            new ProcessImage('Add New Mistakes', Qualitaetskontrolle_Fehlererfassung2),
            new ProcessImage('Quality Message', Qualitaetskontrolle_Fehlererfassung3)
        ]
    ),
    new ProcessImageContainer(
        `P_QUALITAETSKONTROLLE_VERWENDUNGSENTSCHEID_UND_BESTANDSBUCHUNG_MM_QM`,
        [
            new ProcessImage('Use Decision', Qualitaetskontrolle_Verwendungsentscheid1),
            new ProcessImage('Test Stock', Qualitaetskontrolle_Verwendungsentscheid2)
        ]
    ),
    new ProcessImageContainer(
        `P_QUALITAETSKONTROLLE_VERWENDUNGSENTSCHEID_UND_BESTANDSBUCHUNG_WM_QM`,
        [
            new ProcessImage('Use Decision', Qualitaetskontrolle_Verwendungsentscheid1),
            new ProcessImage('Test Stock', Qualitaetskontrolle_Verwendungsentscheid2)
        ]
    ),
    new ProcessImageContainer(
        `P_QUALITAETSKONTROLLE_VERWENDUNGSENTSCHEID_UND_BESTANDSBUCHUNG_EWM_QM`,
        [
            new ProcessImage('Use Decision', Qualitaetskontrolle_Verwendungsentscheid1),
            new ProcessImage('Test Stock', Qualitaetskontrolle_Verwendungsentscheid2)
        ]
    )
]