// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        scenarioListContainer: {
            boxSizing: 'border-box',
            padding: '88px 24px 88px 24px',
            // marginTop: 66,
            background: ColorSAP.neutral[15],
            borderRadius: 12
        },
        scenarioListDiamondBackground: {
            width: 195,
            height: 195,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // background: Color.gradient[100],
            transform: 'rotate(45deg)'
        },
        scenarioListIconContainer: {
            "& svg": {
                width: 120,
                height: 120,
                color: ColorSAP.primary[20],
                transform: 'rotate(-45deg)'
            }
        },

        smallerScenarioListDiamondBackground: {
            width: 65,
            height: 65,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: ColorSAP.primary[70],
            borderRadius: 8,
            transform: 'rotate(45deg)'
        },
        smallerScenarioListIconContainer: {
            "& svg": {
                width: 34,
                height: 34,
                color: ColorSAP.primary[20],
                transform: 'rotate(-45deg)'
            }
        },

        designerOverviewContainer: {
            width: '100%',
            height: 500,
            marginTop: 45,
            boxSizing: 'border-box',
            padding: 24,
            background: Color.primary[400],
            position: 'relative'
        },
        designerOverviewIconContainer: {
            marginTop: 20,
            "& svg": {
                width: 40,
                height: 40,
                color: Color.primary[100]
            }
        }
    })
);