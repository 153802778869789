// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from "@commsult/ontego-ui";

export const useStyles = makeStyles((theme) =>
    createStyles({
        buttonContainerDark: {
            '&:hover': {
                background: ColorSAP.neutral[70],
                color: ColorSAP.neutral[0],
                borderColor: ColorSAP.neutral[70],
                '& $arrowContainer__dark': {
                    background: ColorSAP.neutral[50],
                    color: ColorSAP.neutral[15],
                    borderColor: ColorSAP.neutral[50]
                },
            },
        },
        buttonContainerDanger: {
            '&:hover': {
                background: ColorSAP.danger[70],
                color: ColorSAP.neutral[0],
                borderColor: ColorSAP.danger[70],
                '& $arrowContainer__danger': {
                    background: '#F16158',
                    color: ColorSAP.neutral[15],
                    borderColor: '#F16158'
                },
            },
        },
        buttonContainerLight: {
            '&:hover': {
                background: ColorSAP.neutral[20],
                color: ColorSAP.neutral[100],
                '& $arrowContainer__light': {
                    background: ColorSAP.neutral[15],
                    color: ColorSAP.neutral[80],
                    borderColor: ColorSAP.neutral[15]
                },
            },
        },
        container__dark: {
            transition: 'background 300ms ease',
            background: ColorSAP.neutral[90],
            color: ColorSAP.neutral[0],
            borderColor: ColorSAP.neutral[90]
        },
        container__danger: {
            transition: 'background 300ms ease',
            background: '#EE4137',
            color: ColorSAP.neutral[0],
            borderColor: '#EE4137'
        },
        container__light: {
            transition: 'background 300ms ease',
            background: ColorSAP.neutral[0],
            color: ColorSAP.neutral[100]
        },
        arrowContainer__dark: {
            transition: 'background 300ms ease',
            background: ColorSAP.neutral[70],
            color: ColorSAP.neutral[15],
            borderColor: ColorSAP.neutral[70]
        },
        arrowContainer__danger: {
            transition: 'background 300ms ease',
            background: '#F16158',
            color: ColorSAP.neutral[15],
            borderColor: '#F16158'
        },
        arrowContainer__light: {
            transition: 'background 300ms ease',
            background: ColorSAP.neutral[15],
            color: ColorSAP.neutral[80],
            borderColor: ColorSAP.neutral[15]
        },
        container__primary: {

        },
        container__secondary: {
            background: 'transparent',
            border: '2px solid'
        },
        container__tertiary: {
            border: 'none',
        },
        arrowContainer__primary: {

        },
        arrowContainer__secondary: {
            background: 'transparent',
            border: '2px solid'
        },
        container__small: {
            height: 28,
            padding: '8px 12px 8px 12px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 100,
            width: 'fit-content',
            cursor: 'pointer'
        },
        container__medium: {
            height: 40,
            padding: '16px 24px 16px 24px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 100,
            width: 'fit-content',
            cursor: 'pointer'
        },
        container__large: {
            height: 72,
            padding: '24px 40px 24px 40px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 100,
            width: 'fit-content',
            cursor: 'pointer'
        },

        arrowContainer__small: {
            display: 'flex',
            width: 20,
            height: 20,
            padding: 4,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            marginLeft: 8,
            "& svg": {
                width: 14,
                height: 14
            }
        },
        arrowContainer__medium: {
            display: 'flex',
            width: 28,
            height: 28,
            padding: 6,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            marginLeft: 24,
            "& svg": {
                width: 20,
                height: 20
            }
        },
        arrowContainer__large: {
            display: 'flex',
            width: 50,
            height: 50,
            padding: 11,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            marginLeft: 40,
            "& svg": {
                width: 36,
                height: 36
            }
        }
    })
);