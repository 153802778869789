import { Color, ColorSAP, FONT_FAMILY } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";


export const useStyles = makeStyles((theme) =>
    createStyles({
        input: {
            position: "absolute",
            opacity: 0,
            cursor: "pointer",
            height: 0,
            width: 0
        },
        label: {
            display: "block",
            position: "relative",
            paddingLeft: "30px",
            // marginBottom: "24px",
            lineHeight: "16px",
            cursor: "pointer",
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
            fontSize: "14px",
            "&::before": {
                boxSizing: "border-box",
                position: "absolute",
                content: "''",
                top: 0,
                left: 0,
                height: 20,
                width: 20,
                borderRadius: "50%",
                borderStyle: "solid",
                borderWidth: "1px"
            },
            "&:hover": {
                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR
            },
            "&::after": {
                position: "absolute",
                content: "''",
                top: 4,
                left: 4,
                width: 12,
                height: 12,
                borderRadius: "50%",
                display: "none"
            },
            "$input:checked ~ &": {
                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
                "&::after": {
                    display: "block"
                },
                "&$disabled::before": {
                    backgroundColor: "transparent"
                }
            },
            "&$disabled": {
                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
                color: Color.neutral[500],
                "&::before": {
                    backgroundColor: Color.neutral[300],
                    borderColor: Color.neutral[400]
                },
                "&:hover::before": {
                    backgroundColor: Color.neutral[300],
                    borderColor: Color.neutral[400]
                },
                "&::after": {
                    backgroundColor: Color.neutral[500]
                }
            }
        },
        n900: {
            color: ColorSAP.neutral[100],
            "&::before": {
                borderColor: ColorSAP.neutral[100],
            },
            "&:hover::before": {
                backgroundColor: ColorSAP.neutral[20],
                opacity: 0.5,
                borderColor: ColorSAP.neutral[100],
            },
            "&::after": {
                backgroundColor: ColorSAP.primary[70],
            }
        },
        n000: {
            color: Color.neutral[0],
            "&::before": {
                borderColor: Color.neutral[0]
            },
            "&:hover::before": {
                backgroundColor: Color.neutral[300],
                borderColor: Color.neutral[300]
            },
            "&::after": {
                backgroundColor: Color.neutral[100]
            }
        },
        disabled: {}
    })
);