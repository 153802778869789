import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** WA UMLAGERUNG IMAGES */
import Umlagerung_Einfuehrung1 from '../../assets/img/umlagerung/Einfuehrung/1.png'
import Umlagerung_Einfuehrung2 from '../../assets/img/umlagerung/Einfuehrung/2.png'
import Umlagerung_Einfuehrung3 from '../../assets/img/umlagerung/Einfuehrung/3.png'
import Umlagerung_Einfuehrung4 from '../../assets/img/umlagerung/Einfuehrung/4.png'

import Umlagerung_LagerplatzAnLagerplatz_WM1 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_WM/1.png'
import Umlagerung_LagerplatzAnLagerplatz_WM2 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_WM/2.png'
import Umlagerung_LagerplatzAnLagerplatz_WM3 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_WM/3.png'
import Umlagerung_LagerplatzAnLagerplatz_WM4 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_WM/4.png'
import Umlagerung_LagerplatzAnLagerplatz_WM5 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_WM/5.png'
import Umlagerung_LagerplatzAnLagerplatz_WM6 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_WM/6.png'

import Umlagerung_LagerplatzAnLagerplatz_EWM1 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_EWM/1.png'
import Umlagerung_LagerplatzAnLagerplatz_EWM2 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_EWM/2.png'
import Umlagerung_LagerplatzAnLagerplatz_EWM3 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_EWM/3.png'
import Umlagerung_LagerplatzAnLagerplatz_EWM4 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_EWM/4.png'
import Umlagerung_LagerplatzAnLagerplatz_EWM5 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_EWM/5.png'
import Umlagerung_LagerplatzAnLagerplatz_EWM6 from '../../assets/img/umlagerung/LagerplatzAnLagerplatz_EWM/6.png'

import Umlagerung_LagerortAnLagerort_MM1 from '../../assets/img/umlagerung/LagerortAnLagerort_MM/1.png'
import Umlagerung_LagerortAnLagerort_MM2 from '../../assets/img/umlagerung/LagerortAnLagerort_MM/2.png'
import Umlagerung_LagerortAnLagerort_MM3 from '../../assets/img/umlagerung/LagerortAnLagerort_MM/3.png'
import Umlagerung_LagerortAnLagerort_MM4 from '../../assets/img/umlagerung/LagerortAnLagerort_MM/4.png'

import Umlagerung_LagerortAnLagerort_WM1 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/1.png'
import Umlagerung_LagerortAnLagerort_WM2 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/2.png'
import Umlagerung_LagerortAnLagerort_WM3 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/3.png'
import Umlagerung_LagerortAnLagerort_WM4 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/4.png'
import Umlagerung_LagerortAnLagerort_WM5 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/5.png'
import Umlagerung_LagerortAnLagerort_WM6 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/6.png'
import Umlagerung_LagerortAnLagerort_WM7 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/7.png'
import Umlagerung_LagerortAnLagerort_WM8 from '../../assets/img/umlagerung/LagerortAnLagerort_WM/8.png'

import Umlagerung_LagerortAnLagerort_EWM1 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/1.png'
import Umlagerung_LagerortAnLagerort_EWM2 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/2.png'
import Umlagerung_LagerortAnLagerort_EWM3 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/3.png'
import Umlagerung_LagerortAnLagerort_EWM4 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/4.png'
import Umlagerung_LagerortAnLagerort_EWM5 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/5.png'
import Umlagerung_LagerortAnLagerort_EWM6 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/6.png'
import Umlagerung_LagerortAnLagerort_EWM7 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/7.png'
import Umlagerung_LagerortAnLagerort_EWM8 from '../../assets/img/umlagerung/LagerortAnLagerort_EWM/8.png'

import Umlagerung_WerkAnWerk_MM1 from '../../assets/img/umlagerung/WerkAnWerk_MM/1.png'
import Umlagerung_WerkAnWerk_MM2 from '../../assets/img/umlagerung/WerkAnWerk_MM/2.png'
import Umlagerung_WerkAnWerk_MM3 from '../../assets/img/umlagerung/WerkAnWerk_MM/3.png'
import Umlagerung_WerkAnWerk_MM4 from '../../assets/img/umlagerung/WerkAnWerk_MM/4.png'

import Umlagerung_WerkAnWerk_WM1 from '../../assets/img/umlagerung/WerkAnWerk_WM/1.png'
import Umlagerung_WerkAnWerk_WM2 from '../../assets/img/umlagerung/WerkAnWerk_WM/2.png'
import Umlagerung_WerkAnWerk_WM3 from '../../assets/img/umlagerung/WerkAnWerk_WM/3.png'
import Umlagerung_WerkAnWerk_WM4 from '../../assets/img/umlagerung/WerkAnWerk_WM/4.png'
import Umlagerung_WerkAnWerk_WM5 from '../../assets/img/umlagerung/WerkAnWerk_WM/5.png'
import Umlagerung_WerkAnWerk_WM6 from '../../assets/img/umlagerung/WerkAnWerk_WM/6.png'

import Umlagerung_WerkAnWerk_EWM1 from '../../assets/img/umlagerung/WerkAnWerk_EWM/1.png'
import Umlagerung_WerkAnWerk_EWM2 from '../../assets/img/umlagerung/WerkAnWerk_EWM/2.png'
import Umlagerung_WerkAnWerk_EWM3 from '../../assets/img/umlagerung/WerkAnWerk_EWM/3.png'
import Umlagerung_WerkAnWerk_EWM4 from '../../assets/img/umlagerung/WerkAnWerk_EWM/4.png'
import Umlagerung_WerkAnWerk_EWM5 from '../../assets/img/umlagerung/WerkAnWerk_EWM/5.png'
import Umlagerung_WerkAnWerk_EWM6 from '../../assets/img/umlagerung/WerkAnWerk_EWM/6.png'

import Umlagerung_TransferZumEmpfangswerk_MM1 from '../../assets/img/umlagerung/TransferZumEmpfangswerk_MM/1.png'
import Umlagerung_TransferZumEmpfangswerk_MM2 from '../../assets/img/umlagerung/TransferZumEmpfangswerk_MM/2.png'

import Umlagerung_TransferZumEmpfangswerk_WM_EWM1 from '../../assets/img/umlagerung/TransferZumEmpfangswerk_WM_EWM/1.png'
import Umlagerung_TransferZumEmpfangswerk_WM_EWM2 from '../../assets/img/umlagerung/TransferZumEmpfangswerk_WM_EWM/2.png'
import Umlagerung_TransferZumEmpfangswerk_WM_EWM3 from '../../assets/img/umlagerung/TransferZumEmpfangswerk_WM_EWM/3.png'
import Umlagerung_TransferZumEmpfangswerk_WM_EWM4 from '../../assets/img/umlagerung/TransferZumEmpfangswerk_WM_EWM/4.png'

import Umlagerung_Bestandsinfo_MM1 from '../../assets/img/umlagerung/Bestandsinfo_MM/1.png'

import Umlagerung_Bestandsinfo_WM_EWM1 from '../../assets/img/umlagerung/Bestandsinfo_WM_EWM/1.png'

import Umlagerung_Sonderbestand_MM1 from '../../assets/img/umlagerung/Sonderbestand_MM/1.png'

import Umlagerung_Sonderbestand_WM_EWM1 from '../../assets/img/umlagerung/Sonderbestand_WM_EWM/1.png'

import Umlagerung_NullKontrolle1 from '../../assets/img/umlagerung/NullKontrolle/1.png'
import Umlagerung_NullKontrolle2 from '../../assets/img/umlagerung/NullKontrolle/2.png'
import Umlagerung_NullKontrolle3 from '../../assets/img/umlagerung/NullKontrolle/3.png'

export const UmlagerungImages = [
    new ProcessImageContainer(
        `P_UMLAGERUNG_EINFUEHRUNG_MM`,
        [
            new ProcessImage('Optional Vonlagerplatz', Umlagerung_Einfuehrung1),
            new ProcessImage('Details', Umlagerung_Einfuehrung2),
            new ProcessImage('Nachlagerplatz', Umlagerung_Einfuehrung3),
            new ProcessImage('Nachlagerort', Umlagerung_Einfuehrung4)
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_EINFUEHRUNG_WM`,
        [
            new ProcessImage('Optional Vonlagerplatz', Umlagerung_Einfuehrung1),
            new ProcessImage('Details', Umlagerung_Einfuehrung2),
            new ProcessImage('Nachlagerplatz', Umlagerung_Einfuehrung3),
            new ProcessImage('Nachlagerort', Umlagerung_Einfuehrung4)
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_EINFUEHRUNG_EWM`,
        [
            new ProcessImage('Optional Vonlagerplatz', Umlagerung_Einfuehrung1),
            new ProcessImage('Details', Umlagerung_Einfuehrung2),
            new ProcessImage('Nachlagerplatz', Umlagerung_Einfuehrung3),
            new ProcessImage('Nachlagerort', Umlagerung_Einfuehrung4)
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_PLATZ_AN_PLATZ_WM`,
        [
            new ProcessImage('Vonlagerplatz', Umlagerung_LagerplatzAnLagerplatz_WM1),
            new ProcessImage('Optional Vonlagerort', Umlagerung_LagerplatzAnLagerplatz_WM2),
            new ProcessImage('Optional Material', Umlagerung_LagerplatzAnLagerplatz_WM3),
            new ProcessImage('Details', Umlagerung_LagerplatzAnLagerplatz_WM4),
            new ProcessImage('Nachlagerplatz', Umlagerung_LagerplatzAnLagerplatz_WM5),
            new ProcessImage('Optional Nachlagerort (Modal)', Umlagerung_LagerplatzAnLagerplatz_WM6)
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_PLATZ_AN_PLATZ_EWM`,
        [
            new ProcessImage('Material', Umlagerung_LagerplatzAnLagerplatz_EWM1),
            new ProcessImage('Optional Vonlagerort', Umlagerung_LagerplatzAnLagerplatz_EWM2),
            new ProcessImage('Optional Vonlagerplatz', Umlagerung_LagerplatzAnLagerplatz_EWM3),
            new ProcessImage('Details', Umlagerung_LagerplatzAnLagerplatz_EWM4),
            new ProcessImage('Nachlagerplatz', Umlagerung_LagerplatzAnLagerplatz_EWM5),
            new ProcessImage('Optional Nachlagerort (Modal)', Umlagerung_LagerplatzAnLagerplatz_EWM6)
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_LAGERORT_AN_LAGERORT_MM`,
        [
            new ProcessImage('Vonlagerort', Umlagerung_LagerortAnLagerort_MM1),
            new ProcessImage('Optional Material', Umlagerung_LagerortAnLagerort_MM2),
            new ProcessImage('Details', Umlagerung_LagerortAnLagerort_MM3),
            new ProcessImage('Nachlagerort', Umlagerung_LagerortAnLagerort_MM4),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_LAGERORT_AN_LAGERORT_WM`,
        [
            new ProcessImage('Vonlagerort', Umlagerung_LagerortAnLagerort_WM1),
            new ProcessImage('Optional WM_Vonlagerort_Lager', Umlagerung_LagerortAnLagerort_WM2),
            new ProcessImage('Vonlagerplatz', Umlagerung_LagerortAnLagerort_WM3),
            new ProcessImage('Optional Material', Umlagerung_LagerortAnLagerort_WM4),
            new ProcessImage('Details', Umlagerung_LagerortAnLagerort_WM5),
            new ProcessImage('Nachlagerort', Umlagerung_LagerortAnLagerort_WM6),
            new ProcessImage('Optional Nachlagerort (Modal)', Umlagerung_LagerortAnLagerort_WM7),
            new ProcessImage('Nachlagerplatz', Umlagerung_LagerortAnLagerort_WM8),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_LAGERORT_AN_LAGERORT_EWM`,
        [
            new ProcessImage('Vonlagerort', Umlagerung_LagerortAnLagerort_EWM1),
            new ProcessImage('Optional WM_Vonlagerort_Lager', Umlagerung_LagerortAnLagerort_EWM2),
            new ProcessImage('Material', Umlagerung_LagerortAnLagerort_EWM3),
            new ProcessImage('Optional Vonlagerplatz', Umlagerung_LagerortAnLagerort_EWM4),
            new ProcessImage('Details', Umlagerung_LagerortAnLagerort_EWM5),
            new ProcessImage('Nachlagerort', Umlagerung_LagerortAnLagerort_EWM6),
            new ProcessImage('Optional Nachlagerort (Modal)', Umlagerung_LagerortAnLagerort_EWM7),
            new ProcessImage('Nachlagerplatz', Umlagerung_LagerortAnLagerort_EWM8),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_WERK_AN_WERK_MM`,
        [
            new ProcessImage('Lagerort', Umlagerung_WerkAnWerk_MM1),
            new ProcessImage('Optional Material', Umlagerung_WerkAnWerk_MM2),
            new ProcessImage('Details', Umlagerung_WerkAnWerk_MM3),
            new ProcessImage('Empfangswerk Nachlagerort', Umlagerung_WerkAnWerk_MM4),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_WERK_AN_WERK_WM`,
        [
            new ProcessImage('Lagerort', Umlagerung_WerkAnWerk_WM1),
            new ProcessImage('Optional Vonlagerot', Umlagerung_WerkAnWerk_WM2),
            new ProcessImage('Vonlagerplatz', Umlagerung_WerkAnWerk_WM3),
            new ProcessImage('Optional Material', Umlagerung_WerkAnWerk_WM4),
            new ProcessImage('Details', Umlagerung_WerkAnWerk_WM5),
            new ProcessImage('Nachlagerort', Umlagerung_WerkAnWerk_WM6),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_UMLAGERUNG_WERK_AN_WERK_EWM`,
        [
            new ProcessImage('Lagerort', Umlagerung_WerkAnWerk_EWM1),
            new ProcessImage('Optional Vonlagerot', Umlagerung_WerkAnWerk_EWM2),
            new ProcessImage('Material', Umlagerung_WerkAnWerk_EWM3),
            new ProcessImage('Optional Vonlagerplatz', Umlagerung_WerkAnWerk_EWM4),
            new ProcessImage('Details', Umlagerung_WerkAnWerk_EWM5),
            new ProcessImage('Nachlagerort', Umlagerung_WerkAnWerk_EWM6),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_TRANSFER_ZUM_NACH_LAGERORT_WERK_MM`,
        [
            new ProcessImage('Nachwerk', Umlagerung_TransferZumEmpfangswerk_MM1),
            new ProcessImage('Nachlagerort', Umlagerung_TransferZumEmpfangswerk_MM2),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_TRANSFER_ZUM_NACH_LAGERORT_WERK_WM_EWM`,
        [
            new ProcessImage('Empfangswerk', Umlagerung_TransferZumEmpfangswerk_WM_EWM1),
            new ProcessImage('Nachlagerort', Umlagerung_TransferZumEmpfangswerk_WM_EWM2),
            new ProcessImage('Optional Nachlagerort WM (Modal)', Umlagerung_TransferZumEmpfangswerk_WM_EWM3),
            new ProcessImage('Nachlagerplatz', Umlagerung_TransferZumEmpfangswerk_WM_EWM4),

        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_EINSTIEG_IN_UMLAGERUNG_UBER_BESTANDSINFO_MM`,
        [
            new ProcessImage('Bestandsinfo Detail MM Options', Umlagerung_Bestandsinfo_MM1),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_EINSTIEG_IN_UMLAGERUNG_UBER_BESTANDSINFO_WM_EWM`,
        [
            new ProcessImage('Bestandsinfo Detail WM Options', Umlagerung_Bestandsinfo_WM_EWM1),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_BEHANDLUNG_VON_SONDERBESTANDEN_MM`,
        [
            new ProcessImage('Details Sonderbestand', Umlagerung_Sonderbestand_MM1),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_BEHANDLUNG_VON_SONDERBESTANDEN_WM_EWM`,
        [
            new ProcessImage('Details Sonderbestand', Umlagerung_Sonderbestand_WM_EWM1),
        ]
    ),
    new ProcessImageContainer(
        `P_UMLAGERUNG_NULL_KONTROLLE_UND_ODER_RESTMENGENPRUFUNG`,
        [
            new ProcessImage('Restmengenprüfung', Umlagerung_NullKontrolle1),
            new ProcessImage('Nullkontrolle', Umlagerung_NullKontrolle2),
            new ProcessImage('Nullkontrolle Korrektur', Umlagerung_NullKontrolle3),
        ]
    ),
]