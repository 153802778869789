
// Etc
import { createStyles, makeStyles } from '@material-ui/styles';
import { arrowGenerator } from './ArrowGenerator';
import { ColorSAP } from '@commsult/ontego-ui';

export const useStyles = makeStyles((theme) =>
    createStyles({
        arrow: {
            color: ColorSAP.primary[80]
        },
        popper: arrowGenerator(ColorSAP.primary[80]),
        tooltip: {
            position: 'relative',
            borderRadius: 4,
            padding: '8px 24px 8px 24px',
            backgroundColor: ColorSAP.primary[80],
            color: ColorSAP.neutral[0],
        },
        tooltipPlacementLeft: {
            margin: '0 8px',
        },
        tooltipPlacementRight: {
            margin: '0 8px',
        },
        tooltipPlacementTop: {
            margin: '8px 0',
        },
        tooltipPlacementBottom: {
            margin: '8px 0',
        },
    }),
);