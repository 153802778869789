import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** EINLAGERUNG IMAGES */
import Einlagerung_Einfuehrung_WM1 from '../../assets/img/einlagerung/Einfuehrung_WM/1.png'
import Einlagerung_Einfuehrung_WM2 from '../../assets/img/einlagerung/Einfuehrung_WM/2.png'
import Einlagerung_Einfuehrung_WM3 from '../../assets/img/einlagerung/Einfuehrung_WM/3.png'
import Einlagerung_Einfuehrung_WM4 from '../../assets/img/einlagerung/Einfuehrung_WM/4.png'

import Einlagerung_Einfuehrung_EWM1 from '../../assets/img/einlagerung/Einfuehrung_EWM/1.png'
import Einlagerung_Einfuehrung_EWM2 from '../../assets/img/einlagerung/Einfuehrung_EWM/2.png'
import Einlagerung_Einfuehrung_EWM3 from '../../assets/img/einlagerung/Einfuehrung_EWM/3.png'
import Einlagerung_Einfuehrung_EWM4 from '../../assets/img/einlagerung/Einfuehrung_EWM/4.png'

import Einlagerung_Materialbeleg1 from '../../assets/img/einlagerung/Materialbeleg/1.png'
import Einlagerung_Materialbeleg2 from '../../assets/img/einlagerung/Materialbeleg/2.png'
import Einlagerung_Materialbeleg3 from '../../assets/img/einlagerung/Materialbeleg/3.png'
import Einlagerung_Materialbeleg4 from '../../assets/img/einlagerung/Materialbeleg/4.png'

import Einlagerung_Transportbedarf1 from '../../assets/img/einlagerung/Transportbedarf/1.png'
import Einlagerung_Transportbedarf2 from '../../assets/img/einlagerung/Transportbedarf/2.png'
import Einlagerung_Transportbedarf3 from '../../assets/img/einlagerung/Transportbedarf/3.png'
import Einlagerung_Transportbedarf4 from '../../assets/img/einlagerung/Transportbedarf/4.png'

import Einlagerung_Transportauftrag1 from '../../assets/img/einlagerung/Transportauftrag/1.png'
import Einlagerung_Transportauftrag2 from '../../assets/img/einlagerung/Transportauftrag/2.png'
import Einlagerung_Transportauftrag3 from '../../assets/img/einlagerung/Transportauftrag/3.png'
import Einlagerung_Transportauftrag4 from '../../assets/img/einlagerung/Transportauftrag/4.png'
import Einlagerung_Transportauftrag5 from '../../assets/img/einlagerung/Transportauftrag/5.png'

import Einlagerung_Lagereinheit1 from '../../assets/img/einlagerung/Lagereinheit/1.png'
import Einlagerung_Lagereinheit2 from '../../assets/img/einlagerung/Lagereinheit/2.png'
import Einlagerung_Lagereinheit3 from '../../assets/img/einlagerung/Lagereinheit/3.png'
import Einlagerung_Lagereinheit4 from '../../assets/img/einlagerung/Lagereinheit/4.png'

import Einlagerung_Lagerauftrag1 from '../../assets/img/einlagerung/Lagerauftrag/1.png'
import Einlagerung_Lagerauftrag2 from '../../assets/img/einlagerung/Lagerauftrag/2.png'
import Einlagerung_Lagerauftrag3 from '../../assets/img/einlagerung/Lagerauftrag/3.png'
import Einlagerung_Lagerauftrag4 from '../../assets/img/einlagerung/Lagerauftrag/4.png'

import Einlagerung_Lageraufgabe1 from '../../assets/img/einlagerung/Lageraufgabe/1.png'
import Einlagerung_Lageraufgabe2 from '../../assets/img/einlagerung/Lageraufgabe/2.png'
import Einlagerung_Lageraufgabe3 from '../../assets/img/einlagerung/Lageraufgabe/3.png'
import Einlagerung_Lageraufgabe4 from '../../assets/img/einlagerung/Lageraufgabe/4.png'

import Einlagerung_HandlingUnit1 from '../../assets/img/einlagerung/HandlingUnit/1.png'
import Einlagerung_HandlingUnit2 from '../../assets/img/einlagerung/HandlingUnit/2.png'
import Einlagerung_HandlingUnit3 from '../../assets/img/einlagerung/HandlingUnit/3.png'
import Einlagerung_HandlingUnit4 from '../../assets/img/einlagerung/HandlingUnit/4.png'

import Einlagerung_Quittierung_WM1 from '../../assets/img/einlagerung/Quittierung_WM/1.png'
import Einlagerung_Quittierung_WM2 from '../../assets/img/einlagerung/Quittierung_WM/2.png'
import Einlagerung_Quittierung_WM3 from '../../assets/img/einlagerung/Quittierung_WM/3.png'
import Einlagerung_Quittierung_WM4 from '../../assets/img/einlagerung/Quittierung_WM/4.png'

import Einlagerung_Quittierung_EWM1 from '../../assets/img/einlagerung/Quittierung_EWM/1.png'
import Einlagerung_Quittierung_EWM2 from '../../assets/img/einlagerung/Quittierung_EWM/2.png'
import Einlagerung_Quittierung_EWM3 from '../../assets/img/einlagerung/Quittierung_EWM/3.png'
import Einlagerung_Quittierung_EWM4 from '../../assets/img/einlagerung/Quittierung_EWM/4.png'

import Einlagerung_Storno_WM1 from '../../assets/img/einlagerung/Storno_WM/1.png'
import Einlagerung_Storno_WM2 from '../../assets/img/einlagerung/Storno_WM/2.png'
import Einlagerung_Storno_WM3 from '../../assets/img/einlagerung/Storno_WM/3.png'
import Einlagerung_Storno_WM4 from '../../assets/img/einlagerung/Storno_WM/4.png'
import Einlagerung_Storno_WM5 from '../../assets/img/einlagerung/Storno_WM/5.png'

import Einlagerung_Storno_EWM1 from '../../assets/img/einlagerung/Storno_EWM/1.png'
import Einlagerung_Storno_EWM2 from '../../assets/img/einlagerung/Storno_EWM/2.png'
import Einlagerung_Storno_EWM3 from '../../assets/img/einlagerung/Storno_EWM/3.png'
import Einlagerung_Storno_EWM4 from '../../assets/img/einlagerung/Storno_EWM/4.png'

export const EinlagerungImages = [
    new ProcessImageContainer(
        `P_EINLAGERUNG_EINFUEHRUNG_WM`,
        [
            new ProcessImage('Optional Positionen', Einlagerung_Einfuehrung_WM1),
            new ProcessImage('LE - Details', Einlagerung_Einfuehrung_WM2),
            new ProcessImage('Optional Details Menge', Einlagerung_Einfuehrung_WM3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Einfuehrung_WM4)
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_EINFUEHRUNG_EWM`,
        [
            new ProcessImage('Optional Positionen', Einlagerung_Einfuehrung_EWM1),
            new ProcessImage('HU - Details', Einlagerung_Einfuehrung_EWM2),
            new ProcessImage('Optional Details', Einlagerung_Einfuehrung_EWM3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Einfuehrung_EWM4)
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_ZUM_MATERIALBELEG_WM`,
        [
            new ProcessImage('Materialbeleg', Einlagerung_Materialbeleg1),
            new ProcessImage('Optional Positionen', Einlagerung_Materialbeleg2),
            new ProcessImage('Optional Details', Einlagerung_Materialbeleg3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Materialbeleg4)
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_ZUM_MATERIALBELEG_EWM`,
        [
            new ProcessImage('Materialbeleg', Einlagerung_Materialbeleg1),
            new ProcessImage('Optional Positionen', Einlagerung_Materialbeleg2),
            new ProcessImage('Optional Details', Einlagerung_Materialbeleg3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Materialbeleg4)
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_ZUM_TRANSPORTBEDARF_WM`,
        [
            new ProcessImage('Transportbedarf', Einlagerung_Transportbedarf1),
            new ProcessImage('Optional Positionen', Einlagerung_Transportbedarf2),
            new ProcessImage('Optional Details', Einlagerung_Transportbedarf3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Transportbedarf4)
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_ZUM_TRANSPORTAUFTRAG_WM`,
        [
            new ProcessImage('Transportauftrag', Einlagerung_Transportauftrag1),
            new ProcessImage('Optional Positionen', Einlagerung_Transportauftrag2),
            new ProcessImage('Optional Details Menge', Einlagerung_Transportauftrag3),
            new ProcessImage('Optional Details Seriennummern', Einlagerung_Transportauftrag4),
            new ProcessImage('Nachlagerplatz', Einlagerung_Transportauftrag5)
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_LAGEREINHEIT_EINLAGERN_WM`,
        [
            new ProcessImage('Transportauftrag', Einlagerung_Lagereinheit1),
            new ProcessImage('Optional Positionen', Einlagerung_Lagereinheit2),
            new ProcessImage('Details', Einlagerung_Lagereinheit3),
            new ProcessImage('Optional Lagereinheit Inhalt', Einlagerung_Lagereinheit4),
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_ZUM_LAGERAUFTRAG_EWM`,
        [
            new ProcessImage('Lagerauftrag', Einlagerung_Lagerauftrag1),
            new ProcessImage('Optional Positionen', Einlagerung_Lagerauftrag2),
            new ProcessImage('Optional Details', Einlagerung_Lagerauftrag3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Lagerauftrag4),
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_ZUR_LAGERAUFGABE_EWM`,
        [
            new ProcessImage('Lageraufgabe', Einlagerung_Lageraufgabe1),
            new ProcessImage('Optional Details Menge', Einlagerung_Lageraufgabe2),
            new ProcessImage('Optional Details Seriennummern', Einlagerung_Lageraufgabe3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Lageraufgabe4),
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_HANDLING_UNIT_EINLAGERN_EWM`,
        [
            new ProcessImage('Lagerauftrag', Einlagerung_HandlingUnit1),
            new ProcessImage('Optional Positionen', Einlagerung_HandlingUnit2),
            new ProcessImage('Details', Einlagerung_HandlingUnit3),
            new ProcessImage('Optional Modal HU Info', Einlagerung_HandlingUnit4),
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_VARIANTEN_ZUR_QUITTIERUNG_WM`,
        [
            new ProcessImage('LE - Details', Einlagerung_Quittierung_WM1),
            new ProcessImage('Nachlagerplatz', Einlagerung_Quittierung_WM2),
            new ProcessImage('Material', Einlagerung_Quittierung_WM3),
            new ProcessImage('Optional Details Menge', Einlagerung_Quittierung_WM4),
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_VARIANTEN_ZUR_QUITTIERUNG_EWM`,
        [
            new ProcessImage('HU - Details', Einlagerung_Quittierung_EWM1),
            new ProcessImage('Nachlagerplatz', Einlagerung_Quittierung_EWM2),
            new ProcessImage('Material', Einlagerung_Quittierung_EWM3),
            new ProcessImage('Optional Details Menge', Einlagerung_Quittierung_EWM4),
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_STORNO_AUS_WARENAUSGANG_WM`,
        [
            new ProcessImage('Transportauftrag', Einlagerung_Storno_WM1),
            new ProcessImage('Optional Positionen', Einlagerung_Storno_WM2),
            new ProcessImage('Optional Details Menge', Einlagerung_Storno_WM3),
            new ProcessImage('Optional Details Seriennummern', Einlagerung_Storno_WM4),
            new ProcessImage('Nachlagerplatz', Einlagerung_Storno_WM5)
        ]
    ),
    new ProcessImageContainer(
        `P_EINLAGERUNG_STORNO_AUS_WARENAUSGANG_EWM`,
        [
            new ProcessImage('Lagerauftrag', Einlagerung_Storno_EWM1),
            new ProcessImage('Optional Positionen', Einlagerung_Storno_EWM2),
            new ProcessImage('Optional Details', Einlagerung_Storno_EWM3),
            new ProcessImage('Nachlagerplatz', Einlagerung_Storno_EWM4)
        ]
    )
]