// React libraries
import React from "react";
import classnames from 'classnames';

// Custom Styles
import { useStyles } from "./CarouselStyles";

export const Carousel = props => {
    const classes = useStyles(props)

    const { id, length, index, onClick, style } = props;

    return (
        <div
            className={classes.carouselCompoundContainer}
            style={style}
            id={id}
        >
            {new Array(length).fill(true).map((value, arrIndex) => {
                const carouselNavClasses = classnames({
                    [classes.carouselContainer]: true,
                    [classes.carouselActive]: arrIndex === index
                })
                return (
                    <div
                        key={`value${arrIndex}`}
                        className={carouselNavClasses}
                        onClick={onClick(arrIndex)}
                    />
                )
            })}
        </div>
    )
}

Carousel.defaultProps = {
    length: 2,
    index: 0,
    onClick: () => { }
}

export default React.memo(Carousel)