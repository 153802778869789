// React libraries
import React, { useState } from "react";

// Custom Styles
import { useStyles } from "./DropdownStyles";
import { ArrowHeadDown, Color, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { OverflowMenuSelection } from "../OverflowMenuSelection/OverflowMenuSelection";

export const Dropdown = (props) => {
    const classes = useStyles(props)

    const { id, menu, type, selected, backgroundColor, style, placeholder, withCheckMark } = props

    const [anchorEl, setAnchorEl] = useState(null);

    let defaultItem = null
    let defaultPlaceholder = ""

    if (selected) {
        defaultItem = selected
    }
    // else if (selected === null || typeof selected === 'undefined') {
    //     // defaultItem = menu[0].text
    // }

    if (placeholder) {
        defaultPlaceholder = placeholder
    }

    const [selectedItem, setSelectedItem] = useState(defaultItem)

    const handleClickMenuSelection = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        };
    }

    return (
        <div
            id={id}
            className={classes.dropdownContainer}
            aria-controls={`${id}-dropdownMenu`}
            aria-haspopup="true"
            onClick={handleClickMenuSelection}
            style={{
                background: backgroundColor,
                ...style
            }}
        >
            <div
                id={`${id}-dropdownText`}
                className={classes.dropdownText}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={14}
                    color={Color.neutral[400]}
                >
                    {selectedItem ? selectedItem : placeholder}
                </Typography>
            </div>
            <div
                id={`${id}-dropdownArrow`}
                className={classes.dropdownArrowIcon}
            >
                <ArrowHeadDown />
            </div>
            <OverflowMenuSelection
                id={`${id}-dropdownMenu`}
                anchorElement={anchorEl}
                menuItems={menu ? menu : []}
                setValue={setSelectedItem}
                setAnchorEl={setAnchorEl}
                selectedItem={selectedItem}
                menuWidth={style?.width}
                withCheckMark={withCheckMark ? true : false}
            />
        </div>
    )
}

Dropdown.defaultProps = {
    id: "",
    menu: [],
    selected: null,
    withCheckMark: true
}

export default React.memo(Dropdown)