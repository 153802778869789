// React Lib
import React, { useState } from "react";
import classnames from 'classnames';

// Component Styles
import { useStyles } from "./ProcessCardStyles";

// @otg-one/ui_components/components
import { Typography, Chip, CollectionAdded } from "@commsult/ontego-ui";

// @otg-one/ui_components/constants
import { ColorSAP, FONT_FAMILY, FUNCTION_TYPE, getFunctionTypeBackgroundColor, getFunctionTypeLabelColor } from "@commsult/ontego-ui";
import { AddCollection, GoodsReceipt } from "@commsult/ontego-ui";
import TertiaryButton from "../TertiaryButton/TertiaryButton";
import Tooltip from "../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

export const ProcessCard = (props) => {
    const classes = useStyles(props)
    const [isHover, setIsHover] = useState(false)
    const [isAddToCollectionHover, setIsAddToCollectionHover] = useState(false)

    const { t } = useTranslation()

    const { id, style, func, icon, onClick, onAddToCollectionOnClick, isAdded, textFunctionDetail, textAddedToCollection, textAddToCollection, functionDetailOnClick } = props

    const handleOnMouseOver = () => setIsHover(true)
    const handleOnMouseOut = () => setIsHover(false)

    const handleOnAddToCollectionMouseOver = () => setIsAddToCollectionHover(true)
    const handleOnAddToCollectionMouseOut = () => setIsAddToCollectionHover(false)

    const functionBoxIconClasses = classnames({
        [classes.functionBoxIconLabelDefault]: !isHover,
        [classes.functionBoxIconLabelHovered]: isHover
    })

    const functionBoxCollectionIconClasses = classnames({
        [classes.functionBoxCollectionIcon]: true,
        [classes.functionBoxCollectionIconDefault]: !isAdded && !isAddToCollectionHover,
        [classes.functionBoxCollectionIconHovered]: !isAdded && isAddToCollectionHover,
        [classes.functionBoxAddedToCollectionIconDefault]: isAdded && !isAddToCollectionHover,
        [classes.functionBoxAddedToCollectionIconHovered]: isAdded && isAddToCollectionHover
    })

    return (
        <div
            className={classes.functionBoxContainer}
            onMouseEnter={handleOnMouseOver}
            onMouseLeave={handleOnMouseOut}
            onClick={onClick}
            style={style}
            id={id}
        >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
                <div className={functionBoxIconClasses}>
                    {icon}
                </div>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={18}
                    color={isHover ? ColorSAP.neutral[0] : ColorSAP.neutral[100]}
                    className={classes.functionBoxTypography}
                    onClick={onClick}
                >
                    {func.textName}
                </Typography>
            </div>

            {isHover && (
                <TertiaryButton
                    size="small"
                    theme="dark"
                    style={{ position: 'absolute', bottom: 12, right: 12 }}
                    onClick={functionDetailOnClick}
                >
                    {t('functionDetails.functionDetail')}
                </TertiaryButton>
            )}

            <div className={classes.functionBoxChipContainer}>
                {func.type.includes(FUNCTION_TYPE.MM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.MM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.MM)}
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    >
                        {FUNCTION_TYPE.MM}
                    </Chip>
                )}
                {func.type.includes(FUNCTION_TYPE.SD) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.SD)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.SD)}
                        style={{ marginLeft: func.type[0] !== FUNCTION_TYPE.SD && 8 }}
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    >
                        {FUNCTION_TYPE.SD}
                    </Chip>
                )}
                {func.type.includes(FUNCTION_TYPE.PP) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.PP)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.PP)}
                        style={{ marginLeft: func.type[0] !== FUNCTION_TYPE.PP && 8 }}
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    >
                        {FUNCTION_TYPE.PP}
                    </Chip>
                )}
                {func.type.includes(FUNCTION_TYPE.WM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.WM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.WM)}
                        style={{ marginLeft: func.type[0] !== FUNCTION_TYPE.WM && 8 }}
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    >
                        {FUNCTION_TYPE.WM}
                    </Chip>
                )}
                {func.type.includes(FUNCTION_TYPE.EWM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.EWM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.EWM)}
                        style={{ marginLeft: func.type[0] !== FUNCTION_TYPE.EWM && 8 }}
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    >
                        {FUNCTION_TYPE.EWM}
                    </Chip>
                )}
                {func.type.includes(FUNCTION_TYPE.QM) && (
                    <Chip
                        backgroundColor={getFunctionTypeBackgroundColor(FUNCTION_TYPE.QM)}
                        color={getFunctionTypeLabelColor(FUNCTION_TYPE.QM)}
                        style={{ marginLeft: func.type[0] !== FUNCTION_TYPE.QM && 8 }}
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    >
                        {FUNCTION_TYPE.QM}
                    </Chip>
                )}
            </div>

            <div
                className={functionBoxCollectionIconClasses}
                onMouseOver={handleOnAddToCollectionMouseOver}
                onMouseOut={handleOnAddToCollectionMouseOut}
                onClick={onAddToCollectionOnClick}
            >
                {isAdded ? (
                    <Tooltip title={textAddedToCollection} placement="right">
                        <div>
                            <CollectionAdded />
                        </div>
                    </Tooltip>

                ) : (
                    <Tooltip title={textAddToCollection} placement="right">
                        <div>
                            <AddCollection />
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

ProcessCard.defaultProps = {
    func: {
        textName: `Wareneingang`,
        type: [
            FUNCTION_TYPE.MM,
            FUNCTION_TYPE.WM,
            FUNCTION_TYPE.EWM
        ]
    },
    icon: <GoodsReceipt />,
    onClick: () => { },
    textFunctionDetail: 'Function Detail',
    textAddedToCollection: 'Added to Collection',
    textAddToCollection: 'Add to Collection',

}

export default React.memo(ProcessCard)