import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';

// Models
import Process from '../../Models/Process';
import ProcessType from '../../Models/ProcessType';

import { useStyles } from './SpecialFunctionDialogStyles';

import { isProcessAlreadyAddedToCollection, convertNotesToJSON, convertNotesToEditorState } from '../../Utils/Utils';

import { useTranslation } from 'react-i18next';

// Components
import {
    Typography,
    FONT_FAMILY,
    ColorSAP,
    FUNCTION_TYPE,
} from '@commsult/ontego-ui';

import Grid from '@material-ui/core/Grid';

import AppService from '../../AppService';
import { Edit, Plus } from '@commsult/ontego-ui/dist/sap_icons';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Form from '../../ReusableComponents/Form/Form';
import Button from '../../ReusableComponents/Button/Button';
import Checkbox from '../../ReusableComponents/Checkbox/Checkbox';

const SpecialFunctionDialog = props => {
    const { open, onClose, isEditing, func } = props

    const { specialFunctions, setSpecialFunctions, collection, setCollection, userInfo, inspectedFunctionId, handleShowSnackbar, handleShowErrorSnackbar } = useContext(AppContext)

    const [title, setTitle] = useState("")
    const [info, setInfo] = useState("")
    const [types, setTypes] = useState([])

    const [titleError, setTitleError] = useState(false)
    const [infoError, setInfoError] = useState(false)

    const { t } = useTranslation();

    const [titleErrorMessage, setTitleErrorMessage] = useState(t(`manage.fieldRequired`));

    useEffect(() => {
        if (func && isEditing) {
            setTitle(func.name)
            setInfo(func.dataType[0].info[0])
            setTypes(func.dataType[0].type)
        } else {
            setTitle('')
            setInfo('')
            setTypes([])
        }
    }, [open])

    const handleClose = () => {
        onClose()
        setTitleError(false)
        setInfoError(false)
    }

    const handleChangeTitle = e => {
        setTitle(e.target.value)
        setTitleError(false)
    }

    const handleChangeInfo = e => {
        setInfo(e.target.value)
        setInfoError(false)
    }

    const handleChangeType = (e) => {
        if (types.includes(e.target.value)) {
            setTypes(types.filter((event) => (event !== e.target.value)));
        } else {
            const orderedTypes = [FUNCTION_TYPE.MM, FUNCTION_TYPE.SD, FUNCTION_TYPE.PP, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM];
            let tempTypes = [];
            orderedTypes.forEach(type => {
                if (types.includes(type) || e.target.value.includes(type)) {
                    tempTypes.push(type)
                }
            })
            setTypes(tempTypes);
        }
    }

    const handleSubmit = () => {
        let hasError = false

        if (title === "") {
            setTitleError(true)
            hasError = true
            setTitleErrorMessage(t(`manage.fieldRequired`));
        }

        if (info === "") {
            setInfoError(true)
            hasError = true
        }

        if (!isEditing && specialFunctions.data.some(datum => datum.name === title)) {
            setTitleError(true);
            hasError = true;
            setTitleErrorMessage(t('error.specialFunctionAlreadyExists'));
        }

        if (!hasError) {
            if (isEditing) {
                let tempSpecialFunctions = { ...specialFunctions }
                const newCollection = [...collection]

                const tempFunc = JSON.parse(JSON.stringify(func))

                tempSpecialFunctions.data.forEach((p, j) => {
                    if (p.name === func.name && JSON.stringify(p.type) === JSON.stringify(func.type)) {
                        tempSpecialFunctions.data[j].name = title;
                        tempSpecialFunctions.data[j].dataType[0].type = types;
                        tempSpecialFunctions.data[j].dataType[0].info[0] = info;
                    }
                })

                if (isProcessAlreadyAddedToCollection(collection, tempFunc)) {

                    newCollection.forEach((c, i) => {
                        if (c.systemID === inspectedFunctionId) {
                            c.processes.forEach((p, j) => {
                                if (p.name === tempFunc.name) {
                                    if (JSON.stringify(p.type) === JSON.stringify(tempFunc.dataType[tempFunc.activeIndex].type)) {
                                        p.name = title;
                                        p.type = types;
                                    }
                                }
                            })
                        }
                    })
                }

                AppService.updateSpecialFunctions(userInfo.key, tempSpecialFunctions)
                    .then(res => {
                        setSpecialFunctions(tempSpecialFunctions);
                        handleShowSnackbar({
                            message: t("manage.objectEdited", { object: title }),
                            autoHideDuration: 3000,
                            icon: <Edit />,
                            showButton: true,
                        });
                    })

                AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
                    setCollection(convertNotesToEditorState(newCollection))
                }).catch(err => {
                    handleShowErrorSnackbar(err);
                });
            } else {
                const process = new Process(
                    title,
                    [
                        new ProcessType(
                            "P_SONDERFUNKTIONEN_ERWEITERUNGEN_" + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
                            types,
                            [info],
                            true,
                            null,
                        )
                    ]
                )

                const functions = {
                    ...specialFunctions,
                    data: [...specialFunctions.data,
                        process
                    ]
                }

                AppService.updateSpecialFunctions(userInfo.key, functions)
                    .then(res => {
                        setSpecialFunctions(functions)
                        handleShowSnackbar({
                            message: title + " " + t("manage.created"),
                            autoHideDuration: 3000,
                            icon: <Plus />,
                            showButton: true,
                        });
                    })
            }

            handleClose()
        }
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={isEditing ? t("dialogs.editFunction") : t("dialogs.newFunction")}
            content={
                <div
                    style={{
                        width: 600,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 40,
                        boxSizing: 'border-box'
                    }}
                >
                    <Grid container spacing={0} style={{ flexGrow: 1 }}>
                        <Grid item md={12} style={{ marginTop: 32 }}>
                            <Form
                                label={`Title`}
                                placeholder="Title"
                                value={title}
                                onChange={handleChangeTitle}
                                error={titleError}
                                errorMessage={titleError && titleErrorMessage}
                                required
                            />
                        </Grid>
                        <Grid item md={12} style={{ marginTop: 24 }}>
                            <Form
                                label={t("dialogs.operationManual")}
                                placeholder={t("dialogs.operationManual")}
                                value={info}
                                onChange={handleChangeInfo}
                                error={infoError}
                                errorMessage={infoError && t(`manage.fieldRequired`)}
                                multiline
                                rows={6}
                                required
                            />
                        </Grid>
                        <Grid item md={12} style={{ marginTop: 24, marginBottom: 12 }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {t("dialogs.types")}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    name="types"
                                    label={FUNCTION_TYPE.MM}
                                    value={FUNCTION_TYPE.MM}
                                    checked={types.includes(FUNCTION_TYPE.MM)}
                                    style={{ marginRight: 40 }}
                                    onChange={handleChangeType}
                                />
                                <Checkbox
                                    name="types"
                                    label={FUNCTION_TYPE.SD}
                                    value={FUNCTION_TYPE.SD}
                                    checked={types.includes(FUNCTION_TYPE.SD)}
                                    style={{ marginRight: 40 }}
                                    onChange={handleChangeType}
                                />
                                <Checkbox
                                    name="types"
                                    label={FUNCTION_TYPE.PP}
                                    value={FUNCTION_TYPE.PP}
                                    checked={types.includes(FUNCTION_TYPE.PP)}
                                    style={{ marginRight: 40 }}
                                    onChange={handleChangeType}
                                />
                                <Checkbox
                                    name="types"
                                    label={FUNCTION_TYPE.WM}
                                    value={FUNCTION_TYPE.WM}
                                    checked={types.includes(FUNCTION_TYPE.WM)}
                                    style={{ marginRight: 40 }}
                                    onChange={handleChangeType}
                                />
                                <Checkbox
                                    name="types"
                                    label={FUNCTION_TYPE.EWM}
                                    value={FUNCTION_TYPE.EWM}
                                    checked={types.includes(FUNCTION_TYPE.EWM)}
                                    onChange={handleChangeType}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 40 }}>
                        <Grid item md={12}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    type="primary"
                                    theme="dark"
                                    size="small"
                                    onClick={handleSubmit}
                                >
                                    {t("dialogs.saveFunction")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        />
    )
};

export default SpecialFunctionDialog;