import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';

import { useStyles } from './DeviceSettingDialogStyles';

import { useTranslation } from 'react-i18next';

// Components
import {
    DialogBox,
    Button,
    Checkbox,
    Typography,
    Separator,
    FONT_FAMILY,
    Color,
    Form,
    Warehouse,
    ProductionEHP,
    USER_TYPE,
    Edit,
    Plus,
    Password,
    Delete,
    Banner,
    IconButton
} from '@commsult/ontego-ui';

import DeleteDialog from '../DeleteDialog/DeleteDialog';

import Grid from '@material-ui/core/Grid';

const TableColumn = props => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {t("manage.name")}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {t("customMainDashboard.value")}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={14}
                        color={Color.neutral[800]}
                        style={{ float: "right" }}
                    >
                        {t("adminDashboard.action")}
                    </Typography>
                </Grid>
            </Grid>
            <Separator type="n400" width={2} marginTop="10px" />
        </Grid>
    )
}

const TableRow = props => {
    const { deviceSetting, setDeviceSettingAction, handleShowForm, handleOpenDeleteDialog, setInspectedDeviceSettingData } = props

    const handleEditDeviceSetting = () => {
        setInspectedDeviceSettingData(deviceSetting)
        setDeviceSettingAction("EDIT")
        handleShowForm()
    }

    const handleDeleteDeviceSetting = () => {
        setInspectedDeviceSettingData(deviceSetting);
        handleOpenDeleteDialog();
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                    >
                        {deviceSetting.name}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                    >
                        {deviceSetting.value}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <IconButton
                                    color="primary"
                                    icon={<Edit />}
                                    onClick={handleEditDeviceSetting}
                                    tooltip="Edit Setting"
                                />
                                <IconButton
                                    color="danger"
                                    icon={<Delete />}
                                    onClick={handleDeleteDeviceSetting}
                                    tooltip="Delete Setting"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Separator type="n400" width={1} marginTop="10px" />
        </Grid>
    )
}

const TableRowForm = props => {
    const { showForm, onCloseForm, deviceSettingAction, deviceSetting, deviceSettings, handlePersistence, refKey } = props

    const [settingName, setSettingName] = useState("")
    const [settingValue, setSettingValue] = useState("")

    const [settingNameError, setSettingNameError] = useState(false)
    const [settingValueError, setSettingValueError] = useState(false)

    const { t } = useTranslation();

    useEffect(() => {
        if (deviceSetting && deviceSettingAction === "EDIT") {
            setSettingName(deviceSetting.name)
            setSettingValue(deviceSetting.value)
        } else {
            setSettingName('')
            setSettingValue('')
        }
    }, [showForm])

    const handleCloseForm = () => {
        onCloseForm()
        setSettingName("")
        setSettingValue("")
        setSettingNameError(false)
        setSettingValueError(false)
    }

    const handleChangeSettingName = e => {
        setSettingName(e.target.value)
        setSettingNameError(false)
    }

    const handleChangeSettingValue = e => {
        setSettingValue(e.target.value)
        setSettingValueError(false)
    }

    const handleSubmit = () => {
        let hasError = false

        if (settingName === "") {
            setSettingNameError(true)
            hasError = true
        }
        if (settingValue === "") {
            setSettingValueError(true)
            hasError = true
        }

        if (!hasError) {
            let selectedDeviceSetting = null;
            let updatedDeviceSettings = [];

            if (deviceSettingAction === "EDIT") {
                let tempDeviceSettings = JSON.parse(JSON.stringify(deviceSettings));

                tempDeviceSettings.forEach((p, j) => {
                    if (p.key === deviceSetting.key) {
                        p.name = settingName;
                        p.value = settingValue;
                        selectedDeviceSetting = p;
                    }
                })

                updatedDeviceSettings = tempDeviceSettings;
            }

            if (deviceSettingAction === "CREATE") {
                const tempDeviceSetting = {
                    id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
                    refKey: refKey,
                    name: settingName,
                    value: settingValue
                }

                selectedDeviceSetting = tempDeviceSetting;
                updatedDeviceSettings = JSON.parse(JSON.stringify(deviceSettings));
            }

            handlePersistence(deviceSettingAction, refKey, JSON.parse(JSON.stringify(selectedDeviceSetting)), updatedDeviceSettings);
            handleCloseForm()
        }
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                <Grid item md={4}>
                    <Form
                        placeholder={t("dialogs.settingName")}
                        value={settingName}
                        onChange={handleChangeSettingName}
                        error={settingNameError}
                        errorMessage={settingNameError && t(`manage.fieldRequired`)}
                        required
                    />
                </Grid>
                <Grid item md={4}>
                    <Form
                        placeholder={t("dialogs.settingValue")}
                        value={settingValue}
                        onChange={handleChangeSettingValue}
                        error={settingValueError}
                        errorMessage={settingValueError && t(`manage.fieldRequired`)}
                        required
                    />
                </Grid>
                <Grid item md={4}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Button
                            type={"secondary"}
                            onClick={handleCloseForm}
                            noIcon={true}
                        >
                            {t(`manage.cancel`)}
                        </Button>
                        <Button
                            onClick={handleSubmit}
                        >
                            {t(`manage.save`)}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Separator type="n400" width={1} marginTop="10px" />
        </Grid>
    )
}

const DeviceSettingDialog = props => {
    // const classes = useStyles(props)

    const { open, onClose, deviceSettings, deviceSettingType, handlePersistence } = props

    const [deviceSettingAction, setDeviceSettingAction] = useState("CREATE")

    const [inspectedDeviceSettingData, setInspectedDeviceSettingData] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleClose = () => {
        onClose()
        setShowForm(false)
    }

    const handleShowForm = () => {
        setShowForm(true)
    }

    const handleCloseForm = () => {
        setShowForm(false)
        setInspectedDeviceSettingData(null)
        setDeviceSettingAction("CREATE")
    }

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
    }
    const handleDeleteDialog = () => {
        handlePersistence("DELETE", inspectedDeviceSettingData.refKey, JSON.parse(JSON.stringify(inspectedDeviceSettingData)), deviceSettings);
    }

    const handleCreateDeviceSetting = () => {
        setDeviceSettingAction("CREATE")
        handleShowForm()
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            title={`${deviceSettingType.title} Device Setting`}
            content={
                <>
                    <div
                        style={{
                            width: '600px',
                            transition: 'width 0.5s, height 0.5s',
                            transitionDelay: '0.5s'
                        }}
                    >
                        <Button
                            icon={<Plus />}
                            alignIcon="right"
                            onClick={handleCreateDeviceSetting}
                            type={"secondary"}
                            style={{ marginTop: 12 }}
                        >
                            {`Create New ${deviceSettingType.title} Device Setting`}
                        </Button>
                        <div style={{ height: 400 }}>
                            {(deviceSettings.length > 0 || showForm) ? (
                                <Grid container spacing={2} style={{ paddingTop: 10 }}>
                                    <TableColumn />
                                    {(showForm && deviceSettingAction === "CREATE") && (
                                        <TableRowForm
                                            showForm={showForm}
                                            onCloseForm={handleCloseForm}
                                            deviceSettingAction={deviceSettingAction}
                                            deviceSetting={inspectedDeviceSettingData}
                                            handlePersistence={handlePersistence}
                                            deviceSettings={deviceSettings}
                                            refKey={deviceSettingType.refKey}
                                        />
                                    )}
                                    {deviceSettings.map((deviceSetting, index) => {
                                        if (inspectedDeviceSettingData && deviceSetting.key === inspectedDeviceSettingData.key && deviceSettingAction === "EDIT") {
                                            return (
                                                <TableRowForm
                                                    key={`deviceSettingForm${index}`}
                                                    showForm={showForm}
                                                    onCloseForm={handleCloseForm}
                                                    deviceSettingAction={deviceSettingAction}
                                                    deviceSetting={inspectedDeviceSettingData}
                                                    deviceSettings={deviceSettings}
                                                    handlePersistence={handlePersistence}
                                                />
                                            )
                                        } else {
                                            return (
                                                <TableRow
                                                    key={`deviceSetting${index}`}
                                                    deviceSetting={deviceSetting}
                                                    handleShowForm={handleShowForm}
                                                    setDeviceSettingAction={setDeviceSettingAction}
                                                    setInspectedDeviceSettingData={setInspectedDeviceSettingData}
                                                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                                                />
                                            )
                                        }
                                    })}
                                </Grid>
                            ) : (
                                <Grid container spacing={2} style={{ paddingTop: 50 }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
                                        <Typography fontFamily={FONT_FAMILY.ROBOTO_BOLD} color={Color.neutral[300]} size={30}>{`No ${deviceSettingType.title} Device Setting`}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </div>
                    <DeleteDialog
                        open={deleteDialogOpen}
                        onClose={handleCloseDeleteDialog}
                        onDelete={handleDeleteDialog}
                        title={`${deviceSettingType.title} Device Setting`}
                        dataName={inspectedDeviceSettingData && inspectedDeviceSettingData.name}
                    />
                </>
            }
        />
    )
};

export default DeviceSettingDialog;