import { ColorSAP } from "@commsult/ontego-ui";
import { createStyles, makeStyles } from "@material-ui/styles";

// Material-UI Styles
export const useStyles = makeStyles((theme) =>
    createStyles({
        // drawerContainer: {
        //     width: "100%",
        // },
        drawerMenu: {
            position: "relative",
            marginBottom: 48,
            wordBreak: 'break-word'
        },
        drawerMenuLabel: {
            cursor: "pointer",
            marginLeft: 2
        },

        drawerSubmenuContainer: {
            position: "relative",
            width: "100%",
            marginTop: 16,
            // border: "2px solid red"
        },
        drawerThirdSubmenuContainer: {
            position: "relative",
            width: "100%",
            marginTop: 16,
            // border: "2px solid black"
        },

        drawerSubmenu: {
            boxSizing: "border-box",
            paddingTop: 14,
            paddingBottom: 14,
            backgroundColor: ColorSAP.neutral[0],
            cursor: "pointer",
            "&:hover": {
                background: ColorSAP.neutral[100]
            }
        },
        drawerThirdSubmenu: {
            boxSizing: "border-box",
            paddingTop: 14,
            paddingBottom: 14,
            backgroundColor: ColorSAP.neutral[0],
            cursor: "pointer",
            "&:hover": {
                background: ColorSAP.neutral[100]
            }
        },

        drawerSubmenuLabel: {
            position: "relative",
        },

        drawerSubmenuVerticalBar: {
            position: "absolute",
            top: 18,
            left: 40,
            bottom: 18,
            marginRight: 16,
            borderLeft: `2px solid ${ColorSAP.neutral[100]}`,
            zIndex: 100000,
        },
        drawerSubmenuVerticalPosition: {
            height: 16,
            position: "absolute",
            left: 40,
            marginRight: 16,
            transition: `all 0.2s linear`,
            borderLeft: `2px solid ${ColorSAP.neutral[100]}`,
            zIndex: 100001,
        },

        drawerThirdSubmenuVerticalBar: {
            position: "absolute",
            top: 18,
            left: 60,
            bottom: 18,
            marginRight: 16,
            borderLeft: `2px solid ${ColorSAP.neutral[100]}`,
            zIndex: 100000,
        },
        drawerThirdSubmenuVerticalPosition: {
            height: 16,
            position: "absolute",
            left: 60,
            marginRight: 16,
            transition: `all 0.2s linear`,
            borderLeft: `2px solid ${ColorSAP.neutral[100]}`,
            zIndex: 100001,
        },

        drawerMenuIsCustomizedTag: {
            position: "absolute",
            top: 2,
            left: 0,
            width: 8,
            height: 8,
            background: ColorSAP.secondary[40],
            borderRadius: "50%",
            zIndex: 99999
        },
        drawerSubmenuIsCustomizedTag: {
            position: "absolute",
            top: 0,
            left: 50,
            width: 8,
            height: 8,
            // background: ColorSAP.warning[100],
            borderRadius: "50%"
        },
        drawerThirdSubmenuIsCustomizedTag: {
            position: "absolute",
            top: 0,
            left: 70,
            width: 8,
            height: 8,
            // background: ColorSAP.warning[100],
            borderRadius: "50%"
        }
    })
);