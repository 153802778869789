// React Lib
import React, { useState } from "react";

// Component Styles
import { useStyles } from "./SystemMessageStyles";

// @otg-one/ui_components/components
import { Grid } from "@material-ui/core";
import { Separator, Color, FONT_FAMILY, Typography, ColorSAP } from "@commsult/ontego-ui";
import Chip from "../Chip/Chip";
import Spinner from "../Spinner/Spinner";
import TertiaryButton from "../TertiaryButton/TertiaryButton";


const SystemMessageList = (props) => {
    const classes = useStyles(props)

    const { id, log, style } = props

    return (
        <div
            className={classes.systemMessageList}
            style={style}
        >
            <Grid container spacing={0}>
                <Grid item md={2} style={{ textAlign: "center" }}>
                    <Chip
                        backgroundColor={ColorSAP.neutral[80]}
                        color={Color.neutral[0]}
                        smallPadding
                    >
                        {log.log_type}
                    </Chip>
                </Grid>
                <Grid item md={9}>
                    <Typography
                        id={`${id}-system-message`}
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: '100%' }}
                    >
                        {log.messages}
                    </Typography>
                </Grid>
                <Grid item md={1}>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                        <Typography
                            id={`${id}-system-message-date`}
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                            size={10}
                            color={ColorSAP.neutral[100]}
                            style={{ marginBottom: 4 }}
                        >
                            {log.date}
                        </Typography>
                        <Typography
                            id={`${id}-system-message-time`}
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                            size={10}
                            color={ColorSAP.neutral[100]}
                        >
                            {log.time}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export const SystemMessage = (props) => {
    const classes = useStyles(props)
    const { id, log, style, systemMessageListStyle, textSystemMessages, handleViewMore, textViewMore, isLoading } = props

    return (
        <div
            id={id}
            className={classes.systemMessageContainer}
            style={{
                position: 'relative',
                ...style
            }}
        >
            <div className={classes.systemMessageHeaderContainer}>
                <Typography
                    id={`${id}-system-message-title`}
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={24}
                    color={ColorSAP.neutral[100]}
                >
                    {textSystemMessages}
                </Typography>
                {
                    handleViewMore &&
                    // <Typography
                    //     fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    //     size={16}
                    //     color={ColorSAP.neutral[100]}
                    //     style={{ cursor: "pointer" }}
                    //     onClick={handleViewMore}
                    // >
                    //     {textViewMore}
                    // </Typography>
                    <TertiaryButton
                        theme="primary"
                        size="small"
                        onClick={handleViewMore}
                    >
                        {textViewMore}
                    </TertiaryButton>
                }
            </div>

            <div
                id={`${id}-system-message-container`}
                className={classes.systemMessageListContainer}
            >
                {log.map((l, index) => (
                    <React.Fragment key={`l${index}`}>
                        <SystemMessageList
                            key={`${l.messages}-${index}`}
                            id={`${id}-${index}`}
                            log={l}
                            style={systemMessageListStyle}
                        />
                        {index !== log.length - 1 && <Separator type="n100" />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

SystemMessage.defaultProps = {
    messages: [],
    log: [],
    textSystemMessages: 'System Messages',
    textViewMore: 'View More',
}

export default React.memo(SystemMessage)