import { Color, FONT_FAMILY } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";


export const useStyles = makeStyles((theme) =>
    createStyles({
        popoverRoot: {
            zIndex: 12000 + ` !important`,
        },
        menuRoot: {
            overflow: "visible",
            borderRadius: 0,
            boxShadow: "0px 2px 29px 0px rgba(3, 3, 3, 0.15)",
        },
        menuList: {
            padding: 0,
        },

        menuItem: {
            height: 36,
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
            fontSize: 12,
            color: Color.neutral[900],
            cursor: "pointer",
            display: "flex",
            overflow: "visible",
            "& a": {
                color: Color.neutral[900]
            },
            "&:hover": {
                backgroundColor: Color.neutral[100]
            }
        },

        menuItemText: {
            alignItems: "center",
            display: "flex",
        },

        menuItemCheckIcon: {
            minWidth: 0,
            marginTop: 4,
            marginRight: 8
        }
    })
);