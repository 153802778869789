// React libraries
import React from "react";

// React Google Charts
import { Chart as GoogleChart } from "react-google-charts";

export const Chart = (props) => {
    const { id, chartType, chartData, chartWidth, chartHeight,
        chartOptions, chartEvents, useLegendToggle, style, mapsApiKey, rootProps } = props

    return (
        <div
            id={id}
            style={style}
        >
            <GoogleChart
                loader={<div>{"Loading Chart"}</div>}
                chartType={chartType}
                data={chartData}
                width={chartWidth}
                height={chartHeight}
                options={chartOptions}
                chartEvents={chartEvents}
                legendToggle={useLegendToggle}
                mapsApiKey={mapsApiKey}
                rootProps={rootProps}
            />
        </div>
    )
}

Chart.defaultProps = {
    id: "",
    chartType: "ColumnChart",
    width: "100%",
    height: "100%",
    useLegendToggle: false,
    mapsApiKey: '',
    rootProps: null
}

export default React.memo(Chart);