import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';

import { useTranslation } from 'react-i18next';

import AppService from '../../AppService';

import Grid from '@material-ui/core/Grid';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Form from '../../ReusableComponents/Form/Form';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';
import Button from '../../ReusableComponents/Button/Button';

import { EditorState } from 'draft-js'
import { convertUpAndRunningNotesToEditorState, convertUpAndRunningNotesToJSON } from '../../Utils/Utils';

const UpAndRunningPhaseDialog = props => {
    const { title, open, onClose, upAndRunningCollection, setUpAndRunningCollection, inspectedData } = props
    const { userInfo, handleShowSnackbar, handleShowErrorSnackbar } = useContext(AppContext)

    const [phaseName, setPhaseName] = useState("")
    const [nameError, setNameError] = useState(false)
    const [nameErrorMessage, setNameErrorMessage] = useState("")

    const { t } = useTranslation();

    useEffect(() => {
        if (inspectedData) {
            setPhaseName(inspectedData.name)
        }
        else {
            setPhaseName("")
        }
    }, [inspectedData])

    const handleChangePhaseName = e => {
        setPhaseName(e.target.value)
        setNameError(false)
        setNameErrorMessage("")
    }

    const handleCreatePhase = () => {
        if (phaseName !== "") {
            const newUpAndRunningCollection = { ...upAndRunningCollection }

            const id = phaseName.toLowerCase().replace(/\s/g, '')

            let dataExist = false;

            newUpAndRunningCollection[id] = {
                id: id,
                name: phaseName,
                timeline: ["90", "120"],
                selectedTimeline: "90",
                isEditMode: false,
                notes: EditorState.createEmpty()
            }

            Object.keys(upAndRunningCollection).forEach(upAndRunning => {
                if (upAndRunningCollection[upAndRunning].name.toLowerCase() === phaseName.toLowerCase()) {
                    dataExist = true
                    setNameError(true)
                    setNameErrorMessage(t("manage.dataExist"))
                }
            });

            if (!dataExist) {
                AppService.updateUpAndRunningCollection(userInfo.key, convertUpAndRunningNotesToJSON(newUpAndRunningCollection)).then(res => {
                    setUpAndRunningCollection(convertUpAndRunningNotesToEditorState(newUpAndRunningCollection))
                    onClose()
                    handleShowSnackbar({
                        message: `Neue Up and Running Phase hinzugefügt`,
                        autoHideDuration: 5000,
                        showButton: true
                    });
                }).catch(error => handleShowErrorSnackbar(error));
            }
        }
        else {
            setNameError(true)
            setNameErrorMessage(t("error.dataCantEmpty"))
        }
    }

    const handleEditPhase = () => {
        const newUpAndRunningCollection = { ...upAndRunningCollection }

        let dataExist = false;
        Object.keys(upAndRunningCollection).forEach(upAndRunning => {
            if (upAndRunningCollection[upAndRunning].name.toLowerCase() === phaseName.toLowerCase()) {
                dataExist = true
                setNameError(true)
            }
        });

        if (!dataExist) {
            newUpAndRunningCollection[inspectedData.id].name = phaseName
            setUpAndRunningCollection(newUpAndRunningCollection)
            onClose()
            AppService.updateUpAndRunningCollection(userInfo.key, convertUpAndRunningNotesToJSON(newUpAndRunningCollection)).then(res => {
                setUpAndRunningCollection(convertUpAndRunningNotesToEditorState(newUpAndRunningCollection))
                onClose()
                handleShowSnackbar({
                    message: `${inspectedData.name} bearbeitet`,
                    autoHideDuration: 5000,
                    showButton: true
                });
            }).catch(error => handleShowErrorSnackbar(error));
        }
    }

    const handleClose = () => onClose()

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={title}
            content={
                <div
                    style={{
                        width: 508,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 40,
                        boxSizing: 'border-box'
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item md={12} style={{ marginTop: 24 }}>
                            <Form
                                label={`Name der Phase *`}
                                required
                                placeholder={t("manage.phaseName")}
                                value={phaseName}
                                onChange={handleChangePhaseName}
                                error={nameError}
                                errorMessage={nameErrorMessage}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 24 }}>
                                <TertiaryButton
                                    onClick={handleClose}
                                    theme="light"
                                    size="small"
                                    noIcon={true}
                                >
                                    {t(`manage.cancel`)}
                                </TertiaryButton>
                                <Button
                                    type="primary"
                                    theme="dark"
                                    size="small"
                                    onClick={inspectedData ? handleEditPhase : handleCreatePhase}
                                    disabled={phaseName === ""}
                                >
                                    {t(`manage.saveChanges`)}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        />
    )
};

export default UpAndRunningPhaseDialog;