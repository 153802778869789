// Material-UI Styles
import { makeStyles, createStyles } from '@material-ui/core/styles';

// Constants
import { ColorSAP } from "@commsult/ontego-ui"

export const useStyles = makeStyles((theme) =>
    createStyles({
        functionBoxContainer: {
            position: 'relative',
            cursor: 'pointer',
            boxSizing: "border-box",
            padding: 16,
            width: "100%",
            height: 110,
            borderRadius: 12,
            backgroundClip: 'padding-box',
            border: `2px solid ${ColorSAP.primary[70]}`,
            // background: "linear-gradient(178.68deg, #2A2E34 -191.38%, #3B3FEA 98.88%)",
            // border: `2px solid transparent`,
            transition: 'all ease-in-out 400ms',
            '&:before': {
                content: "''",
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: -1,
                margin: '-2px',
                borderRadius: 'inherit',
                background: `linear-gradient(${ColorSAP.primary[80]}, ${ColorSAP.primary[70]})`
            },
            "&:hover": {
                borderRadius: 12,
                border: `2px solid ${ColorSAP.primary[70]}`,
                background: ColorSAP.primary[70],
                // color: `${ColorSAP.neutral[0]} !important`,
                // transition: 'all ease-in-out 400ms'
            },
            "&:active": {
                border: `2px solid ${ColorSAP.primary[80]}`,
                background: ColorSAP.primary[80],
                transition: 'none',
            }
        },
        functionBoxIconLabelDefault: {
            display: 'flex',
            justifyContent: 'space-between',
            "& svg": {
                width: 32,
                height: 32,
                color: ColorSAP.primary[70],
                transition: "all .3s ease-in-out",
            }
        },
        functionBoxIconLabelHovered: {
            display: 'flex',
            justifyContent: 'space-between',
            "& svg": {
                width: 32,
                height: 32,
                color: ColorSAP.neutral[0],
                transition: "all .3s ease-in-out",
            }
        },

        functionBoxChipContainer: {
            display: 'flex',
            alignItems: 'center'
        },

        functionBoxCollectionIcon: {
            position: 'absolute',
            cursor: 'pointer',
            top: 15,
            right: 12,
            width: 32,
            height: 32,
            padding: 4,
            boxSizing: 'border-box',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        functionBoxCollectionIconDefault: {
            background: 'transparent',
            transition: '0.15s all ease',
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.primary[40]
            }
        },
        functionBoxCollectionIconHovered: {
            background: ColorSAP.primary[30],
            transition: '0.15s all ease',
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.primary[60]
            }
        },

        functionBoxAddedToCollectionIconDefault: {
            background: 'transparent',
            transition: '0.15s all ease',
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.secondary[40]
            }
        },
        functionBoxAddedToCollectionIconHovered: {
            background: ColorSAP.secondary[20],
            transition: '0.15s all ease',
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.secondary[50]
            }
        },
        functionBoxTypography: {
            width: 'fit-content',
            cursor: 'pointer',
            letterSpacing: -0.53,
            transition: "all .2s ease-in-out",
            marginLeft: 12,
        },
    })
);