// React libraries
import React from "react";

// Component Styles
import { useStyles } from "./EmptyChartDataStyles";
import { Grid } from "@material-ui/core";

export const EmptyChartData = (props) => {
    const classes = useStyles(props)

    const { id, style, textNoChartContent, textNoChartContentSmall, } = props

    return (
        <div
            style={style}
            id={id}
        >
            <Grid container alignItems={"center"} justifyContent={"center"} className={classes.noChartWrapper}>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={12} container justifyContent={"center"} alignItems={"center"}>
                            {/* <NoChart className={classes.noChartIcon} /> */}
                        </Grid>
                        <Grid item md={12} container justifyContent={"center"} alignItems={"center"}>
                            <Grid container>
                                <Grid item md={12} container justifyContent={"center"} alignItems={"center"}>
                                    <span className={classes.noChartContent}>
                                        {textNoChartContent}
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid item md={12} container justifyContent={"center"} alignItems={"center"}>
                                <span className={classes.noChartContentSmall}>
                                    {textNoChartContentSmall}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

EmptyChartData.defaultProps = {
    textNoChartContent: "Not enough data to show",
    textNoChartContentSmall: "Please use our system for at least a month to see your first monthly chart",
}

export default React.memo(EmptyChartData);