import React from 'react';

import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';

import './video.css';
import { ColorSAP } from '@commsult/ontego-ui';

const VideoDialog = props => {
    const { open, onClose } = props

    const handleClose = () => {
        onClose()
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={null}
            noBorderRadius
            noClose
            content={
                <div
                    style={{
                        background: ColorSAP.neutral[100],
                        width: '-webkit-fill-available',
                        height: 'auto',
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                >
                    <video
                        id="cockpit-video"
                        autoPlay playsInline controls loop
                    >
                        <source src="assets/videos/cockpit.webm" type="video/webm" />
                    </video>
                </div>
            }
        />
    )
};

export default VideoDialog;