import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../App';
import moment from 'moment';
import loc from 'moment/locale/de';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStyles } from './ManageMainDashboardStyles';

import {
    Typography,
    FONT_FAMILY,
    Color,
    DialogBox,
    Checkbox,
    Separator,
    Dropdown,
    Download,
    Delete,
    CalendarDateRangePicker
} from '@commsult/ontego-ui';

import Grid from '@material-ui/core/Grid';
import DebugLogDialogService from './DebugLogDialogService';
import DeleteDialog from '../../Dialogs/DeleteDialog/DeleteDialog';

const TableColumn = props => {
    const { handleCheckAll, isAllChecked } = props;

    const { t } = useTranslation();

    return (
        <>
            <Grid item xs={12} sm={12} md={12} >
                <div style={{
                    backgroundColor: Color.neutral[0],
                    zIndex: 1511,
                    width: 950,
                    position: 'fixed',
                    paddingTop: 30
                }}>
                    <Grid container spacing={2}  >
                        <Grid item md={4} style={{ position: 'relative' }}>
                            <Checkbox
                                name={'checkAll'}
                                value={'checkAll'}
                                onChange={handleCheckAll}
                                checked={isAllChecked}
                                style={{
                                    position: 'absolute'
                                }}
                            />
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                                style={{ paddingLeft: 40 }}
                            >
                                {t(`manage.logFileName`)}

                            </Typography>
                        </Grid>
                        <Grid item md={4} >
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.deviceName`)}
                            </Typography>
                        </Grid>
                        <Grid item md={2} >
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.date`)}
                            </Typography>
                        </Grid>
                        <Grid item md={2} >
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.time`)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Separator type="n800" width={1} marginTop="10px" />
                </div>
            </Grid >
        </>
    )
}

const TableRow = (props) => {
    const { data, handleCheck } = props;

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} style={{ display: 'flex', aligntItems: 'center' }}>
                <Grid item md={4} style={{ position: 'relative' }}>
                    <Checkbox
                        name={data.name}
                        value={data.key}
                        checked={data.isSelected}
                        onChange={handleCheck}
                        style={{
                            position: 'absolute'
                        }}
                    />
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={16}
                        color={Color.neutral[700]}
                        style={{ paddingLeft: 40 }}
                    >
                        {data.name}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={14}
                        color={Color.neutral[900]}
                    >
                        {data.deviceName}
                    </Typography>
                </Grid>
                <Grid item md={2} >
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {data.date}
                    </Typography>
                </Grid>
                <Grid item md={2} >
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                    >
                        {data.time}
                    </Typography>
                </Grid>
            </Grid>
            <Separator type="n200" width={1} marginTop="10px" />
        </Grid>
    )
}

const DebugLogDialog = props => {
    const classes = useStyles(props)
    const history = useHistory()
    const { t } = useTranslation();
    const { inspectedSystem, userInfo, handleShowErrorSnackbar } = useContext(AppContext)

    const { open, handleClose } = props;
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [data, setData] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [devices, setDevices] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const handleOpenDeleteDialog = () => setDeleteDialogOpen(true)
    const handleCloseDeleteDialog = () => setDeleteDialogOpen(false)

    const handleCheck = (e) => {
        const key = e.target.value;

        const temp = JSON.parse(JSON.stringify(data));
        temp.forEach(d => {
            if (d.key === key) d.isSelected = !d.isSelected;
        });

        const tempViewData = JSON.parse(JSON.stringify(viewData));
        temp.forEach(d => {
            tempViewData.forEach(vd => {
                if (d.key === vd.key) vd.isSelected = d.isSelected;
            });
        });

        setData(temp);
        setViewData(tempViewData);
    }

    const handleOnDateChange = (start, end) => {
        const tempData = JSON.parse(JSON.stringify(data));
        let filteredData = tempData;

        if (start)
            filteredData = filteredData.filter(d => moment(d.timestamp).startOf('day') >= start.startOf('day'))

        if (end)
            filteredData = filteredData.filter(d => moment(d.timestamp).startOf('day') <= end.startOf('day'))

        setViewData(filteredData);
    }

    const handleCheckAll = () => {
        const tempViewData = JSON.parse(JSON.stringify(viewData));
        let value = false;

        if (tempViewData.every(d => d.isSelected)) value = false;
        else if (tempViewData.some(d => d.isSelected)) value = true;
        else value = true;

        tempViewData.forEach(d => {
            d.isSelected = value;
        });

        const tempData = JSON.parse(JSON.stringify(data));
        tempViewData.forEach(vd => {
            tempData.forEach(d => {
                if (d.name === vd.name) d.isSelected = vd.isSelected;
            });
        });

        setData(tempData);
        setViewData(tempViewData);
    }

    const handleDelete = () => {
        const temp = JSON.parse(JSON.stringify(data));
        const notSelected = temp.filter(t => !t.isSelected);
        const selected = temp.filter(t => t.isSelected);

        const tempViewData = JSON.parse(JSON.stringify(viewData));
        const notSelectedViewData = tempViewData.filter(t => !t.isSelected);

        (new Promise((resolve, reject) => {
            const selectedData = [];
            selected.forEach(s => {
                DebugLogDialogService.deleteDeviceLogFile(userInfo.key, inspectedSystem.key, s.key)
                    .then(response => selectedData.push(response.data))
                    .catch(error => {
                        handleShowErrorSnackbar(error);
                        reject(error);
                    });
            })
            resolve(selectedData);
        })).then(val => {
            setData(notSelected);
            setViewData(notSelectedViewData);
        });
    }

    const handleSave = () => {
        const temp = JSON.parse(JSON.stringify(data));
        const selected = temp.filter(t => t.isSelected);
        const keys = selected.map(s => s.key)

        DebugLogDialogService.downloadFiles(userInfo.key, inspectedSystem.key, keys)
            .then(response => {
                (function (filename, data) {
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;base64,' + data);
                    element.setAttribute('download', filename);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                }(`${inspectedSystem.name}_log.zip`, response.data))
            })
            .catch(error => {
                handleShowErrorSnackbar(error);
            });
    }

    const handleSelectDropdown = (name) => {
        if (name === 'ALL') {
            const temp = JSON.parse(JSON.stringify(data));
            setViewData(temp);
            return;
        }

        const tempViewData = JSON.parse(JSON.stringify(data));
        const selected = tempViewData.filter(t => t.deviceName.includes(name));
        setViewData(selected);
    }

    const checkIsAllChecked = () => {
        const temp = JSON.parse(JSON.stringify(viewData));
        const value = temp.length === 0 ? false : temp.every(d => d.isSelected);
        setIsAllChecked(value);
    }

    const checkIsAnyChecked = () => {
        const temp = JSON.parse(JSON.stringify(viewData));
        return temp.length === 0 ? false : temp.some(d => d.isSelected);
    }

    useEffect(() => {
        moment.updateLocale('de', loc);
        if (userInfo)
            fetchLogFilesData();
    }, []);

    useEffect(() => {
        checkIsAllChecked();
    }, [viewData]);

    const fetchLogFilesData = () => {
        DebugLogDialogService.getDeviceLogFiles(userInfo.key, inspectedSystem.key)
            .then(response => {
                const logFiles = response.data;
                const deviceList = [];

                logFiles.forEach(file => {
                    file.date = moment(file.timestamp).format('ddd, DD MMM YYYY');
                    file.time = moment(file.timestamp).format('HH:mm:ss');
                    file.isSelected = false;

                    if (!deviceList.includes(file.deviceName))
                        deviceList.push(file.deviceName);
                })

                setDevices(deviceList);
                setData(logFiles);
                setViewData(logFiles);
            })
            .catch(error => handleShowErrorSnackbar(error))
    }

    const DEVICE_DATA = () => {
        const deviceList = [];
        deviceList.push({
            text: t('manage.allDevices'),
            onClick: () => handleSelectDropdown('ALL')
        });

        devices.forEach(device => deviceList.push({
            text: device,
            onClick: () => handleSelectDropdown(device)
        }));

        return deviceList;
    }

    return (
        <>
            <DialogBox
                id="debug-dialog-box"
                open={open}
                handleClose={handleClose}
                title={t("manage.deviceLogs")}
                content={
                    <div style={{ width: 950, height: '60vh', overflowX: 'hidden' }}>
                        <Typography
                            fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                            size={16}
                            color={Color.neutral[800]}
                            style={{
                                position: 'fixed',
                                backgroundColor: Color.neutral[0],
                                zIndex: 1512
                            }}
                        >
                            {inspectedSystem ? inspectedSystem.name : 'System Name'}
                        </Typography>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            position: 'fixed',
                            width: 950,
                            paddingTop: 30,
                            backgroundColor: Color.neutral[0],
                            zIndex: 1511
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <Dropdown
                                    menu={DEVICE_DATA()}
                                    placeholder={t('manage.selectDevice')}
                                    type='SELECTION'
                                    style={{ marginRight: 30 }}
                                />
                                {/* <CalendarDateRangePicker
                                    start={null}
                                    end={null}
                                    calendarRef={document.getElementById('root')}
                                    onChange={handleOnDateChange}
                                /> */}
                            </div>
                            {checkIsAnyChecked() && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Download
                                        onClick={handleSave}
                                        style={{
                                            width: 24,
                                            height: 24,
                                            color: Color.primary[200],
                                            paddingRight: 20,
                                            cursor: 'pointer'
                                        }}
                                    />
                                    <Delete
                                        onClick={handleOpenDeleteDialog}
                                        style={{
                                            width: 24,
                                            height: 24,
                                            color: Color.danger[100],
                                            cursor: 'pointer'
                                        }} />
                                </div>
                            )}
                        </div>
                        <Grid container spacing={2} style={{ marginTop: 60 }}>
                            <TableColumn isAllChecked={isAllChecked} handleCheckAll={handleCheckAll} />
                            <div style={{ marginTop: 75 }} />
                            {viewData.map((data, index) => (
                                <TableRow key={`debugData${index}`} data={data} handleCheck={handleCheck} />
                            ))}
                        </Grid>
                    </div>
                }
            />
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                onDelete={handleDelete}
                title={t("manage.deviceLogs")}
                dataName={t("manage.deviceLogs")}
            />
        </>
    )
}

export default DebugLogDialog