import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** VERPACKUNG IMAGES */
import Verpackung_Einfuehrung1 from '../../assets/img/verpackung/Einfuehrung/1.png'
import Verpackung_Einfuehrung2 from '../../assets/img/verpackung/Einfuehrung/2.png'
import Verpackung_Einfuehrung3 from '../../assets/img/verpackung/Einfuehrung/3.png'
import Verpackung_Einfuehrung4 from '../../assets/img/verpackung/Einfuehrung/4.png'

import Verpackung_HU_Ohne_Packvorschrift1 from '../../assets/img/verpackung/HU_Ohne_Packvorschrift/1.png'
import Verpackung_HU_Ohne_Packvorschrift2 from '../../assets/img/verpackung/HU_Ohne_Packvorschrift/2.png'
import Verpackung_HU_Ohne_Packvorschrift3 from '../../assets/img/verpackung/HU_Ohne_Packvorschrift/3.png'
import Verpackung_HU_Ohne_Packvorschrift4 from '../../assets/img/verpackung/HU_Ohne_Packvorschrift/4.png'
import Verpackung_HU_Ohne_Packvorschrift5 from '../../assets/img/verpackung/HU_Ohne_Packvorschrift/5.png'
import Verpackung_HU_Ohne_Packvorschrift6 from '../../assets/img/verpackung/HU_Ohne_Packvorschrift/6.png'

import Verpackung_HU_Mit_Packvorschrift1 from '../../assets/img/verpackung/HU_Mit_Packvorschrift/1.png'
import Verpackung_HU_Mit_Packvorschrift2 from '../../assets/img/verpackung/HU_Mit_Packvorschrift/2.png'
import Verpackung_HU_Mit_Packvorschrift3 from '../../assets/img/verpackung/HU_Mit_Packvorschrift/3.png'
import Verpackung_HU_Mit_Packvorschrift4 from '../../assets/img/verpackung/HU_Mit_Packvorschrift/4.png'
import Verpackung_HU_Mit_Packvorschrift5 from '../../assets/img/verpackung/HU_Mit_Packvorschrift/5.png'
import Verpackung_HU_Mit_Packvorschrift6 from '../../assets/img/verpackung/HU_Mit_Packvorschrift/6.png'

import Verpackung_HU_Aendern1 from '../../assets/img/verpackung/HU_Aendern/1.png'
import Verpackung_HU_Aendern2 from '../../assets/img/verpackung/HU_Aendern/2.png'
import Verpackung_HU_Aendern3 from '../../assets/img/verpackung/HU_Aendern/3.png'

import Verpackung_HU_Verpacken1 from '../../assets/img/verpackung/HU_Verpacken/1.png'
import Verpackung_HU_Verpacken2 from '../../assets/img/verpackung/HU_Verpacken/2.png'

import Verpackung_Auslieferung1 from '../../assets/img/verpackung/Auslieferung/1.png'
import Verpackung_Auslieferung2 from '../../assets/img/verpackung/Auslieferung/2.png'
import Verpackung_Auslieferung3 from '../../assets/img/verpackung/Auslieferung/3.png'
import Verpackung_Auslieferung4 from '../../assets/img/verpackung/Auslieferung/4.png'
import Verpackung_Auslieferung5 from '../../assets/img/verpackung/Auslieferung/5.png'
import Verpackung_Auslieferung6 from '../../assets/img/verpackung/Auslieferung/6.png'
import Verpackung_Auslieferung7 from '../../assets/img/verpackung/Auslieferung/7.png'
import Verpackung_Auslieferung8 from '../../assets/img/verpackung/Auslieferung/8.png'
import Verpackung_Auslieferung9 from '../../assets/img/verpackung/Auslieferung/9.png'

import Verpackung_AutomatischesVerpacken1 from '../../assets/img/verpackung/AutomatischesVerpacken/1.png'
import Verpackung_AutomatischesVerpacken2 from '../../assets/img/verpackung/AutomatischesVerpacken/2.png'
import Verpackung_AutomatischesVerpacken3 from '../../assets/img/verpackung/AutomatischesVerpacken/3.png'
import Verpackung_AutomatischesVerpacken4 from '../../assets/img/verpackung/AutomatischesVerpacken/4.png'
import Verpackung_AutomatischesVerpacken5 from '../../assets/img/verpackung/AutomatischesVerpacken/5.png'
import Verpackung_AutomatischesVerpacken6 from '../../assets/img/verpackung/AutomatischesVerpacken/6.png'

import Verpackung_Anlieferung1 from '../../assets/img/verpackung/Anlieferung/1.png'
import Verpackung_Anlieferung2 from '../../assets/img/verpackung/Anlieferung/2.png'
import Verpackung_Anlieferung3 from '../../assets/img/verpackung/Anlieferung/3.png'
import Verpackung_Anlieferung4 from '../../assets/img/verpackung/Anlieferung/4.png'
import Verpackung_Anlieferung5 from '../../assets/img/verpackung/Anlieferung/5.png'
import Verpackung_Anlieferung6 from '../../assets/img/verpackung/Anlieferung/6.png'

export const VerpackungImages = [
    new ProcessImageContainer(
        `P_VERPACKUNG_EINFUEHRUNG`,
        [
            new ProcessImage('Positionen mit Packmittel-Vorschlag', Verpackung_Einfuehrung1),
            new ProcessImage('Packvorschriften', Verpackung_Einfuehrung2),
            new ProcessImage('HU Inhalt', Verpackung_Einfuehrung3),
            new ProcessImage('Abschluss HUs ohne offene Positionen', Verpackung_Einfuehrung4)
        ]
    ),
    new ProcessImageContainer(
        `P_VERPACKUNG_FREIE_HANDLING_UNIT_OHNE_PACKVORSCHRIFT_ERSTELLEN`,
        [
            new ProcessImage('Optional Menu', Verpackung_HU_Ohne_Packvorschrift1),
            new ProcessImage('Werk und Lagerort', Verpackung_HU_Ohne_Packvorschrift2),
            new ProcessImage('Positionen mit Packmittel-Vorschlag', Verpackung_HU_Ohne_Packvorschrift3),
            new ProcessImage('Positionen ohne Packmittel-Vorschlag', Verpackung_HU_Ohne_Packvorschrift4),
            new ProcessImage('Inhalt', Verpackung_HU_Ohne_Packvorschrift5),
            new ProcessImage('Optional Material Details', Verpackung_HU_Ohne_Packvorschrift6)
        ]
    ),
    new ProcessImageContainer(
        `P_VERPACKUNG_FREIE_HANDLING_UNIT_MIT_PACKVORSCHRIFT_ERSTELLEN`,
        [
            new ProcessImage('Optional Menu', Verpackung_HU_Mit_Packvorschrift1),
            new ProcessImage('Werk und Lagerort', Verpackung_HU_Mit_Packvorschrift2),
            new ProcessImage('Packvorschriften', Verpackung_HU_Mit_Packvorschrift3),
            new ProcessImage('Positionen unvollständig', Verpackung_HU_Mit_Packvorschrift4),
            new ProcessImage('Details', Verpackung_HU_Mit_Packvorschrift5),
            new ProcessImage('Positionen vollständig', Verpackung_HU_Mit_Packvorschrift6)
        ]
    ),
    new ProcessImageContainer(
        `P_VERPACKUNG_FREIE_HANDLING_UNIT_AENDERN`,
        [
            new ProcessImage('HU', Verpackung_HU_Aendern1),
            new ProcessImage('Positionen', Verpackung_HU_Aendern2),
            new ProcessImage('Detail', Verpackung_HU_Aendern3)
        ]
    ),
    new ProcessImageContainer(
        `P_VERPACKUNG_FREIE_HANDLING_UNIT_VERPACKEN`,
        [
            new ProcessImage('HU', Verpackung_HU_Verpacken1),
            new ProcessImage('Ziel', Verpackung_HU_Verpacken2)
        ]
    ),
    new ProcessImageContainer(
        `P_VERPACKUNG_VERPACKEN_ZUR_AUSLIEFERUNG`,
        [
            new ProcessImage('Auslieferung', Verpackung_Auslieferung1),
            new ProcessImage('Initial keine HUs', Verpackung_Auslieferung2),
            new ProcessImage('HU erstellen', Verpackung_Auslieferung3),
            new ProcessImage('Erstellte HUs', Verpackung_Auslieferung4),
            new ProcessImage('HU Inhalt initial leer', Verpackung_Auslieferung5),
            new ProcessImage('HU mit Inhalt füllen', Verpackung_Auslieferung6),
            new ProcessImage('Material zu HU hinzufügen', Verpackung_Auslieferung7),
            new ProcessImage('HU Inhalt', Verpackung_Auslieferung8),
            new ProcessImage('Abschluss HUs ohne offene Positionen', Verpackung_Auslieferung9)
        ]
    ),
    new ProcessImageContainer(
        `P_VERPACKUNG_AUTOMATISCHES_VERPACKEN_NACH_PACKVORSCHRIFT`,
        [
            new ProcessImage('Auslieferung', Verpackung_AutomatischesVerpacken1),
            new ProcessImage('Erstellte HUs', Verpackung_AutomatischesVerpacken2),
            new ProcessImage('HU Inhalt', Verpackung_AutomatischesVerpacken3),
            new ProcessImage('HU mit Inhalt füllen', Verpackung_AutomatischesVerpacken4),
            new ProcessImage('Material zu HU hinzufügen', Verpackung_AutomatischesVerpacken5),
            new ProcessImage('Abschluss HUs ohne offene Positionen', Verpackung_AutomatischesVerpacken6)
        ]
    ),
    new ProcessImageContainer(
        `P_VERPACKUNG_VERPACKEN_ZUR_ANLIEFERUNG`,
        [
            new ProcessImage('Anlieferung', Verpackung_Anlieferung1),
            new ProcessImage('Positionen', Verpackung_Anlieferung2),
            new ProcessImage('Detail', Verpackung_Anlieferung3),
            new ProcessImage('HU Inhalt', Verpackung_Anlieferung4),
            new ProcessImage('Neu hinzufügen offene Anlieferpositionen / HUs', Verpackung_Anlieferung5),
            new ProcessImage('Detail', Verpackung_Anlieferung6)
        ]
    )
]