import React, { useState, useEffect } from 'react';

// Components
import {
    Typography,
    FONT_FAMILY,
    ColorSAP,
    Check,
} from '@commsult/ontego-ui';

// Material UI Core
import Grid from '@material-ui/core/Grid'

import { ReactComponent as MonthlyCostIllustration } from './pricing_license_1.svg';
import { ReactComponent as MonthlyCostIllustrationSwitzerland } from './pricing_license_1_switzerland.svg';
import { ReactComponent as MoreThan100DeviceIllustration } from './pricing_license_2.svg';
import { ReactComponent as AppConnectAtLeastIllustration } from './pricing_license_3.svg';
import { ReactComponent as AppNeedsToReconnectAfterIllustration } from './pricing_license_4.svg';
import { useTranslation } from 'react-i18next';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Carousel from '../../ReusableComponents/Carousel/Carousel';
import Button from '../../ReusableComponents/Button/Button';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';
import { iconSizeTertiaryButtonIcon } from '../../Utils/Utils';
import ArrowHeadSpecial from '../../Icons/ArrowHeadSpecial';

const CheckmarkText = props => {
    const { children } = props
    return (
        <div style={{ display: 'flex', textAlign: 'center', marginTop: 10 }}>
            <Check style={{ width: 20, height: 20, color: ColorSAP.primary[70], marginRight: 16, marginBottom: -10 }} />
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                size={14}
                color={ColorSAP.neutral[100]}
            >
                {children}
            </Typography>
        </div>
    )
}

const MonthlyCost = props => {
    const { country } = props;
    const { t } = useTranslation();

    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {`${t("dialogs.howMuchMonthly")}?`}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={0}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.paymentAccordingConsume")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.MDEUser")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.includeSupportOperation")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.usageExplanation")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: 248 }}>
                        {country === "GERMANY" &&
                            <MonthlyCostIllustration />
                        }
                        {country === "SWITZERLAND" &&
                            <MonthlyCostIllustrationSwitzerland />
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const MoreThan100Device = props => {
    const { t } = useTranslation();
    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {t("dialogs.eachDeviceReport")}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={0}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.MDECheckin")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.noDataTransfer")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.noInternet")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.appExp")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: 248 }}>
                        <MoreThan100DeviceIllustration />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const MoreThan250Device = props => {
    const { t } = useTranslation();
    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {t("dialogs.buyInAdvance")}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={0}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.budgetFriendly")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.additionalBill")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.additionalBillExp")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: 248 }}>
                        <AppConnectAtLeastIllustration />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const AppConnectAtLeast = props => {
    const { t } = useTranslation();
    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {t("dialogs.noMinimum")}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={0}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.easyStart")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.neverthelessIndividual")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.cancelAnyTime")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.noMinimumExp")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: 248 }}>
                        <AppNeedsToReconnectAfterIllustration />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const PricingAndLicenseDialog = props => {
    const { open, onClose, country } = props

    const { t } = useTranslation();

    /** Init nav length */
    const navLength = 4;
    /** Set carousel nav starting index */
    const [navIndex, setNavIndex] = useState(0);

    useEffect(() => {
        setNavIndex(0)
    }, [open])

    /** Set carousel onClick */
    const handleCarouselNavOnClick = index => () => {
        setNavIndex(index)
    };

    const nav = [
        <MonthlyCost country={country} />,
        <MoreThan100Device />,
        <MoreThan250Device />,
        <AppConnectAtLeast />
    ];

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            textTitle={t("navbar.priceModel")}
            content={
                <div style={{ width: 750, overflowX: 'hidden', paddingLeft: 24, paddingRight: 24, paddingBottom: 40, boxSizing: 'border-box' }}>
                    {nav[navIndex]}

                    <div style={{ width: '100%', textAlign: 'center', marginTop: 24, marginBottom: 16 }}>
                        <Carousel
                            length={navLength}
                            index={navIndex}
                            onClick={handleCarouselNavOnClick}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", marginTop: 24 }}>
                        <div>
                            {navIndex > 0 && (
                                <TertiaryButton
                                    theme="light"
                                    size="xsmall"
                                    onClick={handleCarouselNavOnClick(navIndex - 1)}
                                    isIconBeforeText={true}
                                    icon={<ArrowHeadSpecial style={{ marginRight: 8, width: iconSizeTertiaryButtonIcon("xsmall"), height: iconSizeTertiaryButtonIcon("xsmall"), transform: "rotate(180deg)" }} />}
                                >
                                    {t("common.return")}
                                </TertiaryButton>
                            )}
                        </div>
                        <div style={{ display: 'flex' }}>
                            {navIndex !== navLength - 1 ? (
                                <Button
                                    type="primary"
                                    theme="dark"
                                    size="small"
                                    style={{ outline: 'none' }}
                                    onClick={handleCarouselNavOnClick(navIndex + 1)}
                                >
                                    {t("common.continue")}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    theme="dark"
                                    size="small"
                                    style={{ outline: 'none' }}
                                    onClick={onClose}
                                >
                                    {t("common.conclude")}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            }
        />
    )
};

export default PricingAndLicenseDialog;