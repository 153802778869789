// React Lib
import React, { useEffect, useState } from "react";
import classnames from 'classnames';

import { CopyToClipboard as CopyFunction } from 'react-copy-to-clipboard';

// Component Styles
import { useStyles } from "./UpAndRunningStyles";
import Button from "../Button/Button";
import { Color, ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { Copy, Edit, Delete, ShowMoreVertical } from "@commsult/ontego-ui/dist/sap_icons";
import Form from "../Form/Form";
import TertiaryButton from "../TertiaryButton/TertiaryButton";
import Radio from "../Radio/Radio";
import { OverflowMenu } from "../OverflowMenu/OverflowMenu";

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import EditorComponent from "../TextEditor/TextEditor";
import useScreenSize from "../../Utils/useScreenSize";
import { useTranslation } from "react-i18next";
import { iconSizeTertiaryButtonIcon } from "../../Utils/Utils";
import PlusIcon from "../../Icons/PlusIcon";

const NoteRenderer = (props) => {
    const classes = useStyles(props)
    const { t } = useTranslation()

    const { data, type, onEditNote, onSaveNote, onDeleteNote,
        onCopyNote, addNote, phaseNote, addPhaseNote, cancel, saveNote } = props

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [showEditor, setShowEditor] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setShowEditor(false)
        setEditorState(data.notes)

        if (data.notes.getCurrentContent().hasText()) {
            setShowEditor(true)
            setIsEdit(false)
        }
    }, [])

    const handleAddNewNote = () => {
        setShowEditor(true)
        setIsEdit(true)
    }

    const handleSaveNote = (editorState) => {
        onSaveNote(data, type, editorState)
    }

    const handleDeleteNote = () => {
        onDeleteNote(data, type)
        setShowEditor(false)
    }

    return (
        <>
            {!showEditor && (
                <TertiaryButton
                    theme="light"
                    size="small"
                    onClick={handleAddNewNote}
                    style={{ color: ColorSAP.neutral[100] }}
                    icon={<PlusIcon style={{ marginLeft: 8, width: iconSizeTertiaryButtonIcon("small"), height: iconSizeTertiaryButtonIcon("small") }} />}
                >
                    {t('dialogs.addNote')}
                </TertiaryButton>
            )}
            {showEditor && (
                <EditorComponent
                    handleSaveChange={handleSaveNote}
                    handleDeleteNotes={handleDeleteNote}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    setShowEditor={setShowEditor}
                />
            )}
        </>
    )
}

export const UpAndRunning = (props) => {
    const classes = useStyles(props)

    const { data, isDragging, children, hasChild, onEditNote, onSaveNote, onDeleteNote, onCopyNote, onTimelineChange, addNote,
        phaseNote, addPhaseNote, cancel, saveNote, noFunctionAssigned, dragAndDropAFunctionHere, menuItem, onEdit, onDelete, style } = props

    const upAndRunningClasses = classnames({
        [classes.upAndRunningContainer]: !isDragging,
        [classes.upAndRunningContainerIsDraggingOver]: isDragging
    })

    const { t } = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClickShowMoreIcon = (event) => {
        event.stopPropagation();

        if (!anchorEl) {
            setAnchorEl(event.currentTarget)
        }
    }

    return (
        <div
            id={data.id}
            className={upAndRunningClasses}
            style={{
                ...style
            }}
        >
            <ShowMoreVertical
                style={{
                    position: 'absolute',
                    top: 16,
                    right: 10,
                    width: 24,
                    height: 24,
                    color: ColorSAP.primary[40],
                    cursor: 'pointer'
                }}
                onClick={handleClickShowMoreIcon}
            />
            <OverflowMenu
                anchorElement={anchorEl}
                setAnchorEl={setAnchorEl}
                // menuItems={menuItem}
                menuItems={[
                    {
                        text: t(`collection.editPhase`),
                        onClick: () => onEdit()
                    },
                    {
                        text: t(`collection.deletePhase`),
                        onClick: () => onDelete(),
                        destructive: true
                    }
                ]}
            />
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                size={18}
                color={ColorSAP.neutral[100]}
                style={{ paddingBottom: 20, wordBreak: 'break-word', paddingRight: 24 }}
            >
                {data.name}
            </Typography>
            <div className={classes.timelineInputContainer}>
                {data.timeline.map((time, index) => (
                    <Radio
                        name={data.id}
                        value={time}
                        label={`${time} Tage`}
                        checked={data.selectedTimeline === time}
                        onChange={onTimelineChange}
                        style={{ marginRight: index === 0 ? 30 : 'auto' }}
                    />
                ))}
            </div>
            {children}
            <div
                style={{
                    width: '100%',
                    paddingTop: 15,
                    paddingBottom: 25,
                    textAlign: 'center'
                }}
            >
                {!hasChild && (
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ paddingBottom: 5 }}
                    >
                        {t('collection.noFunctionAssigned')}
                    </Typography>
                )}
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {t('collection.dragAndDropHere')}
                </Typography>

            </div>
            <NoteRenderer
                data={data}
                type="UP_AND_RUNNING"
                onEditNote={onEditNote}
                onSaveNote={onSaveNote}
                onDeleteNote={onDeleteNote}
                onCopyNote={onCopyNote}
                addNote={addNote}
                phaseNote={phaseNote}
                addPhaseNote={addPhaseNote}
                cancel={cancel}
                saveNote={saveNote}
            />
        </div>
    )
}

UpAndRunning.defaultProps = {
    data: {
        id: "up-running-1",
        name: "Up and Running #",
        timeline: [90, 120],
        selectedTimeline: 90,
        isEditMode: false,
        notes: ""
    },
    isDraggingOver: false,
    children: null,
    isDragging: false,
    hasChild: false,
    addNote: 'Add Note',
    phaseNote: 'Phase Note',
    addPhaseNote: 'Add Phase Note',
    cancel: 'Cancel',
    saveNote: 'Save Note',
    editPhase: 'Edit Phase',
    deletePhase: 'Delete Phase',
    noFunctionAssigned: 'No Function Assigned',
    dragAndDropAFunctionHere: 'Drag And Drop A Function Here',
    menuItem: [{
        text: 'Edit Phase',
        onClick: () => { }
    },
    {
        text: 'Delete Phase',
        onClick: () => { }
    }],
    onEditNote: () => { }
}

export default React.memo(UpAndRunning)