import React, { useState, useContext, useEffect } from 'react';
import AdminUserService from '../../Pages/AdminDashboard/AdminUserService';
import { AppContext } from '../../App'
import {
    Plus,
    Typography,
    Color,
    FONT_FAMILY,
    Separator,
    Edit,
    ColorSAP
} from '@commsult/ontego-ui';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { ROLE_ADMIN, ROLE_USER } from '../../Constants/ClientRoles';
import { ACTION_TYPE_ADD, ACTION_TYPE_UPDATE, ACTION_TYPE_ADD_CLIENT } from '../../Constants/DashboardActionType';
import Button from '../../ReusableComponents/Button/Button';
import Dropdown from '../../ReusableComponents/Dropdown/Dropdown';
import Form from '../../ReusableComponents/Form/Form';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';

const ClientDialog = props => {
    const { open, onClose, setIsSuccess, actionType, inspectedData } = props;
    const { t } = useTranslation();

    const {
        handleShowErrorSnackbar, handleShowSnackbar
    } = useContext(AppContext);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState(ROLE_USER);
    const [dropMenuRoleItems, setDropMenuRoleItems] = useState([]);

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)

    useEffect(() => {
        const UserRoleList = [
            {
                text: ROLE_USER,
                onClick: () => { setRole(ROLE_USER) }
            },
            {
                text: ROLE_ADMIN,
                onClick: () => { setRole(ROLE_ADMIN) }
            }
        ]
        setDropMenuRoleItems(UserRoleList);
    }, [])

    useEffect(() => {
        if (actionType === ACTION_TYPE_UPDATE) {
            setName(inspectedData.name);
            setEmail(inspectedData.email);
            setRole(inspectedData.roles);
        } else if (actionType === ACTION_TYPE_ADD || actionType === ACTION_TYPE_ADD_CLIENT) {
            setName("");
            setEmail("");
            setRole(ROLE_USER);
        }
    }, [open])

    const handleChangeName = e => {
        setName(e.currentTarget.value)
        setNameError(false)
    };

    const handleChangeEmail = e => {
        setEmail(e.currentTarget.value)
        setEmailError(false)
    };

    const handleChangePassword = e => {
        setPassword(e.currentTarget.value)
        setPasswordError(false)
    };

    const handleChangeConfirmPassword = e => {
        setConfirmPassword(e.currentTarget.value)
        setConfirmPasswordError(false)
    };

    const handleSubmit = e => {
        e.preventDefault();
        const hasError = errorValidation();


        if (!hasError) {
            if (actionType === ACTION_TYPE_ADD) {
                const formModel = {
                    email: email,
                    name: name,
                    password: password,
                    role: role
                };

                AdminUserService.createNewClient(formModel).then(res => {
                    setIsSuccess(true);

                    handleShowSnackbar({
                        message: t("manage.objectCreated", { object: name }),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    });
                    handleClose();
                }).catch(err => {
                    handleShowErrorSnackbar(err);
                })
            } else if (actionType === ACTION_TYPE_ADD_CLIENT) {
                const formModel = {
                    user_mail: email,
                    user_password: password,
                    role: role,
                    client_id: inspectedData.id
                };

                AdminUserService.createNewClientUser(formModel).then(res => {
                    setIsSuccess(true);

                    handleShowSnackbar({
                        message: t("manage.objectCreated", { object: email }),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    });
                    handleClose();
                }).catch(err => {
                    handleShowErrorSnackbar(err);
                })
            }
            else {
                const formModel = {
                    email: email,
                    name: name,
                    password: password,
                    role: role
                };

                AdminUserService.updateClient(inspectedData.key, formModel).then(res => {
                    setIsSuccess(true);

                    handleShowSnackbar({
                        message: t("manage.objectEdited", { object: name }),
                        autoHideDuration: 3000,
                        icon: <Edit />,
                        showButton: true,
                    });
                    handleClose();
                }).catch(err => {
                    handleShowErrorSnackbar(err);
                })
            }
        }
    }

    const handleClose = () => {
        onClose()
        setEmail("")
        setName("")
        setPassword("")
        setConfirmPassword("")
        setNameError(false)
        setEmailError(false)
        setPasswordError(false)
        setConfirmPasswordError(false)
    }

    const errorValidation = () => {
        let hasError = false

        if (actionType !== ACTION_TYPE_ADD_CLIENT) {
            if (name === "") {
                setNameError(true);
                hasError = true;
            }
        }

        if (email === "") {
            setEmailError(true);
            hasError = true;
        }

        if (actionType === ACTION_TYPE_ADD || actionType === ACTION_TYPE_ADD_CLIENT) {
            if (password === "") {
                setPasswordError(true);
                hasError = true;
            }

            if (confirmPassword === "" || confirmPassword !== password) {
                setConfirmPasswordError(true);
                hasError = true;
            }
        }

        if (actionType === ACTION_TYPE_UPDATE) {
            if (confirmPassword !== password) {
                setConfirmPasswordError(true);
                hasError = true;
            }
        }

        return hasError;
    }

    const titleForm = {
        ADD: t("adminDashboard.addNewClient"),
        ADD_CLIENT: t("adminDashboard.createNewClientCredential"),
        UPDATE: t("adminDashboard.updateClientData"),
    }

    const buttonText = {
        ADD: t("adminDashboard.addNewUser"),
        ADD_CLIENT: t("adminDashboard.addNewUser"),
        UPDATE: t("manage.ok"),
    }

    return (
        <>
            <DialogBox
                open={open}
                handleClose={handleClose}
                textTitle={titleForm[actionType]}
                content={
                    <div style={{ width: 500, paddingLeft: 24, paddingRight: 24, paddingBottom: 40, boxSizing: 'border-box' }}>
                        <Grid container spacing={0} style={{ flexGrow: 1 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                {actionType !== ACTION_TYPE_ADD_CLIENT && (
                                    <div style={{ marginTop: 24 }}>
                                        <Form
                                            label={t("manage.name")}
                                            placeholder="Name"
                                            required
                                            value={name}
                                            onChange={handleChangeName}
                                            error={nameError}
                                            errorMessage={nameError && t(`manage.fieldRequired`)}
                                            tabIndex={1}
                                        />
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{ marginTop: 24 }}>
                                    <Form
                                        label={t("common.email")}
                                        placeholder="E-mail"
                                        required
                                        value={email}
                                        onChange={handleChangeEmail}
                                        error={emailError}
                                        errorMessage={emailError && t(`manage.fieldRequired`)}
                                        tabIndex={1}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{ marginTop: 24 }}>
                                    <Form
                                        label={t("common.password")}
                                        placeholder="Password"
                                        value={password}
                                        onChange={handleChangePassword}
                                        error={passwordError}
                                        errorMessage={passwordError && t(`manage.fieldRequired`)}
                                        required={actionType === ACTION_TYPE_ADD || actionType === ACTION_TYPE_ADD_CLIENT ? true : false}
                                        password
                                        tabIndex={2}
                                        autoComplete="new-password"
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{ marginTop: 24 }}>
                                    <Form
                                        label={t("common.confirmPassword")}
                                        placeholder={t("common.confirmPassword")}
                                        value={confirmPassword}
                                        onChange={handleChangeConfirmPassword}
                                        error={confirmPasswordError}
                                        errorMessage={confirmPasswordError && t(`manage.fieldRequired`)}
                                        required={actionType === ACTION_TYPE_ADD || actionType === ACTION_TYPE_ADD_CLIENT ? true : false}
                                        password
                                        tabIndex={2}
                                        disabled={actionType === ACTION_TYPE_UPDATE && password === "" ? true : false}
                                        autoComplete="new-password"
                                    />
                                </div>
                            </Grid>

                            <Grid item md={12}>
                                <div style={{ marginTop: 24 }} />
                            </Grid>


                            <Grid item md={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                    size={14}
                                    color={ColorSAP.neutral[100]}
                                    style={{ marginRight: 40 }}
                                >
                                    {t("common.userRole")}
                                </Typography>

                                {dropMenuRoleItems.length > 0 &&
                                    <Dropdown
                                        menu={dropMenuRoleItems}
                                        selected={role}
                                        type="SELECTION"
                                        placeholder={t("common.userRole")}
                                        style={{ color: "black", cursor: "pointer" }}
                                    />
                                }
                            </Grid>

                            <Grid item md={12}>
                                <div tabIndex={3} style={{ marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <TertiaryButton
                                        theme="light"
                                        size="small"
                                        onClick={handleClose}
                                    >
                                        {`Cancel`}
                                    </TertiaryButton>
                                    <Button
                                        type="primary"
                                        theme="dark"
                                        size="small"
                                        onClick={handleSubmit}
                                    >
                                        {buttonText[actionType]}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div >
                }
            />
        </>
    )
}

export default ClientDialog;