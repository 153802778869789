import axios from 'axios';
import { USER_API_BASE_DEVICES_URL, getApiUrl } from '../../Constants/Api';

export class UserHistoryDialogService {
    getUserHistory = (clientKey, deviceKey, interval = '') => {
        let query = `${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/${deviceKey}/history`;
        if (interval) query += `?interval=${interval}`

        return axios.get(query);
    }
}

export default new UserHistoryDialogService();