import React from 'react';
import { DialogBox } from '@commsult/ontego-ui';

const OntegoDesignerDialog = props => {
    const { open, onClose, src } = props

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            noClose
            noPadding
            title=""
            titleType=""
            content={
                <div style={{ width: 1280, height: 720 }}>
                    <iframe
                        width="1280"
                        height="720"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        src={`${src}?autoplay=1`}
                    >
                    </iframe>
                </div>
            }
        />
    )
};

export default OntegoDesignerDialog;