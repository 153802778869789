export const LOGIN_PATH = '/'
export const REGISTER_PATH = '/register'
export const SCENARIO_PATH = '/scenario'
export const FUNCTION_DETAILS_PATH = '/functionDetails'
export const SPECIAL_FUNCTIONS_PATH = '/specialFunctions'

export const CUSTOMIZE_MAIN_DASHBOARD_PATH = '/customizeMainDashboard'

export const MANAGE_MAIN_DASHBOARD_PATH = '/manageMainDashboard'
export const MANAGE_PERMISSION_ASSIGN_PATH = '/managePermissionAssign'
export const MANAGE_GLOBAL_PERMISSION_PATH = '/manageGlobalPermission'
export const MANAGE_GLOBAL_DEVICE_PATH = '/manageGlobalDevice'
export const MANAGE_GLOBAL_USERS_PATH = '/manageGlobalUsers'
export const MANAGE_GLOBAL_SIGNING_KEYS_PATH = '/manageGlobalSigningKeys'

export const UPDATE_MANAGEMENT = '/updateManagement'

export const ADMIN_DASHBOARD = '/adminDashboard'
export const BLACKLISTED_DEVICE = '/blacklistedDevice'