import React from 'react';

import { useStyles } from './IconButtonStyles'
import { Delete } from '@commsult/ontego-ui/dist/sap_icons';

const DeleteIconButton = props => {
    const classes = useStyles(props)

    const { onClick } = props

    return (
        <div
            className={classes.deleteIconButton}
            onClick={onClick}
        >
            <Delete />
        </div>
    )
}

export default DeleteIconButton;