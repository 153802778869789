import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** WARENEINGANG IMAGES */
import Wareneingang_Einfuehrung1 from '../../assets/img_en/wareneingang/Einfuehrung/1.png';
import Wareneingang_Einfuehrung2 from '../../assets/img_en/wareneingang/Einfuehrung/2.png';
import Wareneingang_Einfuehrung3 from '../../assets/img_en/wareneingang/Einfuehrung/3.png';

import Wareneingang_Bestellung1 from '../../assets/img_en/wareneingang/Bestellung/1.png';
import Wareneingang_Bestellung2 from '../../assets/img_en/wareneingang/Bestellung/2.png';
import Wareneingang_Bestellung3 from '../../assets/img_en/wareneingang/Bestellung/3.png';
import Wareneingang_Bestellung4 from '../../assets/img_en/wareneingang/Bestellung/4.png';

import Wareneingang_Anlieferung1 from '../../assets/img_en/wareneingang/Anlieferung/1.png';
import Wareneingang_Anlieferung2 from '../../assets/img_en/wareneingang/Anlieferung/2.png';
import Wareneingang_Anlieferung3 from '../../assets/img_en/wareneingang/Anlieferung/3.png';

import Wareneingang_Lieferplan1 from '../../assets/img_en/wareneingang/Lieferplan/1.png';
import Wareneingang_Lieferplan2 from '../../assets/img_en/wareneingang/Lieferplan/2.png';
import Wareneingang_Lieferplan3 from '../../assets/img_en/wareneingang/Lieferplan/3.png';

import Wareneingang_Auslieferung1 from '../../assets/img_en/wareneingang/Auslieferung/1.png';
import Wareneingang_Auslieferung2 from '../../assets/img_en/wareneingang/Auslieferung/2.png';
import Wareneingang_Auslieferung3 from '../../assets/img_en/wareneingang/Auslieferung/3.png';

import Wareneingang_OhneBezug1 from '../../assets/img_en/wareneingang/OhneBezug/1.png';
import Wareneingang_OhneBezug2 from '../../assets/img_en/wareneingang/OhneBezug/2.png';
import Wareneingang_OhneBezug3 from '../../assets/img_en/wareneingang/OhneBezug/3.png';

import Wareneingang_Retoure1 from '../../assets/img_en/wareneingang/Retoure/1.png';
import Wareneingang_Retoure2 from '../../assets/img_en/wareneingang/Retoure/2.png';
import Wareneingang_Retoure3 from '../../assets/img_en/wareneingang/Retoure/3.png';

import Wareneingang_Storno1 from '../../assets/img_en/wareneingang/Storno/1.png';
import Wareneingang_Storno2 from '../../assets/img_en/wareneingang/Storno/2.png';

import Wareneingang_AutomHU1 from '../../assets/img_en/wareneingang/AutomHU/1.png';

import Wareneingang_Suche1 from '../../assets/img_en/wareneingang/Suche/1.png';
import Wareneingang_Suche2 from '../../assets/img_en/wareneingang/Suche/2.png';
import Wareneingang_Suche3 from '../../assets/img_en/wareneingang/Suche/3.png';
import Wareneingang_Suche4 from '../../assets/img_en/wareneingang/Suche/4.png';

import Wareneingang_OCR1 from '../../assets/img_en/wareneingang/OCR/1.png';

import Wareneingang_Einlagerung_WM1 from '../../assets/img_en/wareneingang/Einlagerung_WM/1.png';
import Wareneingang_Einlagerung_WM2 from '../../assets/img_en/wareneingang/Einlagerung_WM/2.png';

import Wareneingang_Einlagerung_EWM1 from '../../assets/img_en/wareneingang/Einlagerung_EWM/1.png';
import Wareneingang_Einlagerung_EWM2 from '../../assets/img_en/wareneingang/Einlagerung_EWM/2.png';

export const WareneingangImagesEN = [
    new ProcessImageContainer(
        `P_WARENEINGANG_EINFUEHRUNG`,
        [
            new ProcessImage('Positions', Wareneingang_Einfuehrung1),
            new ProcessImage('Pallets Detail', Wareneingang_Einfuehrung2),
            new ProcessImage('Orders', Wareneingang_Einfuehrung3)
        ]
    ),
    new ProcessImageContainer(
        `P_WARENEINGANG_ZUR_BESTELLUNG`,
        [
            new ProcessImage('Order', Wareneingang_Bestellung1),
            new ProcessImage('Positions', Wareneingang_Bestellung2),
            new ProcessImage('Details', Wareneingang_Bestellung3),
            new ProcessImage('Serial Number Details', Wareneingang_Bestellung4)
        ]
    ),
    new ProcessImageContainer(
        `P_WARENEINGANG_ZUR_ANLIEFERUNG`,
        [
            new ProcessImage('Delivery', Wareneingang_Anlieferung1),
            new ProcessImage('Positions', Wareneingang_Anlieferung2),
            new ProcessImage('Details', Wareneingang_Anlieferung3)
        ]
    ),
    new ProcessImageContainer(
        `P_WARENEINGANG_ZUM_LIEFERPLAN`,
        [
            new ProcessImage('Delivery Schedule', Wareneingang_Lieferplan1),
            new ProcessImage('Positions', Wareneingang_Lieferplan2),
            new ProcessImage('Details', Wareneingang_Lieferplan3)
        ]
    ),
    new ProcessImageContainer(
        `P_WARENEINGANG_ZUR_AUSLIEFERUNG`,
        [
            new ProcessImage('Delivery', Wareneingang_Auslieferung1),
            new ProcessImage('Postiions', Wareneingang_Auslieferung2),
            new ProcessImage('Details', Wareneingang_Auslieferung3)
        ]
    ),
    new ProcessImageContainer(
        `P_WARENEINGANG_OHNE_BEZUG`,
        [
            new ProcessImage('Positions', Wareneingang_OhneBezug1),
            new ProcessImage('Material', Wareneingang_OhneBezug2),
            new ProcessImage('Details', Wareneingang_OhneBezug3)
        ]
    ),
    new ProcessImageContainer(
        `P_WARENEINGANG_AUS_KUNDENREKLAMATION_RETOURE`,
        [
            new ProcessImage('Return', Wareneingang_Retoure1),
            new ProcessImage('Positions', Wareneingang_Retoure2),
            new ProcessImage('Details', Wareneingang_Retoure3)
        ]
    ),
    new ProcessImageContainer(
        `P_STORNO_ZUM_WARENEINGANG`,
        [
            new ProcessImage('Material Document', Wareneingang_Storno1),
            new ProcessImage('Positions', Wareneingang_Storno2)
        ]
    ),
    new ProcessImageContainer(
        `P_AUTOMATISCHE_ERSTELLUNG_VON_HUS_ODER_MANUELLE_PALETTIERUNG`,
        [
            new ProcessImage('Pallets Detail', Wareneingang_AutomHU1)
        ]
    ),
    new ProcessImageContainer(
        `P_SUCHE_UEBER_BELEGE_LIEFERANT_ODER_MATERIAL`,
        [
            new ProcessImage('Search', Wareneingang_Suche1),
            new ProcessImage('Orders', Wareneingang_Suche2),
            new ProcessImage('Positions', Wareneingang_Suche3),
            new ProcessImage('Details', Wareneingang_Suche4)
        ]
    ),
    new ProcessImageContainer(
        `P_OCR_TEXTERKENNUNG_IM_WARENEINGANG`,
        [
            new ProcessImage('OCR', Wareneingang_OCR1)
        ]
    ),
    new ProcessImageContainer(
        `P_ANSCHLIESSENDE_EINLAGERUNG_WM`,
        [
            new ProcessImage('Storage Bin', Wareneingang_Einlagerung_WM1),
            new ProcessImage('Storage Bin (with Charge)', Wareneingang_Einlagerung_WM2)
        ]
    ),
    new ProcessImageContainer(
        `P_ANSCHLIESSENDE_EINLAGERUNG_EWM`,
        [
            new ProcessImage('Storage Bin', Wareneingang_Einlagerung_EWM1),
            new ProcessImage('Storage Bin (with Charge)', Wareneingang_Einlagerung_EWM2)
        ]
    )
]