import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';

import { useStyles } from './RegisterNewDeviceDialogStyles';

import { useTranslation } from 'react-i18next';

// Components
import {
    DialogBox,
    Button,
    Checkbox,
    Typography,
    Separator,
    FONT_FAMILY,
    Color,
    Form,
    Warehouse,
    ProductionEHP,
    USER_TYPE,
    Edit,
    Plus,
    Password,
    Delete,
    Banner
} from '@commsult/ontego-ui';

import Grid from '@material-ui/core/Grid';

const RegisterNewDeviceDialog = props => {
    const classes = useStyles(props)
    const { open, onClose, handleSubmit } = props

    const [deviceID, setDeviceID] = useState("")
    const [deviceAlias, setDeviceAlias] = useState("")
    const [isActivated, setIsActivated] = useState(false)
    const [pin, setPin] = useState("1234")

    const [deviceIDError, setDeviceIDError] = useState(false)
    const [deviceAliasError, setDeviceAliasError] = useState(false)

    const { t } = useTranslation()

    const handleClose = () => {
        onClose()
        setDeviceAlias('')
        setDeviceID('')
        setIsActivated(false)
    }

    const handleChangeDeviceID = e => {
        setDeviceID(e.target.value)
        setDeviceIDError(false)
    }

    const handleChangeDeviceAlias = e => {
        setDeviceAlias(e.target.value)
        setDeviceAliasError(false)
    }

    const handleActivate = () => {
        let hasError = false

        if (deviceID === "") {
            setDeviceIDError(true)
            hasError = true
        }

        if (deviceAlias === "") {
            setDeviceAliasError(true)
            hasError = true
        }

        if (!hasError) {
            handleSubmit({ deviceId: deviceID, alias: deviceAlias })
            setIsActivated(true)
        }
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            title={t("manage.registerNewDevice")}
            content={
                <>
                    <div
                        style={{
                            width: '320px',
                            transition: 'width 0.5s, height 0.5s',
                            transitionDelay: '0.5s'
                        }}
                    >
                        <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 50 }}>
                            {!isActivated ? (
                                <>
                                    <Grid item md={12}>
                                        <Grid container spacing={1}>
                                            <Grid item md={12}>
                                                <Form
                                                    placeholder={t("manage.deviceId")}
                                                    value={deviceID}
                                                    onChange={handleChangeDeviceID}
                                                    error={deviceIDError}
                                                    errorMessage={deviceIDError && t(`manage.fieldRequired`)}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <Typography fontFamily={FONT_FAMILY.ROBOTO_REGULAR} size={12} color={Color.neutral[500]}>
                                                    {t("manage.enterDeviceIdDesc")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Form
                                            placeholder={t("manage.deviceAlias")}
                                            value={deviceAlias}
                                            onChange={handleChangeDeviceAlias}
                                            error={deviceAliasError}
                                            errorMessage={deviceAliasError && t(`manage.fieldRequired`)}
                                            required
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item md={12}>
                                        <Grid container>
                                            <Grid item md={12}>
                                                <Typography fontFamily={FONT_FAMILY.ROBOTO_REGULAR} size={12} color={Color.neutral[500]}>
                                                    {t(`manage.deviceId`)}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Typography fontFamily={FONT_FAMILY.ROBOTO_BOLD} size={18} color={Color.neutral[500]}>
                                                    {deviceID}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Grid container>
                                            <Grid item md={12}>
                                                <Typography fontFamily={FONT_FAMILY.ROBOTO_REGULAR} size={12} color={Color.neutral[500]}>
                                                    {t(`manage.deviceAlias`)}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Typography fontFamily={FONT_FAMILY.ROBOTO_BOLD} size={18} color={Color.neutral[500]}>
                                                    {deviceAlias}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Grid container spacing={1}>
                                            <Grid item md={12} style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{ padding: "3px 15px", backgroundColor: Color.neutral[200], width: 130, textAlign: "center" }}>
                                                    <Typography fontFamily={FONT_FAMILY.ROBOTO_BOLD} size={36} color={Color.neutral[800]}>
                                                        {pin}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item md={12} style={{ textAlign: "center" }}>
                                                <Typography fontFamily={FONT_FAMILY.ROBOTO_REGULAR} size={12} color={Color.neutral[500]}>
                                                    {t(`manage.enterPinDesc`)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                        </Grid>
                        <Grid container style={{ marginTop: 30 }}>
                            <Grid item md={12}>
                                {!isActivated ? (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Button
                                            onClick={handleClose}
                                            type={"secondary"}
                                        >
                                            {t(`manage.cancel`)}
                                        </Button>
                                        <Button
                                            onClick={handleActivate}
                                        >
                                            {t(`manage.activateLicense`)}
                                        </Button>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <Button
                                            onClick={handleClose}
                                        >
                                            {t(`manage.ok`)}
                                        </Button>
                                    </div>
                                )}

                            </Grid>
                        </Grid>
                    </div>
                </>
            }
        />
    )
};

export default RegisterNewDeviceDialog;