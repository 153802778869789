// React Lib
import React, { useState, useEffect } from "react";

// Component Styles
import { useStyles } from "./SnackbarStyles";
import { Grid, Slide } from "@material-ui/core";
import Button from "../Button/Button";
import { Typography, Color, FONT_FAMILY } from "@commsult/ontego-ui";

// @otg-one/ui_components/icons
// import { OntegoOneLogoWhiteAlt, Settings, Alert } from '../../icons';

export const Snackbar = (props) => {
    const classes = useStyles(props)

    const { id, show, onClick, icon, children, showButton, textButtonLabel, animationDuration, backgroundColor, meterColor } = props

    return (
        <Slide direction="up" in={show} mountOnEnter unmountOnExit>
            <div className={classes.snackbarWrapper} style={{ backgroundColor: backgroundColor }} id={id}>
                <div className={classes.meter} style={{ animationDuration: `${animationDuration}ms`, WebkitAnimationDuration: `${animationDuration}ms`, background: meterColor }} />
                <div className={classes.snackbarContainer}>
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                        <Grid item md={2}>
                            <div style={{ display: 'flex', alignItems: 'center', float: "left" }}>
                                <div className={classes.snackbarIconContainer}>
                                    {icon}
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={8}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                    size={14}
                                    color={Color.neutral[0]}
                                >
                                    {children}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item md={2}>
                            <div style={{ float: "right" }}>
                                {showButton && (
                                    <Button
                                        type="primary"
                                        theme="light"
                                        size="small"
                                        onClick={onClick}
                                    >
                                        {textButtonLabel}
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Slide>
    )
}

Snackbar.defaultProps = {
    showButton: false,
    textButtonLabel: "Close",
    animationDuration: 1000,
    backgroundColor: Color.primary[400],
}

export default React.memo(Snackbar)