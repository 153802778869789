// React Lib
import React from "react";

// Component Styles
import { useStyles } from "./TotalCostStyles";

// Lib
import moment from 'moment'
import { dialogClasses } from "@mui/material";
import { Color, ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { formatNumericalWithThousandSeparator } from "../../Utils/Utils";
import Button from "../Button/Button";

export const TotalCost = (props) => {
    const classes = useStyles(props)

    const { id, totalCost, actionHandler, textTotalCostThisMonth, textGoToBilling, style, textBilledOn } = props

    return (
        <div
            id={`${id}-total-cost-container`}
            className={classes.totalCostContainer}
            style={style}
        >
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                size={24}
                color={ColorSAP.neutral[0]}
            >
                {textTotalCostThisMonth}
            </Typography>
            <div
                id={`${id}-total-cost`}
                className={classes.totalCost}
                style={{ marginTop: 73 }}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={56}
                    color={Color.neutral[0]}
                >
                    {`${formatNumericalWithThousandSeparator(totalCost?.price ?? 0)} €`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR_ITALIC}
                    size={12}
                    color={Color.neutral[0]}
                    style={{ marginTop: 12 }}
                >
                    {totalCost?.textBilledDate ? textBilledOn + ` ${moment(totalCost?.textBilledDate).format("DD MMMM YYYY")}` : `No billing data yet!`}
                </Typography>
            </div>
            {
                actionHandler &&
                <Button
                    id={`${id}-total-price-box-button`}
                    type="secondary"
                    color="white"
                    alignIcon="right"
                    style={{ marginTop: 20 }}
                    onClick={actionHandler}
                >
                    {textGoToBilling}
                </Button>
            }
        </div >
    );
}

TotalCost.defaultProps = {
    totalCost: {
        price: 1470,
        textBilledDate: "31 January 2019"
    },
    textTotalCostThisMonth: 'Total Cost This Month',
    textGoToBilling: 'Go to Billing',
    textBilledOn: 'Billed on'
}

export default React.memo(TotalCost)