import React, { useContext } from 'react';
import { AppContext } from '../../App';

import { useTranslation } from 'react-i18next';

import AppService from '../../AppService';

import {
    Typography,
    FONT_FAMILY,
    Color,
    ColorSAP,
    Info
} from '@commsult/ontego-ui';

import Grid from '@material-ui/core/Grid';
import { convertNotesToEditorState, convertNotesToJSON, convertUpAndRunningNotesToEditorState, convertUpAndRunningNotesToJSON } from '../../Utils/Utils';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Button from '../../ReusableComponents/Button/Button';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';

const UpAndRunningPhaseDeleteDialog = props => {
    const { open, onClose, collection, setCollection, upAndRunningCollection, setUpAndRunningCollection, inspectedData } = props
    const { userInfo, handleShowSnackbar, handleShowErrorSnackbar } = useContext(AppContext)

    const { t } = useTranslation();

    const handleDeletePhase = () => {
        const newCollection = [...collection]
        const newUpAndRunningCollection = { ...upAndRunningCollection }

        delete newUpAndRunningCollection[inspectedData.id]

        newCollection.forEach(collection => {
            if (collection.upAndRunningId === inspectedData.id) {
                collection.upAndRunningId = ''
            }
        })

        AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
            setCollection(convertNotesToEditorState(newCollection))

            AppService.updateUpAndRunningCollection(userInfo.key, convertUpAndRunningNotesToJSON(newUpAndRunningCollection)).then(res => {
                setUpAndRunningCollection(convertUpAndRunningNotesToEditorState(newUpAndRunningCollection))
                handleShowSnackbar({
                    message: `${inspectedData.name} gelöscht`,
                    autoHideDuration: 5000,
                    showButton: true
                });
                onClose()
            }).catch(error => handleShowErrorSnackbar(error));

        }).catch(error => handleShowErrorSnackbar(error));
    }

    const handleClose = () => onClose()

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            dynamicTitle
            textTitle={`Phase "${inspectedData.name}" ${t('common.delete')}`}
            content={
                <div
                    style={{
                        width: 556,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 40,
                        boxSizing: 'border-box'
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item md={12} style={{ marginTop: 24 }}>
                            <div style={{ padding: "16px 24px 16px 24px", boxSizing: 'border-box', background: ColorSAP.secondary[30], borderRadius: 8, display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}>
                                <Info
                                    style={{
                                        width: 16,
                                        height: 16,
                                        color: ColorSAP.secondary[60],
                                        marginRight: 8
                                    }}
                                />
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                    size={14}
                                    color={ColorSAP.neutral[100]}
                                >
                                    {t('common.cantUndone')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                size={14}
                                color={ColorSAP.neutral[100]}
                                style={{ marginTop: 8 }}
                            >
                                {t('common.deletePhaseInfo')}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 24 }}>
                                <TertiaryButton
                                    theme="light"
                                    size="small"
                                    onClick={handleClose}
                                    noIcon={true}
                                >
                                    {t(`manage.cancel`)}
                                </TertiaryButton>
                                <Button
                                    theme="danger"
                                    type="primary"
                                    size="small"
                                    onClick={handleDeletePhase}
                                >
                                    {t(`manage.ok`)}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        />
    )
}

export default UpAndRunningPhaseDeleteDialog