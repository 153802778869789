import axios from 'axios';
import { USER_API_BASE_CLIENTS_URL, getApiUrl } from '../../Constants/Api';

export class ManageMainDashboardService {
    getClientsData(clientKey, logParam) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}?logPage=${logParam.currentPage}&logLimit=${logParam.logLimit}&startDate=${logParam.startDate}&endDate=${logParam.endDate}&logType=${logParam.logType}&search=${logParam.search}`)
    }

    getClientsChartData(clientKey, period) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/chart?period=${period}`)
    }

    updateClientDeviceLimit(clientKey, deviceLimit) {
        return axios.put(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientKey}/devicelimit`, { deviceLimit })
    }
}

export default new ManageMainDashboardService();