import { FONT_FAMILY } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        chartWrapper: {
            position: "relative"
        },
        noChartWrapper: {
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            borderRadius: 18
        },
        noChartIcon: {
            fontSize: "90px !important",
            width: "90px !important",
            height: "90px !important",
            color: "white !important",
            "& svg": {
                color: "white !important",
            }
        },
        noChartContent: {
            color: "white",
            textAlign: "center",
            marginTop: "20px",
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD,
            fontSize: "16px",
            fontWeight: 500
        },
        noChartContentSmall: {
            color: "white",
            textAlign: "center",
            marginTop: "10px",
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD,
            fontSize: "12px",
        }
    })
);