import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** BESTANDSINFO IMAGES */
import Bestandsinfo_Einfuehrung1 from '../../assets/img_en/bestandsinfo/Einfuehrung/1.png';

import Bestandsinfo_Lagerort_MM1 from '../../assets/img_en/bestandsinfo/Lagerort_MM/1.png';
import Bestandsinfo_Lagerort_MM2 from '../../assets/img_en/bestandsinfo/Lagerort_MM/2.png';
import Bestandsinfo_Lagerort_MM3 from '../../assets/img_en/bestandsinfo/Lagerort_MM/3.png';

import Bestandsinfo_Lagerort_WM_EWM1 from '../../assets/img_en/bestandsinfo/Lagerort_WM_EWM/1.png';
import Bestandsinfo_Lagerort_WM_EWM2 from '../../assets/img_en/bestandsinfo/Lagerort_WM_EWM/2.png';
import Bestandsinfo_Lagerort_WM_EWM3 from '../../assets/img_en/bestandsinfo/Lagerort_WM_EWM/3.png';
import Bestandsinfo_Lagerort_WM_EWM4 from '../../assets/img_en/bestandsinfo/Lagerort_WM_EWM/4.png';

import Bestandsinfo_Lagerplatz_WM_EWM1 from '../../assets/img_en/bestandsinfo/Lagerplatz_WM_EWM/1.png';
import Bestandsinfo_Lagerplatz_WM_EWM2 from '../../assets/img_en/bestandsinfo/Lagerplatz_WM_EWM/2.png';
import Bestandsinfo_Lagerplatz_WM_EWM3 from '../../assets/img_en/bestandsinfo/Lagerplatz_WM_EWM/3.png';

import Bestandsinfo_Material_MM1 from '../../assets/img_en/bestandsinfo/Material_MM/1.png';
import Bestandsinfo_Material_MM2 from '../../assets/img_en/bestandsinfo/Material_MM/2.png';
import Bestandsinfo_Material_MM3 from '../../assets/img_en/bestandsinfo/Material_MM/3.png';
import Bestandsinfo_Material_MM4 from '../../assets/img_en/bestandsinfo/Material_MM/4.png';

import Bestandsinfo_Material_WM_EWM1 from '../../assets/img_en/bestandsinfo/Material_WM_EWM/1.png';
import Bestandsinfo_Material_WM_EWM2 from '../../assets/img_en/bestandsinfo/Material_WM_EWM/2.png';
import Bestandsinfo_Material_WM_EWM3 from '../../assets/img_en/bestandsinfo/Material_WM_EWM/3.png';
import Bestandsinfo_Material_WM_EWM4 from '../../assets/img_en/bestandsinfo/Material_WM_EWM/4.png';
import Bestandsinfo_Material_WM_EWM5 from '../../assets/img_en/bestandsinfo/Material_WM_EWM/5.png';

import Bestandsinfo_Kanban_MM1 from '../../assets/img_en/bestandsinfo/Kanban_MM/1.png';
import Bestandsinfo_Kanban_MM2 from '../../assets/img_en/bestandsinfo/Kanban_MM/2.png';
import Bestandsinfo_Kanban_MM3 from '../../assets/img_en/bestandsinfo/Kanban_MM/3.png';

import Bestandsinfo_Kanban_WM_EWM1 from '../../assets/img_en/bestandsinfo/Kanban_WM_EWM/1.png';
import Bestandsinfo_Kanban_WM_EWM2 from '../../assets/img_en/bestandsinfo/Kanban_WM_EWM/2.png';
import Bestandsinfo_Kanban_WM_EWM3 from '../../assets/img_en/bestandsinfo/Kanban_WM_EWM/3.png';
import Bestandsinfo_Kanban_WM_EWM4 from '../../assets/img_en/bestandsinfo/Kanban_WM_EWM/4.png';

export const BestandsinfoImagesEN = [
    new ProcessImageContainer(
        `P_BESTANDSINFO_EINFUEHRUNG`,
        [
            new ProcessImage('Stocks', Bestandsinfo_Einfuehrung1)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSINFO_FUER_LAGERORT_MM`,
        [
            new ProcessImage('Warehouse', Bestandsinfo_Lagerort_MM1),
            new ProcessImage('Stocks', Bestandsinfo_Lagerort_MM2),
            new ProcessImage('Ad Hoc Inv', Bestandsinfo_Lagerort_MM3)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSINFO_FUER_LAGERORT_WM_EWM`,
        [
            new ProcessImage('Warehouse', Bestandsinfo_Lagerort_WM_EWM1),
            new ProcessImage('Stocks (Storage Bin Grouped)', Bestandsinfo_Lagerort_WM_EWM2),
            new ProcessImage('Storage Location (Individual Storage Bin)', Bestandsinfo_Lagerort_WM_EWM3),
            new ProcessImage('Ad Hoc Inv', Bestandsinfo_Lagerort_WM_EWM4)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSINFO_FUER_LAGERPLATZ_WM_EWM`,
        [
            new ProcessImage('Warehouse', Bestandsinfo_Lagerplatz_WM_EWM1),
            new ProcessImage('Stocks (at The Warehouse)', Bestandsinfo_Lagerplatz_WM_EWM2),
            new ProcessImage('Ad Hoc Inv', Bestandsinfo_Lagerplatz_WM_EWM3)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSINFO_FUER_MATERIAL_MM`,
        [
            new ProcessImage('Material', Bestandsinfo_Material_MM1),
            new ProcessImage('Stocks', Bestandsinfo_Material_MM2),
            new ProcessImage('Inventory Information Detail MM Options', Bestandsinfo_Material_MM3),
            new ProcessImage('Ad hoc Inv', Bestandsinfo_Material_MM4)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSINFO_FUER_MATERIAL_WM_EWM`,
        [
            new ProcessImage('Material', Bestandsinfo_Material_WM_EWM1),
            new ProcessImage('Stocks (Storage Locations)', Bestandsinfo_Material_WM_EWM2),
            new ProcessImage('Stocks (Storage Spaces)', Bestandsinfo_Material_WM_EWM3),
            new ProcessImage('Investment Information Detail WM Options', Bestandsinfo_Material_WM_EWM4),
            new ProcessImage('Ad Hoc Inv', Bestandsinfo_Material_WM_EWM5)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSINFO_FUER_KANBAN_MM`,
        [
            new ProcessImage('Kanban', Bestandsinfo_Kanban_MM1),
            new ProcessImage('Stocks', Bestandsinfo_Kanban_MM2),
            new ProcessImage('Ad Hoc Inv', Bestandsinfo_Kanban_MM3)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSINFO_FUER_KANBAN_WM_EWM`,
        [
            new ProcessImage('Kanban', Bestandsinfo_Kanban_WM_EWM1),
            new ProcessImage('Stocks MM', Bestandsinfo_Kanban_WM_EWM2),
            new ProcessImage('Stocks WM', Bestandsinfo_Kanban_WM_EWM3),
            new ProcessImage('Ad Hoc Inv', Bestandsinfo_Kanban_WM_EWM4)
        ]
    )
]