import { Color, ColorSAP } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        deviceLimitContainer: {
            position: "relative",
            width: "100%",
            height: 475,
            backgroundColor: ColorSAP.neutral[15],
            boxSizing: "border-box",
            padding: "32px 40px 32px 40px",
            borderRadius: 16
        },
        deviceLimitTypographyNumber: {
            letterSpacing: -1.7,
        },
        deviceLimitTypographyCapacity: {
            marginLeft: 6,
            marginTop: 12,
            width: 50,
        },
        deviceLimitShowMoreHorizontal: {
            position: "absolute",
            top: 20,
            right: 20,
            color: ColorSAP.primary[70],
            cursor: "pointer"
        },
    })
);