import axios from 'axios';
import { USER_API_BASE_APPS_URL, USER_API_BASE_ROLES_URL, getApiUrl } from '../../Constants/Api';

export class ManagePermissionAssignService {
    getRoles = (clientKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_ROLES_URL}/${clientKey}`);
    }
    getProperties = (clientKey, appKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/properties`);
    }
    getRoles2Properties = (clientKey, appKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/propertyvalues`);
    }
    updateRole2Property = (clientKey, appKey, role2property) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/propertyvalues/${role2property.key}`, { role2property });
    }
}

export default new ManagePermissionAssignService();