// React Lib
import React from "react";

// Component Styles
import { useStyles } from "./ChipStyles";
import { Color, FONT_FAMILY, Typography } from "@commsult/ontego-ui";

export const Chip = (props) => {
    const classes = useStyles(props)

    const { id, icon, backgroundColor, color, children, fontFamily, onClick, style, smallPadding } = props

    return (
        <div
            id={id}
            className={classes.chipContainer}
            style={{
                backgroundColor: backgroundColor,
                color: color,
                padding: smallPadding ? "2px 12px 2px 12px" : "4px 16px 4px 16px",
                ...style
            }}
            onClick={onClick}
        >
            {icon}
            <Typography
                id={`${id}-chip-text`}
                fontFamily={fontFamily}
                size={12}
                color={color}
            >
                {(children).toUpperCase()}
            </Typography>
        </div>
    )
}

Chip.defaultProps = {
    children: "",
    backgroundColor: "red",
    color: Color.neutral[0],
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    icon: null,
    onClick: () => { },
    className: false
}

export default React.memo(Chip)