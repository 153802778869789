import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const StyledDialog = withStyles({
    root: {
        padding: 0,
        margin: 0,
    },
    container: {
        padding: 0,
        margin: 0,
    },
    paper: {
        padding: 0,
        margin: 0,
    },
    paperScrollPaper: {
        padding: 0,
        margin: 0,
    }
})(Dialog);

export const StyledDialogTitle = withStyles({
    root: {
        textAlign: '-webkit-center',
        padding: 0,
        margin: 0,
    }
})(DialogTitle);

export const StyledDialogContent = withStyles({
    root: {
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    }
})(DialogContent);