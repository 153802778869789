// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        scenarioListContainer: {
            boxSizing: 'border-box',
            padding: 25,
            paddingBottom: 80,
            marginTop: 200,
            background: Color.neutral[100]
        },
        scenarioListDiamondBackground: {
            width: 195,
            height: 195,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: Color.gradient[100],
            transform: 'rotate(45deg)'
        },
        scenarioListIconContainer: {
            "& svg": {
                width: 120,
                height: 120,
                color: Color.primary[100],
                transform: 'rotate(-45deg)'
            }
        },

        designerOverviewContainer: {
            height: 600,
            boxSizing: 'border-box',
            padding: 32,
            background: Color.primary[400],
            position: 'relative'
        },
        designerOverviewIconContainer: {
            "& svg": {
                width: 40,
                height: 40,
                color: Color.primary[100]
            }
        },
        smallerScenarioListDiamondBackground: {
            width: 45,
            height: 45,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: ColorSAP.primary[70],
            transform: 'rotate(45deg)'
        },
    })
);