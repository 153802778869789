import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import HistoryService from './UserHistoryDialogService'
import { AppContext } from '../../App';

import { useTranslation } from 'react-i18next';

import {
    Typography,
    FONT_FAMILY,
    Separator,
    Color,
    Device,
    Mobile,
    ColorSAP
} from '@commsult/ontego-ui';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Dropdown from '../../ReusableComponents/Dropdown/Dropdown';

const TableColumn = props => {

    const { t } = useTranslation();

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: 40 }}>
                <Grid container spacing={2}>
                    <Grid item md={3} style={{ paddingLeft: 20 }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.user`)}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.app`)}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.version`)}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.loginOn`)}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <Separator type="n400" width={2} marginTop="10px" />
        </Grid>
    )
}

const TableRow = props => {
    const { log } = props;

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: 24 }}>
                <Grid container spacing={2} style={{ alignItems: 'center' }}>
                    <Grid item md={3} style={{ paddingLeft: 20 }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                            size={16}
                            color={ColorSAP.neutral[100]}
                        >
                            {log.username}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {log.appname}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {`${log.versionname}`}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {log.lastlogin}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <Separator type="n200" width={2} marginTop="20px" />
        </Grid>
    )
}

const UserHistoryDialog = props => {
    const {
        open,
        handleClose,
        device
    } = props

    const { userInfo, handleShowErrorSnackbar } = useContext(AppContext)
    const [logs, setLogs] = useState([])

    const { t } = useTranslation();

    const fetchUserHistory = (interval = '') => {
        HistoryService.getUserHistory(userInfo.key, device.key, interval)
            .then(res => setLogs(res.data))
            .catch(err => handleShowErrorSnackbar(err))
    }

    useEffect(() => {
        if (userInfo != null && device != null)
            fetchUserHistory()
    }, [device])

    useEffect(() => {
        if (!open) {
            setLogs([])
        }
    }, [open])

    const DROPDOWN_MENU = [
        {
            text: 'manage.24hours',
            onClick: () => { fetchUserHistory('24 Hours') }
        },
        {
            text: 'manage.7days',
            onClick: () => { fetchUserHistory('7 Days') }
        },
        {
            text: 'manage.1month',
            onClick: () => { fetchUserHistory('1 Month') }
        }
    ]

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={t(`manage.userHistory`)}
            content={
                <div style={{
                    width: '90vw',
                    height: '60vh',
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingBottom: 40,
                    boxSizing: 'border-box'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Mobile
                                style={{ height: 40, width: 40, color: ColorSAP.primary[70] }}
                            />
                            <div>
                                <div>
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                        size={20}
                                        color={ColorSAP.neutral[100]}
                                    >
                                        {device ? device.alias : ''}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                        size={14}
                                        color={ColorSAP.neutral[100]}
                                    >
                                        {device ? device.deviceId : ''}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Dropdown
                            menu={DROPDOWN_MENU}
                            selected={t(DROPDOWN_MENU[0].text)}
                            type="SELECTION"
                        />
                    </div>
                    <TableColumn />
                    {logs.length > 0 ? (
                        <>
                            {logs.map((log, index) => (
                                <TableRow key={`log${index}`} log={log} />
                            ))}
                        </>
                    ) : (
                        <Grid container spacing={2} style={{ paddingTop: 50 }}>
                            <Grid item xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
                                <Typography fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD} color={ColorSAP.neutral[100]} size={30}>{t(`manage.noUserHistory`)}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </div>
            }
        />
    )
}

export default UserHistoryDialog;