import axios from 'axios';
import { USER_API_BASE_ONTEGO_URL, getApiUrl } from '../../Constants/Api';

export class ManageGlobalSigningKeysService {
    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        return { headers: { "Authorization": this.getUserInfo() } };
    }

    getKeysMetaInformation(clientKey) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_ONTEGO_URL}/${clientKey}/keys`, this.getAuthHeader())
    }

    getKeyStore(clientKey, keystoreKey) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_ONTEGO_URL}/${clientKey}/keys`, this.getAuthHeader())
    }

    addKey(clientKey, body) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_ONTEGO_URL}/${clientKey}/keys`, body, this.getAuthHeader())
    }

    updateAndroidKeystore(clientkey, keystoreKey, body) {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_ONTEGO_URL}/${clientkey}/keys/${keystoreKey}/android`, body, this.getAuthHeader())
    }

    deleteKey(clientKey, keystoreKey) {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_ONTEGO_URL}/${clientKey}/keys/${keystoreKey}`, this.getAuthHeader())
    }
}

export default new ManageGlobalSigningKeysService()