import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';

import { Typography, FONT_FAMILY, Color, Separator, ColorSAP } from '@commsult/ontego-ui';
import { AppContext } from '../../App'
import AppPhasesService from './AppPhasesDialogService'

import { useTranslation } from 'react-i18next';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';

const TableColumn = props => {

    const { t } = useTranslation();

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: 24 }}>
                <Grid container spacing={2}>
                    <Grid container item md={12} >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                            style={{ marginLeft: 50 }}
                        >
                            {t(`manage.appsInstalled`)}
                        </Typography>
                    </Grid>
                    {/* <Grid item md={8} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.phases`)}
                        </Typography>
                    </Grid> */}
                </Grid>
            </div>
            <Separator type="n400" width={2} marginTop="10px" />
        </Grid >
    )
}

const TableRow = props => {
    const { app, index } = props

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div style={{ height: 65, backgroundColor: index % 2 ? ColorSAP.neutral[15] : 'transparent', display: 'flex' }}>
                <Grid container spacing={2} >
                    <Grid item md={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={16}
                            color={ColorSAP.neutral[100]}
                            style={{ marginLeft: 50 }}

                        >
                            {app.name}
                        </Typography>
                    </Grid>
                    <Grid item md={8} style={{ display: 'flex' }}>
                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}  >
                            {app.steps.map(step => (
                                <Grid item md={3} key={`${step.stepName}-${step.versionName}`}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                        size={12}
                                        color={ColorSAP.neutral[100]}
                                    >
                                        {step.stepName}
                                    </Typography>
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                        size={20}
                                        color={ColorSAP.neutral[100]}
                                    >
                                        {step.versionName}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid >
    )
}

const AppPhasesDialog = props => {
    const { open, handleClose, device } = props;

    const { userInfo, handleShowErrorSnackbar } = useContext(AppContext)
    const [apps, setApps] = useState([]);

    useEffect(() => {
        if (userInfo != null && device != null && open)
            fetchAppsData()
    }, [device, open])

    useEffect(() => {
        if (!open) {
            setApps([])
        }
    }, [open])

    const { t } = useTranslation();

    const fetchAppsData = () => {
        AppPhasesService.getAppPhases(userInfo.key, device.key)
            .then(res => setApps(res.data))
            .catch(err => handleShowErrorSnackbar(err))
    }
    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={t("manage.appsPhases") + ` - ${(device || {}).alias || (device || {}).deviceId || t("manage.noAlias")}`}
            content={
                <div
                    style={{
                        width: 950,
                        height: '60vh',
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 40,
                        boxSizing: 'border-box'
                    }}
                >
                    <Grid container spacing={0}>
                        <TableColumn />
                        {apps && apps.length > 0 ? (apps.map((app, index) => <TableRow key={index} app={app} index={index} />)) : (
                            <Grid container spacing={0} style={{ paddingTop: 50 }}>
                                <Grid item xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                        color={ColorSAP.neutral[100]}
                                        size={30}>
                                        {t(`manage.noApp`)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>
            }
        />
    )
}

export default AppPhasesDialog