// React libraries
import React from 'react';
import classnames from 'classnames';

// Component Style
import { useStyles } from './CheckboxStyles';
import { Check } from '@commsult/ontego-ui';

export const Checkbox = (props) => {
    const classes = useStyles(props);
    const { id, name, value, label, theme, style, onChange, disabled, checked, error } = props;

    const labelClasses = classnames({
        [classes.label]: true,
        [classes.disabled]: disabled,
        [classes.error]: error,
    });

    const checkMarkClasses = classnames({
        [classes.checkmark]: true,
        [classes.disabled]: disabled,
    });

    return (
        <div
            style={{
                ...style
            }}
            id={id}
        >
            <input
                id={`${name}-${value}`}
                type="checkbox"
                className={classes.input}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                checked={checked}
            />
            <label
                className={labelClasses}
                htmlFor={`${name}-${value}`}
            >
                <Check
                    style={{
                        height: 16,
                        width: 16,
                        display: checked ? 'block' : 'none'
                    }}
                    className={checkMarkClasses}
                />
                {label}
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    disabled: false,
    error: false,
};

export default React.memo(Checkbox);