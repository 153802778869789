// React libraries
import React from 'react';

// Component Style
import { StyledDialog, StyledDialogContent, StyledDialogTitle, useStyles } from "./DialogBoxStyles";

// Material UI Core
import { Color, ColorSAP, FONT_FAMILY, Typography } from '@commsult/ontego-ui';
import { Close } from '@commsult/ontego-ui/dist/sap_icons';

export const DialogBox = props => {
    const { id, open, noClose, noBorderRadius, handleClose,
        textTitle, titleType, content, maxWidth, fullWidth, dynamicTitle } = props

    const dialogTitleTextProps = {
        fontFamily: titleType === 'header' ? FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD : FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
        size: titleType === 'header' ? 24 : 12,
        color: titleType === 'header' ? ColorSAP.neutral[100] : Color.neutral[500]
    }

    return (
        <StyledDialog
            id={id}
            open={open || false}
            onClose={handleClose}
            PaperProps={{
                style: {
                    borderRadius: noBorderRadius ? 0 : 20,
                    boxShadow: `0px 16px 24px 0px rgba(0, 0, 0, 0.14)`,
                    overflowX: 'hidden'
                }
            }}
            maxWidth={maxWidth ? maxWidth : false}
            fullWidth={fullWidth}
        >
            <StyledDialogTitle>
                {!noClose && (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            paddingTop: 24,
                            paddingRight: 24,
                            boxSizing: 'border-box',
                        }}
                    >
                        <div onClick={handleClose} style={{ cursor: 'pointer', height: 24 }}>
                            <Close style={{ color: ColorSAP.neutral[100], width: 24, height: 24 }} />
                        </div>
                    </div>
                )}
                {textTitle && (
                    <Typography
                        style={{
                            textAlign: 'center',
                            wordBreak: 'break-word',
                            width: dynamicTitle ? 508 : '100%'
                        }}
                        fontFamily={dialogTitleTextProps.fontFamily}
                        size={dialogTitleTextProps.size}
                        color={dialogTitleTextProps.color}
                    >
                        {textTitle}
                    </Typography>
                )}
            </StyledDialogTitle>
            <StyledDialogContent>
                <div style={{ width: '100%' }}>
                    {content}
                </div>
            </StyledDialogContent>
        </StyledDialog>
    );
};

export const DefaultContent = () => (
    <>
        <Typography>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
        </Typography>
        <Typography>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor.
        </Typography>
        <Typography>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
            auctor fringilla.
        </Typography>
    </>
);

DialogBox.defaultProps = {
    textTitle: "Title",
    titleType: "header",
    content: <DefaultContent />,
    noClose: false,
    noPadding: false,
    maxWidth: false,
    fullWidth: false
};

export default React.memo(DialogBox);