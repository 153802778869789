import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** KANBAN IMAGES */
import Kanban_Einfuehrung1 from '../../assets/img/kanban/Einfuehrung/1.png';

import Kanban_Statuswechsel1 from '../../assets/img/kanban/Statuswechsel/1.png';
import Kanban_Statuswechsel2 from '../../assets/img/kanban/Statuswechsel/2.png';
import Kanban_Statuswechsel3 from '../../assets/img/kanban/Statuswechsel/3.png';
import Kanban_Statuswechsel4 from '../../assets/img/kanban/Statuswechsel/4.png';

import Kanban_Info1 from '../../assets/img/kanban/Info/1.png';
import Kanban_Info2 from '../../assets/img/kanban/Info/2.png';
import Kanban_Info3 from '../../assets/img/kanban/Info/3.png';

export const KanbanImages = [
    new ProcessImageContainer(
        `P_KANBAN_EINFUEHRUNG`,
        [
            new ProcessImage('Menu', Kanban_Einfuehrung1)
        ]
    ),
    new ProcessImageContainer(
        `P_KANBAN_STATUSWECHSEL`,
        [
            new ProcessImage('Menu', Kanban_Statuswechsel1),
            new ProcessImage('Behälter', Kanban_Statuswechsel2),
            new ProcessImage('Status Wechsel', Kanban_Statuswechsel3),
            new ProcessImage('Status Aktivieren', Kanban_Statuswechsel4)
        ]
    ),
    new ProcessImageContainer(
        `P_KANBAN_INFO`,
        [
            new ProcessImage('Lagerort', Kanban_Info1),
            new ProcessImage('Status anzeigen', Kanban_Info2),
            new ProcessImage('Status Wechsel', Kanban_Info3)
        ]
    )
]