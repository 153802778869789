// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        tableContentWrapper: {
            display: "inline-block",
            minWidth: "100%"
        },
        contentWrapper: {
            display: 'flex',
            alignItems: "center",
            paddingLeft: 330 // contentAbsoluteAction width + contentAbsoluteProperty width
        },
        contentAbsolute: {
            position: "absolute",
            display: 'flex',
            alignItems: "center",
            background: "white",
            marginLeft: -330 // -(contentAbsoluteAction width + contentAbsoluteProperty width)
        },
        contentAbsoluteAction: {
            minWidth: 80
        },
        contentAbsoluteProperty: {
            minWidth: 250
        },
        contentRelative: {
            textAlign: 'center',
            minWidth: 250
        },
    })
);