import { convertToRaw, EditorState } from 'draft-js'
class Cart {
    constructor(systemID, name, icon, typeOf, processes = []) {
        this.systemID = systemID
        this.name = name
        this.icon = icon
        this.typeOf = typeOf
        this.processes = processes
        this.notes = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()))
        this.isEditMode = false
        this.upAndRunningId = ""
    }
}

export default Cart