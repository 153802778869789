import { Color, ColorSAP, FONT_FAMILY } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";


export const useStyles = makeStyles((theme) =>
    createStyles({
        popoverRoot: {
            zIndex: 12000 + ` !important`,
            borderRadius: 4,
        },
        menuRoot: {
            overflow: "visible",
            borderRadius: 4,
            minWidth: 240,
            boxShadow: "0px 2px 29px 0px rgba(0, 0, 0, 0.12)"
        },
        menuList: {
            padding: 0,
            borderRadius: 4,
        },

        menuItemDestructive: {
            height: 36,
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
            fontSize: 12,
            color: ColorSAP.neutral[100],
            cursor: "pointer",
            display: "flex",
            overflow: "visible",
            "&:hover": {
                backgroundColor: ColorSAP.danger[50],
                color: ColorSAP.neutral[0],
                borderRadius: 8,
                "& $listItemIconRoot": {
                    color: ColorSAP.neutral[0],
                }
            },
        },
        menuItem: {
            height: 36,
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
            fontSize: 12,
            color: ColorSAP.neutral[100],
            cursor: "pointer",
            display: "flex",
            overflow: "visible",
            background: ColorSAP.neutral[0],
            "& a": {
                color: ColorSAP.neutral[100]
            },
            "&:hover": {
                backgroundColor: ColorSAP.neutral[20]
            },
            "&.Mui-disabled": {
                backgroundColor: Color.neutral[300],
                color: Color.neutral[400],
                cursor: "default",
                pointerEvents: "none",
                "& a": {
                    color: Color.neutral[400]
                },
                "&:hover": {
                    backgroundColor: Color.neutral[300]
                }
            },
            "&.MuiListItem-divider": {
                borderBottom: `1px solid ${Color.neutral[400]}`
            },
            '& [class*="MuiListItem-focusVisible"]': {
                background: 'transparent'
            }
        },
        menuItemDivider: {
            borderBottom: "2px solid red"
        },

        text: {
            alignItems: "center",
            display: "flex",
            background: 'transparent'
        },
        arrowHeadIcon: {
            position: "absolute",
            right: 0,
            paddingLeft: 10,
            color: Color.neutral[500]
        },
        subMenu: {
            minWidth: 200,
            borderRadius: 0,
            opacity: 0,
            position: "absolute",
            left: "100%",
            transform: "scale(1.75, 0.5625)",
            transformOrigin: "top right",
            transition: `opacity 0.3ms 0ms, transform 0.3ms 0ms`,
            top: 0,
            visibility: "hidden",
            boxShadow: "0px 2px 29px 0px rgba(3, 3, 3, 0.15)"
        },
        subMenuListRoot: {
            padding: 0,
            background: 'transparent'
        },
        subMenuOpen: {
            transform: "scale(1, 1) translateZ(0px)",
            visibility: "visible",
            opacity: 1
        },

        listItemIconRoot: {
            minWidth: 0,
            marginTop: 4,
            marginRight: 8,
            color: Color.neutral[400]
        }
    })
)