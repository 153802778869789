import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() =>
    createStyles({
        tabItem: {
            width: 'fill-content',
            height: 22,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 40,
            cursor: 'pointer',
            paddingBottom: 3
        }
    })
);