// React Lib
import React, { useEffect, useState } from "react";

// Component Styles
import { useStyles } from "./DeviceLimitStyles";
import { Color, ColorSAP, Device, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { ShowMoreHorizontal } from "@commsult/ontego-ui/dist/sap_icons";
import Chart from "../Chart/Chart";
import DeviceIcon from "../../Icons/DeviceIcon";

export const DeviceLimitType = {
    CLIENT: "Customer",
    SYSTEM: "System"
}

export const DeviceLimit = (props) => {
    const classes = useStyles(props);
    const { type, data, handleSubmitDeviceLimit, style, textStatus, textCount,
        textActiveDevice, textDeviceLimitLeft, textDeviceLimit, textChangeDeviceLimit } = props;

    const activeDevice = data.activeDevice == null ? 0 : data.activeDevice;
    const deviceLimit = data.deploymentEnv === 0 || data.deviceLimit == null ? data.defaultDeviceLimit : data.deviceLimit;
    const deviceLimitLeft = deviceLimit - activeDevice;

    const deviceLimitData = [
        [textStatus, textCount],
        [textActiveDevice, activeDevice],
        [textDeviceLimitLeft, deviceLimitLeft]
    ];

    let changeDeviceLimitDisabled = false;

    if (type === DeviceLimitType.SYSTEM && data.deploymentEnv === 0) {
        deviceLimitData[1][0] = `Parallel ${deviceLimitData[1][0]}`;
        deviceLimitData[2][0] = `Parallel ${deviceLimitData[2][0]}`;
        changeDeviceLimitDisabled = true;
    }

    useEffect(() => {
        if (data.textKey === "482e610668567b783f1f2ab684a98ed4") {
            deviceLimitData[1][1] = 15;
        } else if (data.textKey === "386baf44424675ae2198938824c9ab2d") {
            deviceLimitData[1][1] = 13;
        } else if (data.textKey === "25493550db2679aeb55ea4f757546545") {
            deviceLimitData[1][1] = 17;
        }
    }, []);

    const [editDeviceLimitDialogOpen, setEditDeviceLimitDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenEditDeviceLimitDialog = () => { setEditDeviceLimitDialogOpen(true) };
    const handleCloseEditDeviceLimitDialog = () => { setEditDeviceLimitDialogOpen(false) };

    const handleClickShowMoreIcon = (event) => {
        event.stopPropagation();

        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        };
    }

    return (
        <div
            className={classes.deviceLimitContainer}
            style={style}
        >
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                size={24}
                color={ColorSAP.neutral[100]}
            >
                {textDeviceLimit}
            </Typography>
            <div style={{ position: "relative", marginTop: 30 }}>
                <Chart
                    chartType="PieChart"
                    chartData={deviceLimitData}
                    chartWidth={"100%"}
                    chartHeight={"380px"}
                    chartOptions={{
                        chartArea: {
                            left: 20,
                            right: 20,
                            top: 5,
                            bottom: 100,
                            width: "100%",
                            height: "380",
                        },
                        backgroundColor: "transparent",
                        colors: [ColorSAP.primary[70], ColorSAP.neutral[20]],
                        legend: {
                            position: "bottom",
                            textStyle: {
                                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD,
                                fontSize: 12,
                                color: ColorSAP.neutral[100],
                            }
                        },
                        pieHole: 0.6,
                        pieSliceText: 'none',
                        // tooltip: { isHtml: true, trigger: "visible" }
                    }}
                />
                <div style={{ position: "absolute", top: '25%', left: data.deploymentEnv === 0 ? '52%' : '47%' }}>
                    {/* <Device style={{ width: 24, height: 24, color: ColorSAP.primary[70], marginLeft: data.deploymentEnv === 0 ? -18 : 5 }} /> */}
                    <DeviceIcon style={{ width: 24, height: 24, color: ColorSAP.primary[70], marginLeft: data.deploymentEnv === 0 ? -18 : 5 }} />
                    <div style={{ display: 'flex', marginTop: -10, marginLeft: data.deploymentEnv === 0 ? -40 : (deviceLimitData[1][1] > 10 ? -40 : -20) }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={48}
                            color={Color.neutral[400]}
                            className={classes.deviceLimitTypographyNumber}
                        >
                            {deviceLimitData[1][1]}
                        </Typography>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={18}
                            color={Color.neutral[600]}
                            className={classes.deviceLimitTypographyCapacity}
                        >
                            {`/ ${parseInt(`${deviceLimitData[1][1]}`, 10) + parseInt(`${deviceLimitData[2][1]}`, 10)}`}
                        </Typography>
                    </div>
                </div>
            </div >
            {/* <ShowMoreHorizontal
                className={classes.deviceLimitShowMoreHorizontal}
                onClick={handleClickShowMoreIcon}
            /> */}
            {/* {!changeDeviceLimitDisabled && (
                <OverflowMenu
                    anchorElement={anchorEl}
                    setAnchorEl={setAnchorEl}
                    menuItems={[
                        {
                            text: textChangeDeviceLimit,
                            onClick: () => {
                                handleOpenEditDeviceLimitDialog()
                            }
                        }
                    ]}
                />
            )} */}
            {/* <EditDeviceLimit
                open={editDeviceLimitDialogOpen}
                onClose={handleCloseEditDeviceLimitDialog}
                data={{
                    textName: data.textName,
                    deviceLimit: data.deviceLimit,
                    defaultDeviceLimit: data.defaultDeviceLimit,
                }}
                type={type}
                handleSubmitDeviceLimit={handleSubmitDeviceLimit}
            /> */}
        </div>
    )
};

DeviceLimit.defaultProps = {
    type: DeviceLimitType.CLIENT,
    data: {
        textKey: "1",
        textName: "Data Name",
        activeDevice: 7,
        deviceLimit: 25,
        defaultDeviceLimit: 25,
        deploymentEnv: 1
    },
    handleSubmitDeviceLimit: (data) => { },
    textStatus: "Status",
    textCount: "Count",
    textActiveDevice: "Active Device",
    textDeviceLimitLeft: "Device Limit Left",
    textDeviceLimit: "Device Limit",
    textChangeDeviceLimit: "Change Device Limit",

}

export default React.memo(DeviceLimit)