import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../App';
import {
    Typography,
    Color,
    FONT_FAMILY,
    Separator,
    // Chip,
    // CalendarDateRangePicker,
    // Button,
    FilterableChip,
    ColorSAP,
    // Dropdown,
    // Form
} from '@commsult/ontego-ui';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './SystemMessageDialogStyle';
import DashboardService from '../../Pages/Manage/ManageMainDashboardService';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LOG_TYPES_ENUM, TYPE_FILTER_LOG } from '../../Constants/LogTypeEnum';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';
import Dropdown from '../../ReusableComponents/Dropdown/Dropdown';
import Form from '../../ReusableComponents/Form/Form';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Chip from '../../ReusableComponents/Chip/Chip';

const SystemMessageTableColumn = props => {
    const [columnSorts, setColumnSorts] = useState({
        log_type: true,
        timestamp: true
    });

    const { handleSort, typeList, handleOnDateChange, handleClearDateRange, startDate, endDate, handleOnFilterTypeClick, handleOnFilterTypeClear, activeFilter, setPageSize, setSearch, search, handleChangeProcessSearch, setLoading, isLoading } = props;

    const { t } = useTranslation()

    const onClickHeader = (key) => () => {
        handleSort(key, columnSorts[key]);
        setColumnSorts({ ...columnSorts, [key]: !columnSorts[key] })
    }

    const filterType = [
        {
            text: '1 - 10',
            onClick: () => { setPageSize(10) }
        },
        {
            text: '1 - 50',
            onClick: () => { setPageSize(50) }
        },
    ]

    return (
        <div style={{ margin: "25px 0" }}>
            <div style={{ marginBottom: "20px", display: "flex", alignItems: 'center', justifyContent: "space-between", background: ColorSAP.neutral[15], borderRadius: 16, padding: '16px 8px', boxSizing: 'border-box' }}>
                <div style={{ display: "flex", alignItems: "center", position: "relative", padding: "0 16px" }}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={16}
                        color={ColorSAP.neutral[100]}
                        style={{ marginRight: 24 }}
                    >
                        {t("manage.timestamp")}
                    </Typography>
                    {/* <CalendarDateRangePicker
                        containerId="system-message-datepicker-container"
                        calenderId="system-message-datepicker"
                        start={startDate}
                        end={endDate}
                        onChange={handleOnDateChange}
                    /> */}
                    {/* <TertiaryButton
                        theme="light"
                        size="small"
                        onClick={handleClearDateRange}
                        style={{ marginLeft: 24 }}
                    >
                        {t("manage.clearDate")}
                    </TertiaryButton> */}
                </div>
                <div style={{ borderRight: "1px solid", borderColor: ColorSAP.neutral[100] }} />
                <div style={{ width: "40%" }}>
                    <FilterableChip
                        data={typeList}
                        activeFilter={activeFilter}
                        onClick={handleOnFilterTypeClick}
                        onClear={handleOnFilterTypeClear}
                        scrollable={true}
                    />
                </div>
                <div style={{ borderRight: "1px solid", borderColor: ColorSAP.neutral[100] }} />
                <div style={{ width: "10%", display: "flex", alignItems: "center", position: "relative", padding: "0 16px" }}>
                    <Dropdown
                        menu={filterType}
                        selected={"1 - 10"}
                        type="SELECTION"
                        style={{ color: "black", cursor: "pointer", width: '150px', background: ColorSAP.neutral[20], borderRadius: 16 }}
                    />
                </div>
            </div>
            <div
                style={{
                    width: 315
                }}
            >
                <Form
                    placeholder={t(
                        "manage.searchDeviceNoOrAlias"
                    )}
                    search
                    value={search}
                    style={{ width: "40%", marginBottom: 8 }}
                    onChange={handleChangeProcessSearch}
                />
            </div>
            <Grid container spacing={2} style={{ padding: 24 }}>
                <Grid item md={2} style={{ cursor: "pointer" }} onClick={onClickHeader('log_type')} >
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {t("manage.logType")}
                    </Typography>
                </Grid>
                <Grid item md={8}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {t("manage.logMessage")}
                    </Typography>
                </Grid>
                <Grid item md={2} style={{ cursor: "pointer" }} onClick={onClickHeader('timestamp')} >
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {t("manage.timestamp")}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

const SystemMessageTableRow = props => {
    const { id, log } = props

    return (
        <div style={{
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            height: 65,
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: Color.neutral[500]
            }
        }}>
            <Grid container spacing={2}>
                <Grid item md={2}>
                    <Chip
                        backgroundColor={Color.neutral[900]}
                    >
                        {log.log_type}
                    </Chip>
                </Grid>
                <Grid item md={8}>
                    <Typography
                        id={`${id}-system-message`}
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                    >
                        {log.messages}
                    </Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography
                        id={`${id}-system-message-date`}
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                    >
                        {log.date + " " + log.time}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

const SystemMessageDialog = props => {
    const classes = useStyles();

    const { userInfo, handleShowErrorSnackbar } = useContext(AppContext)
    const { open, onClose, log, setLogs } = props;
    const [filteredData, setFilteredData] = useState([]);

    const [typeList, setTypeList] = useState([]);
    const [activeFilter, setActiveFilter] = useState([])

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [dateFilter, setDateFilter] = useState([])

    const [currPage, setCurrPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [logType, setLogType] = useState([]);

    const [search, setSearch] = useState("")

    const [isLoading, setLoading] = useState(false)


    const { t } = useTranslation();

    useEffect(() => {
        let totalPage = 0;
        if (log.length > 0) {
            totalPage = parseInt(log[0].totalRow) / pageSize;
        }
        let totalPageCount = Math.ceil(totalPage)
        setFilteredData(log);
        setTotalPage(totalPageCount)

        //initialize log_type filter
        // let logTypeList = log.map((data) => data.log_type)
        // let logTypeDistinctList = [...new Set(logTypeList)]
        // let typeFilterData = []
        // logTypeDistinctList.forEach(type => {
        //     typeFilterData.push({
        //         id: type,
        //         label: type
        //     })
        // })

        let chipFilterData = [];
        chipFilterData.push({
            parameterName: "Type",
            filters: TYPE_FILTER_LOG
        })

        setTypeList(chipFilterData);
    }, [log])

    useEffect(() => {
        let newFilteredData = [...log];
        let newFilteredDateRangeData = [];
        let newFilteredTypeData = [];

        if (dateFilter.length === 2) {
            newFilteredData.forEach(data => {
                if (
                    moment(moment(data.timestamp).format('YYYY-MM-DD')).isSameOrAfter(moment(dateFilter[0]).format('YYYY-MM-DD')) &&
                    moment(moment(data.timestamp).format('YYYY-MM-DD')).isSameOrBefore(moment(dateFilter[1]).format('YYYY-MM-DD'))
                ) {
                    newFilteredDateRangeData.push(data)
                }
            })

            newFilteredData = newFilteredDateRangeData;
        }

        if (activeFilter.length > 0) {

            newFilteredData.forEach(el => {
                if (activeFilter.some(filter => el.log_type.indexOf(filter) >= 0)) {
                    newFilteredTypeData.push(el)
                }
            })

            newFilteredData = newFilteredTypeData;
        }

        setCurrPage(1)
        if (activeFilter.length === 2) {
            setCurrPage(1);
        }
        if (activeFilter.length > 0) {
            let totalPage = 0;
            if (log.length > 0) {
                totalPage = parseInt(log[0].totalRow) / pageSize;
            }
            let totalPageCount = Math.ceil(totalPage)
            setTotalPage(totalPageCount)
        } else if (dateFilter.length === 2) {
            setTotalPage(Math.ceil(newFilteredData.length / pageSize))
        }
        setFilteredData(newFilteredData);
    }, [dateFilter, activeFilter])

    const handleSortSystemMessage = (key, isAsc) => {
        const _log = JSON.parse(JSON.stringify(filteredData));

        const sortedArr = _log.sort((a, b) => {
            return (isAsc ? (b[key] > a[key] ? -1 : 1) : (a[key] > b[key] ? -1 : 1))
        })

        setFilteredData(sortedArr);
    }

    const getDataFromApi = async () => {
        const logParam = { currentPage: currPage, logLimit: pageSize, startDate: dateFilter.length > 1 ? moment(dateFilter[0]).format('YYYY/MM/DD') : "", endDate: dateFilter.length > 1 ? moment(dateFilter[1]).format('YYYY/MM/DD') : "", logType: logType ? logType : [], search: search ? search : "" }
        await DashboardService.getClientsData(userInfo.key, logParam)
            .then(results => {
                const { chartData, logEntries, apps: appList } = results.data;
                logEntries.forEach(log => {
                    // TODO i18n dates
                    log.date = moment(log.timestamp).format('DD.MM.YYYY');
                    log.time = moment(log.timestamp).format('HH:mm:ss');
                    log.log_type = LOG_TYPES_ENUM[log.log_type];
                });
                setLogs(logEntries);
            })
            .catch(error => handleShowErrorSnackbar(error));
        setLoading(false)
    }


    useEffect(() => {
        getDataFromApi()
    }, [currPage, logType, dateFilter, pageSize, search])

    const handleChangePage = (e, value) => {
        setCurrPage(value);
    };

    const handleOnDateChange = (start, end) => {
        if (start && end === null) {
            const newDateFilter = []
            newDateFilter.push(moment(start).startOf('days'))
            newDateFilter.push(moment(start).add(1, 'days'))
            setDateFilter(newDateFilter)
        }
        setLoading(true)
        if (start && end) {
            const newDateFilter = []
            newDateFilter.push(start)
            newDateFilter.push(end)
            setDateFilter(newDateFilter)
        }
        setStartDate(start)
        setEndDate(end)
    }

    useEffect(() => {
        if (dateFilter.length > 1) {
            setCurrPage(1);
        }
    }, [dateFilter])

    const handleClearDateRange = () => {
        setLoading(true)
        setDateFilter([])
        setStartDate(null)
        setEndDate(null)
        setCurrPage(1);
        let totalPage = 0;
        if (log.length > 0) {
            totalPage = parseInt(log[0].totalRow) / pageSize;
        }
        let totalPageCount = Math.ceil(totalPage)
        setTotalPage(totalPageCount)
    }

    const handleOnFilterTypeClick = id => () => {
        setLoading(true)
        const newActiveFilter = [...activeFilter]
        if (newActiveFilter.includes(id)) {
            newActiveFilter.splice(newActiveFilter.indexOf(id), 1)
        }
        else {
            newActiveFilter.push(id)
        }
        setLogType(newActiveFilter)
        setActiveFilter(newActiveFilter)
    }

    const handleOnFilterTypeClear = () => {
        setLoading(true)
        setActiveFilter([])
        setLogType([]);
        setCurrPage(1)
        let totalPage = 0;
        if (log.length > 0) {
            totalPage = parseInt(log[0].totalRow) / pageSize;
        }
        let totalPageCount = Math.ceil(totalPage)
        setTotalPage(totalPageCount)
    }

    const handleChangeProcessSearch = e => {
        setLoading(true)
        setSearch(e.target.value)
    }

    return (
        <>
            <DialogBox
                open={open}
                handleClose={onClose}
                textTitle={t("manage.systemMessage")}
                content={
                    <div
                        style={{
                            height: '75vh',
                            width: '90vw',
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 40,
                            boxSizing: 'border-box'
                        }}
                    >
                        <div>
                            <SystemMessageTableColumn
                                handleSort={handleSortSystemMessage}
                                handleOnDateChange={handleOnDateChange}
                                handleClearDateRange={handleClearDateRange}
                                startDate={startDate}
                                endDate={endDate}
                                typeList={typeList}
                                handleOnFilterTypeClick={handleOnFilterTypeClick}
                                handleOnFilterTypeClear={handleOnFilterTypeClear}
                                activeFilter={activeFilter}
                                setPageSize={setPageSize}
                                setSearch={setSearch}
                                search={search}
                                handleChangeProcessSearch={handleChangeProcessSearch}
                                setLoading={setLoading}
                                isLoading={isLoading}
                            />
                            <div style={{ overflowY: "scroll", maxHeight: "400px", padding: "0px 24px" }}>
                                {filteredData.length === 0 && !isLoading
                                    ?
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_LIGHT_ITALIC}
                                        size={25}
                                        color={Color.neutral[900]}
                                        style={{ textAlign: "Center", marginTop: 150 }}
                                    >
                                        {t("dialogs.noLogMessage")}
                                    </Typography>
                                    :
                                    filteredData.map((l, index) => (
                                        <React.Fragment key={`l${index}`}>
                                            <SystemMessageTableRow
                                                key={`${l.messages}-${index}`}
                                                id={`${index}`}
                                                log={l}
                                            />
                                            {index !== filteredData.length - 1 && <Separator type="n100" />}
                                        </React.Fragment>
                                    ))
                                }
                                {isLoading ? (
                                    <div style={{ textAlign: "center", marginTop: 150 }}>
                                        <CircularProgress
                                            size={68}
                                            thickness={4}
                                            style={{ color: Color.primary[200] }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {filteredData.length !== 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    bottom: "0px",
                                    left: "0px",
                                    width: "100%",
                                    height: 80,
                                    backgroundColor: Color.neutral[0],
                                }}
                            >
                                <Pagination
                                    page={currPage}
                                    count={totalPage}
                                    classes={{ ul: classes.ul }}
                                    onChange={handleChangePage}
                                />
                            </div>
                        )}
                    </div>

                }
            />
        </>
    );
}

export default SystemMessageDialog;