// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
    createStyles({
        rootContainer: {
            flexGrow: 1,
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            position: "relative",
            marginTop: -80
        },
        registerContainer: {
            [theme.breakpoints.between('1280', '1440')]: {
                paddingLeft: "3%",
                paddingRight: "3%",
            },
            [theme.breakpoints.up('1440')]: {
                paddingLeft: 120,
                paddingRight: 120,
            },
            flexGrow: 1,
            boxSizing: "border-box",
            maxHeight: "100%",
        },
        registerBottomAction: {
            position: "absolute",
            bottom: 50
        }
    })
)