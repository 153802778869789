import React from 'react'
import { OntegoSAPLogoDefault, Typography, FONT_FAMILY, Form, Button, Color } from '@commsult/ontego-ui'
import { Link } from "react-router-dom";

import { useStyles } from './RegisterStyles';

import Grid from '@material-ui/core/Grid';

import RegisterImage from "../../assets/img/register/register.svg";
import { useTranslation } from 'react-i18next';

const Register = props => {
    const classes = useStyles(props);
    const { t } = useTranslation();

    return (
        <div className={classes.rootContainer}>
            <Grid container style={{ flexGrow: 1, width: "100%", height: "100%" }}>
                <Grid item md={6}>
                    <div id="registerContainer" className={classes.registerContainer}>
                        <Grid container style={{ flexGrow: 1 }}>
                            <Grid item md={12}>
                                <OntegoSAPLogoDefault
                                    style={{
                                        width: 120,
                                        height: 60,
                                        marginTop: 70,
                                        marginBottom: 100
                                    }}
                                />
                                <Typography
                                    fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                    size={48}
                                    color={Color.neutral[600]}
                                    style={{ letterSpacing: -1, marginBottom: 35 }}
                                >
                                    {t("register.createNewAccount")}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Form
                                    id="emailForm"
                                    placeholder="E-mail"
                                    required
                                    // value={email}
                                    // onChange={handleChangeEmail}
                                    // error={emailError}
                                    style={{ marginBottom: 20 }}
                                    tabIndex={1}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Form
                                    id="passwordForm"
                                    placeholder="Password"
                                    required
                                    password
                                    // value={password}
                                    // onChange={handleChangePassword}
                                    // error={passwordError}
                                    // errorMessage={errorMessage}
                                    style={{ marginBottom: 14 }}
                                    tabIndex={2}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Form
                                    id="confirmPasswordForm"
                                    placeholder="Confirm Password"
                                    required
                                    password
                                    // value={password}
                                    // onChange={handleChangePassword}
                                    // error={passwordError}
                                    // errorMessage={errorMessage}
                                    style={{ marginBottom: 14 }}
                                    tabIndex={3}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <div tabIndex={3}>
                                    <Button
                                        id="registerButton"
                                        style={{ marginTop: 30 }}
                                    // onClick={login}
                                    >
                                        {t("register.register")}
                                    </Button>
                                </div>
                            </Grid>
                            <div className={classes.registerBottomAction}>
                                <div style={{ display: "flex", marginBottom: 15 }}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                                        size={14}
                                        color={Color.neutral[600]}
                                    >
                                        {`${t("register.alreadyHaveAcc")}?`}
                                    </Typography>
                                    <Link
                                        to='/' style={{ textDecoration: "none" }}
                                    >
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                            size={14}
                                            color={Color.primary[600]}
                                            style={{ marginLeft: 10, cursor: "pointer" }}
                                        >
                                            {t("register.signIn")}
                                        </Typography>
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url(${RegisterImage})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Register;