import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const ArrowHeadSpecial = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 13 12" version="1.1">
            <path d="M12.4031 5.42416C12.4024 5.37202 12.3814 5.32222 12.3445 5.28535L7.67537 0.616196C7.63634 0.577162 7.5829 0.555997 7.52773 0.557714L4.99795 0.636452C4.82284 0.641902 4.73887 0.853894 4.86275 0.977777L8.27119 4.38622C8.39719 4.51222 8.30795 4.72765 8.12976 4.72764L0.414485 4.72732C0.303554 4.72732 0.213812 4.8176 0.214481 4.92852L0.225508 6.75899C0.22617 6.86887 0.315359 6.95764 0.425244 6.95778L8.16038 6.96786C8.33844 6.96809 8.42744 7.18338 8.30154 7.30928L4.87466 10.7362C4.75107 10.8598 4.83434 11.0713 5.00902 11.0775L7.55447 11.1674C7.60993 11.1694 7.66371 11.1482 7.70295 11.109L12.3562 6.45575C12.3943 6.4176 12.4154 6.36566 12.4147 6.31171L12.4031 5.42416Z" />
        </SvgIcon>
    );
}

export default ArrowHeadSpecial;