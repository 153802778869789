import axios from 'axios';
import { USER_API_BASE_BLACKLISTED_DEVICE_URL, getApiUrl } from '../../Constants/Api';

export class ManageBlacklistedDeviceService {
    getAllBlacklistedDevice = () => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_BLACKLISTED_DEVICE_URL}`);
    }
    addBlacklistedDevice = (blacklistData) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_BLACKLISTED_DEVICE_URL}`, blacklistData);
    }
    updateBlacklistedDevice = (blacklistedId, blacklistData) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_BLACKLISTED_DEVICE_URL}/${blacklistedId}`, blacklistData);
    }
    deleteBlackListedDevice = (blacklistedId) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_BLACKLISTED_DEVICE_URL}/${blacklistedId}`);
    }
}

export default new ManageBlacklistedDeviceService();