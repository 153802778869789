import { Color } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        dropdownContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
            padding: "16px 12px 16px 12px",
            width: 280,
            height: 44,
            backgroundColor: Color.neutral[100],
            cursor: "pointer"
        },

        dropdownText: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        },
        dropdownArrowIcon: {
            color: Color.primary[600],
            width: 24,
            height: 24
        }
    })
);