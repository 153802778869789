class ProcessType {
    constructor(systemID, type, info, isCollectible, subInfo) {
        this.systemID = systemID
        this.type = type
        this.info = info
        this.isCollectible = isCollectible
        this.subInfo = subInfo
    }
}

export default ProcessType