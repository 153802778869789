// React libraries
import React, { useState, useEffect } from "react";

// Custom Styles
import { useStyles } from "./OverflowMenuStyles";
import { ChevronRight } from "@commsult/ontego-ui/dist/sap_icons";
import { Menu, MenuItem, MenuList, Paper } from "@material-ui/core";

export const OverflowMenu = props => {
    const classes = useStyles(props);
    const [menus, setMenus] = useState([]);
    const [subMenuStates, setSubMenuStates] = useState([]);

    const { id, anchorElement, setAnchorEl, menuItems, style } = props;

    useEffect(() => {
        let intialDepth = 0;
        let counter = 1;

        const assignDepth = (menuItems, depth) => {
            menuItems.forEach(menuItem => {
                menuItem.key = counter++;
                menuItem.depth = depth + 1;
                if ('subMenuItems' in menuItem) {
                    if (menuItem.subMenuItems.length > 0) {
                        assignDepth(menuItem.subMenuItems, menuItem.depth);
                    }
                }
            })
        }

        assignDepth(menuItems, intialDepth);
        setMenus(menuItems);
    }, [])

    const closeAllMenus = () => {
        setSubMenuStates([]);
        setAnchorEl(null);
    };

    const handleItemClick = menuItem => event => {
        event.stopPropagation();

        // Filter same and deeper level of group
        const filterDepth = depth => {
            return (state) => {
                return state.depth >= depth;
            }
        }

        const hasSubMenu = !!(
            menuItem.subMenuItems && menuItem.subMenuItems.length
        );

        if (hasSubMenu) {
            // hide already open sub menus and open the requested sub menu
            const newSubMenuStates = [...subMenuStates];

            let clickedSubMenuState = subMenuStates.find((menuState) => menuState.key === menuItem.key);
            let sameSubMenuDepthState = newSubMenuStates.filter(filterDepth(clickedSubMenuState.depth));

            // Set open to true for clicked sub menu
            if (newSubMenuStates) {
                clickedSubMenuState.anchorElement = event.target;
                clickedSubMenuState.open = !clickedSubMenuState.open;
            }

            // Set open to false for other clicked sub menu on the same level and children level
            sameSubMenuDepthState.forEach((sameSubMenuState) => {
                if (sameSubMenuState.key !== clickedSubMenuState.key) {
                    if (sameSubMenuState.open) {
                        sameSubMenuState.open = false;
                        sameSubMenuState.anchorElement = null;
                    }
                }
            })

            setSubMenuStates(newSubMenuStates);
        } else {
            closeAllMenus();
        }

        if (menuItem.onClick) {
            menuItem.onClick();
        }
    };

    const renderMenuItem = menuItem => {
        const newSubMenuStates = [...subMenuStates];

        const hasIcon = !!(menuItem.icon);
        const hasSubMenu = !!(menuItem.subMenuItems && menuItem.subMenuItems.length);

        let subMenuState = newSubMenuStates.find((menuState) => menuState.key === menuItem.key);

        // initialize state for sub menu
        if (hasSubMenu && !subMenuState) {
            subMenuState = {
                key: menuItem.key,
                depth: menuItem.depth,
                anchorElement: null,
                open: false
            };

            newSubMenuStates.push(subMenuState);
            setSubMenuStates(newSubMenuStates);
        }

        return (
            <MenuItem
                key={menuItem.key}
                disabled={menuItem.disabled}
                divider={menuItem.divider}
                className={menuItem.destructive ? classes.menuItemDestructive : classes.menuItem}
                onClick={handleItemClick(menuItem)}
            >
                {hasIcon && (
                    <div className={classes.listItemIconRoot}>
                        {menuItem.icon}
                    </div>
                )}
                <div className={classes.text}>{menuItem.text}</div>
                {hasSubMenu && (
                    <>
                        <ChevronRight className={classes.arrowHeadIcon} />
                        <Paper
                            className={`${classes.subMenu} ${subMenuState.open ? classes.subMenuOpen : ""}`}
                        >
                            <MenuList
                                classes={{
                                    root: classes.subMenuListRoot
                                }}
                            >
                                {menuItem.subMenuItems.map(subMenuItem =>
                                    renderMenuItem(subMenuItem)
                                )}
                            </MenuList>
                        </Paper>
                    </>
                )}
            </MenuItem >
        );
    };

    return (
        <Menu
            id={id}
            anchorEl={anchorElement}
            classes={{
                paper: classes.menuRoot,
                list: classes.menuList
            }}
            PaperProps={{
                style: {
                    ...style,
                    transform: 'translateY(10%)',
                    borderRadius: 8
                }
            }}
            PopoverClasses={{
                root: classes.popoverRoot
            }}
            open={Boolean(anchorElement)}
            onClose={closeAllMenus}
        >
            {menus.map(menuItem => renderMenuItem(menuItem))}
        </Menu>
    );
}