import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** KOMMISSIONIERUNG IMAGES */
import Kommissionierung_Einfuehrung1 from '../../assets/img/kommissionierung/Einfuehrung/1.png'
import Kommissionierung_Einfuehrung2 from '../../assets/img/kommissionierung/Einfuehrung/2.png'

import Kommissionierung_Auslieferung_WM1 from '../../assets/img/kommissionierung/Auslieferung_WM/1.png'
import Kommissionierung_Auslieferung_WM2 from '../../assets/img/kommissionierung/Auslieferung_WM/2.png'
import Kommissionierung_Auslieferung_WM3 from '../../assets/img/kommissionierung/Auslieferung_WM/3.png'
import Kommissionierung_Auslieferung_WM4 from '../../assets/img/kommissionierung/Auslieferung_WM/4.png'
import Kommissionierung_Auslieferung_WM5 from '../../assets/img/kommissionierung/Auslieferung_WM/5.png'

import Kommissionierung_Auslieferung_EWM1 from '../../assets/img/kommissionierung/Auslieferung_EWM/1.png'
import Kommissionierung_Auslieferung_EWM2 from '../../assets/img/kommissionierung/Auslieferung_EWM/2.png'
import Kommissionierung_Auslieferung_EWM3 from '../../assets/img/kommissionierung/Auslieferung_EWM/3.png'
import Kommissionierung_Auslieferung_EWM4 from '../../assets/img/kommissionierung/Auslieferung_EWM/4.png'
import Kommissionierung_Auslieferung_EWM5 from '../../assets/img/kommissionierung/Auslieferung_EWM/5.png'

import Kommissionierung_Transport1 from '../../assets/img/kommissionierung/Transport/1.png'
import Kommissionierung_Transport2 from '../../assets/img/kommissionierung/Transport/2.png'

import Kommissionierung_Sammellieferung1 from '../../assets/img/kommissionierung/Sammellieferung/1.png'
import Kommissionierung_Sammellieferung2 from '../../assets/img/kommissionierung/Sammellieferung/2.png'

import Kommissionierung_Queues_WM1 from '../../assets/img/kommissionierung/Queues_WM/1.png'
import Kommissionierung_Queues_WM2 from '../../assets/img/kommissionierung/Queues_WM/2.png'
import Kommissionierung_Queues_WM3 from '../../assets/img/kommissionierung/Queues_WM/3.png'
import Kommissionierung_Queues_WM4 from '../../assets/img/kommissionierung/Queues_WM/4.png'

import Kommissionierung_Queues_EWM1 from '../../assets/img/kommissionierung/Queues_EWM/1.png'
import Kommissionierung_Queues_EWM2 from '../../assets/img/kommissionierung/Queues_EWM/2.png'
import Kommissionierung_Queues_EWM3 from '../../assets/img/kommissionierung/Queues_EWM/3.png'
import Kommissionierung_Queues_EWM4 from '../../assets/img/kommissionierung/Queues_EWM/4.png'

import Kommissionierung_Wellen_WM1 from '../../assets/img/kommissionierung/Wellen_WM/1.png'
import Kommissionierung_Wellen_WM2 from '../../assets/img/kommissionierung/Wellen_WM/2.png'
import Kommissionierung_Wellen_WM3 from '../../assets/img/kommissionierung/Wellen_WM/3.png'
import Kommissionierung_Wellen_WM4 from '../../assets/img/kommissionierung/Wellen_WM/4.png'

import Kommissionierung_Wellen_EWM1 from '../../assets/img/kommissionierung/Wellen_EWM/1.png'
import Kommissionierung_Wellen_EWM2 from '../../assets/img/kommissionierung/Wellen_EWM/2.png'
import Kommissionierung_Wellen_EWM3 from '../../assets/img/kommissionierung/Wellen_EWM/3.png'
import Kommissionierung_Wellen_EWM4 from '../../assets/img/kommissionierung/Wellen_EWM/4.png'

import Kommissionierung_NullKontrolle1 from '../../assets/img/kommissionierung/NullKontrolle/1.png'
import Kommissionierung_NullKontrolle2 from '../../assets/img/kommissionierung/NullKontrolle/2.png'
import Kommissionierung_NullKontrolle3 from '../../assets/img/kommissionierung/NullKontrolle/3.png'

export const KommissionierungImages = [
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_EINFUEHRUNG_WM`,
        [
            new ProcessImage('Queues', Kommissionierung_Einfuehrung1),
            new ProcessImage('Auslieferungen direkt', Kommissionierung_Einfuehrung2),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_EINFUEHRUNG_EWM`,
        [
            new ProcessImage('Queues', Kommissionierung_Einfuehrung1),
            new ProcessImage('Auslieferungen direkt', Kommissionierung_Einfuehrung2),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUR_AUSLIEFERUNG_WM`,
        [
            new ProcessImage('Auslieferung direkt', Kommissionierung_Auslieferung_WM1),
            new ProcessImage('Auslieferungen direkt', Kommissionierung_Auslieferung_WM2),
            new ProcessImage('Optional Positionen', Kommissionierung_Auslieferung_WM3),
            new ProcessImage('Optional Details', Kommissionierung_Auslieferung_WM4),
            new ProcessImage('Optional Nachlagerplatz', Kommissionierung_Auslieferung_WM5),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUR_AUSLIEFERUNG_EWM`,
        [
            new ProcessImage('Auslieferung direkt', Kommissionierung_Auslieferung_EWM1),
            new ProcessImage('Auslieferungen direkt', Kommissionierung_Auslieferung_EWM2),
            new ProcessImage('Optional Positionen', Kommissionierung_Auslieferung_EWM3),
            new ProcessImage('Optional Details', Kommissionierung_Auslieferung_EWM4),
            new ProcessImage('Optional Nachlagerplatz', Kommissionierung_Auslieferung_EWM5),
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUM_TRANSPORT`,
        [
            new ProcessImage('Transport', Kommissionierung_Transport1),
            new ProcessImage('Auslieferungen zum Transport', Kommissionierung_Transport2)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_ZUR_GRUPPE`,
        [
            new ProcessImage('Gruppe', Kommissionierung_Sammellieferung1),
            new ProcessImage('Auslieferungen Gruppe', Kommissionierung_Sammellieferung2)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERLISTEN_UND_QUEUES_WM`,
        [
            new ProcessImage('Queues', Kommissionierung_Queues_WM1),
            new ProcessImage('Positionen', Kommissionierung_Queues_WM2),
            new ProcessImage('Optional Details', Kommissionierung_Queues_WM3),
            new ProcessImage('Optional Nachlagerplatz', Kommissionierung_Queues_WM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERLISTEN_UND_QUEUES_EWM`,
        [
            new ProcessImage('Queues', Kommissionierung_Queues_EWM1),
            new ProcessImage('Positionen', Kommissionierung_Queues_EWM2),
            new ProcessImage('Optional Details', Kommissionierung_Queues_EWM3),
            new ProcessImage('Optional Nachlagerplatz', Kommissionierung_Queues_EWM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERWELLEN_WM`,
        [
            new ProcessImage('Kommi-Wellen', Kommissionierung_Wellen_WM1),
            new ProcessImage('Positionen', Kommissionierung_Wellen_WM2),
            new ProcessImage('Optional Details', Kommissionierung_Wellen_WM3),
            new ProcessImage('Optional Nachlagerplatz', Kommissionierung_Wellen_WM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERWELLEN_EWM`,
        [
            new ProcessImage('Kommi-Wellen', Kommissionierung_Wellen_EWM1),
            new ProcessImage('Positionen', Kommissionierung_Wellen_EWM2),
            new ProcessImage('Optional Details', Kommissionierung_Wellen_EWM3),
            new ProcessImage('Optional Nachlagerplatz', Kommissionierung_Wellen_EWM4)
        ]
    ),
    new ProcessImageContainer(
        `P_KOMMISSIONIERUNG_NULL_KONTROLLE_UND_RESTMENGENPRUEFUNG`,
        [
            new ProcessImage('Restmengenprüfung', Kommissionierung_NullKontrolle1),
            new ProcessImage('Nullkontrolle', Kommissionierung_NullKontrolle2),
            new ProcessImage('Nullkontrolle Korrektur', Kommissionierung_NullKontrolle3)
        ]
    )
]