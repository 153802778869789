export const LOG_TYPES_ENUM = {
    100: "Auth",
    200: "Config",
    201: "Info",
    401: "Error"
}

export const TYPE_FILTER_LOG = [
    {
        id: Object.keys(LOG_TYPES_ENUM)[0],
        label: LOG_TYPES_ENUM[100]
    },
    {
        id: Object.keys(LOG_TYPES_ENUM)[1],
        label: LOG_TYPES_ENUM[200]
    },
    {
        id: Object.keys(LOG_TYPES_ENUM)[2],
        label: LOG_TYPES_ENUM[201]
    },
    {
        id: Object.keys(LOG_TYPES_ENUM)[3],
        label: LOG_TYPES_ENUM[401]
    },
]