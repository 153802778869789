import React, { useState, useContext, useEffect } from 'react';
import { AppContext, RootWrapperContainer } from '../../App';

import { useHistory } from 'react-router-dom';

import { useStyles } from './ManageMainDashboardStyles';

import { Chart, Typography, FONT_FAMILY, Color, Roles, User, Device, Key, ColorSAP, Globe } from '@commsult/ontego-ui';

import { PaddedContainer } from '../../App'

import Grid from '@material-ui/core/Grid';
import { MANAGE_PERMISSION_ASSIGN_PATH, MANAGE_GLOBAL_PERMISSION_PATH, MANAGE_GLOBAL_DEVICE_PATH, MANAGE_GLOBAL_USERS_PATH, UPDATE_MANAGEMENT, MANAGE_GLOBAL_SIGNING_KEYS_PATH } from '../../Constants/Path';
import DebugLogDialog from './DebugLogDialog';
import { getUpperBoundValue, getTicks, generateDropDownItems, getLastNYear } from "../../Utils/Utils";
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DashboardService from './ManageMainDashboardService';
import ManageGlobalDevice from './ManageGlobalDevice';
import SystemMessageDialog from '../../Dialogs/SystemDialog/SystemMessageDialog';
import { LOG_TYPES_ENUM } from '../../Constants/LogTypeEnum';
import TotalCost from '../../ReusableComponents/TotalCost/TotalCost';
import DeviceLimit from '../../ReusableComponents/DeviceLimit/DeviceLimit';
import EmptyChartData from '../../ReusableComponents/EmptyChartData/EmptyChartData';
import { SystemMessage } from '../../ReusableComponents/SystemMessage/SystemMessage';

const CustomerCostAndLogin = (props) => {
    const { data, currency } = props;
    const { t } = useTranslation();
    const customerCostAndLogin = data;

    let noChart = true;
    let costMaxValue = 0;
    let loginMaxValue = 0;

    if (customerCostAndLogin) {
        customerCostAndLogin.forEach((row, index) => {
            if (index > 0) {
                if (row[2] !== 0)
                    noChart = false;
                if (row[1] > costMaxValue)
                    costMaxValue = row[1];
                if (row[2] > loginMaxValue)
                    loginMaxValue = row[2];
            } else {
                row[1] = t(row[1]);
                row[2] = t(row[2]);
            }
        })
    }

    const costUpperBound = getUpperBoundValue(costMaxValue);
    const loginUpperBound = getUpperBoundValue(loginMaxValue);

    return (
        <div style={{ paddingTop: 0 }}>
            <Chart
                chartType="ColumnChart"
                chartData={customerCostAndLogin}
                chartWidth={"100%"}
                chartHeight={"355px"}
                chartOptions={{
                    chartArea: {
                        left: '9%',
                        right: '4%',
                        top: 5,
                        bottom: 100,
                        width: "100%",
                        height: "300"
                    },
                    backgroundColor: "transparent",
                    colors: [ColorSAP.primary[70], ColorSAP.primary[40]],
                    animation: {
                        startup: true,
                        easing: 'out',
                        duration: 500,
                    },
                    legend: { position: 'bottom' },
                    series: {
                        0: { axis: 'cost', targetAxisIndex: 0 },
                        1: { axis: 'login', targetAxisIndex: 1 }
                    },
                    vAxes: {
                        0: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM,
                                color: Color.neutral[400],
                            },
                            ticks: getTicks(costUpperBound),
                            viewWindow: {
                                min: 0,
                                max: costUpperBound
                            },
                            format: '#,##0.00 ' + currency
                        },
                        1: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM,
                                color: Color.neutral[400],
                            },
                            ticks: getTicks(loginUpperBound),
                            viewWindow: {
                                min: 0,
                                max: loginUpperBound
                            },
                        }
                    },
                    hAxis: {
                        textStyle: {
                            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM,
                            color: Color.neutral[400],
                        }
                    },
                    bar: {
                        groupWidth: "40",
                        borderRadius: 4
                    },
                    tooltip: { isHtml: true, trigger: "visible" }
                }}
                chartEvents={
                    [
                        {
                            eventName: 'ready',
                            callback: (ce) => {
                                const chart = ce.chartWrapper.getChart();
                                var defses = document.getElementsByTagName('defs');
                                var gradientInjectFound = false;
                                Array.prototype.forEach.call(defses, function (defs) {
                                    if (defs.getAttribute('id') === 'gradientInject') {
                                        gradientInjectFound = true;
                                    }
                                });
                                if (!gradientInjectFound) {
                                    Array.prototype.forEach.call(defses, function (defs) {
                                        defs.insertAdjacentHTML('afterend', '<defs id="gradientInject"><linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" style="stop-color:#0077b6;stop-opacity:1" /><stop offset="100%" style="stop-color:#00527f;stop-opacity:1" /></linearGradient></defs>');
                                    });
                                }
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseover", (e) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseout", (e) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "animationfinish", (e) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                            },
                        },
                        {
                            eventName: 'select',
                            callback: (ce) => {
                                const chart = ce.chartWrapper.getChart();
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                            },
                        },
                    ]
                }
            />
            {noChart && (
                <EmptyChartData />
            )}
        </div>
    )
}

const ManageMainDashboard = props => {
    const classes = useStyles(props)
    const history = useHistory()
    const { t } = useTranslation()

    const { handleChangeInspectedSystem, userInfo, handleShowErrorSnackbar, client, setClient } = useContext(AppContext)
    const [selectedChartIndex, setSelectedChartIndex] = useState(0)
    const [debugLogDialogOpen, setDebugLogDialogOpen] = useState(false)

    const [cost, setCost] = useState(0);
    const [deviceCount, setDeviceCount] = useState(0);
    const [chart, setChart] = useState([]);
    const [logs, setLogs] = useState([]);
    const [apps, setApps] = useState([]);
    const [dropMenuItems, setDropMenuItems] = useState([]);
    const [systemMessageDialogOpen, setSystemMessageDialogOpen] = useState(false);
    const [isClientDataLoading, setIsClientDataLoading] = useState(false)

    const handleOpenDebugLogDialog = () => setDebugLogDialogOpen(true)
    const handleCloseDebugLogDialog = () => setDebugLogDialogOpen(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        setDropMenuItems(generateDropDownItems([t("manage.last12Months"), ...getLastNYear()], handleDropDownClick));

        if (userInfo)
            fetchClientData();
    }, [])

    const fetchClientData = () => {
        setIsClientDataLoading(true)
        const logParam = { currentPage: 1, logLimit: 10, startDate: "", endDate: "", logType: "", search: "" }
        DashboardService.getClientsData(userInfo.key, logParam)
            .then(results => {
                const { chartData, logEntries, apps: appList } = results.data;
                logEntries.forEach(log => {
                    // TODO i18n dates
                    log.date = moment(log.timestamp).format('DD.MM.YYYY');
                    log.time = moment(log.timestamp).format('HH:mm:ss');
                    log.log_type = LOG_TYPES_ENUM[log.log_type];
                });

                setCost(chartData[chartData.length - 1][1]);
                setChart(chartData);
                setLogs(logEntries);
                setApps(appList);
                setDeviceCount(chartData[chartData.length - 1][2]);
                setIsClientDataLoading(false)
            })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const FunctionCards = [
        {
            label: t('manage.devices'),
            icon: <Device />,
            onClick: () => {
                history.push(MANAGE_GLOBAL_DEVICE_PATH)
            }
        },
        {
            label: t('manage.roles'),
            icon: <Roles />,
            onClick: () => {
                history.push(MANAGE_GLOBAL_PERMISSION_PATH)
            }
        },
        {
            label: t('manage.users'),
            icon: <User />,
            onClick: () => {
                history.push(MANAGE_GLOBAL_USERS_PATH)
            }
        },
        {
            label: t('manage.signingKeys'),
            icon: <Key />,
            onClick: () => {
                history.push(MANAGE_GLOBAL_SIGNING_KEYS_PATH)
            }
        }
    ]

    const handleChangeSelectedChartIndex = (event, newValue) => {
        setSelectedChartIndex(newValue)
    }

    const handleAppOverviewCardKeyIconClick = system => () => {
        handleChangeInspectedSystem(system)
        history.push(MANAGE_PERMISSION_ASSIGN_PATH)
    }

    const handleAppOverviewCardBugIconClick = system => () => {
        handleChangeInspectedSystem(system)
        handleOpenDebugLogDialog()
    }

    const handleAppOverviewCardUpdateIconClick = system => () => {
        handleChangeInspectedSystem(system);
        history.push(UPDATE_MANAGEMENT);
    }

    const handleDropDownClick = (value) => {
        const period = typeof value === 'string' ? -1 : value;
        DashboardService.getClientsChartData(userInfo.key, period)
            .then(response => setChart(response.data))
            .catch(error => handleShowErrorSnackbar(error));
    }

    const getDeviceLimitData = () => {
        return {
            key: client.key,
            activeDevice: deviceCount,
            deviceLimit: client.deviceLimit
        }
    }

    const handleSubmitDeviceLimit = (device) => {
        DashboardService.updateClientDeviceLimit(userInfo.key, device.deviceLimit)
            .then(response => { })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const handleOpenSystemMessageDialog = () => {
        setSystemMessageDialogOpen(true);
    }

    const handleCloseSystemMessageDialog = () => {
        setSystemMessageDialogOpen(false);
    }

    return (
        <RootWrapperContainer>
            <Grid container spacing={2}>
                {/* <div style={{ height: 70, width: '100%' }} /> */}
                <Grid item xs={12} sm={12} md={12}>
                    <PaddedContainer>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 64, marginTop: 16 }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={28}
                                color={ColorSAP.neutral[100]}
                            >
                                {t("manage.greetingWords")} {client.name}
                            </Typography>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Grid item md={12}>
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            height: 435
                                        }}
                                    >
                                        <TotalCost totalCost={{ price: cost, billedDate: moment().endOf("month").toDate(), currency: client.currency }} />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item md={8}>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: 435,
                                        backgroundColor: ColorSAP.neutral[15],
                                        boxSizing: "border-box",
                                        padding: "32px 40px 32px 40px",
                                        borderRadius: 18
                                    }}
                                >
                                    {/** TITLE */}
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                        size={24}
                                        color={ColorSAP.neutral[100]}
                                    >
                                        {t("manage.customerCostAndDevices")}
                                    </Typography>
                                    {/** TABS */}
                                    <div
                                        style={{
                                            marginTop: 20
                                        }}
                                    >
                                        <CustomerCostAndLogin data={chart} currency={client.currency} />
                                    </div>
                                    {/* <div style={{ position: "absolute", top: 16, right: 16 }}>
                                        {dropMenuItems.length > 0 &&
                                            <Dropdown
                                                menu={dropMenuItems}
                                                selected={t(dropMenuItems[0].text)}
                                                type="SELECTION"
                                                backgroundColor={Color.neutral[0]}
                                            />
                                        }
                                    </div> */}
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <DeviceLimit
                                    data={getDeviceLimitData()}
                                    handleSubmitDeviceLimit={handleSubmitDeviceLimit}
                                />
                            </Grid>
                            <Grid item md={8}>
                                <SystemMessage
                                    log={logs.slice(0, 20)}
                                    handleViewMore={handleOpenSystemMessageDialog}
                                    isLoading={isClientDataLoading}
                                />
                            </Grid>
                        </Grid>
                    </PaddedContainer>
                </Grid>
            </Grid>
            <div style={{ width: '100%', marginTop: 24, boxSizing: 'border-box', paddingTop: 40, paddingBottom: 90 }}>
                <PaddedContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Globe
                            style={{
                                width: 24,
                                height: 24,
                                color: ColorSAP.primary[70],
                                marginRight: 10,
                            }}
                        />
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={16}
                            color={ColorSAP.neutral[100]}
                        >
                            {t("breadcrumb.appManagement")}
                        </Typography>
                    </div>
                </PaddedContainer>

                <ManageGlobalDevice />
            </div>

            {debugLogDialogOpen && (
                <DebugLogDialog
                    open={debugLogDialogOpen}
                    handleClose={handleCloseDebugLogDialog}
                />
            )}

            <SystemMessageDialog
                open={systemMessageDialogOpen}
                onClose={handleCloseSystemMessageDialog}
                log={logs}
                setLogs={setLogs}
            />
        </RootWrapperContainer>
    )
}

export default ManageMainDashboard