import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** VERLADUNG IMAGES */
import Verladung_Einfuehrung1 from '../../assets/img/verladung/Einfuehrung/1.png'
import Verladung_Einfuehrung2 from '../../assets/img/verladung/Einfuehrung/2.png'
import Verladung_Einfuehrung3 from '../../assets/img/verladung/Einfuehrung/3.png'

import Verladung_WA_Kontrolle_Ohne_HU1 from '../../assets/img/verladung/WA_Kontrolle_Ohne_HU/1.png'
import Verladung_WA_Kontrolle_Ohne_HU2 from '../../assets/img/verladung/WA_Kontrolle_Ohne_HU/2.png'
import Verladung_WA_Kontrolle_Ohne_HU3 from '../../assets/img/verladung/WA_Kontrolle_Ohne_HU/3.png'
import Verladung_WA_Kontrolle_Ohne_HU4 from '../../assets/img/verladung/WA_Kontrolle_Ohne_HU/4.png'
import Verladung_WA_Kontrolle_Ohne_HU5 from '../../assets/img/verladung/WA_Kontrolle_Ohne_HU/5.png'
import Verladung_WA_Kontrolle_Ohne_HU6 from '../../assets/img/verladung/WA_Kontrolle_Ohne_HU/6.png'

import Verladung_WA_Kontrolle_Mit_HU1 from '../../assets/img/verladung/WA_Kontrolle_Mit_HU/1.png'
import Verladung_WA_Kontrolle_Mit_HU2 from '../../assets/img/verladung/WA_Kontrolle_Mit_HU/2.png'
import Verladung_WA_Kontrolle_Mit_HU3 from '../../assets/img/verladung/WA_Kontrolle_Mit_HU/3.png'

import Verladung_Transport1 from '../../assets/img/verladung/Transport/1.png'
import Verladung_Transport2 from '../../assets/img/verladung/Transport/2.png'

import Verladung_Gruppe1 from '../../assets/img/verladung/Gruppe/1.png'
import Verladung_Gruppe2 from '../../assets/img/verladung/Gruppe/2.png'

export const VerladungImages = [
    new ProcessImageContainer(
        `P_VERLADUNG_EINFUEHRUNG`,
        [
            new ProcessImage('Auslieferungen', Verladung_Einfuehrung1),
            new ProcessImage('Positionen unvollständig', Verladung_Einfuehrung2),
            new ProcessImage('Positionen vollständig', Verladung_Einfuehrung3)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_FUER_VERSANDELEMENTE`,
        [
            new ProcessImage('Auslieferung', Verladung_WA_Kontrolle_Ohne_HU1),
            new ProcessImage('Suche', Verladung_WA_Kontrolle_Ohne_HU2),
            new ProcessImage('Auslieferungen', Verladung_WA_Kontrolle_Ohne_HU3),
            new ProcessImage('Positionen unvollständig', Verladung_WA_Kontrolle_Ohne_HU4),
            new ProcessImage('Detail', Verladung_WA_Kontrolle_Ohne_HU5),
            new ProcessImage('Positionen vollständig', Verladung_WA_Kontrolle_Ohne_HU6)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_MIT_HANDLING_UNITS`,
        [
            new ProcessImage('Auslieferung', Verladung_WA_Kontrolle_Mit_HU1),
            new ProcessImage('Positionen unvollständig', Verladung_WA_Kontrolle_Mit_HU2),
            new ProcessImage('Positionen vollständig', Verladung_WA_Kontrolle_Mit_HU3)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_ZUM_TRANSPORT`,
        [
            new ProcessImage('Transport', Verladung_Transport1),
            new ProcessImage('Auslieferungen Transport', Verladung_Transport2)
        ]
    ),
    new ProcessImageContainer(
        `P_VERLADUNG_WA_KONTROLLE_ZUR_GRUPPE`,
        [
            new ProcessImage('Gruppe', Verladung_Gruppe1),
            new ProcessImage('Auslieferungen Gruppe', Verladung_Gruppe2)
        ]
    )
]