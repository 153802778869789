import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
    Typography,
    FONT_FAMILY,
    Color,
    Information,
    Global,
    Separator,
    Close,
    Device,
    Bug,
    Delete,
    Archive,
    Plus,
    Settings,
    Edit,
    ChevronDoubleRight,
    ColorSAP,
    Check,
    TriangleDown,
    Info,
} from '@commsult/ontego-ui';
import { PaddedContainer, AppContext } from '../../App'
import UserHistoryDialog from './UserHistoryDialog';
import AppPhasesDialog from './AppPhasesDialog';
import RegisterNewDeviceDialog from '../../Dialogs/RegisterNewDeviceDialog/RegisterNewDeviceDialog';
import DeviceGroupDialog from '../../Dialogs/DeviceGroupDialog/DeviceGroupDialog';
import DeviceSettingDialog from '../../Dialogs/DeviceSettingDialog/DeviceSettingDialog';
import { MANAGE_MAIN_DASHBOARD_PATH } from '../../Constants/Path';
import ManageDeviceService from './ManageGlobalDeviceService';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { initializeDeviceFilter } from '../../Utils/Utils';
import Form from '../../ReusableComponents/Form/Form';
import Checkbox from '../../ReusableComponents/Checkbox/Checkbox';
import { OverflowMenu } from '../../ReusableComponents/OverflowMenu/OverflowMenu';
import { History } from '@commsult/ontego-ui/dist/sap_icons';
import Tooltip from '../../ReusableComponents/Tooltip/Tooltip';

const useChipStyle = makeStyles((theme) =>
    createStyles({
        chipContainer: (props) => ({
            width: 'fit-content',
            display: "inline-flex",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "0px 0px 0px 10px",
            borderRadius: 13,
            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
            fontSize: 12,
            background: props.backgroundColor,
            color: props.color,
            "& svg": {
                width: 16,
                height: 16,
                marginRight: 8
            }
        }),
        customChipContainer: {
            borderRadius: 120,
            border: `1px solid ${ColorSAP.primary[70]}`
        },
        versionContainer: (props) => ({
            display: "inline-flex",
            alignItems: "center",
            boxSizing: 'border-box',
            borderLeft: `1px solid ${props.isActive ? ColorSAP.neutral[0] : ColorSAP.neutral[100]}`
            // marginLeft: 12,
            // paddingRight: 10,
            // borderTopRightRadius: 13,
            // borderBottomRightRadius: 13,
            // transition: 'all 0.3s ease',
            // "&:hover": {
            //     backgroundColor: props.isActive ? Color.primary[900] : Color.neutral[200],
            //     transition: 'all 0.3s ease',
            // }
        }),
        innerVersionContainer: (props) => ({
            background: 'transparent',
            "&:hover": {
                background: props.isActive ? ColorSAP.primary[80] : ColorSAP.primary[20],
            }
        })
    })
)

const getStatusIcon = status => {
    switch (status) {
        case 'INACTIVE':
            return <Close style={{ width: 24, height: 24, color: Color.neutral[400] }} />
        case 'IN DEBUG':
            return <Bug style={{ width: 28, height: 28, color: Color.neutral[400] }} />
        case 'ARCHIVED':
            return <Archive style={{ width: 24, height: 24, color: Color.neutral[400] }} />
        default:
            return <Check style={{ width: 28, height: 28, color: Color.neutral[400] }} />
    }
}



const TableColumn = props => {
    const [columnSorts, setColumnSorts] = useState({
        alias: true,
        lastAccess: true,
    });

    const { handleSort } = props
    const { t } = useTranslation();

    const onClickHeader = (key) => () => {
        handleSort(key, columnSorts[key]);
        setColumnSorts({ ...columnSorts, [key]: !columnSorts[key] });
    };

    return (
        <Grid item xs={12} sm={12} md={12}>
            <PaddedContainer>
                <div style={{ marginTop: 36 }}>
                    <Grid container spacing={2}>
                        <Grid item md={2} onClick={onClickHeader('alias')} style={{ cursor: "pointer" }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {t(`manage.alias`)}
                            </Typography>
                        </Grid>
                        {/* <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.status`)}
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.group`)}
                            </Typography>
                        </Grid> */}
                        <Grid item md={5} onClick={onClickHeader('lastAccess')} style={{ cursor: "pointer" }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {t(`manage.lastAccessAndCreated`)}
                            </Typography>
                        </Grid>
                        <Grid item md={4} style={{ display: 'flex', paddingRight: 30 }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {t(`manage.appsAndPhases`)}
                            </Typography>
                        </Grid>
                        <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {t(`manage.actions`)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Separator type="n400" width={2} marginTop="10px" />
            </PaddedContainer>
        </Grid>
    )
}

const TableRow = props => {
    const { data, deviceGroups, handleOpen, handleOpenApps, handleChangeStatus, handleChangeDeviceGroup, handleOpenDeviceSettingDialog, hasDeviceSettings } = props

    const [anchorEl, setAnchorEl] = useState(null)
    const [showMoreScroll, setShowMoreScroll] = useState(false)

    const { t } = useTranslation();

    useEffect(() => {
        const tableOverflowElement = document.getElementById(`table-overflow${data.id}`);

        if (tableOverflowElement) {
            if (isOverflown(tableOverflowElement)) {
                setShowMoreScroll(true);
            }
            tableOverflowElement.addEventListener('scroll', () => {
                getScrollerEndPoint(tableOverflowElement);
            })

            return () => {
                tableOverflowElement.removeEventListener('scroll', () => {
                    getScrollerEndPoint(tableOverflowElement);
                })
            }
        }
    }, [data])

    const isOverflown = (element) => {
        if (element) {
            return element.scrollHeight > element.offsetHeight || element.scrollWidth > element.offsetWidth;
        }
    }

    const getScrollerEndPoint = (element) => {
        var scrollWidth = element.scrollWidth;
        var divWidth = element.clientWidth;
        var scrollerEndPoint = scrollWidth - divWidth;
        var divScrollerLeft = element.scrollLeft;

        if (divScrollerLeft === scrollerEndPoint) {
            setShowMoreScroll(false)
        } else {
            setShowMoreScroll(true)
        }
    }

    const handleOnClickStatus = e => {
        e.stopPropagation()

        if (!anchorEl) {
            setAnchorEl(e.currentTarget)
        };
    }

    const MENU_ITEMS = () => [
        {
            text: t(`manage.ACTIVE`),
            icon: <Check />,
            onClick: () => {
                handleChangeStatus('ACTIVE', data)
            }
        },
        {
            text: t(`manage.INACTIVE`),
            icon: <Close />,
            onClick: () => {
                handleChangeStatus('INACTIVE', data)
            }
        },
        {
            text: t(`manage.INDEBUG`),
            icon: <Bug />,
            onClick: () => {
                handleChangeStatus('IN DEBUG', data)
            }
        },
        {
            text: t(`manage.ARCHIVED`),
            icon: <Archive />,
            onClick: () => {
                handleChangeStatus('ARCHIVED', data)
            }
        }
    ]

    const DEVICE_GROUP_MENU = () => {
        const menus = [{
            key: 'UNASSIGNED',
            text: t(`manage.unassigned`),
            onClick: handleChangeDeviceGroup(null, data)
        }];
        deviceGroups.forEach(deviceGroup => menus.push({
            key: deviceGroup.key,
            text: deviceGroup.name,
            onClick: handleChangeDeviceGroup(deviceGroup.id, data)
        }));
        return menus;
    }

    const getDeviceGroupNameByGroupId = (deviceGroups, groupId) => {
        if (groupId === null) {
            return t('manage.unassigned');
        } else {
            const filteredDeviceGroup = deviceGroups.filter(deviceGroup => deviceGroup.id === groupId);
            return filteredDeviceGroup[0] ? filteredDeviceGroup[0].name : t('manage.unassigned');
        }
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <PaddedContainer>
                <div >
                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                        <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={20}
                                color={ColorSAP.neutral[100]}
                            >
                                {data.alias}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {data.deviceId}
                            </Typography>
                        </Grid>
                        {/* <Grid item md={2}>
                            <div
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 44, background: Color.neutral[100], padding: "0px 12px" }}
                                onClick={handleOnClickStatus}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    {getStatusIcon(data.status)}
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                        size={14}
                                        color={Color.neutral[400]}
                                        style={{ marginLeft: 12 }}
                                    >
                                        {t("manage." + data.status)}
                                    </Typography>
                                </div>
                                <ArrowHeadDown style={{ color: Color.primary[500], marginLeft: 25 }} />
                            </div>
                            <OverflowMenu
                                anchorElement={anchorEl}
                                setAnchorEl={setAnchorEl}
                                menuItems={MENU_ITEMS()}
                                open={Boolean(anchorEl)}
                            />
                        </Grid>
                        <Grid item md={2} >
                            <Dropdown
                                key={data.id + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}
                                menu={DEVICE_GROUP_MENU()}
                                type="SELECTION"
                                placeholder="Assign other group"
                                selected={getDeviceGroupNameByGroupId(deviceGroups, data.groupId)}
                                style={{ width: '100%' }}
                            />
                        </Grid> */}
                        <Grid item md={5} >
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {moment(data.lastAccess).format("DD.MM.YYYY HH:mm:ss")}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={14}
                                color={ColorSAP.neutral[100]}
                            >
                                {moment(data.createdOn).format("DD.MM.YYYY HH:mm:ss")}
                            </Typography>
                        </Grid>
                        <Grid item md={4} style={{ position: "relative", paddingRight: 30 }}>
                            {showMoreScroll && (
                                <ChevronDoubleRight style={{ color: Color.neutral[500], fontSize: 18, position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)" }} />
                            )}
                            {data.apps && data.apps.length > 0 ?
                                (
                                    <div id={`table-overflow${data.id}`} style={{ overflowX: "scroll", alignItems: "center", whiteSpace: "nowrap", width: "100%" }}>
                                        {
                                            data.apps && data.apps.map((app, index) => (
                                                <div key={`app${index}`} style={{ marginRight: 10, textAlign: "center", display: "inline-block", width: 120 }}>
                                                    <Grid container >
                                                        <Grid item md={12}>
                                                            <Typography
                                                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                                                size={12}
                                                                color={ColorSAP.neutral[100]}
                                                                style={{ textTransform: "uppercase" }}
                                                            >
                                                                {app.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={12}>
                                                            <Typography
                                                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                                                size={20}
                                                                color={ColorSAP.neutral[100]}
                                                            >
                                                                {app.versionName}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={12}>
                                                            <Typography
                                                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                                size={12}
                                                                color={ColorSAP.neutral[100]}
                                                            >
                                                                {app.stepName}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                            size={16}
                                            color={ColorSAP.neutral[100]}
                                        >
                                            {t("manage.noAppsInstalled")}
                                        </Typography>
                                    </div>
                                )
                            }
                        </Grid>
                        <Grid item md={1}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {/* <IconButton
                                            color="primary"
                                            icon={<Information />}
                                            onClick={handleOpenApps(data)}
                                            tooltip={t("manage.appsAndPhasesInfo")}
                                        /> */}
                                        <Tooltip title={t("manage.appsAndPhasesInfo")} placement="left">
                                            <div>
                                                <Info style={{ color: ColorSAP.primary[70], marginRight: 24, cursor: 'pointer' }} onClick={handleOpenApps(data)} />
                                            </div>
                                        </Tooltip>

                                        <Tooltip title={t("manage.deviceSettings")} placement="left">
                                            <div>
                                                <History style={{ color: ColorSAP.primary[70], cursor: 'pointer' }} onClick={handleOpen(data)} />
                                            </div>
                                        </Tooltip>
                                        {/* {hasDeviceSettings &&
                                            <IconButton
                                                color="primary"
                                                icon={<Settings />}
                                                onClick={handleOpenDeviceSettingDialog(data.alias, data.key)}
                                                tooltip={t("manage.deviceSettings")}
                                            />
                                        } */}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Separator type="n400" width={1} marginTop="20px" />
            </PaddedContainer>
        </Grid >
    )
}

const CustomChip = props => {
    const chipClasses = useChipStyle(props)

    const { isActive, data, onClick, onSelectVersion } = props

    const [anchorEl, setAnchorEl] = useState(null)
    const [isHover, setIsHover] = useState(false)

    const handleOpenVersionMenu = e => {
        e.stopPropagation()
        setAnchorEl(document.getElementById(`container-${data.name}`))
    }

    const handleOnMouseOver = () => setIsHover(true)
    const handleOnMouseOut = () => setIsHover(false)

    const menuItems = [
        {
            text: 'Alle Versionien',
            onClick: onSelectVersion(data, null)
        },
        ...data.versions.map(v => {
            return {
                text: v,
                onClick: onSelectVersion(data, v)
            }
        })
    ]

    return (
        <div
            className={chipClasses.customChipContainer}
            style={{
                background: isActive ? ColorSAP.primary[70] : 'transparent',
                height: 36,
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                cursor: 'pointer',
                userSelect: 'none',
                transition: 'all 0.3s ease'
            }}
            onClick={onClick(data)}
        >
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                    size={14}
                    color={isActive ? ColorSAP.neutral[0] : ColorSAP.neutral[100]}
                    style={{ padding: '0px 16px' }}
                >
                    {data.name}
                </Typography>
            </div>
            <div>
                <div
                    className={chipClasses.versionContainer}
                    onClick={handleOpenVersionMenu}
                    onMouseOver={handleOnMouseOver}
                    onMouseOut={handleOnMouseOut}
                >
                    <div
                        className={chipClasses.innerVersionContainer}
                        style={{
                            marginLeft: 8,
                            marginRight: 8,
                            display: "inline-flex",
                            alignItems: "center",
                            boxSizing: "border-box",
                            borderRadius: 64,
                        }}
                    >
                        <div id={`container-${data.name}`} style={{ position: 'absolute', marginRight: 12, bottom: -10, right: 140, width: 0, height: 0 }} />

                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                            size={14}
                            color={isActive ? ColorSAP.neutral[0] : ColorSAP.neutral[100]}
                            style={{ transition: 'all 0.3s linear', marginLeft: 16 }}
                        >
                            {data.selectedVersion ?? `Alle Versionen`}
                        </Typography>
                        <TriangleDown
                            style={{ transition: 'all 0.3s linear', marginRight: 16, marginLeft: 16, width: 16, height: 16, color: isActive ? ColorSAP.neutral[0] : ColorSAP.primary[70] }}
                        />
                    </div>
                </div>
            </div>

            <OverflowMenu
                anchorElement={anchorEl}
                setAnchorEl={setAnchorEl}
                menuItems={menuItems}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
            />
        </div>
    )
}

const FilterBar = props => {
    const { data, onToggleChip, onSelectVersion } = props

    const { t } = useTranslation()

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div style={{ width: '100%', backgroundColor: ColorSAP.neutral[15], boxSizing: 'border-box', padding: '16px 24px', display: 'flex', alignItems: 'flex-start', borderRadius: 16 }}>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={16}
                    color={ColorSAP.neutral[100]}
                    style={{ marginRight: 40 }}
                >
                    {t("manage.application")}
                </Typography>

                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 16 }}>
                    {data.map(app => (
                        <CustomChip
                            data={app}
                            isActive={app.isActive}
                            onClick={onToggleChip}
                            onSelectVersion={onSelectVersion}
                        />
                    ))}
                </div>
            </div>
        </Grid>
    )
}

const ManageGlobalDevice = props => {
    // const classes = useStyles(props)
    const history = useHistory()

    const { t } = useTranslation()

    const { devices, setDevices, userInfo, handleShowSnackbar, handleShowErrorSnackbar, deviceGroups, setDeviceGroups } = useContext(AppContext)

    const [searchableDevices, setSearchableDevices] = useState([])
    const [search, setSearch] = useState("")

    const [showFilter, setShowFilter] = useState(true)

    const [filters, setFilters] = useState([])
    const [applicationNamesFilter, setApplicationNamesFilter] = useState([])
    const [dateFilter, setDateFilter] = useState([])

    const [selectedDevice, setSelectedDevice] = useState(null)

    const [inspectedDeviceSettings, setInspectedDeviceSettings] = useState([])
    const [inspectedDeviceSettingType, setInspectedDeviceSettingType] = useState({ title: "Global", refKey: "" })

    const [userHistoryDialogOpen, setUserHistoryDialogOpen] = useState(false);
    const [appsPhasesDialogOpen, setAppsPhasesDialogOpen] = useState(false);
    const [registerNewDeviceDialogOpen, setRegisterNewDeviceDialogOpen] = useState(false);
    const [deviceGroupDialogOpen, setDeviceGroupDialogOpen] = useState(false);
    const [deviceSettingDialogOpen, setDeviceSettingDialogOpen] = useState(false);
    const [hasDeviceSettings, setHasDeviceSettings] = useState(false);

    const [isAsc, setIsAsc] = useState(true);

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [minDateRange, setMinDateRange] = useState(null)
    const [maxDateRange, setMaxDateRange] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0);

        if (userInfo) {
            fetchDevicesData();
            fetchDeviceGroupsData();
            initializeFilter();
        }
    }, [])

    useEffect(() => {
        setSearchableDevices(devices.sort((a, b) => {
            return a.lastAccess > b.lastAccess ? -1 : 1
        }));
    }, [devices])

    useEffect(() => {
        /** Search field */
        let filter = ""

        let newSearchableDevices = [...devices]

        let filteredDevicesFromSearch = []
        let filteredDevicesFromChip = []
        let filteredDevicesFromDateRange = []

        if (search !== "") {
            filteredDevicesFromSearch = newSearchableDevices.filter(inspectedDevice => {
                let temp = ""
                temp += ` ${inspectedDevice?.alias?.toLowerCase()}`
                temp += ` ${inspectedDevice?.deviceId?.toLowerCase()}`
                // temp += ` ${inspectedDevice.status.toLowerCase()}`
                // temp += ` ${getDeviceGroupNameByGroupId(deviceGroups, inspectedDevice.groupId).toLowerCase()}`
                inspectedDevice.apps && inspectedDevice.apps.forEach(app => {
                    temp += ` ${app?.name?.toLowerCase()}`
                    temp += ` ${app?.versionName?.toLowerCase()}`
                    temp += ` ${app?.stepName?.toLowerCase()}`
                })

                // const lc = inspectedFunc.name.toLowerCase();
                filter = search.toLowerCase();
                return temp.includes(filter)
            })
            newSearchableDevices = filteredDevicesFromSearch
        }

        /** Application name chip filter */
        if (applicationNamesFilter.filter(app => app.isActive).length > 0) {
            newSearchableDevices.forEach(device => {
                device.apps.forEach(app => {
                    applicationNamesFilter.forEach(appFilter => {
                        if (appFilter.isActive) {
                            if (appFilter.selectedVersion === null) {
                                if (appFilter.name === app.name) {
                                    filteredDevicesFromChip.push(device)
                                }
                            }
                            else {
                                if (appFilter.name === app.name && appFilter.selectedVersion === app.versionName) {
                                    filteredDevicesFromChip.push(device)
                                }
                            }
                        }
                    })
                })
            })

            newSearchableDevices = filteredDevicesFromChip
        }

        /** Daterange filter */
        if (dateFilter.length === 2) {
            newSearchableDevices.forEach(device => {
                if (
                    moment(moment(device.createdOn).format('YYYY-MM-DD')).isSameOrAfter(moment(dateFilter[0]).format('YYYY-MM-DD')) &&
                    moment(moment(device.createdOn).format('YYYY-MM-DD')).isSameOrBefore(moment(dateFilter[1]).format('YYYY-MM-DD'))
                ) {
                    filteredDevicesFromDateRange.push(device)
                }
            })

            newSearchableDevices = filteredDevicesFromDateRange
        }

        if (search === "" && applicationNamesFilter.filter(app => app.isActive).length === 0 && dateFilter.length === 0) {
            newSearchableDevices = [...devices]
        }

        setSearchableDevices(newSearchableDevices)
    }, [search, applicationNamesFilter, dateFilter])

    const fetchDevicesData = () => {
        ManageDeviceService.getDevices(userInfo.key)
            .then(response => {
                const data = response.data;
                setApplicationNamesFilter(initializeDeviceFilter(data));
                setDevices(data);
                setMinDateRange(moment(new Date(Math.min.apply(null, data.map(d => new Date(d.createdOn))))))
                // setMaxDateRange(moment(new Date(Math.max.apply(null, data.map(d => new Date(d.createdOn))))))
                setMaxDateRange(moment().add('days', 1))
                // setStartDate(moment(new Date(Math.min.apply(null, data.map(d => new Date(d.createdOn))))))
                // setEndDate(moment(new Date(Math.max.apply(null, data.map(d => new Date(d.createdOn))))))
            })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const fetchDeviceGroupsData = () => {
        ManageDeviceService.getDeviceGroups(userInfo.key)
            .then(response => {
                const data = response.data;
                setDeviceGroups(data);
            })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const fetchInspectedDeviceSettingsData = (refKey) => {
        ManageDeviceService.getDeviceSettings(userInfo.key, refKey)
            .then(response => {
                const data = response.data;
                setInspectedDeviceSettings(data);
            })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const statusFilter = [
        {
            id: 'ACTIVE',
            label: t("manage.active")
        },
        {
            id: 'INACTIVE',
            label: t("manage.inactive")
        },
        {
            id: 'IN DEBUG',
            label: t("manage.inDebug")
        },
        {
            id: 'ARCHIVED',
            label: t("manage.archived")
        }
    ]

    const initializeFilter = () => {
        const filterData = []

        filterData.push({
            parameterName: t("manage.status"),
            filters: statusFilter
        })

        setFilters(filterData)
    }

    const handleToggleChipFilter = data => () => {
        const newApplicationNamesFilter = [...applicationNamesFilter]
        newApplicationNamesFilter.forEach(app => {
            if (app.name === data.name) {
                app.isActive = !app.isActive
            }
        })
        setApplicationNamesFilter(newApplicationNamesFilter)
    }

    const handleSelectVersion = (data, version) => () => {
        const newApplicationNamesFilter = [...applicationNamesFilter]
        newApplicationNamesFilter.forEach(app => {
            if (app.name === data.name) {
                app.selectedVersion = version
            }
        })
        setApplicationNamesFilter(newApplicationNamesFilter)
    }

    const handleChangeStatus = (status, device) => {
        const deviceToBeUpdated = JSON.parse(JSON.stringify(device));
        const newRowData = [...searchableDevices];

        deviceToBeUpdated.status = status;
        newRowData.forEach(data => {
            if (data.key === device.key) data.status = status;
        })

        ManageDeviceService.updateDevice(userInfo.key, device.key, deviceToBeUpdated)
            .then(response => setDevices(newRowData))
            .catch(error => handleShowErrorSnackbar(error));
    }

    const handleChangeDeviceGroup = (deviceGroupId, device) => () => {
        const deviceToBeUpdated = JSON.parse(JSON.stringify(device));
        const newRowData = [...searchableDevices];

        deviceToBeUpdated.groupId = deviceGroupId;
        newRowData.forEach(data => {
            if (data.key === device.key) data.groupId = deviceGroupId;
        })

        ManageDeviceService.updateDevice(userInfo.key, device.key, deviceToBeUpdated)
            .then(response => setDevices(newRowData))
            .catch(error => handleShowErrorSnackbar(error));
    }

    const handleDeviceGroupDialogPersistence = (action, deviceGroup, deviceGroups) => {
        if (action === "EDIT") {
            ManageDeviceService.updateDeviceGroup(userInfo.key, deviceGroup.key, deviceGroup)
                .then(res => {
                    setDeviceGroups(deviceGroups);
                    handleShowSnackbar({
                        message: t("manage.objectEdited", { object: deviceGroup.name }),
                        autoHideDuration: 3000,
                        icon: <Edit />,
                        showButton: true,
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));
        }
        if (action === "CREATE") {
            ManageDeviceService.addDeviceGroup(userInfo.key, deviceGroup)
                .then(res => {
                    fetchDeviceGroupsData();
                    handleShowSnackbar({
                        message: t("manage.objectCreated", { object: deviceGroup.name }),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));
        }
        if (action === "DELETE") {
            const newDeviceGroups = JSON.parse(JSON.stringify(deviceGroups))
            const tempDeviceGroups = JSON.parse(JSON.stringify(newDeviceGroups))

            newDeviceGroups.forEach((p, j) => {
                if (p.key === deviceGroup.key) {
                    newDeviceGroups.splice(j, 1)
                }
            })

            ManageDeviceService.deleteDeviceGroup(userInfo.key, deviceGroup.key)
                .then(res => {
                    setDeviceGroups(newDeviceGroups);
                    handleShowSnackbar({
                        message: t("manage.objectDeleted", { object: deviceGroup.name }),
                        autoHideDuration: 5000,
                        icon: <Delete />,
                        showButton: true,
                        buttonLabel: t("manage.undo"),
                        onUndo: () => {
                            ManageDeviceService.undeleteDeviceGroup(userInfo.key, deviceGroup.key)
                                .then(res => setDeviceGroups(tempDeviceGroups))
                                .catch(error => handleShowErrorSnackbar(error));
                        }
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));
        }
    }

    const handleDeviceSettingDialogPersistence = (action, refKey, deviceSetting, deviceSettings) => {
        if (action === "EDIT") {
            ManageDeviceService.updateDeviceSetting(userInfo.key, deviceSetting.key, deviceSetting)
                .then(res => {
                    setInspectedDeviceSettings(deviceSettings);
                    handleShowSnackbar({
                        message: t("manage.objectEdited", { object: deviceSetting.name }),
                        autoHideDuration: 3000,
                        icon: <Edit />,
                        showButton: true,
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));
        }
        if (action === "CREATE") {
            ManageDeviceService.addDeviceSetting(userInfo.key, deviceSetting)
                .then(res => {
                    fetchInspectedDeviceSettingsData(refKey);
                    handleShowSnackbar({
                        message: t("manage.objectCreated", { object: deviceSetting.name }),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));
        }
        if (action === "DELETE") {
            const newDeviceSettings = JSON.parse(JSON.stringify(deviceSettings))
            const tempDeviceSettings = JSON.parse(JSON.stringify(newDeviceSettings))

            newDeviceSettings.forEach((p, j) => {
                if (p.key === deviceSetting.key) {
                    newDeviceSettings.splice(j, 1)
                }
            })

            ManageDeviceService.deleteDeviceSetting(userInfo.key, deviceSetting.key)
                .then(res => {
                    setInspectedDeviceSettings(newDeviceSettings);
                    handleShowSnackbar({
                        message: t("manage.objectDeleted", { object: deviceSetting.name }),
                        autoHideDuration: 5000,
                        icon: <Delete />,
                        showButton: true,
                        buttonLabel: t("manage.undo"),
                        onUndo: () => {
                            ManageDeviceService.undeleteDeviceSetting(userInfo.key, deviceSetting.key)
                                .then(res => setInspectedDeviceSettings(tempDeviceSettings))
                                .catch(error => handleShowErrorSnackbar(error));
                        }
                    });
                })
                .catch(error => handleShowErrorSnackbar(error));
        }
    }

    const getDeviceGroupNameByGroupId = (deviceGroups, groupId) => {
        if (groupId === null) {
            return t('manage.unassigned');
        } else {
            const filteredDeviceGroup = deviceGroups.filter(deviceGroup => deviceGroup.id === groupId);
            return filteredDeviceGroup[0] ? filteredDeviceGroup[0].name : t('manage.unassigned');
        }
    }

    const handleChangeProcessSearch = e => {
        setSearch(e.target.value)
    }

    const handleSortSearchableDevice = (key, isAscending) => {
        const _searchableDevices = JSON.parse(JSON.stringify(searchableDevices))

        const sortedArr = _searchableDevices.sort((a, b) => {
            return (isAscending ? (b[key] > a[key] ? -1 : 1) : (a[key] > b[key] ? -1 : 1))
        });

        setSearchableDevices(sortedArr);
    };

    const handleOpenAppsPhasesDialog = (device) => () => {
        setSelectedDevice(device);
        setAppsPhasesDialogOpen(true);
    }
    const handleCloseAppsPhasesDialog = () => setAppsPhasesDialogOpen(false);

    const handleOpenUserHistoryDialog = (device) => () => {
        setSelectedDevice(device);
        setUserHistoryDialogOpen(true);
    }

    const handleCloseUserHistoryDialog = () => setUserHistoryDialogOpen(false);

    const handleOpenRegisterNewDeviceDialog = () => {
        setRegisterNewDeviceDialogOpen(true)
    }

    const handleCloseRegisterNewDeviceDialog = () => setRegisterNewDeviceDialogOpen(false);

    const handleOpenDeviceGroupDialog = () => {
        setDeviceGroupDialogOpen(true)
    }

    const handleCloseDeviceGroupDialog = () => setDeviceGroupDialogOpen(false);

    const handleOpenDeviceSettingDialog = (title, refKey) => () => {
        setDeviceSettingDialogOpen(true)
        setInspectedDeviceSettingType({ title: title, refKey: refKey })
        fetchInspectedDeviceSettingsData(refKey)
    }

    const handleCloseDeviceSettingDialog = () => {
        setInspectedDeviceSettings([])
        setDeviceSettingDialogOpen(false)
    }

    const handleToggleFilter = () => {
        setShowFilter(!showFilter)
    }

    const handleSubmitRegisterNewDeviceDialog = (device) => {
        ManageDeviceService.registerDevice(userInfo.key, device)
            .then(response => {
                fetchDevicesData();
                handleShowSnackbar({
                    message: t("manage.objectCreated", { object: device.name }),
                    autoHideDuration: 3000,
                    icon: <Plus />,
                    showButton: true,
                });
            })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const handleOnDateChange = (start, end) => {
        if (start && end === null) {
            const newDateFilter = []
            newDateFilter.push(moment(start).startOf('days'))
            newDateFilter.push(moment(start).add(1, 'days'))
            setDateFilter(newDateFilter)
        }
        if (start && end) {
            const newDateFilter = []
            newDateFilter.push(start)
            newDateFilter.push(end)
            setDateFilter(newDateFilter)
        }
        setStartDate(start)
        setEndDate(end)
    }

    const handleClearDateRange = () => {
        setDateFilter([])
        setStartDate(null)
        setEndDate(null)
    }

    const BREADCRUMB_STACK = [
        {
            icon: <Global />,
            label: t("breadcrumb.appManagement"),
            typeOf: "",
            onClick: () => {
                history.push(MANAGE_MAIN_DASHBOARD_PATH)
            }
        },
        {
            icon: <Device />,
            label: t("breadcrumb.devices"),
            typeOf: "",
            onClick: () => { }
        }
    ]

    return (
        <>
            {/* <div style={{ paddingBottom: 200 }}> */}
            <Grid container spacing={2}>
                <div style={{ height: 30, width: "100%" }} />
                {/* <Grid item xs={12} sm={12} md={12}>
                    <div
                        id="breadcrumb-container"
                        style={{
                            width: "100%",
                            position: "fixed",
                            top: 64,
                            paddingTop: 24,
                            paddingBottom: 24,
                            background: Color.neutral[0],
                            zIndex: 1200,
                        }}
                    >
                        <PaddedContainer>
                            <Breadcrumb stacks={BREADCRUMB_STACK} />
                        </PaddedContainer>
                    </div>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                    <PaddedContainer>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: 12,
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: 350
                                    }}
                                >
                                    <Form
                                        placeholder={t(
                                            "manage.searchDeviceNoOrAlias"
                                        )}
                                        search
                                        value={search}
                                        onChange={handleChangeProcessSearch}
                                    />
                                </div>
                                <Checkbox
                                    name="showFilter"
                                    label={t(`manage.showFilter`)}
                                    value={showFilter}
                                    checked={showFilter}
                                    onChange={handleToggleFilter}
                                    style={{ marginLeft: 24 }}
                                />
                            </div>
                            {/* <Button
                                    style={{ outline: "none" }}
                                    icon={<ShoppingCart />}
                                    alignIcon="left"
                                    onClick={handleOpenRegisterNewDeviceDialog}
                                >
                                    {t("manage.registerNewDevice")}
                                </Button> */}
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                paddingBottom: 12,
                            }}
                        >
                            {/* <CalendarDateRangePicker
                                width={330}
                                start={startDate}
                                end={endDate}
                                minDate={minDateRange}
                                maxDate={maxDateRange}
                                calendarRef={document.getElementById('root')}
                                onChange={handleOnDateChange}
                            /> */}
                            {/* <TertiaryButton
                                theme="light"
                                onClick={handleClearDateRange}
                                style={{ marginLeft: 24 }}
                            >
                                {t("manage.clearDate")}
                            </TertiaryButton> */}
                        </div>
                        {showFilter && (
                            <FilterBar
                                data={applicationNamesFilter}
                                onToggleChip={handleToggleChipFilter}
                                onSelectVersion={handleSelectVersion}
                            />
                        )}
                        {/* <div style={{ width: '50%' }}>
                            {hasDeviceSettings &&
                                <Button
                                    icon={<Device />}
                                    alignIcon="right"
                                    onClick={handleOpenDeviceSettingDialog("Global", userInfo.key)}
                                    type={"secondary"}
                                    style={{ marginTop: 12, marginRight: 50 }}
                                >
                                    {t("manage.globalDeviceSettings")}
                                </Button>
                            }
                            {hasDeviceSettings &&
                                <Button
                                    icon={<Settings />}
                                    alignIcon="right"
                                    onClick={handleOpenDeviceGroupDialog}
                                    type={"secondary"}
                                    style={{ marginTop: 12 }}
                                >
                                    {t("manage.groupSettings")}
                                </Button>
                            }
                        </div> */}
                    </PaddedContainer>
                </Grid>
                <TableColumn handleSort={handleSortSearchableDevice} />
                {searchableDevices.length > 0 ? (
                    <>
                        {searchableDevices.map((data, index) => (
                            <TableRow
                                key={`device${index}`}
                                data={data}
                                deviceGroups={deviceGroups}
                                handleOpen={handleOpenUserHistoryDialog}
                                handleOpenApps={handleOpenAppsPhasesDialog}
                                handleChangeStatus={handleChangeStatus}
                                handleChangeDeviceGroup={handleChangeDeviceGroup}
                                handleOpenDeviceSettingDialog={handleOpenDeviceSettingDialog}
                            />
                        ))}
                    </>
                ) : (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ textAlign: "center" }}
                    >
                        <Device
                            style={{
                                width: 200,
                                height: 200,
                                color: Color.neutral[100],
                            }}
                        />
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                            size={36}
                            color={Color.neutral[400]}
                            style={{ paddingTop: 15 }}
                        >
                            {t("manage.noDeviceFound")}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {/* </div> */}

            <UserHistoryDialog
                open={userHistoryDialogOpen}
                handleClose={handleCloseUserHistoryDialog}
                device={selectedDevice}
            />
            <AppPhasesDialog
                open={appsPhasesDialogOpen}
                handleClose={handleCloseAppsPhasesDialog}
                device={selectedDevice}
            />
            <RegisterNewDeviceDialog
                open={registerNewDeviceDialogOpen}
                onClose={handleCloseRegisterNewDeviceDialog}
                handleSubmit={handleSubmitRegisterNewDeviceDialog}
            />
            <DeviceGroupDialog
                open={deviceGroupDialogOpen}
                onClose={handleCloseDeviceGroupDialog}
                handlePersistence={handleDeviceGroupDialogPersistence}
                handleOpenDeviceSettingDialog={handleOpenDeviceSettingDialog}
            />
            <DeviceSettingDialog
                open={deviceSettingDialogOpen}
                onClose={handleCloseDeviceSettingDialog}
                deviceSettingType={inspectedDeviceSettingType}
                deviceSettings={inspectedDeviceSettings}
                handlePersistence={handleDeviceSettingDialogPersistence}
            />
        </>
    );
}

export default ManageGlobalDevice