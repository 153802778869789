import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** UMBUCHUNG IMAGES */
import Umbuchung_Einfuehrung1 from '../../assets/img_en/umbuchung/Einfuehrung/1.png'
import Umbuchung_Einfuehrung2 from '../../assets/img_en/umbuchung/Einfuehrung/2.png'
import Umbuchung_Einfuehrung3 from '../../assets/img_en/umbuchung/Einfuehrung/3.png'

import Umbuchung_Bestandsart1 from '../../assets/img_en/umbuchung/Bestandsart/1.png'
import Umbuchung_Bestandsart2 from '../../assets/img_en/umbuchung/Bestandsart/2.png'
import Umbuchung_Bestandsart3 from '../../assets/img_en/umbuchung/Bestandsart/3.png'
import Umbuchung_Bestandsart4 from '../../assets/img_en/umbuchung/Bestandsart/4.png'

import Umbuchung_Bestandswechsel1 from '../../assets/img_en/umbuchung/Bestandswechsel/1.png'
import Umbuchung_Bestandswechsel2 from '../../assets/img_en/umbuchung/Bestandswechsel/2.png'
import Umbuchung_Bestandswechsel3 from '../../assets/img_en/umbuchung/Bestandswechsel/3.png'
import Umbuchung_Bestandswechsel4 from '../../assets/img_en/umbuchung/Bestandswechsel/4.png'

import Umbuchung_Material1 from '../../assets/img_en/umbuchung/Material/1.png'
import Umbuchung_Material2 from '../../assets/img_en/umbuchung/Material/2.png'
import Umbuchung_Material3 from '../../assets/img_en/umbuchung/Material/3.png'
import Umbuchung_Material4 from '../../assets/img_en/umbuchung/Material/4.png'

import Umbuchung_Sonderbestandskennzeichen1 from '../../assets/img_en/umbuchung/Sonderbestandskennzeichen/1.png'
import Umbuchung_Sonderbestandskennzeichen2 from '../../assets/img_en/umbuchung/Sonderbestandskennzeichen/2.png'
import Umbuchung_Sonderbestandskennzeichen3 from '../../assets/img_en/umbuchung/Sonderbestandskennzeichen/3.png'
import Umbuchung_Sonderbestandskennzeichen4 from '../../assets/img_en/umbuchung/Sonderbestandskennzeichen/4.png'

import Umbuchung_Bestandsinfo1 from '../../assets/img_en/umbuchung/Bestandsinfo/1.png'

export const UmbuchungImagesEN = [
    new ProcessImageContainer(
        `P_UMBUCHUNG_EINFUEHRUNG`,
        [
            new ProcessImage('Stock Type Change', Umbuchung_Einfuehrung1),
            new ProcessImage('Details', Umbuchung_Einfuehrung2),
            new ProcessImage('Menu Front-defined Change', Umbuchung_Einfuehrung3)
        ]
    ),
    new ProcessImageContainer(
        `P_UMBUCHUNG_ZWISCHEN_BESTANDSARTEN`,
        [
            new ProcessImage('Material', Umbuchung_Bestandsart1),
            new ProcessImage('Optional Storage Location', Umbuchung_Bestandsart2),
            new ProcessImage('Stock Type Change', Umbuchung_Bestandsart3),
            new ProcessImage('Details', Umbuchung_Bestandsart4)
        ]
    ),
    new ProcessImageContainer(
        `P_BESTANDSWECHSEL_VORDEFINIERT_ZB_Q_AN_FREI_GESPERRT_AN_FREI`,
        [
            new ProcessImage('Menu Front-defined Change', Umbuchung_Bestandswechsel1),
            new ProcessImage('Material', Umbuchung_Bestandswechsel2),
            new ProcessImage('Optional Storage Location', Umbuchung_Bestandswechsel3),
            new ProcessImage('Details', Umbuchung_Bestandswechsel4)
        ]
    ),
    new ProcessImageContainer(
        `P_UMBUCHUNG_MATERIAL_AN_MATERIAL`,
        [
            new ProcessImage('From Material', Umbuchung_Material1),
            new ProcessImage('Optional Storage Location', Umbuchung_Material2),
            new ProcessImage('According to Material', Umbuchung_Material3),
            new ProcessImage('Details', Umbuchung_Material4)
        ]
    ),
    new ProcessImageContainer(
        `P_BEHANDLUNG_VON_SONDERBESTAENDEN`,
        [
            new ProcessImage('From Material', Umbuchung_Sonderbestandskennzeichen1),
            new ProcessImage('Optional Storage Location', Umbuchung_Sonderbestandskennzeichen2),
            new ProcessImage('According to Material', Umbuchung_Sonderbestandskennzeichen3),
            new ProcessImage('Details', Umbuchung_Sonderbestandskennzeichen4)
        ]
    ),
    new ProcessImageContainer(
        `P_EINSTIEG_UEBER_BESTANDSINFO`,
        [
            new ProcessImage('Menu Front-defined Change', Umbuchung_Bestandsinfo1)
        ]
    )
]