export const F_AUSLAGERUNG = `F_AUSLAGERUNG`
export const F_BESTANDSINFO = `F_BESTANDSINFO`
export const F_BESTELLERFASSUNG = `F_BESTELLERFASSUNG`
export const F_EINLAGERUNG = `F_EINLAGERUNG`
export const F_VERPACKUNG = `F_VERPACKUNG`
export const F_KOMMISSIONIERUNG = `F_KOMMISSIONIERUNG`
export const F_UMBUCHUNG = `F_UMBUCHUNG`
export const F_UMLAGERUNG = `F_UMLAGERUNG`
export const F_VERLADUNG = `F_VERLADUNG`
export const F_WARENAUSGANG_EXTERN = `F_WARENAUSGANG_EXTERN`
export const F_WARENAUSGANG_INTERN = `F_WARENAUSGANG_INTERN`
export const F_WARENEINGANG = `F_WARENEINGANG`

export const F_INVENTUR = `F_INVENTUR`
export const F_KANBAN = `F_KANBAN`
export const F_PRODUKTIONSVERSORGUNG = `F_PRODUKTIONSVERSORGUNG`
export const F_QUALITAETSKONTROLLE = `F_QUALITAETSKONTROLLE`
export const F_RUECKMELDUNG_PRODUKTION = `F_RUECKMELDUNG_PRODUKTION`

export const F_SONDERFUNKTIONEN_ERWEITERUNGEN = `F_SONDERFUNKTIONEN_ERWEITERUNGEN`