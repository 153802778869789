import axios from 'axios';
import { USER_API_BASE_APPS_URL, getApiUrl } from '../../Constants/Api';

export class UpdateManagementService {
    getDeploySteps = (clientKey, appKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/deploysteps`);
    }

    getVersionSteps = (clientKey, appKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/appversions`);
    }
    updateVersionStep = (clientKey, appKey, versionStep) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/appversions/${versionStep.key}`, { versionStep });
    }

    updateAppVersionStatus = (clientKey, appKey, versionKey, status) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/versions/${versionKey}/updatestatus`, { status });
    }
    deleteAppVersion = (clientKey, appKey, versionKey) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/versions/${versionKey}`);
    }
    undeleteAppVersion = (clientKey, appKey, versionKey) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/versions/${versionKey}/undelete`);
    }

    enableAllDevices = (clientKey, appKey, versionKey) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/appversions/${versionKey}/devicerestrictions/enableall`);
    }
    disableAllDevices = (clientKey, appKey, versionKey) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/appversions/${versionKey}/devicerestrictions/disableall`);
    }
}

export default new UpdateManagementService();