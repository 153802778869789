// Constants
import { Color, ColorSAP, FONT_FAMILY } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        input: {
            position: "absolute",
            opacity: 0,
            cursor: "pointer",
            height: 0,
            width: 0
        },
        label: {
            // display: "block",
            position: "relative",
            paddingLeft: 40,
            lineHeight: "17px",
            cursor: "pointer",
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
            fontSize: 14,
            "&::before": {
                boxSizing: "border-box",
                position: "absolute",
                content: "''",
                top: 0,
                left: 0,
                height: 20,
                width: 20,
                padding: 2,
                borderRadius: 4,
                border: `1.5px solid ${ColorSAP.primary[40]}`,
                background: 'transparent'
            },
            "&:hover": {
                "&::before": {
                    background: ColorSAP.primary[20]
                }
            },
            "$input:checked ~ &": {
                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM,
                "&::before": {
                    border: `1.5px solid ${ColorSAP.primary[70]}`,
                },
                "& $checkmark": {
                    // display: "block"
                }
            },
            "&$disabled": {
                fontFamily: "roboto-medium",
                color: Color.neutral[300],
                "&::before": {
                    backgroundColor: Color.neutral[300],
                    border: "none"
                },
                "&:hover::before": {
                    backgroundColor: Color.neutral[300],
                    border: "none"
                }
            },
            "&$error": {
                "&::before": {
                    border: `2px solid ${Color.danger[400]}`,
                    color: Color.neutral[900],
                }
            }
        },
        checkmark: {
            position: "absolute",
            top: 2,
            left: 2,
            zIndex: 1,
            display: 'none',
            color: ColorSAP.primary[70],
            "&$disabled": {
                color: ColorSAP.neutral[40]
            }
        },
        disabled: {},
        error: {}
    })
);