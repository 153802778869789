import { ColorSAP, FONT_FAMILY } from "@commsult/ontego-ui";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        chipContainer: {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            width: 'fit-content',
            borderRadius: 24,
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD,
            fontSize: 14,
            "& svg": {
                width: 16,
                height: 16,
                marginRight: 6
            }
        }
    })
);