import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import {
    DialogBox,
    Typography,
    FONT_FAMILY,
    Color,
    Radio,
    Form,
    // SearchableDropdown,
    Dropdown,
    Button
} from '@commsult/ontego-ui';
import moment from 'moment';

import { AppContext } from '../../App';

import ManageDeviceService from '../../Pages/Manage/ManageGlobalDeviceService';
import ManageBlacklistedDeviceService from '../../Pages/Manage/ManageBlacklistedDeviceService';

const BlacklistedDeviceDialog = props => {
    const {
        open,
        handleClose,
        type,
        blacklistedDevice,
        setBlacklistedDevice,
        setInitialBlacklistedDevice,
        editData
    } = props

    const [selectedDevice, setSelectedDevice] = useState("")
    const [deviceId, setDeviceId] = useState("")
    const [deviceAlias, setDeviceAlias] = useState("")
    const [description, setDescription] = useState("")
    const [deviceType, setDeviceType] = useState("")
    const [period, setPeriod] = useState("")

    const { handleShowErrorSnackbar, handleShowSnackbar, devicesWithoutClient, setDevicesWithoutClient, userInfo } = useContext(AppContext)

    const { t } = useTranslation();

    useEffect(() => {
        fetchDevicesData();
    }, [])

    useEffect(() => {
        if (editData) {
            const created = moment(editData.createdOn, "YYYY-MM-DDTHH:mm:ss.SSSZ");
            const period = moment(editData.period, "YYYY-MM-DDTHH:mm:ss.SSSZ").add(1, 'days');
            const remainingWeeks = editData.period && editData.createdOn ? period.diff(created, 'weeks') : "";
            setSelectedDevice(editData.deviceId + "," + editData.alias)
            setDescription(editData.description)
            setDeviceType(editData.period ? "Customer" : "commsult")
            setPeriod(remainingWeeks + " " + t(`manage.week`))
        }
    }, [editData])

    useEffect(() => {
        if (selectedDevice) {
            const splitSelected = selectedDevice.split(",")
            setDeviceId(splitSelected[0])
            setDeviceAlias(splitSelected[1])
        }
    }, [selectedDevice])

    const deviceTypeRadio = [
        { value: "commsult", label: "commsult", key: "commsult" },
        { value: "Customer", label: t(`manage.customer`), key: "Customer" }
    ]

    const fetchDevicesData = () => {
        ManageDeviceService.getAllDevices()
            .then(response => {
                const data = response.data;
                setDevicesWithoutClient(data);
            })
            .catch(error => handleShowErrorSnackbar(error));
    }

    const deviceList = () => {
        const menus = [];
        if (devicesWithoutClient && devicesWithoutClient.length > 0) {
            devicesWithoutClient.forEach((device) => {
                menus.push({
                    key: device.key,
                    label: device.alias ? device.alias + " (" + device.deviceId + ")" : device.deviceId,
                    value: device.deviceId + "," + device.alias
                })
            })
            if (editData) {
                const editedDevice = { value: editData.deviceId + "," + editData.alias, label: editData.alias ? editData.alias + " (" + editData.deviceId + ")" : editData.deviceId }
                menus.push(editedDevice)
            }
        }
        return menus;
    }

    const periodList = [
        {
            key: "1",
            text: "1 " + t(`manage.week`),
            onClick: () => setPeriod("1 " + t(`manage.week`))
        },
        {
            key: "2",
            text: "2 " + t(`manage.week`),
            onClick: () => setPeriod("2 " + t(`manage.week`))
        },
        {
            key: "3",
            text: "3 " + t(`manage.week`),
            onClick: () => setPeriod("3 " + t(`manage.week`))
        },
        {
            key: "4",
            text: "4 " + t(`manage.week`),
            onClick: () => setPeriod("4 " + t(`manage.week`))
        }
    ]

    const closeDialog = () => {
        handleClose()
        setSelectedDevice()
        setDescription("")
        setDeviceType("")
        setPeriod("")
    }

    const changeDeviceType = (e) => {
        setDeviceType(e.target.value)
        setPeriod("")
    }

    const handleAddBlacklistedDevice = async () => {
        const requestApiData = {
            deviceId: deviceId,
            alias: deviceAlias,
            period: period.length > 0 ? moment().add(parseInt(period), 'weeks').format() : "",
            description,
        }
        try {
            const response = await ManageBlacklistedDeviceService.addBlacklistedDevice(requestApiData)
            if (response.status === 200) {
                handleShowSnackbar({
                    message: t(`manage.blacklistAdded`),
                    autoHideDuration: 5000,
                    showButton: true
                });
                const data = [{
                    ...response.data,
                    description,
                    period: period.length > 0 ? moment().add(parseInt(period), 'weeks').format("YYYY-MM-DDTHH:mm:ss.SSSZ") : "",
                    remainingDay: parseInt(period) * 7
                }];
                setBlacklistedDevice([...data, ...blacklistedDevice])
                setInitialBlacklistedDevice([...data, ...blacklistedDevice])
                const filteredDevices = devicesWithoutClient.filter((dev) => {
                    return dev.alias !== deviceAlias && dev.deviceId !== deviceId;
                });
                setDevicesWithoutClient(filteredDevices)
                closeDialog();
            }
        } catch (error) {
            handleShowErrorSnackbar(error)
        }
    }

    const handleEditBlacklistedDevice = async () => {
        const requestApiData = {
            period: period.length > 0 ? moment().add(parseInt(period), 'weeks').format() : "",
            description,
        }
        try {
            const response = await ManageBlacklistedDeviceService.updateBlacklistedDevice(editData.id, requestApiData)
            if (response.status === 200) {
                handleShowSnackbar({
                    message: t(`manage.blacklistEdited`),
                    autoHideDuration: 5000,
                    showButton: true
                });
                const formatedPeriod = period.length > 0 ? moment().add(parseInt(period), 'weeks') : ""
                const data = [{
                    ...response.data,
                    description,
                    period: period.length > 0 ? moment(formatedPeriod, "YYYY-MM-DDTHH:mm:sssSS").format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z" : "",
                    remainingDay: parseInt(period) * 7
                }];
                const blacklistedResult = blacklistedDevice.map(obj => data.find(o => o.id === obj.id) || obj);
                setBlacklistedDevice(blacklistedResult)
                setInitialBlacklistedDevice(blacklistedResult)
                closeDialog();
            }
        } catch (error) {
            handleShowErrorSnackbar(error)
        }
    }

    const disabledButtonAdd = selectedDevice && deviceType.length > 0 && (deviceType === "commsult" || (deviceType === "Customer" && period.length > 0)) ? false : true

    return (
        <DialogBox
            open={open}
            handleClose={closeDialog}
            title={type === "add" ? t(`manage.addNewBlacklist`) : t(`manage.editBlacklist`)}
            content={
                <div style={{ width: 1000, height: '60vh', marginTop: 10, overflowX: 'hidden' }}>
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                        <Grid item md={3}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={20}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.device`)} ({t(`manage.deviceId`)})
                            </Typography>
                        </Grid>
                        {/* <Grid item md={7}>
                            <SearchableDropdown
                                menu={deviceList()}
                                value={selectedDevice}
                                setValue={setSelectedDevice}
                                width="100%"
                                placeholder={t(`manage.select`)}
                                disabled={editData?.deviceId.length > 0 ? true : false}
                            />
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                        <Grid item md={3}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={20}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.description`)}
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <Form
                                placeholder={t(`manage.description`)}
                                value={description}
                                style={{ width: "100%" }}
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                rows="6"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                        <Grid item md={3}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={20}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.deviceType`)}
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            {deviceTypeRadio.map((radio, index) => (
                                <Radio key={`radio${index}`} name="deviceType" value={radio.value} label={radio.label} style={{ marginTop: 10 }} checked={deviceType === radio.value ? true : false} onChange={(e) => changeDeviceType(e)} />
                            ))}
                        </Grid>
                    </Grid>
                    {deviceType === "Customer" && (
                        <Grid container spacing={2} style={{ marginTop: 20 }}>
                            <Grid item md={3}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                    size={20}
                                    color={Color.neutral[800]}
                                >
                                    {t(`manage.period`)}
                                </Typography>
                            </Grid>
                            <Grid item md={7}>
                                <Dropdown
                                    menu={periodList}
                                    selected={period}
                                    type="SELECTION"
                                    placeholder={t(`manage.period`)}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {editData
                        ? <Button
                            onClick={() => handleEditBlacklistedDevice()}
                            style={{ position: "absolute", right: 24, bottom: 24 }}
                            disabled={disabledButtonAdd}
                        >
                            {t(`manage.save`)}
                        </Button>
                        : <Button
                            onClick={() => handleAddBlacklistedDevice()}
                            style={{ position: "absolute", right: 24, bottom: 24 }}
                            disabled={disabledButtonAdd}
                        >
                            {t(`manage.addNewBlacklist`)}
                        </Button>}
                </div>
            }
        />
    )
}

export default BlacklistedDeviceDialog;