// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
    createStyles({
        rootContainer: {
            flexGrow: 1,
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            position: "relative",
            marginTop: -80
        },
        loginContainer: {
            [theme.breakpoints.between('1280', '1440')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            },
            [theme.breakpoints.up('1440')]: {
                paddingLeft: "15%",
                paddingRight: "15%",
            },
            flexGrow: 1,
            boxSizing: "border-box",
            maxHeight: "100%",
        },
        loginBottomAction: {
            position: "absolute",
            bottom: 50
        }
    })
)