// Material-UI Styles
import { ColorSAP } from '@commsult/ontego-ui';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
export const useStyles = makeStyles(theme =>
    createStyles({
        "@keyframes infinite-rotate": {
            "0%": {
                transform: 'rotate(0deg)',
            },
            "100%": {
                transform: 'rotate(360deg)'
            }
        },
        loading: {
            "& svg": {
                animation: '$infinite-rotate 1s linear infinite'
            }
        }
    })
);