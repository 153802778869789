import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../App';

import { useHistory } from 'react-router-dom';

import { useStyles } from './ManageMainDashboardStyles';

import { Checkbox, FilterableChip, Edit, Password, Plus, Delete, Breadcrumb, Dropdown, Typography, FONT_FAMILY, Color, Global, User, Separator, Button, Form, Switch, DeletableChip, USER_TYPE, OntegoLogoDefault, SAPLogoDefault, IconButton, Key, Android, IOS } from '@commsult/ontego-ui';

import { PaddedContainer } from '../../App'

import Grid from '@material-ui/core/Grid';
import { MANAGE_MAIN_DASHBOARD_PATH } from '../../Constants/Path';

import SigningKeyDialog from '../../Dialogs/SigningKeyDialog/SigningKeyDialog';

import ManageGlobalSigningKeysService from './ManageGlobalSigningKeysService';
import SigningKey from '../../Models/SigningKey';
import { useTranslation } from 'react-i18next';

const deviceTypeFilter = [
    {
        id: 'android',
        label: 'Android'
    },
    {
        id: 'ios',
        label: 'iOS'
    }
]

const TableHeader = props => {

    const { t } = useTranslation();

    return (
        <Grid item xs={12} sm={12} md={12}>
            <PaddedContainer>
                <div style={{ marginTop: 36 }}>
                    <Grid container spacing={4}>
                        <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.name`)}
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.platform`)}
                            </Typography>
                        </Grid>
                        <Grid item md={5} />
                        <Grid item md={3} style={{ textAlign: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {t(`manage.actions`)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Separator type="n400" width={2} marginTop="10px" marginBottom="-5px" />
            </PaddedContainer>
        </Grid>
    )
}

const TableRow = props => {
    const { data, handleOpenSigningKeyDialog } = props
    const { t } = useTranslation();
    return (
        <Grid item xs={12} sm={12} md={12}>
            <PaddedContainer>
                <div style={{ position: "relative" }}>
                    <Grid container spacing={4} style={{ display: "flex", alignItems: "center" }}>
                        <Grid item md={2}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                size={14}
                                color={Color.neutral[800]}
                            >
                                {data.keyname}
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            {data.platform === 'android' ? (
                                <Android style={{ width: 24, height: 24, color: Color.primary[500] }} />
                            ) : (
                                    <IOS style={{ width: 24, height: 24, color: Color.primary[500], marginLeft: 2 }} />
                                )}
                        </Grid>
                        <Grid item md={5} />
                        <Grid item md={3}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IconButton
                                            color="primary"
                                            icon={<Edit />}
                                            onClick={handleOpenSigningKeyDialog("EDIT", data)}
                                            tooltip={t("manage.editObject", {object: "Signing Key"})}
                                            disabled
                                        />
                                        <IconButton
                                            color="danger"
                                            icon={<Delete />}
                                            onClick={handleOpenSigningKeyDialog("DELETE", data)}
                                            tooltip={t("manage.deleteObject", {object: "Signing Key"})}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Separator type="n400" width={1} marginTop="10px" marginBottom="-5px" />
            </PaddedContainer>
        </Grid>
    )
}

const ManageGlobalSigningKeys = props => {
    const { t } = useTranslation()

    const BREADCRUMB_STACK = [
        {
            icon: <Global />,
            label: t("breadcrumb.appManagement"),
            typeOf: "",
            onClick: () => {
                history.push(MANAGE_MAIN_DASHBOARD_PATH)
            }
        },
        {
            icon: <Key />,
            label: t("breadcrumb.signingKeys"),
            typeOf: "",
            onClick: () => { }
        }
    ]

    const classes = useStyles(props)
    const history = useHistory()

    const { userInfo, signingKeys, setSigningKeys, handleShowSnackbar, handleShowErrorSnackbar } = useContext(AppContext)

    const [searchableSigningKeys, setSearchableSigningKeys] = useState([])
    const [search, setSearch] = useState("")

    const [signingKeyDialogOpen, setSigningKeyDialogOpen] = useState(false)
    const [userAction, setUserAction] = useState("CREATE")

    const [inspectedSigningKeyData, setInspectedSigningKeyData] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (userInfo) {
            fetchSigningKeysData();
        }
    }, [])

    useEffect(() => {
        setSearchableSigningKeys(signingKeys)
    }, [signingKeys])

    const fetchSigningKeysData = () => {
        ManageGlobalSigningKeysService.getKeysMetaInformation(userInfo.key)
            .then(response => {
                const arr = []
                response.data.map(key => {
                    const keyObj = new SigningKey('android', key.keyname)
                    keyObj.key = key.key
                    arr.push(keyObj)
                })
                setSigningKeys(arr)
            })
            .catch(error => handleShowErrorSnackbar(error))
    }

    const handleChangeProcessSearch = e => {
        setSearch(e.target.value)

        let searchKey = e.target.value;
        let filter = ""

        let newSearchableSigningKeys = [...searchableSigningKeys]

        let filteredSigningKeysData = [];

        if (searchKey !== "") {
            filteredSigningKeysData = newSearchableSigningKeys.filter(inspectedSigningKey => {
                let temp = ""

                temp += ` ${inspectedSigningKey.keyname.toLowerCase()}`

                // const lc = inspectedFunc.name.toLowerCase();
                filter = searchKey.toLowerCase();
                return temp.includes(filter)
            })

            newSearchableSigningKeys = filteredSigningKeysData
        }
        else if (searchKey === "") {
            newSearchableSigningKeys = signingKeys
        }

        setSearchableSigningKeys(newSearchableSigningKeys)
    }

    const handleOpenSigningKeyDialog = (action, key) => () => {
        setInspectedSigningKeyData(key)
        setUserAction(action)
        setSigningKeyDialogOpen(true)
    }

    const handleCloseSigningKeyDialog = () => {
        setSigningKeyDialogOpen(false)
    }

    const handleOnSubmit = body => {
        ManageGlobalSigningKeysService.addKey(userInfo.key, body)
            .then(response => {
                if (response.status === 200) {
                    fetchSigningKeysData()
                    handleCloseSigningKeyDialog()
                    handleShowSnackbar({
                        message: t("manage.objectCreated", {object: t("manage.signingKey")}),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    });
                }
            })
            .catch(error => handleShowErrorSnackbar(error))
    }

    const handleOnDelete = keystoreKey => {
        ManageGlobalSigningKeysService.deleteKey(userInfo.key, keystoreKey)
            .then(response => {
                if (response.status === 200) {
                    fetchSigningKeysData()
                    handleCloseSigningKeyDialog()
                    handleShowSnackbar({
                        message: t("manage.objectDeleted", {object: t("manage.signingKey")}),
                        autoHideDuration: 3000,
                        icon: <Delete />,
                        showButton: true,
                    });
                }
            })
            .catch(error => handleShowErrorSnackbar(error))
    }

    return (
        <div style={{ paddingBottom: 200 }}>
            <Grid container spacing={2}>
                <div style={{ height: 70, width: '100%' }} />
                <Grid item xs={12} sm={12} md={12}>
                    <div id='breadcrumb-container' style={{ width: '100%', position: 'fixed', top: 64, paddingTop: 24, paddingBottom: 24, background: Color.neutral[0], zIndex: 1200 }}>
                        <PaddedContainer>
                            <Breadcrumb stacks={BREADCRUMB_STACK} />
                        </PaddedContainer>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <PaddedContainer>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 12 }}>
                            <div style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                                <Form
                                    placeholder={t("manage.searchKey")}
                                    search
                                    value={search}
                                    style={{ width: '25%' }}
                                    onChange={handleChangeProcessSearch}
                                />
                            </div>
                            <Button
                                style={{ outline: "none" }}
                                icon={<Plus />}
                                alignIcon="left"
                                onClick={handleOpenSigningKeyDialog("CREATE", {})}
                            >
                                {t(`manage.addSigningKey`)}
                            </Button>
                        </div>
                    </PaddedContainer>
                </Grid>

                <TableHeader />
                {searchableSigningKeys.length > 0 ? (
                    <>
                        {searchableSigningKeys.map((key, index) => (
                            <TableRow
                                key={`signing-key-${index}`}
                                data={key}
                                handleOpenSigningKeyDialog={handleOpenSigningKeyDialog}
                            />
                        ))}
                    </>
                ) : (
                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                            <Key style={{ width: 200, height: 200, color: Color.neutral[100] }} />
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                size={36}
                                color={Color.neutral[400]}
                                style={{ paddingTop: 15 }}
                            >
                                {t(`manage.noSigningKeyFound`)}
                            </Typography>
                        </Grid>
                    )}
            </Grid>
            {signingKeyDialogOpen && (
                <SigningKeyDialog
                    open={signingKeyDialogOpen}
                    onClose={handleCloseSigningKeyDialog}
                    onSubmit={handleOnSubmit}
                    onDelete={handleOnDelete}
                    userAction={userAction}
                    inspectedData={inspectedSigningKeyData}
                />
            )}
        </div>
    )
}

export default ManageGlobalSigningKeys