import { makeStyles } from '@material-ui/core/styles'
import {
    Color
} from '@commsult/ontego-ui'

export const useStyles = makeStyles({
    ul: {
        "& .MuiPaginationItem-root": {
            color: Color.neutral[500]
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: Color.primary[300],
            color: Color.neutral[0]
        }
    }
});