import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';

import { useStyles } from './DeviceGroupDialogStyles';

import { useTranslation } from 'react-i18next';

// Components
import {
    DialogBox,
    Button,
    Checkbox,
    Typography,
    Separator,
    FONT_FAMILY,
    Color,
    Form,
    Warehouse,
    ProductionEHP,
    USER_TYPE,
    Edit,
    Plus,
    Password,
    Delete,
    Banner,
    IconButton,
    Settings
} from '@commsult/ontego-ui';

import DeleteDialog from '../DeleteDialog/DeleteDialog';

import Grid from '@material-ui/core/Grid';

const TableColumn = props => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {t("manage.Group Name")}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {t("manage.device")}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                        size={14}
                        color={Color.neutral[800]}
                        style={{ float: "right" }}
                    >
                        {t("adminDashboard.action")}
                    </Typography>
                </Grid>
            </Grid>
            <Separator type="n400" width={2} marginTop="10px" />
        </Grid>
    )
}

const TableRow = props => {
    const { deviceGroup, setDeviceGroupAction, handleShowForm, handleOpenDeleteDialog, setInspectedDeviceGroupData, handleOpenDeviceSettingDialog } = props

    const handleEditDeviceGroup = () => {
        setInspectedDeviceGroupData(deviceGroup)
        setDeviceGroupAction("EDIT")
        handleShowForm()
    }

    const handleDeleteDeviceGroup = () => {
        setInspectedDeviceGroupData(deviceGroup);
        handleOpenDeleteDialog();
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                <Grid item md={6}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                    >
                        {deviceGroup.name}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                    >
                        {deviceGroup.deviceCount}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <IconButton
                                    color="primary"
                                    icon={<Edit />}
                                    onClick={handleEditDeviceGroup}
                                    tooltip="Edit Group"
                                />
                                <IconButton
                                    color="primary"
                                    icon={<Settings />}
                                    onClick={handleOpenDeviceSettingDialog("Group", deviceGroup.key)}
                                    tooltip="Group Device Setting"
                                />
                                <IconButton
                                    color="danger"
                                    icon={<Delete />}
                                    onClick={handleDeleteDeviceGroup}
                                    tooltip="Delete Group"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Separator type="n400" width={1} marginTop="10px" />
        </Grid>
    )
}

const TableRowForm = props => {
    const { showForm, onCloseForm, deviceGroupAction, deviceGroup, deviceGroups, handlePersistence } = props

    const [groupName, setGroupName] = useState("")

    const [groupNameError, setGroupNameError] = useState(false)

    const { t } = useTranslation();

    useEffect(() => {
        if (deviceGroup && deviceGroupAction === "EDIT") {
            setGroupName(deviceGroup.name)
        } else {
            setGroupName('')
        }
    }, [showForm])

    const handleCloseForm = () => {
        onCloseForm()
        setGroupName("")
        setGroupNameError(false)
    }

    const handleChangeGroupName = e => {
        setGroupName(e.target.value)
        setGroupNameError(false)
    }

    const handleSubmit = () => {
        let hasError = false


        if (groupName === "") {
            setGroupNameError(true)
            hasError = true
        }

        if (!hasError) {
            let selectedDeviceGroup = null;
            let updatedDeviceGroups = [];

            if (deviceGroupAction === "EDIT") {
                let tempDeviceGroups = JSON.parse(JSON.stringify(deviceGroups));

                tempDeviceGroups.forEach((p, j) => {
                    if (p.key === deviceGroup.key) {
                        p.name = groupName;
                        selectedDeviceGroup = p;
                    }
                })

                updatedDeviceGroups = tempDeviceGroups;
            }

            if (deviceGroupAction === "CREATE") {
                const tempDeviceGroup = {
                    id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
                    name: groupName
                }

                selectedDeviceGroup = tempDeviceGroup;
                updatedDeviceGroups = JSON.parse(JSON.stringify(deviceGroups));
            }

            handlePersistence(deviceGroupAction, JSON.parse(JSON.stringify(selectedDeviceGroup)), updatedDeviceGroups);
            handleCloseForm()
        }
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                <Grid item md={8}>
                    <Form
                        placeholder={t("manage.groupName")}
                        value={groupName}
                        onChange={handleChangeGroupName}
                        error={groupNameError}
                        errorMessage={groupNameError && t(`manage.fieldRequired`)}
                        required
                    />
                </Grid>
                <Grid item md={4}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Button
                            type={"secondary"}
                            onClick={handleCloseForm}
                        >
                            {t(`manage.cancel`)}
                        </Button>
                        <Button
                            onClick={handleSubmit}
                        >
                            {t(`manage.save`)}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Separator type="n400" width={1} marginTop="10px" />
        </Grid>
    )
}

const DeviceGroupDialog = props => {
    // const classes = useStyles(props)

    const { t } = useTranslation();

    const { open, onClose, handlePersistence, handleOpenDeviceSettingDialog } = props

    const { deviceGroups, devices } = useContext(AppContext)

    const [deviceGroupAction, setDeviceGroupAction] = useState("CREATE")

    const [inspectedDeviceGroupData, setInspectedDeviceGroupData] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const [searchableDeviceGroups, setSearchableDeviceGroups] = useState([])

    useEffect(() => {
        let tempDeviceGroups = [...deviceGroups];
        tempDeviceGroups.forEach((deviceGroup) => {
            let deviceCount = 0;

            devices.forEach((row) => {
                if (deviceGroup.id === row.groupId) {
                    deviceCount++;
                }
            })
            deviceGroup.deviceCount = deviceCount;
        })

        setSearchableDeviceGroups(tempDeviceGroups);
    }, [devices, deviceGroups])

    const handleClose = () => {
        onClose()
        setShowForm(false)
    }

    const handleShowForm = () => {
        setShowForm(true)
    }

    const handleCloseForm = () => {
        setShowForm(false)
        setInspectedDeviceGroupData(null)
        setDeviceGroupAction("CREATE")
    }

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
    }
    const handleDeleteDialog = () => {
        handlePersistence("DELETE", JSON.parse(JSON.stringify(inspectedDeviceGroupData)), deviceGroups);
    }

    const handleCreateDeviceGroup = () => {
        setDeviceGroupAction("CREATE")
        handleShowForm()
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            title={"Group Setting"}
            content={
                <>
                    <div
                        style={{
                            width: '500px',
                            transition: 'width 0.5s, height 0.5s',
                            transitionDelay: '0.5s'
                        }}
                    >
                        <Button
                            icon={<Plus />}
                            alignIcon="right"
                            onClick={handleCreateDeviceGroup}
                            type={"secondary"}
                            style={{ marginTop: 12 }}
                        >
                            {t("dialogs.createNewGroup")}
                        </Button>
                        <div style={{ height: 400 }}>
                            {(deviceGroups.length > 0 || showForm) ? (
                                <Grid container spacing={2} style={{ paddingTop: 10 }}>
                                    <TableColumn />
                                    {(showForm && deviceGroupAction === "CREATE") && (
                                        <TableRowForm
                                            showForm={showForm}
                                            onCloseForm={handleCloseForm}
                                            deviceGroupAction={deviceGroupAction}
                                            deviceGroup={inspectedDeviceGroupData}
                                            deviceGroups={deviceGroups}
                                            handlePersistence={handlePersistence}
                                        />
                                    )}
                                    {searchableDeviceGroups.map((deviceGroup, index) => {
                                        if (inspectedDeviceGroupData && deviceGroup.key === inspectedDeviceGroupData.key && deviceGroupAction === "EDIT") {
                                            return (
                                                <TableRowForm
                                                    key={`deviceGroupForm${index}`}
                                                    showForm={showForm}
                                                    onCloseForm={handleCloseForm}
                                                    deviceGroupAction={deviceGroupAction}
                                                    deviceGroup={inspectedDeviceGroupData}
                                                    deviceGroups={deviceGroups}
                                                    handlePersistence={handlePersistence}
                                                />
                                            )
                                        } else {
                                            return (
                                                <TableRow
                                                    key={`deviceGroup${index}`}
                                                    deviceGroup={deviceGroup}
                                                    handleShowForm={handleShowForm}
                                                    setDeviceGroupAction={setDeviceGroupAction}
                                                    setInspectedDeviceGroupData={setInspectedDeviceGroupData}
                                                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                                                    handleOpenDeviceSettingDialog={handleOpenDeviceSettingDialog}
                                                />
                                            )
                                        }
                                    })}
                                </Grid>
                            ) : (
                                <Grid container spacing={2} style={{ paddingTop: 50 }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
                                        <Typography fontFamily={FONT_FAMILY.ROBOTO_BOLD} color={Color.neutral[300]} size={30}>{"No Device Group"}</Typography>
                                    </Grid>
                                </Grid>
                            )}

                        </div>
                    </div>
                    <DeleteDialog
                        open={deleteDialogOpen}
                        onClose={handleCloseDeleteDialog}
                        onDelete={handleDeleteDialog}
                        title={t("devices.deviceGroup")}
                        dataName={inspectedDeviceGroupData && inspectedDeviceGroupData.name}
                    />
                </>
            }
        />
    )
};

export default DeviceGroupDialog;