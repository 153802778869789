import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FunctionCard, Typography, FONT_FAMILY, Color, ShowMoreHorizontal, Separator, Chip, ColorSAP, Globe, Mobile, UserGroup, Bulb } from '@commsult/ontego-ui';
import { Grid } from '@material-ui/core';
import { PaddedContainer, RootWrapperContainer } from '../../App';
import { useTranslation } from 'react-i18next';
import AdminUserService from './AdminUserService';
import ClientDialog from '../../Dialogs/AdminDashboard/ClientDialog';
import { AppContext } from '../../App'
import { ACTION_TYPE_ADD, ACTION_TYPE_UPDATE, ACTION_TYPE_ADD_CLIENT } from '../../Constants/DashboardActionType';
import { BLACKLISTED_DEVICE } from '../../Constants/Path';
import moment from 'moment';
import Form from '../../ReusableComponents/Form/Form';
import Button from '../../ReusableComponents/Button/Button';
import { Dashboard, List } from '@commsult/ontego-ui/dist/sap_icons';
import { OverflowMenu } from '../../ReusableComponents/OverflowMenu/OverflowMenu';

const UserListTableColumn = props => {
    // const [columnSorts, setColumnSorts] = useState({
    //     alias: true,
    //     lastAccess: true,
    // });

    // const { handleSort } = props
    const { t } = useTranslation();

    // const onClickHeader = (key) => () => {
    //     handleSort(key, columnSorts[key]);
    //     setColumnSorts({ ...columnSorts, [key]: !columnSorts[key] });
    // };

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: 36 }}>
                <Grid container spacing={0}>
                    <Grid
                        item
                        md={3}
                        // onClick={onClickHeader('alias')} 
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={Color.neutral[800]}
                        >
                            {t(`manage.name`)}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        // onClick={onClickHeader('lastAccess')}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={Color.neutral[800]}
                        >
                            {t(`common.email`)}
                        </Typography>
                    </Grid>
                    <Grid item md={2} style={{ display: 'flex', paddingRight: 30 }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={Color.neutral[800]}
                        >
                            {t(`adminDashboard.role`)}
                        </Typography>
                    </Grid>
                    <Grid item md={2} style={{ display: 'flex', paddingRight: 30 }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={Color.neutral[800]}
                        >
                            {t(`manage.clientStatus`)}
                        </Typography>
                    </Grid>
                    <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={Color.neutral[800]}
                        >
                            {t(`adminDashboard.action`)}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <Separator width={2} marginTop="5px" />
        </Grid>
    )
}

const UserListTableRow = props => {
    const { data, handleClickShowMoreIcon } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={0} style={{ display: "flex", alignItems: "center" }}>
                <Grid item md={3}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {data.name}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {data.email}
                    </Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={Color.neutral[800]}
                    >
                        {data.roles}
                    </Typography>
                </Grid>
                <Grid item md={2}>
                    <Chip
                        backgroundColor={data.hasPurchased ? ColorSAP.primary[70] : ColorSAP.neutral[40]}
                    >
                        {data.hasPurchased ? t("manage.client") : t("manage.prospectClient")}
                    </Chip>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ShowMoreHorizontal
                        style={{ top: 5, right: 10, color: ColorSAP.primary[70], cursor: "pointer" }}
                        onClick={(e) => { handleClickShowMoreIcon(e, data) }}
                    />
                </Grid>
            </Grid>
            <Separator width={1} marginTop="5px" />
        </Grid>
    );
}

const GridViewUser = props => {
    const { data, handleClickShowMoreIcon } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={4}>
            <div
                style={{
                    height: 100,
                    border: "3px solid",
                    borderColor: data.hasPurchased ? ColorSAP.primary[50] : ColorSAP.neutral[20],
                    padding: "16px 10px",
                    position: "relative",
                    borderRadius: 8
                }}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={16}
                    color={ColorSAP.neutral[100]}
                >
                    {t("manage.name")} : {data.name}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={16}
                    color={ColorSAP.neutral[100]}
                >
                    {t("common.email")} : {data.email}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={16}
                    color={ColorSAP.neutral[100]}
                >
                    {t("adminDashboard.role")} : {data.roles}
                </Typography>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 12 }}>
                    <Chip
                        backgroundColor={data.hasPurchased ? ColorSAP.primary[70] : ColorSAP.neutral[40]}
                    >
                        {data.hasPurchased ? t("manage.client") : t("manage.prospectClient")}
                    </Chip>
                    {data?.ontegoid && data?.ontegoid.length > 1 ?
                        (<Chip
                            backgroundColor={ColorSAP.success[60]}
                        >
                            {`${data?.ontegoid}`}
                        </Chip>) :
                        (
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD_ITALIC}
                                size={12}
                                color={ColorSAP.danger[50]}

                            >
                                {t("adminDashboard.ontegoIdIsEmpty")}
                            </Typography>
                        )}

                </div>

                <ShowMoreHorizontal
                    style={{ position: "absolute", top: 5, right: 10, color: ColorSAP.primary[70], cursor: "pointer" }}
                    onClick={(e) => { handleClickShowMoreIcon(e, data) }}
                />

            </div>
        </Grid>
    );
}

const AdminDashboard = () => {
    const [isGridView, setIsGridView] = useState(true);
    const [search, setSearch] = useState("")
    const [clientsLoginCountData, setClientsLoginCountData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [userDatas, setUserDatas] = useState([]);
    const [inspectedData, setInspectedData] = useState(null);
    const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionType, setActionType] = useState(ACTION_TYPE_ADD)

    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment())

    const history = useHistory()
    const { t } = useTranslation();

    const {
        handleShowErrorSnackbar,
        handleShowSnackbar
    } = useContext(AppContext);

    useEffect(() => {
        if (!isSuccess) {
            AdminUserService.getAllClients().then((res) => {
                setUserDatas(res.data);
                setFilteredData(res.data)
            }).catch((err) => handleShowErrorSnackbar(err));
        }

        AdminUserService.getProspectiveClientsLoginCount(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')).then((res) => {
            setClientsLoginCountData(res.data)
        }).catch((err) => { console.log(err) });

        return () => {
            setIsSuccess(false)
        }
    }, [isSuccess, startDate, endDate])

    const ManagementCards = [
        {
            label: t('manage.adminUser'),
            icon: <UserGroup style={{ marginBottom: 25 }} />,
            onClick: () => {
                // history.push(MANAGE_GLOBAL_DEVICE_PATH)
            }
        },
        {
            label: t(`manage.blacklistedDevice`),
            icon: <Mobile style={{ marginBottom: 25 }} />,
            onClick: () => {
                history.push(BLACKLISTED_DEVICE)
            }
        }
    ]

    const handleChangeProcessSearch = e => {
        let value = e.target.value;
        setSearch(value);
        setFilteredData(userDatas.filter((data) => data.name.toLowerCase().includes(value) || data.email.toLowerCase().includes(value) || data.roles?.toLowerCase().includes(value)));
    }

    const handleOnSearchClear = () => {
        setSearch("");
        setFilteredData(userDatas);
    }

    const handleClickShowMoreIcon = (e, data) => {
        e.stopPropagation();
        setInspectedData(data);

        if (!anchorEl) {
            setAnchorEl(e.currentTarget);
        };
    }

    //handle view dialog
    const handleOpenClientDialog = (type) => {
        setAddClientDialogOpen(true)
        setActionType(type)
    }
    const handleCloseClientDialog = () => {
        setAddClientDialogOpen(false)
    }

    const handleOnDateChange = (start, end) => {
        console.log(start)
        console.log(end)
        setStartDate(start)
        setEndDate(end)
    }

    return (
        <>
            <div
                style={{
                    width: "100%",
                    background: ColorSAP.neutral[10],
                    marginTop: -24,
                    boxSizing: "border-box",
                    paddingTop: 60,
                    paddingBottom: 60,
                }}
            >
                <RootWrapperContainer>
                    <PaddedContainer>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Globe
                                style={{
                                    width: 24,
                                    height: 24,
                                    color: ColorSAP.primary[70],
                                    marginRight: 12,
                                }}
                            />
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={18}
                                color={ColorSAP.neutral[100]}
                            >
                                {t(`navbar.management`)}
                            </Typography>
                        </div>

                        <div
                            style={{
                                marginTop: 60,
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                        >
                            {ManagementCards.map((card, index) => (
                                <div key={`${t("adminDashboard.card")}${index}`} style={{ margin: 8 }}>
                                    <FunctionCard
                                        textDevice={card.label}
                                        icon={card.icon}
                                        onClick={card.onClick}
                                        style={{
                                            backgroundColor: ColorSAP.primary[70]
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </PaddedContainer>
                </RootWrapperContainer>
            </div>
            <RootWrapperContainer>
                <div
                    style={{
                        width: "100%",
                        marginTop: 24,
                        boxSizing: "border-box",
                        paddingTop: 40,
                        paddingBottom: 90,
                    }}
                >
                    <PaddedContainer>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Bulb
                                        style={{
                                            width: 24,
                                            height: 24,
                                            color: ColorSAP.primary[70],
                                            marginRight: 10,
                                        }}
                                    />
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                        size={16}
                                        color={Color.neutral[900]}
                                    >
                                        {t('adminDashboard.interestedClientsLogIn')}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} />

                            <Grid item xs={12}>
                                {/* <CalendarDateRangePicker
                                containerId="login-count-datepicker-container"
                                calenderId="login-count-datepicker"
                                start={startDate}
                                end={endDate}
                                onChange={handleOnDateChange}
                            /> */}
                                <div style={{ marginTop: 20 }} ></div>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item md={4} style={{ cursor: "pointer" }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                            size={14}
                                            color={Color.neutral[800]}
                                        >
                                            {t(`adminDashboard.clientName`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} style={{ cursor: "pointer" }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                            size={14}
                                            color={Color.neutral[800]}
                                        >
                                            {t(`adminDashboard.clientEmail`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} style={{ display: 'flex', paddingRight: 30 }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                            size={14}
                                            color={Color.neutral[800]}
                                        >
                                            {t(`adminDashboard.loginCount`)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Separator type="n400" width={2} marginTop="14px" />

                                <div style={{ marginTop: 15 }}>
                                    {clientsLoginCountData.map((data, index) => (
                                        // <div style={{ backgroundColor: index % 2 == 0 ? Color.primary[100] : Color.neutral[100], height: 50 }}>
                                        <Grid container spacing={1}>
                                            <Grid item md={4} style={{ cursor: "pointer" }}>
                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                    size={14}
                                                    color={Color.neutral[800]}
                                                >
                                                    {data.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} style={{ cursor: "pointer" }}>
                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                    size={14}
                                                    color={Color.neutral[800]}
                                                >
                                                    {data.email}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={2} style={{ display: 'flex', paddingRight: 30 }}>
                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                    size={14}
                                                    color={Color.neutral[800]}
                                                >
                                                    {data.count}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Separator type="n400" width={1} marginBottom="8px" />
                                            </Grid>
                                        </Grid>
                                        // </div>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </PaddedContainer>
                </div>

                <div
                    style={{
                        width: "100%",
                        marginTop: 24,
                        boxSizing: "border-box",
                        paddingTop: 40,
                        paddingBottom: 90,
                    }}
                >
                    <PaddedContainer>
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <UserGroup
                                            style={{
                                                width: 24,
                                                height: 24,
                                                color: ColorSAP.primary[70],
                                                marginRight: 10,
                                            }}
                                        />
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                            size={16}
                                            color={Color.neutral[900]}
                                        >
                                            {t("adminDashboard.clients")}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                    <Dashboard
                                        style={{
                                            color: ColorSAP.primary[70],
                                            cursor: "pointer",
                                            opacity: isGridView ? 1 : 0.5,
                                            marginRight: 8
                                        }}
                                        onClick={() => setIsGridView(true)}
                                    />
                                    <List
                                        style={{
                                            color: ColorSAP.primary[70],
                                            cursor: "pointer",
                                            opacity: !isGridView ? 1 : 0.5
                                        }}
                                        onClick={() => setIsGridView(false)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ marginTop: "10px", marginBottom: "30px" }}
                            >
                                <Grid item xs={4}>
                                    <Form
                                        placeholder={t("common.search")}
                                        onChange={handleChangeProcessSearch}
                                    // onClear={handleOnSearchClear}
                                    />
                                </Grid>

                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button type="primary" theme="dark" size="small" onClick={() => handleOpenClientDialog(ACTION_TYPE_ADD)}>
                                        {t("button.addClient")}
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                {isGridView ?
                                    filteredData.map((data, index) =>
                                        <GridViewUser data={data} key={index} handleClickShowMoreIcon={handleClickShowMoreIcon} />
                                    )
                                    :
                                    (
                                        <>
                                            <UserListTableColumn />
                                            {
                                                filteredData.map((data, index) =>
                                                    <UserListTableRow data={data} key={index} handleClickShowMoreIcon={handleClickShowMoreIcon} />
                                                )
                                            }
                                        </>
                                    )

                                }

                            </Grid>

                            <OverflowMenu
                                anchorElement={anchorEl}
                                setAnchorEl={setAnchorEl}
                                menuItems={[
                                    {
                                        text: t("adminDashboard.updateClientData"),
                                        onClick: () => {
                                            handleOpenClientDialog(ACTION_TYPE_UPDATE)
                                        }
                                    }
                                ]}
                            />
                        </>
                    </PaddedContainer>
                </div>
            </RootWrapperContainer>

            <ClientDialog
                open={addClientDialogOpen}
                onClose={handleCloseClientDialog}
                setIsSuccess={setIsSuccess}
                actionType={actionType}
                inspectedData={inspectedData}
            />
        </>
    );
}

export default AdminDashboard
