import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** INVENTUR IMAGES */
import Inventur_Einfuehrung_MM1 from '../../assets/img_en/inventur/Einfuehrung_MM/1.png';
import Inventur_Einfuehrung_MM2 from '../../assets/img_en/inventur/Einfuehrung_MM/2.png';

import Inventur_Einfuehrung_WM_EWM1 from '../../assets/img_en/inventur/Einfuehrung_WM_EWM/1.png';
import Inventur_Einfuehrung_WM_EWM2 from '../../assets/img_en/inventur/Einfuehrung_WM_EWM/2.png';
import Inventur_Einfuehrung_WM_EWM3 from '../../assets/img_en/inventur/Einfuehrung_WM_EWM/3.png';
import Inventur_Einfuehrung_WM_EWM4 from '../../assets/img_en/inventur/Einfuehrung_WM_EWM/4.png';
import Inventur_Einfuehrung_WM_EWM5 from '../../assets/img_en/inventur/Einfuehrung_WM_EWM/5.png';

import Inventur_PermanenteInventurImLagerort_MM1 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_MM/1.png';
import Inventur_PermanenteInventurImLagerort_MM2 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_MM/2.png';
import Inventur_PermanenteInventurImLagerort_MM3 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_MM/3.png';
import Inventur_PermanenteInventurImLagerort_MM4 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_MM/4.png';
import Inventur_PermanenteInventurImLagerort_MM5 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_MM/5.png';

import Inventur_PermanenteInventurImLagerort_WM_EWM1 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_WM_EWM/1.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM2 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_WM_EWM/2.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM3 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_WM_EWM/3.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM4 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_WM_EWM/4.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM5 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_WM_EWM/5.png';
import Inventur_PermanenteInventurImLagerort_WM_EWM6 from '../../assets/img_en/inventur/PermanenteInventurImLagerort_WM_EWM/6.png';

import Inventur_AdhocInventurAnwenderMaterial1 from '../../assets/img_en/inventur/AdhocInventurAnwenderMaterial/1.png';
import Inventur_AdhocInventurAnwenderMaterial2 from '../../assets/img_en/inventur/AdhocInventurAnwenderMaterial/2.png';
import Inventur_AdhocInventurAnwenderMaterial3 from '../../assets/img_en/inventur/AdhocInventurAnwenderMaterial/3.png';

import Inventur_AdhocInventurAndwenderLagerplatz1 from '../../assets/img_en/inventur/AdhocInventurAndwenderLagerplatz/1.png';
import Inventur_AdhocInventurAndwenderLagerplatz2 from '../../assets/img_en/inventur/AdhocInventurAndwenderLagerplatz/2.png';
import Inventur_AdhocInventurAndwenderLagerplatz3 from '../../assets/img_en/inventur/AdhocInventurAndwenderLagerplatz/3.png';
import Inventur_AdhocInventurAndwenderLagerplatz4 from '../../assets/img_en/inventur/AdhocInventurAndwenderLagerplatz/4.png';
import Inventur_AdhocInventurAndwenderLagerplatz5 from '../../assets/img_en/inventur/AdhocInventurAndwenderLagerplatz/5.png';
import Inventur_AdhocInventurAndwenderLagerplatz6 from '../../assets/img_en/inventur/AdhocInventurAndwenderLagerplatz/6.png';

import Inventur_AdhocInventurSystem_MM1 from '../../assets/img_en/inventur/AdhocInventurSystem_MM/1.png';
import Inventur_AdhocInventurSystem_MM2 from '../../assets/img_en/inventur/AdhocInventurSystem_MM/2.png';
import Inventur_AdhocInventurSystem_MM3 from '../../assets/img_en/inventur/AdhocInventurSystem_MM/3.png';
import Inventur_AdhocInventurSystem_MM4 from '../../assets/img_en/inventur/AdhocInventurSystem_MM/4.png';

import Inventur_AdhocInventurSystem_WM_EWM1 from '../../assets/img_en/inventur/AdhocInventurSystem_WM_EWM/1.png';
import Inventur_AdhocInventurSystem_WM_EWM2 from '../../assets/img_en/inventur/AdhocInventurSystem_WM_EWM/2.png';
import Inventur_AdhocInventurSystem_WM_EWM3 from '../../assets/img_en/inventur/AdhocInventurSystem_WM_EWM/3.png';
import Inventur_AdhocInventurSystem_WM_EWM4 from '../../assets/img_en/inventur/AdhocInventurSystem_WM_EWM/4.png';
import Inventur_AdhocInventurSystem_WM_EWM5 from '../../assets/img_en/inventur/AdhocInventurSystem_WM_EWM/5.png';
import Inventur_AdhocInventurSystem_WM_EWM6 from '../../assets/img_en/inventur/AdhocInventurSystem_WM_EWM/6.png';
import Inventur_AdhocInventurSystem_WM_EWM7 from '../../assets/img_en/inventur/AdhocInventurSystem_WM_EWM/7.png';

import Inventur_NullKontrolle1 from '../../assets/img_en/inventur/NullKontrolle/1.png';
import Inventur_NullKontrolle2 from '../../assets/img_en/inventur/NullKontrolle/2.png';
import Inventur_NullKontrolle3 from '../../assets/img_en/inventur/NullKontrolle/3.png';
import Inventur_NullKontrolle4 from '../../assets/img_en/inventur/NullKontrolle/4.png';

import Inventur_Bestandskorrektur_MM1 from '../../assets/img_en/inventur/Bestandskorrektur_MM/1.png';
import Inventur_Bestandskorrektur_MM2 from '../../assets/img_en/inventur/Bestandskorrektur_MM/2.png';

import Inventur_Bestandskorrektur_WM_EWM1 from '../../assets/img_en/inventur/Bestandskorrektur_WM_EWM/1.png';
import Inventur_Bestandskorrektur_WM_EWM2 from '../../assets/img_en/inventur/Bestandskorrektur_WM_EWM/2.png';

export const InventurImagesEN = [
    new ProcessImageContainer(
        `P_INVENTUR_EINFUEHRUNG_MM`,
        [
            new ProcessImage('Detail with Existing Type', Inventur_Einfuehrung_MM1),
            new ProcessImage('Detail with Count Number', Inventur_Einfuehrung_MM2)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_EINFUEHRUNG_WM_EWM`,
        [
            new ProcessImage('Storage', Inventur_Einfuehrung_WM_EWM1),
            new ProcessImage('Positions Incomplete', Inventur_Einfuehrung_WM_EWM2),
            new ProcessImage('Detail', Inventur_Einfuehrung_WM_EWM3),
            new ProcessImage('Detail with Count Number', Inventur_Einfuehrung_WM_EWM4),
            new ProcessImage('Quants Incomplete', Inventur_Einfuehrung_WM_EWM5)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_STICHTAGS_PERMANENTE_INVENTUR_IM_LAGERORT_MM`,
        [
            new ProcessImage('Warehouse', Inventur_PermanenteInventurImLagerort_MM1),
            new ProcessImage('Optional Material', Inventur_PermanenteInventurImLagerort_MM2),
            new ProcessImage('Detail with Existing Type', Inventur_PermanenteInventurImLagerort_MM3),
            new ProcessImage('Details', Inventur_PermanenteInventurImLagerort_MM4),
            new ProcessImage('Detail with Count Number', Inventur_PermanenteInventurImLagerort_MM5)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_STICHTAGS_PERMANENTE_INVENTUR_AM_LAGERPLATZ_WM`,
        [
            new ProcessImage('Storage', Inventur_PermanenteInventurImLagerort_WM_EWM1),
            new ProcessImage('Optional Storage Space', Inventur_PermanenteInventurImLagerort_WM_EWM2),
            new ProcessImage('Optional Quant', Inventur_PermanenteInventurImLagerort_WM_EWM3),
            new ProcessImage('Detail with Existing Type', Inventur_PermanenteInventurImLagerort_WM_EWM4),
            new ProcessImage('Detail', Inventur_PermanenteInventurImLagerort_WM_EWM5),
            new ProcessImage('Detail with Count Number', Inventur_PermanenteInventurImLagerort_WM_EWM6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_STICHTAGS_PERMANENTE_INVENTUR_AM_LAGERPLATZ_EWM`,
        [
            new ProcessImage('Storage', Inventur_PermanenteInventurImLagerort_WM_EWM1),
            new ProcessImage('Optional Storage Space', Inventur_PermanenteInventurImLagerort_WM_EWM2),
            new ProcessImage('Optional Quant', Inventur_PermanenteInventurImLagerort_WM_EWM3),
            new ProcessImage('Detail with Existing Type', Inventur_PermanenteInventurImLagerort_WM_EWM4),
            new ProcessImage('Detail', Inventur_PermanenteInventurImLagerort_WM_EWM5),
            new ProcessImage('Detail with Count Number', Inventur_PermanenteInventurImLagerort_WM_EWM6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_ANWENDER_GEFUEHRT_FUER_MATERIAL`,
        [
            new ProcessImage('Warehouse', Inventur_AdhocInventurAnwenderMaterial1),
            new ProcessImage('Material', Inventur_AdhocInventurAnwenderMaterial2),
            new ProcessImage('Details', Inventur_AdhocInventurAnwenderMaterial3)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_ANWENDER_GEFUEHRT_AM_LAGERPLATZ_WM`,
        [
            new ProcessImage('Storage', Inventur_AdhocInventurAndwenderLagerplatz1),
            new ProcessImage('Optional Storage Space', Inventur_AdhocInventurAndwenderLagerplatz2),
            new ProcessImage('Quants Incomplete', Inventur_AdhocInventurAndwenderLagerplatz3),
            new ProcessImage('Material', Inventur_AdhocInventurAndwenderLagerplatz4),
            new ProcessImage('Details', Inventur_AdhocInventurAndwenderLagerplatz5),
            new ProcessImage('Quants Complete', Inventur_AdhocInventurAndwenderLagerplatz6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_ANWENDER_GEFUEHRT_AM_LAGERPLATZ_EWM`,
        [
            new ProcessImage('Storage', Inventur_AdhocInventurAndwenderLagerplatz1),
            new ProcessImage('Optional Storage Space', Inventur_AdhocInventurAndwenderLagerplatz2),
            new ProcessImage('Quants Incomplete', Inventur_AdhocInventurAndwenderLagerplatz3),
            new ProcessImage('Material', Inventur_AdhocInventurAndwenderLagerplatz4),
            new ProcessImage('Details', Inventur_AdhocInventurAndwenderLagerplatz5),
            new ProcessImage('Quants Complete', Inventur_AdhocInventurAndwenderLagerplatz6)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_SYSTEM_GEFUEHRT_FUER_MATERIAL_MM`,
        [
            new ProcessImage('Warehouse', Inventur_AdhocInventurSystem_MM1),
            new ProcessImage('Modal start ad hoc', Inventur_AdhocInventurSystem_MM2),
            new ProcessImage('Positions', Inventur_AdhocInventurSystem_MM3),
            new ProcessImage('Detail', Inventur_AdhocInventurSystem_MM4),
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_SYSTEM_GEFUEHRT_AM_LAGERPLATZ_WM`,
        [
            new ProcessImage('Storage', Inventur_AdhocInventurSystem_WM_EWM1),
            new ProcessImage('Warehouse', Inventur_AdhocInventurSystem_WM_EWM2),
            new ProcessImage('Modal Start Ad Hoc', Inventur_AdhocInventurSystem_WM_EWM3),
            new ProcessImage('Positions Incomplete', Inventur_AdhocInventurSystem_WM_EWM4),
            new ProcessImage('Material', Inventur_AdhocInventurSystem_WM_EWM5),
            new ProcessImage('Details', Inventur_AdhocInventurSystem_WM_EWM6),
            new ProcessImage('Positions Incomplete', Inventur_AdhocInventurSystem_WM_EWM7)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_ADHOC_INVENTUR_SYSTEM_GEFUEHRT_AM_LAGERPLATZ_EWM`,
        [
            new ProcessImage('Storage', Inventur_AdhocInventurSystem_WM_EWM1),
            new ProcessImage('Warehouse', Inventur_AdhocInventurSystem_WM_EWM2),
            new ProcessImage('Modal Start Ad Hoc', Inventur_AdhocInventurSystem_WM_EWM3),
            new ProcessImage('Positions Incomplete', Inventur_AdhocInventurSystem_WM_EWM4),
            new ProcessImage('Material', Inventur_AdhocInventurSystem_WM_EWM5),
            new ProcessImage('Details', Inventur_AdhocInventurSystem_WM_EWM6),
            new ProcessImage('Positions Incomplete', Inventur_AdhocInventurSystem_WM_EWM7)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_NULL_KONTROLLE_UND_ODER_RESTMENGENPRUEFUNG_WM`,
        [
            new ProcessImage('Remaining Quantity Test', Inventur_NullKontrolle1),
            new ProcessImage('Zero Control', Inventur_NullKontrolle2),
            new ProcessImage('Zero Control Correction', Inventur_NullKontrolle3),
            new ProcessImage('Zero Control Correction (With Batch)', Inventur_NullKontrolle4),
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_NULL_KONTROLLE_UND_ODER_RESTMENGENPRUEFUNG_EWM`,
        [
            new ProcessImage('Remaining Quantity Test', Inventur_NullKontrolle1),
            new ProcessImage('Zero Control', Inventur_NullKontrolle2),
            new ProcessImage('Zero Control Correction', Inventur_NullKontrolle3),
            new ProcessImage('Zero Control Correction (With Batch)', Inventur_NullKontrolle4),
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_BESTANDSKORREKTUR_MM`,
        [
            new ProcessImage('Inventory Information Detail MM Options', Inventur_Bestandskorrektur_MM1),
            new ProcessImage('AdHocInv', Inventur_Bestandskorrektur_MM2)
        ]
    ),
    new ProcessImageContainer(
        `P_INVENTUR_BESTANDSKORREKTUR_WM_EWM`,
        [
            new ProcessImage('Inventory Information Detail WM Options', Inventur_Bestandskorrektur_WM_EWM1),
            new ProcessImage('AdHocInv WM', Inventur_Bestandskorrektur_WM_EWM2)
        ]
    )
]