import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** BESTELLUNG IMAGES */
import Auslagerung_Einfuehrung1 from '../../assets/img_en/auslagerung/Einfuehrung/1.png'
import Auslagerung_Einfuehrung2 from '../../assets/img_en/auslagerung/Einfuehrung/2.png'
import Auslagerung_Einfuehrung3 from '../../assets/img_en/auslagerung/Einfuehrung/3.png'
import Auslagerung_Einfuehrung4 from '../../assets/img_en/auslagerung/Einfuehrung/4.png'

import Auslagerung_Materialbeleg1 from '../../assets/img_en/auslagerung/Materialbeleg/1.png'
import Auslagerung_Materialbeleg2 from '../../assets/img_en/auslagerung/Materialbeleg/2.png'
import Auslagerung_Materialbeleg3 from '../../assets/img_en/auslagerung/Materialbeleg/3.png'
import Auslagerung_Materialbeleg4 from '../../assets/img_en/auslagerung/Materialbeleg/4.png'

import Auslagerung_Transportbedarf1 from '../../assets/img_en/auslagerung/Transportbedarf/1.png'
import Auslagerung_Transportbedarf2 from '../../assets/img_en/auslagerung/Transportbedarf/2.png'
import Auslagerung_Transportbedarf3 from '../../assets/img_en/auslagerung/Transportbedarf/3.png'
import Auslagerung_Transportbedarf4 from '../../assets/img_en/auslagerung/Transportbedarf/4.png'

import Auslagerung_Transportauftrag1 from '../../assets/img_en/auslagerung/Transportauftrag/1.png'
import Auslagerung_Transportauftrag2 from '../../assets/img_en/auslagerung/Transportauftrag/2.png'
import Auslagerung_Transportauftrag3 from '../../assets/img_en/auslagerung/Transportauftrag/3.png'
import Auslagerung_Transportauftrag4 from '../../assets/img_en/auslagerung/Transportauftrag/4.png'
import Auslagerung_Transportauftrag5 from '../../assets/img_en/auslagerung/Transportauftrag/5.png'

import Auslagerung_Lagerenheit_TA1 from '../../assets/img_en/auslagerung/Lagereinheit_TA/1.png'
import Auslagerung_Lagerenheit_TA2 from '../../assets/img_en/auslagerung/Lagereinheit_TA/2.png'
import Auslagerung_Lagerenheit_TA3 from '../../assets/img_en/auslagerung/Lagereinheit_TA/3.png'
import Auslagerung_Lagerenheit_TA4 from '../../assets/img_en/auslagerung/Lagereinheit_TA/4.png'

import Auslagerung_Lagerauftrag1 from '../../assets/img_en/auslagerung/Lagerauftrag/1.png'
import Auslagerung_Lagerauftrag2 from '../../assets/img_en/auslagerung/Lagerauftrag/2.png'
import Auslagerung_Lagerauftrag3 from '../../assets/img_en/auslagerung/Lagerauftrag/3.png'
import Auslagerung_Lagerauftrag4 from '../../assets/img_en/auslagerung/Lagerauftrag/4.png'

import Auslagerung_Lageraufgabe1 from '../../assets/img_en/auslagerung/Lageraufgabe/1.png'
import Auslagerung_Lageraufgabe2 from '../../assets/img_en/auslagerung/Lageraufgabe/2.png'
import Auslagerung_Lageraufgabe3 from '../../assets/img_en/auslagerung/Lageraufgabe/3.png'
import Auslagerung_Lageraufgabe4 from '../../assets/img_en/auslagerung/Lageraufgabe/4.png'

import Auslagerung_HandlingUnit_LA1 from '../../assets/img_en/auslagerung/HandlingUnit_LA/1.png'
import Auslagerung_HandlingUnit_LA2 from '../../assets/img_en/auslagerung/HandlingUnit_LA/2.png'
import Auslagerung_HandlingUnit_LA3 from '../../assets/img_en/auslagerung/HandlingUnit_LA/3.png'
import Auslagerung_HandlingUnit_LA4 from '../../assets/img_en/auslagerung/HandlingUnit_LA/4.png'

import Auslagerung_Quittierung_WM1 from '../../assets/img_en/auslagerung/Quittierung_WM/1.png'
import Auslagerung_Quittierung_WM2 from '../../assets/img_en/auslagerung/Quittierung_WM/2.png'
import Auslagerung_Quittierung_WM3 from '../../assets/img_en/auslagerung/Quittierung_WM/3.png'
import Auslagerung_Quittierung_WM4 from '../../assets/img_en/auslagerung/Quittierung_WM/4.png'

import Auslagerung_Quittierung_EWM1 from '../../assets/img_en/auslagerung/Quittierung_EWM/1.png'
import Auslagerung_Quittierung_EWM2 from '../../assets/img_en/auslagerung/Quittierung_EWM/2.png'
import Auslagerung_Quittierung_EWM3 from '../../assets/img_en/auslagerung/Quittierung_EWM/3.png'
import Auslagerung_Quittierung_EWM4 from '../../assets/img_en/auslagerung/Quittierung_EWM/4.png'

import Auslagerung_Storno_WM1 from '../../assets/img_en/auslagerung/Storno_WM/1.png'
import Auslagerung_Storno_WM2 from '../../assets/img_en/auslagerung/Storno_WM/2.png'
import Auslagerung_Storno_WM3 from '../../assets/img_en/auslagerung/Storno_WM/3.png'
import Auslagerung_Storno_WM4 from '../../assets/img_en/auslagerung/Storno_WM/4.png'

import Auslagerung_Storno_EWM1 from '../../assets/img_en/auslagerung/Storno_EWM/1.png'
import Auslagerung_Storno_EWM2 from '../../assets/img_en/auslagerung/Storno_EWM/2.png'
import Auslagerung_Storno_EWM3 from '../../assets/img_en/auslagerung/Storno_EWM/3.png'
import Auslagerung_Storno_EWM4 from '../../assets/img_en/auslagerung/Storno_EWM/4.png'

export const AuslagerungImagesEN = [
    new ProcessImageContainer(
        `P_AUSLAGERUNG_EINFUEHRUNG`,
        [
            new ProcessImage('Optional Positions', Auslagerung_Einfuehrung1),
            new ProcessImage('Optional Details Serial Numbers', Auslagerung_Einfuehrung2),
            new ProcessImage('Details', Auslagerung_Einfuehrung3),
            new ProcessImage('Storage Bin', Auslagerung_Einfuehrung4)
        ]
    ),
    new ProcessImageContainer(
        `P_AUSLAGERUNG_ZUM_MATERIALBELEG_WM`,
        [
            new ProcessImage('Material Document', Auslagerung_Materialbeleg1),
            new ProcessImage('Optional Positions', Auslagerung_Materialbeleg2),
            new ProcessImage('Optional Details', Auslagerung_Materialbeleg3),
            new ProcessImage('Storage Bin', Auslagerung_Materialbeleg4)
        ]
    ),
    new ProcessImageContainer(
        `P_AUSLAGERUNG_ZUM_MATERIALBELEG_EWM`,
        [
            new ProcessImage('Material Document', Auslagerung_Materialbeleg1),
            new ProcessImage('Optional Positions', Auslagerung_Materialbeleg2),
            new ProcessImage('Optional Details', Auslagerung_Materialbeleg3),
            new ProcessImage('Storage Bin', Auslagerung_Materialbeleg4)
        ]
    ),
    new ProcessImageContainer(
        `P_AUSLAGERUNG_ZUM_TRANSPORTBEDARF_WM`,
        [
            new ProcessImage('Transport Requirement', Auslagerung_Transportbedarf1),
            new ProcessImage('Optional Positions', Auslagerung_Transportbedarf2),
            new ProcessImage('Optional Details', Auslagerung_Transportbedarf3),
            new ProcessImage('Storage Bin', Auslagerung_Transportbedarf4)
        ]
    ),
    new ProcessImageContainer(
        `P_AUSLAGERUNG_ZUM_TRANSPORTAUFTRAG_WM`,
        [
            new ProcessImage('Transport Order', Auslagerung_Transportauftrag1),
            new ProcessImage('Optional Positions', Auslagerung_Transportauftrag2),
            new ProcessImage('Optional Details', Auslagerung_Transportauftrag3),
            new ProcessImage('Storage Bin', Auslagerung_Transportauftrag4),
            new ProcessImage('Optional Details Serial Numbers', Auslagerung_Transportauftrag5)
        ]
    ),
    new ProcessImageContainer(
        `P_LAGEREINHEIT_AUSLAGERN_WM`,
        [
            new ProcessImage('Transport Order', Auslagerung_Lagerenheit_TA1),
            new ProcessImage('Optional Positions', Auslagerung_Lagerenheit_TA2),
            new ProcessImage('Details', Auslagerung_Lagerenheit_TA3),
            new ProcessImage('Optional Storage Unit Content', Auslagerung_Lagerenheit_TA4)
        ]
    ),
    new ProcessImageContainer(
        `P_AUSLAGERUNG_ZUM_LAGERAUFTRAG_EWM`,
        [
            new ProcessImage('Warehouse', Auslagerung_Lagerauftrag1),
            new ProcessImage('Optional Positions', Auslagerung_Lagerauftrag2),
            new ProcessImage('Optional Details', Auslagerung_Lagerauftrag3),
            new ProcessImage('Storage Bin', Auslagerung_Lagerauftrag4)
        ]
    ),
    new ProcessImageContainer(
        `P_AUSLAGERUNG_ZUR_LAGERAUFGABE_EWM`,
        [
            new ProcessImage('Warehouse Task', Auslagerung_Lageraufgabe1),
            new ProcessImage('Optional Details', Auslagerung_Lageraufgabe2),
            new ProcessImage('Storage Bin', Auslagerung_Lageraufgabe3),
            new ProcessImage('Optional Details Serial Numbers', Auslagerung_Lageraufgabe4)
        ]
    ),
    new ProcessImageContainer(
        `P_HANDLING_UNIT_AUSLAGERN_EWM`,
        [
            new ProcessImage('Warehouse', Auslagerung_HandlingUnit_LA1),
            new ProcessImage('Optional Positions', Auslagerung_HandlingUnit_LA2),
            new ProcessImage('Details', Auslagerung_HandlingUnit_LA3),
            new ProcessImage('Optional Modal HU Info', Auslagerung_HandlingUnit_LA4)
        ]
    ),
    new ProcessImageContainer(
        `P_VARIANTEN_ZUR_QUITTIERUNG_WM`,
        [
            new ProcessImage('Material', Auslagerung_Quittierung_WM1),
            new ProcessImage('Quantity', Auslagerung_Quittierung_WM2),
            new ProcessImage('Warehouse', Auslagerung_Quittierung_WM3),
            new ProcessImage('Storage Bin', Auslagerung_Quittierung_WM4)
        ]
    ),
    new ProcessImageContainer(
        `P_VARIANTEN_ZUR_QUITTIERUNG_EWM`,
        [
            new ProcessImage('Material', Auslagerung_Quittierung_EWM1),
            new ProcessImage('Quantity', Auslagerung_Quittierung_EWM2),
            new ProcessImage('Handling Unit', Auslagerung_Quittierung_EWM3),
            new ProcessImage('Storage Bin', Auslagerung_Quittierung_EWM4)
        ]
    ),
    new ProcessImageContainer(
        `P_STORNO_AUS_WARENEINGANG_WM`,
        [
            new ProcessImage('Transport Order', Auslagerung_Storno_WM1),
            new ProcessImage('Optional Positions', Auslagerung_Storno_WM2),
            new ProcessImage('Optional Details', Auslagerung_Storno_WM3),
            new ProcessImage('Storage Bin', Auslagerung_Storno_WM4)
        ]
    ),
    new ProcessImageContainer(
        `P_STORNO_AUS_WARENEINGANG_EWM`,
        [
            new ProcessImage('Warehouse', Auslagerung_Storno_EWM1),
            new ProcessImage('Optional Positions', Auslagerung_Storno_EWM2),
            new ProcessImage('Optional Details', Auslagerung_Storno_EWM3),
            new ProcessImage('Storage Bin', Auslagerung_Storno_EWM4),
        ]
    )
]