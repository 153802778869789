import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const DeviceIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" version="1.1">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.73478 4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 4.73478 7 5V19C7 19.2652 7.10536 19.5196 7.29289 19.7071C7.48043 19.8946 7.73478 20 8 20H16C16.2652 20 16.5196 19.8946 16.7071 19.7071C16.8946 19.5196 17 19.2652 17 19V5C17 4.73478 16.8946 4.48043 16.7071 4.29289C16.5196 4.10536 16.2652 4 16 4H14C14 4.55228 13.5523 5 13 5H11C10.4477 5 10 4.55228 10 4H8ZM5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2H16C16.7956 2 17.5587 2.31607 18.1213 2.87868C18.6839 3.44129 19 4.20435 19 5V19C19 19.7957 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7957 22 16 22H8C7.20435 22 6.44129 21.6839 5.87868 21.1213C5.31607 20.5587 5 19.7957 5 19V5C5 4.20435 5.31607 3.44129 5.87868 2.87868ZM12 16C12.5523 16 13 16.4477 13 17V17.01C13 17.5623 12.5523 18.01 12 18.01C11.4477 18.01 11 17.5623 11 17.01V17C11 16.4477 11.4477 16 12 16Z" fill="#3B3FEA" />
        </SvgIcon>
    );
}

export default DeviceIcon;