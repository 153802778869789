import { Color } from "@commsult/ontego-ui";
import { createTheme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const theme = createTheme();

export const useStyles = makeStyles(() =>
    createStyles({
        '@-webkit-keyframes progressKeyframe': {
            '0%': {
                width: 0
            },
            '100%': {
                width: '100%'
            }
        },
        '@keyframes progressKeyframe': {
            '0%': {
                width: 0
            },
            '100%': {
                width: '100%'
            }
        },

        snackbarWrapper: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: 65,
            zIndex: 13000,
            // boxShadow: "29px 29px 29px 20px rgba(0, 0, 0, 0.15)"
        },
        snackbarContainer: {
            [theme.breakpoints.between('1280', '1440')]: {
                paddingTop: 4,
                paddingLeft: 40,
                paddingRight: 40,
            },
            [theme.breakpoints.up('1440')]: {
                paddingTop: 4,
                paddingLeft: 120,
                paddingRight: 120,
            },
        },
        snackbarIconContainer: {
            "& svg": {
                width: 30,
                height: 30,
                color: Color.neutral[0],
                marginTop: 6
            }
        },

        meter: {
            height: 8,
            overflow: 'hidden',
            animation: `$progressKeyframe linear`,
            webkitAnimation: `$progressKeyframe linear`,
        }
    })
);