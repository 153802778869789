// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, FONT_FAMILY } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        smallDiamondBackground: {
            width: 16,
            height: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 38,
            position: "absolute",
            zIndex: 150,
            background: Color.gradient[100],
            transform: 'rotate(-45deg)'
        },
        blueDiamondBackground: {
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: Color.gradient[100],
            transform: 'rotate(-45deg)'
        },
        redDiamondBackground: {
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: Color.danger[300],
            transform: 'rotate(-45deg)'
        },
        greyDiamondBackground: {
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: Color.neutral[500],
            transform: 'rotate(-45deg)'
        },
        contentContainer: {
            transform: 'rotate(45deg)'
        }
    })
);