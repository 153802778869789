// React Lib
import React, { useState } from "react";

// Component Styles
import { useStyles } from "./TabsStyles";
import { ColorSAP } from "@commsult/ontego-ui";

export const Tabs = (props) => {
    const classes = useStyles(props)

    const { containerId, tabItems, activeIndex, handleChangeIndex, style } = props

    const [internalIndex, setInternalIndex] = useState(activeIndex)

    const onChangeIndex = index => () => {
        setInternalIndex(index)
        handleChangeIndex(index)
    }

    return (
        <div
            id={containerId}
            style={{ display: 'flex', alignItems: 'center', ...style }}
        >
            {tabItems.map((tab, index) => {
                return (
                    <div
                        className={classes.tabItem}
                        style={{ borderBottom: internalIndex === index ? `1px solid ${ColorSAP.primary[70]}` : '1px solid transparent' }}
                        key={`${tab.id}-${index}`}
                        onClick={onChangeIndex(index)}
                    >
                        {tab.label}
                    </div>
                )
            })}
        </div>
    )
}

Tabs.defaultProps = {
    stacks: [],
}

export default React.memo(Tabs)