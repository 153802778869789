// React Lib
import React from "react";

import classNames from "classnames";

// Component Styles
import { useStyles } from "./ButtonStyles";

import { FONT_FAMILY } from "@commsult/ontego-ui";
import ArrowHeadSpecial from "../../Icons/ArrowHeadSpecial";

const Button = props => {
    const { id, type, size, theme, onClick } = props

    const classes = useStyles(props)

    const fontSize = size => {
        switch (size) {
            case 'small':
                return 12
            case 'medium':
                return 18
            case 'large':
                return 24
        }
    }

    const buttonClasses = classNames({
        [classes.container__primary]: type === 'primary',
        [classes.container__secondary]: type === 'secondary',
        [classes.container__small]: size === 'small',
        [classes.container__medium]: size === 'medium',
        [classes.container__large]: size === 'large',
        [classes.container__dark]: theme === 'dark',
        [classes.container__danger]: theme === 'danger',
        [classes.container__light]: theme === 'light'
    })

    const buttonContainer = classNames({
        [classes.buttonContainerDark]: theme === 'dark',
        [classes.buttonContainerDanger]: theme === 'danger',
        [classes.buttonContainerLight]: theme === 'light'
    })

    const arrowContainerClasses = classNames({
        [classes.arrowContainer__primary]: type === 'primary',
        [classes.arrowContainer__secondary]: type === 'secondary',
        [classes.arrowContainer__small]: size === "small",
        [classes.arrowContainer__medium]: size === "medium",
        [classes.arrowContainer__large]: size === "large",
        [classes.arrowContainer__dark]: theme === 'dark',
        [classes.arrowContainer__danger]: theme === 'danger',
        [classes.arrowContainer__light]: theme === 'light'
    })

    return (
        <div
            id={id}
            className={`${buttonContainer} ${buttonClasses}`}
            style={{
                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD,
                fontSize: fontSize(size),
                userSelect: 'none'
            }}
            onClick={onClick}
        >
            {props.children.toUpperCase()}

            {type !== 'tertiary' && (
                <div
                    className={arrowContainerClasses}
                >
                    <ArrowHeadSpecial />
                </div>
            )}
        </div>
    )
}

export default Button