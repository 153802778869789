import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** PRODUKTIONSVERSORGUNG IMAGES */
import Produktionsversorgung_Einfuehrung_MM1 from '../../assets/img/produktionsversorgung/Einfuehrung_MM/1.png';
import Produktionsversorgung_Einfuehrung_WM1 from '../../assets/img/produktionsversorgung/Einfuehrung_WM/1.png';
import Produktionsversorgung_Einfuehrung_EWM1 from '../../assets/img/produktionsversorgung/Einfuehrung_EWM/1.png';

import Produktionsversorgung_Stueckliste1 from '../../assets/img/produktionsversorgung/Stueckliste/1.png';
import Produktionsversorgung_Stueckliste2 from '../../assets/img/produktionsversorgung/Stueckliste/2.png';
import Produktionsversorgung_Stueckliste3 from '../../assets/img/produktionsversorgung/Stueckliste/3.png';
import Produktionsversorgung_Stueckliste4 from '../../assets/img/produktionsversorgung/Stueckliste/4.png';
import Produktionsversorgung_Stueckliste5 from '../../assets/img/produktionsversorgung/Stueckliste/5.png';
import Produktionsversorgung_Stueckliste6 from '../../assets/img/produktionsversorgung/Stueckliste/6.png';

import Produktionsversorgung_Fertigungsauftrag_WM1 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_WM/1.png';
import Produktionsversorgung_Fertigungsauftrag_WM2 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_WM/2.png';
import Produktionsversorgung_Fertigungsauftrag_WM3 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_WM/3.png';
import Produktionsversorgung_Fertigungsauftrag_WM4 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_WM/4.png';
import Produktionsversorgung_Fertigungsauftrag_WM5 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_WM/5.png';

import Produktionsversorgung_Fertigungsauftrag_EWM1 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_EWM/1.png';
import Produktionsversorgung_Fertigungsauftrag_EWM2 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_EWM/2.png';
import Produktionsversorgung_Fertigungsauftrag_EWM3 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_EWM/3.png';
import Produktionsversorgung_Fertigungsauftrag_EWM4 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_EWM/4.png';
import Produktionsversorgung_Fertigungsauftrag_EWM5 from '../../assets/img/produktionsversorgung/Fertigungsauftrag_EWM/5.png';

import Produktionsversorgung_KanbanStatuswechsel1 from '../../assets/img/produktionsversorgung/KanbanStatuswechsel/1.png';

export const ProduktionsversorgungImages = [
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_EINFUEHRUNG_MM`,
        [
            new ProcessImage('Details mit Seriennummern', Produktionsversorgung_Einfuehrung_MM1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_EINFUEHRUNG_WM`,
        [
            new ProcessImage('Material', Produktionsversorgung_Einfuehrung_WM1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_EINFUEHRUNG_EWM`,
        [
            new ProcessImage('Material', Produktionsversorgung_Einfuehrung_EWM1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_ZUR_STUECKLISTE`,
        [
            new ProcessImage('Material', Produktionsversorgung_Stueckliste1),
            new ProcessImage('Fertigungsauftrag', Produktionsversorgung_Stueckliste2),
            new ProcessImage('Positionen', Produktionsversorgung_Stueckliste3),
            new ProcessImage('Details', Produktionsversorgung_Stueckliste4),
            new ProcessImage('Details mit Seriennummern', Produktionsversorgung_Stueckliste5),
            new ProcessImage('Vonlagerort', Produktionsversorgung_Stueckliste6)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_ZUM_FERTIGUNGSAUFTRAG_WM`,
        [
            new ProcessImage('Fertigungsauftrag', Produktionsversorgung_Fertigungsauftrag_WM1),
            new ProcessImage('Postionen', Produktionsversorgung_Fertigungsauftrag_WM2),
            new ProcessImage('Details', Produktionsversorgung_Fertigungsauftrag_WM3),
            new ProcessImage('Material', Produktionsversorgung_Fertigungsauftrag_WM4),
            new ProcessImage('Vonlagerplatz', Produktionsversorgung_Fertigungsauftrag_WM5)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_ZUM_FERTIGUNGSAUFTRAG_EWM`,
        [
            new ProcessImage('Fertigungsauftrag', Produktionsversorgung_Fertigungsauftrag_EWM1),
            new ProcessImage('Postionen', Produktionsversorgung_Fertigungsauftrag_EWM2),
            new ProcessImage('Details', Produktionsversorgung_Fertigungsauftrag_EWM3),
            new ProcessImage('Material', Produktionsversorgung_Fertigungsauftrag_EWM4),
            new ProcessImage('Vonlagerplatz', Produktionsversorgung_Fertigungsauftrag_EWM5)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_IN_DER_SERIENFERTIGUNG_WM`,
        [
            new ProcessImage('Status Wechsel', Produktionsversorgung_KanbanStatuswechsel1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_IN_DER_SERIENFERTIGUNG_EWM`,
        [
            new ProcessImage('Status Wechsel', Produktionsversorgung_KanbanStatuswechsel1)
        ]
    )
]