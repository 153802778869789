import axios from 'axios';
import { USER_API_BASE_APPS_URL, getApiUrl } from '../../Constants/Api';

export class DebugLogDialogService {
    getDeviceLogFiles = (clientKey, appKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/devicelogfiles`);
    }
    downloadFiles = (clientKey, appKey, keys) => {
        const params = new URLSearchParams();
        keys.forEach(key => {
            params.append('key', key)
        })

        return axios.get(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/devicelogfiles/download`, { params });
    }
    deleteDeviceLogFile = (clientKey, appKey, fileKey) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_APPS_URL}/${clientKey}/${appKey}/devicelogfiles/${fileKey}`);
    }
}

export default new DebugLogDialogService();