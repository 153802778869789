import axios from 'axios';
import { USER_API_BASE_ROLES_URL, getApiUrl } from '../../Constants/Api';

export class ManageGlobalPermissionService {
    getRoles = (clientKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_ROLES_URL}/${clientKey}`);
    }
    addRole = (clientKey, role) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_ROLES_URL}/${clientKey}`, { role });
    }
    updateRole = (clientKey, role) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_ROLES_URL}/${clientKey}/${role.key}`, { role });
    }
    deleteRole = (clientKey, roleKey) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_ROLES_URL}/${clientKey}/${roleKey}`);
    }
    undeleteRole = (clientKey, roleKey) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_ROLES_URL}/${clientKey}/${roleKey}/undelete`);
    }
}

export default new ManageGlobalPermissionService();