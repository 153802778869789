import axios from 'axios';
import { USER_API_BASE_DEVICES_URL, getApiUrl } from '../../Constants/Api';

export class ManageGlobalDeviceService {
    getDevices = (clientKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}`);
    }
    getAllDevices = () => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}`);
    }
    registerDevice = (clientKey, device) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}`, { device });
    }
    updateDevice = (clientKey, deviceKey, device) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/${deviceKey}`, { device });
    }

    getDeviceGroups = (clientKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceGroups`);
    }
    addDeviceGroup = (clientKey, deviceGroup) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceGroups`, { deviceGroup });
    }
    updateDeviceGroup = (clientKey, deviceGroupKey, deviceGroup) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceGroups/${deviceGroupKey}`, { deviceGroup });
    }
    deleteDeviceGroup = (clientKey, deviceGroupKey) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceGroups/${deviceGroupKey}`);
    }
    undeleteDeviceGroup = (clientKey, deviceGroupKey) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceGroups/${deviceGroupKey}/undelete`);
    }

    getDeviceSettings = (clientKey, refKey) => {
        return axios.get(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceSettings/${refKey}`);
    }
    addDeviceSetting = (clientKey, deviceSetting) => {
        return axios.post(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceSettings`, { deviceSetting });
    }
    updateDeviceSetting = (clientKey, deviceSettingKey, deviceSetting) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceSettings/${deviceSettingKey}`, { deviceSetting });
    }
    deleteDeviceSetting = (clientKey, deviceSettingKey) => {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceSettings/${deviceSettingKey}`);
    }
    undeleteDeviceSetting = (clientKey, deviceSettingKey) => {
        return axios.put(`${getApiUrl()}${USER_API_BASE_DEVICES_URL}/${clientKey}/deviceSettings/${deviceSettingKey}/undelete`);
    }
}

export default new ManageGlobalDeviceService();