import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** WA INTERN IMAGES */
import WA_Intern_Einfuehrung1 from '../../assets/img_en/warenausgang_intern/Einfuehrung/1.png'
import WA_Intern_Einfuehrung2 from '../../assets/img_en/warenausgang_intern/Einfuehrung/2.png'
import WA_Intern_Einfuehrung3 from '../../assets/img_en/warenausgang_intern/Einfuehrung/3.png'
import WA_Intern_Einfuehrung4 from '../../assets/img_en/warenausgang_intern/Einfuehrung/4.png'
import WA_Intern_Einfuehrung5 from '../../assets/img_en/warenausgang_intern/Einfuehrung/5.png'

import WA_Intern_Fertigungsauftrag1 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/1.png'
import WA_Intern_Fertigungsauftrag2 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/2.png'
import WA_Intern_Fertigungsauftrag3 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/3.png'
import WA_Intern_Fertigungsauftrag4 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/4.png'
import WA_Intern_Fertigungsauftrag5 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/5.png'

import WA_Intern_Kostenstelle1 from '../../assets/img_en/warenausgang_intern/Kostenstelle/1.png'
import WA_Intern_Kostenstelle2 from '../../assets/img_en/warenausgang_intern/Kostenstelle/2.png'
import WA_Intern_Kostenstelle3 from '../../assets/img_en/warenausgang_intern/Kostenstelle/3.png'
import WA_Intern_Kostenstelle4 from '../../assets/img_en/warenausgang_intern/Kostenstelle/4.png'
import WA_Intern_Kostenstelle5 from '../../assets/img_en/warenausgang_intern/Kostenstelle/5.png'

import WA_Intern_Instandhaltungsauftrag1 from '../../assets/img_en/warenausgang_intern/Instandhaltungsauftrag/1.png'
import WA_Intern_Instandhaltungsauftrag2 from '../../assets/img_en/warenausgang_intern/Instandhaltungsauftrag/2.png'
import WA_Intern_Instandhaltungsauftrag3 from '../../assets/img_en/warenausgang_intern/Instandhaltungsauftrag/3.png'
import WA_Intern_Instandhaltungsauftrag4 from '../../assets/img_en/warenausgang_intern/Instandhaltungsauftrag/4.png'
import WA_Intern_Instandhaltungsauftrag5 from '../../assets/img_en/warenausgang_intern/Instandhaltungsauftrag/5.png'

import WA_Intern_Innenauftrag1 from '../../assets/img_en/warenausgang_intern/Innenauftrag/1.png'
import WA_Intern_Innenauftrag2 from '../../assets/img_en/warenausgang_intern/Innenauftrag/2.png'
import WA_Intern_Innenauftrag3 from '../../assets/img_en/warenausgang_intern/Innenauftrag/3.png'
import WA_Intern_Innenauftrag4 from '../../assets/img_en/warenausgang_intern/Innenauftrag/4.png'
import WA_Intern_Innenauftrag5 from '../../assets/img_en/warenausgang_intern/Innenauftrag/5.png'

import WA_Intern_Kundenauftrag1 from '../../assets/img_en/warenausgang_intern/Kundenauftrag/1.png'
import WA_Intern_Kundenauftrag2 from '../../assets/img_en/warenausgang_intern/Kundenauftrag/2.png'
import WA_Intern_Kundenauftrag3 from '../../assets/img_en/warenausgang_intern/Kundenauftrag/3.png'
import WA_Intern_Kundenauftrag4 from '../../assets/img_en/warenausgang_intern/Kundenauftrag/4.png'
import WA_Intern_Kundenauftrag5 from '../../assets/img_en/warenausgang_intern/Kundenauftrag/5.png'

import WA_Intern_Reservierung1 from '../../assets/img_en/warenausgang_intern/Reservierung/1.png'
import WA_Intern_Reservierung2 from '../../assets/img_en/warenausgang_intern/Reservierung/2.png'
import WA_Intern_Reservierung3 from '../../assets/img_en/warenausgang_intern/Reservierung/3.png'
import WA_Intern_Reservierung4 from '../../assets/img_en/warenausgang_intern/Reservierung/4.png'
import WA_Intern_Reservierung5 from '../../assets/img_en/warenausgang_intern/Reservierung/5.png'

import WA_Intern_PspElement1 from '../../assets/img_en/warenausgang_intern/PspElement/1.png'
import WA_Intern_PspElement2 from '../../assets/img_en/warenausgang_intern/PspElement/2.png'
import WA_Intern_PspElement3 from '../../assets/img_en/warenausgang_intern/PspElement/3.png'
import WA_Intern_PspElement4 from '../../assets/img_en/warenausgang_intern/PspElement/4.png'
import WA_Intern_PspElement5 from '../../assets/img_en/warenausgang_intern/PspElement/5.png'

import WA_Intern_Verschrottung1 from '../../assets/img_en/warenausgang_intern/Verschrottung/1.png'
import WA_Intern_Verschrottung2 from '../../assets/img_en/warenausgang_intern/Verschrottung/2.png'
import WA_Intern_Verschrottung3 from '../../assets/img_en/warenausgang_intern/Verschrottung/3.png'
import WA_Intern_Verschrottung4 from '../../assets/img_en/warenausgang_intern/Verschrottung/4.png'

import WA_Intern_Storno1 from '../../assets/img_en/warenausgang_intern/Storno/1.png'
import WA_Intern_Storno2 from '../../assets/img_en/warenausgang_intern/Storno/2.png'
import WA_Intern_Storno3 from '../../assets/img_en/warenausgang_intern/Storno/3.png'

import WA_Intern_Sonderbestand1 from '../../assets/img_en/warenausgang_intern/Sonderbestand/1.png'
import WA_Intern_Sonderbestand2 from '../../assets/img_en/warenausgang_intern/Sonderbestand/2.png'

import WA_Intern_SmartScan1 from '../../assets/img_en/warenausgang_intern/SmartScan/1.png'

import WA_Intern_Auslagerung_WM1 from '../../assets/img_en/warenausgang_intern/Auslagerung_WM/1.png'

import WA_Intern_Auslagerung_EWM1 from '../../assets/img_en/warenausgang_intern/Auslagerung_EWM/1.png'

export const WarenausgangInternImagesEN = [
    new ProcessImageContainer(
        `P_WA_INTERN_EINFUEHRUNG`,
        [
            new ProcessImage('Menu', WA_Intern_Einfuehrung1),
            new ProcessImage('Storage Location and Batch', WA_Intern_Einfuehrung2),
            new ProcessImage('From Storage Location by Selection', WA_Intern_Einfuehrung3),
            new ProcessImage('Quantity (with Inventory)', WA_Intern_Einfuehrung4),
            new ProcessImage('Quantity with Customer Design', WA_Intern_Einfuehrung5)
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_WARENAUSGANG_ZUM_FERTIGUNGSAUFTRAG`,
        [
            new ProcessImage('Menu', WA_Intern_Fertigungsauftrag1),
            new ProcessImage('Production Order', WA_Intern_Fertigungsauftrag2),
            new ProcessImage('Material', WA_Intern_Fertigungsauftrag3),
            new ProcessImage('From Storage Location by Selection', WA_Intern_Fertigungsauftrag4),
            new ProcessImage('Quantity', WA_Intern_Fertigungsauftrag5),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_WARENAUSGANG_ZUR_KOSTENSTELLE`,
        [
            new ProcessImage('Menu', WA_Intern_Kostenstelle1),
            new ProcessImage('Cost Center', WA_Intern_Kostenstelle2),
            new ProcessImage('Material', WA_Intern_Kostenstelle3),
            new ProcessImage('From Storage Location by Selection', WA_Intern_Kostenstelle4),
            new ProcessImage('Quantity (with Inventory)', WA_Intern_Kostenstelle5),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_WARENAUSGANG_ZUM_INSTANDHALTUNGSAUFTRAG`,
        [
            new ProcessImage('Menu', WA_Intern_Instandhaltungsauftrag1),
            new ProcessImage('Maintenance Order', WA_Intern_Instandhaltungsauftrag2),
            new ProcessImage('Material', WA_Intern_Instandhaltungsauftrag3),
            new ProcessImage('Source Storage Location', WA_Intern_Instandhaltungsauftrag4),
            new ProcessImage('Quantity', WA_Intern_Instandhaltungsauftrag5),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_WARENAUSGANG_ZUM_INNENAUFTRAG`,
        [
            new ProcessImage('Menu', WA_Intern_Innenauftrag1),
            new ProcessImage('Interior Order', WA_Intern_Innenauftrag2),
            new ProcessImage('Material', WA_Intern_Innenauftrag3),
            new ProcessImage('Source Storage Location', WA_Intern_Innenauftrag4),
            new ProcessImage('Quantity', WA_Intern_Innenauftrag5),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_WARENAUSGANG_ZUM_KUNDENAUFTRAG`,
        [
            new ProcessImage('Menu', WA_Intern_Kundenauftrag1),
            new ProcessImage('Customer Order', WA_Intern_Kundenauftrag2),
            new ProcessImage('Material', WA_Intern_Kundenauftrag3),
            new ProcessImage('Source Storage Location', WA_Intern_Kundenauftrag4),
            new ProcessImage('Quantity', WA_Intern_Kundenauftrag5),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_WARENAUSGANG_ZUR_RESERVIERUNG`,
        [
            new ProcessImage('Menu', WA_Intern_Reservierung1),
            new ProcessImage('Reservation', WA_Intern_Reservierung2),
            new ProcessImage('Material', WA_Intern_Reservierung3),
            new ProcessImage('Source Storage Location', WA_Intern_Reservierung4),
            new ProcessImage('Quantity', WA_Intern_Reservierung5),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_WARENAUSGANG_ZUM_PSP_ELEMENT`,
        [
            new ProcessImage('Menu', WA_Intern_PspElement1),
            new ProcessImage('PSP Element', WA_Intern_PspElement2),
            new ProcessImage('Material', WA_Intern_PspElement3),
            new ProcessImage('Source Storage Location', WA_Intern_PspElement4),
            new ProcessImage('Quantity', WA_Intern_PspElement5),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_VERSCHROTTUNG`,
        [
            new ProcessImage('Material', WA_Intern_Verschrottung1),
            new ProcessImage('Source Storage Location', WA_Intern_Verschrottung2),
            new ProcessImage('Quantity without Customer Consignment', WA_Intern_Verschrottung3),
            new ProcessImage('Quantity with Customer Consignment', WA_Intern_Verschrottung4),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_STORNO_ZUM_WARENAUSGANG`,
        [
            new ProcessImage('Material Document', WA_Intern_Storno1),
            new ProcessImage('Positions', WA_Intern_Storno2),
            new ProcessImage('Position', WA_Intern_Storno3),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_BEHANDLUNG_VON_SONDERBESTANDEN`,
        [
            new ProcessImage('Quantity without Customer Consignment', WA_Intern_Sonderbestand1),
            new ProcessImage('Quantity with Customer Consignment', WA_Intern_Sonderbestand2),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_AUTOMATISCHE_ERKENNUNG_DES_KONTIERUNGSOBJEKTS_SMART_SCAN`,
        [
            new ProcessImage('Menu', WA_Intern_SmartScan1),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_ANSCHLIESSENDE_AUSLAGERUNG_WM`,
        [
            new ProcessImage('Outsourcing WM', WA_Intern_Auslagerung_WM1),
        ]
    ),
    new ProcessImageContainer(
        `P_WA_INTERN_ANSCHLIESSENDE_AUSLAGERUNG_EWM`,
        [
            new ProcessImage('Outsourcing EWM', WA_Intern_Auslagerung_EWM1),
        ]
    ),
]