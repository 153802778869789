import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
    Typography,
    FONT_FAMILY,
    Color,
    Separator,
    IconButton,
    Mobile,
    ColorSAP
} from "@commsult/ontego-ui";
import { AppContext } from "../../App";
import BlacklistedDeviceDialog from "../../Dialogs/BlacklistedDeviceDialog/BlacklistedDeviceDialog"
import ManageBlacklistedDeviceService from "./ManageBlacklistedDeviceService";
import DeleteDialog from "../../Dialogs/DeleteDialog/DeleteDialog";
import Form from "../../ReusableComponents/Form/Form";
import { Delete, Edit } from "@commsult/ontego-ui/dist/sap_icons";
import Button from "../../ReusableComponents/Button/Button";

const TableColumn = (props) => {
    const [columnSorts, setColumnSorts] = useState({
        alias: true,
        createdOn: true,
        period: true,
        remainingDay: true,
    });

    const { handleSort } = props;
    const { t } = useTranslation();

    const onClickHeader = (key) => () => {
        handleSort(key, columnSorts[key]);
        setColumnSorts({ ...columnSorts, [key]: !columnSorts[key] });
    };

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div>
                <Grid container spacing={2}>
                    <Grid
                        item
                        md={2}
                        onClick={onClickHeader("alias")}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.alias`)}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        onClick={onClickHeader("createdOn")}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.created`)}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        onClick={onClickHeader("period")}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.period`)}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        onClick={onClickHeader("remainingDay")}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.remainingDay`)}
                        </Typography>
                    </Grid>
                    <Grid item md={3} style={{ display: "flex" }}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.description`)}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={1}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={14}
                            color={ColorSAP.neutral[100]}
                        >
                            {t(`manage.actions`)}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <Separator type="n400" width={2} marginTop="20px" />
        </Grid>
    );
};

const TableRow = (props) => {
    const {
        data,
        handleDeleteBlackListedDeviceFunction,
        handleOpenEditBlacklistedDeviceDialog,
    } = props;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const { t } = useTranslation();

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    return (
        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: "15px" }}>
            <Grid
                container
                spacing={2}
                style={{ display: "flex", alignItems: "center" }}
            >
                <Grid item md={2}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={20}
                        color={ColorSAP.neutral[100]}
                    >
                        {data.alias && data.alias !== "null" ? data.alias : ""}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {data.deviceId}
                    </Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {moment(data.createdOn).format("DD.MM.YYYY HH:mm:ss")}
                    </Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {data.period && data.period !== moment("2000.01.01", "YYYY.MM.DD").format()
                            ? moment(data.period).format("DD.MM.YYYY HH:mm:ss")
                            : "-"}
                    </Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {data.remainingDay ? `${data.remainingDay} ${t(`manage.days`)}` : '0 ' + t(`manage.days`)}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ textTransform: "uppercase" }}
                    >
                        {data.description}
                    </Typography>
                </Grid>
                <Grid item md={1}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton
                                    onClick={() => handleOpenEditBlacklistedDeviceDialog(data)}
                                    color="primary"
                                    icon={<Edit />}
                                    tooltip={t("manage.edit")}
                                />
                                <IconButton
                                    onClick={() => handleOpenDeleteDialog()}
                                    color="primary"
                                    icon={<Delete />}
                                    tooltip={t("manage.delete")}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Separator type="n400" width={1} marginTop="20px" />

            <DeleteDialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                onDelete={handleDeleteBlackListedDeviceFunction(data)}
                title={"Black Listed Device"}
                dataName={data && data.alias}
            />
        </Grid>
    );
};

const ManageBlacklistedDevice = () => {
    const [initialBlacklistedDevice, setInitialBlacklistedDevice] = useState([]);
    const [blacklistedDevice, setBlacklistedDevice] = useState([]);
    const [blacklistedDeviceDialogOpen, setBlacklistedDeviceDialog] =
        useState(false);
    const [dialogType, setDialogType] = useState("");
    const [blacklistedEditData, setBlacklistedEditData] = useState();
    const [search, setSearch] = useState("");

    const { handleShowErrorSnackbar, handleShowSnackbar, devicesWithoutClient, setDevicesWithoutClient } =
        useContext(AppContext);

    const { t } = useTranslation();

    useEffect(() => {
        fetchBlacklistedDeviceData();
    }, []);

    const fetchBlacklistedDeviceData = async () => {
        try {
            const response = await ManageBlacklistedDeviceService.getAllBlacklistedDevice();

            const current = moment().startOf("day");
            response.data.forEach((data) => {
                const period = moment(data.period, "YYYY-MM-DDTHH:mm:ss.SSSZ");
                const remainingDay = data.period
                    ? period.diff(current, "days")
                    : 0;
                data.remainingDay = remainingDay
            })

            setBlacklistedDevice(response.data);
            setInitialBlacklistedDevice(response.data);
        } catch (error) {
            handleShowErrorSnackbar(error);
        }
    };

    const handleOpenAddBlacklistedDeviceDialog = () => {
        setBlacklistedDeviceDialog(true);
        setDialogType("add");
    };

    const handleOpenEditBlacklistedDeviceDialog = (data) => {
        setBlacklistedDeviceDialog(true);
        setDialogType("edit");
        setBlacklistedEditData(data);
    };

    const handleCloseBlacklistedDeviceDialog = () => {
        setBlacklistedDeviceDialog(false);
        setBlacklistedEditData(null);
    };

    const handleDeleteBlackListedDeviceFunction = (data) => async () => {
        try {
            const response =
                await ManageBlacklistedDeviceService.deleteBlackListedDevice(data.id);
            if (response.status === 200) {
                setBlacklistedDevice(blacklistedDevice.filter((Q) => Q.id !== data.id));
                setInitialBlacklistedDevice(blacklistedDevice.filter((Q) => Q.id !== data.id))
                setDevicesWithoutClient([data, ...devicesWithoutClient])
                handleShowSnackbar({
                    message: t("manage.objectDeleted", { object: data.alias }),
                    autoHideDuration: 3000,
                });
            } else {
                handleShowErrorSnackbar(response.error);
            }
        } catch (error) {
            handleShowErrorSnackbar(error);
        }
    };

    useEffect(() => {
        if (search.length > 0) {
            const lowercaseSearch = search.toLowerCase()
            const filteredData = [...initialBlacklistedDevice].filter((device) => {
                return (
                    (device.alias && device.alias.toLowerCase().includes(lowercaseSearch)) ||
                    (device.deviceId && device.deviceId.toLowerCase().includes(lowercaseSearch))
                );
            });
            setBlacklistedDevice(filteredData);
        } else {
            setBlacklistedDevice(initialBlacklistedDevice);
        }
    }, [search]);

    const handleSortBlackListedDevice = (key, isAscending) => {
        const _blackListedDevices = JSON.parse(JSON.stringify(blacklistedDevice))
        const sortedArr = _blackListedDevices.sort((a, b) => {
            if (!a.period) {
                a.period = moment("2000.01.01", "YYYY.MM.DD").format()
            }
            if (!b.period) {
                b.period = moment("2000.01.01", "YYYY.MM.DD").format()
            }
            return (isAscending ? (b[key] > a[key] ? -1 : 1) : (a[key] > b[key] ? -1 : 1)) ||
                (isAscending ? moment(b[key]) - moment(a[key]) : moment(a[key]) - moment(b[key]))
        });
        setBlacklistedDevice(sortedArr);
    };

    return (
        <>
            <Grid container style={{ margin: "30px 0" }}>
                <Grid item md={5}>
                    <Form
                        placeholder={t("manage.searchDeviceNoOrAlias")}
                        search
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs>
                    <Grid container direction="row-reverse">
                        <Grid item>
                            <Button
                                type="primary"
                                theme="dark"
                                size="small"
                                onClick={() => handleOpenAddBlacklistedDeviceDialog()}
                            >
                                {t(`manage.addNewBlacklist`)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TableColumn handleSort={handleSortBlackListedDevice} />
            {blacklistedDevice.length > 0 ? (
                <>
                    {blacklistedDevice.map((data, index) => (
                        <TableRow
                            key={`blacklisted_device_${index}`}
                            data={data}
                            handleDeleteBlackListedDeviceFunction={
                                handleDeleteBlackListedDeviceFunction
                            }
                            handleOpenAddBlacklistedDeviceDialog={
                                handleOpenAddBlacklistedDeviceDialog
                            }
                            handleOpenEditBlacklistedDeviceDialog={
                                handleOpenEditBlacklistedDeviceDialog
                            }
                        />
                    ))}
                </>
            ) : (
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                    <Mobile
                        style={{
                            width: 200,
                            height: 200,
                            color: ColorSAP.neutral[70],
                        }}
                    />
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                        size={36}
                        color={Color.neutral[400]}
                        style={{ paddingTop: 15 }}
                    >
                        {t("manage.noBlacklistedDeviceFound")}
                    </Typography>
                </Grid>
            )}
            <BlacklistedDeviceDialog
                open={blacklistedDeviceDialogOpen}
                handleClose={handleCloseBlacklistedDeviceDialog}
                type={dialogType}
                blacklistedDevice={blacklistedDevice}
                setBlacklistedDevice={setBlacklistedDevice}
                setInitialBlacklistedDevice={setInitialBlacklistedDevice}
                editData={blacklistedEditData}
            />
        </>
    );
};

export default ManageBlacklistedDevice;
