import React from 'react';

import { InventoryStatus, GoodsReceipt, Factory, Inventory, Package, OutSource, Delivery, ProductionFeedback, Puzzle, StockTransfer, PickPack } from "@commsult/ontego-ui";
import { Order, Warehouse, Rebooking, Quality, Kanban, DeliveryList, GoodsIssue } from "@commsult/ontego-ui/dist/sap_icons";
import { F_AUSLAGERUNG, F_BESTANDSINFO, F_BESTELLERFASSUNG, F_EINLAGERUNG, F_KOMMISSIONIERUNG, F_UMBUCHUNG, F_UMLAGERUNG, F_VERLADUNG, F_WARENAUSGANG_EXTERN, F_WARENAUSGANG_INTERN, F_WARENEINGANG, F_INVENTUR, F_KANBAN, F_PRODUKTIONSVERSORGUNG, F_RUECKMELDUNG_PRODUKTION, F_VERPACKUNG, F_QUALITAETSKONTROLLE, F_SONDERFUNKTIONEN_ERWEITERUNGEN } from "../Constants/FunctionIds";

export const getFunctionIconFromId = id => {
    switch (id) {
        case F_AUSLAGERUNG:
            return <OutSource />
        case F_BESTANDSINFO:
            return <InventoryStatus />
        case F_BESTELLERFASSUNG:
            return <Order />
        case F_EINLAGERUNG:
            return <Warehouse />
        case F_VERPACKUNG:
            return <Package />
        case F_KOMMISSIONIERUNG:
            return <PickPack />
        case F_UMBUCHUNG:
            return <Rebooking />
        case F_UMLAGERUNG:
            return <StockTransfer />
        case F_VERLADUNG:
            return <DeliveryList />
        case F_WARENAUSGANG_EXTERN:
            return <Delivery />
        case F_WARENAUSGANG_INTERN:
            return <GoodsIssue />
        case F_WARENEINGANG:
            return <GoodsReceipt />
        case F_INVENTUR:
            return <Inventory />
        case F_KANBAN:
            return <Kanban />
        case F_PRODUKTIONSVERSORGUNG:
            return <Factory />
        case F_QUALITAETSKONTROLLE:
            return <Quality />
        case F_RUECKMELDUNG_PRODUKTION:
            return <ProductionFeedback />
        case F_SONDERFUNKTIONEN_ERWEITERUNGEN:
            return <Puzzle />
        default:
            break;
    }
}

export const getTypeOfFunction = typeOf => {
    switch (typeOf) {
        case 'WAREHOUSE_LOGISTICS':
            return 'Warehouse Logistics'
        case 'PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE':
            return 'Production and Warehouse Enhancement Package'
        default:
            return ''
    }
}