// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// Constants
import { Color } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({

    })
);