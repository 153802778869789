import React, { useState, useEffect } from 'react';

// Components
import {
    Typography,
    FONT_FAMILY,
    ColorSAP,
    Check
} from '@commsult/ontego-ui';

// Material UI Core
import Grid from '@material-ui/core/Grid'

import { ReactComponent as Illus1 } from './up_and_running_1.svg';
import { ReactComponent as Illus2 } from './up_and_running_2.svg';
import { ReactComponent as Illus2Switzerland } from './up_and_running_2_switzerland.svg';
import { ReactComponent as Illus3 } from './up_and_running_3.svg';
import { ReactComponent as Illus4 } from './up_and_running_4.svg';
import { ReactComponent as Illus4Switzerland } from './up_and_running_4_switzerland.svg';
import { useTranslation } from 'react-i18next';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Carousel from '../../ReusableComponents/Carousel/Carousel';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';
import Button from '../../ReusableComponents/Button/Button';

const HEIGHT_IMG = 248

const CheckmarkText = props => {
    const { children } = props
    return (
        <div style={{ display: 'flex', textAlign: 'center', marginTop: 10 }}>
            <Check style={{ width: 20, height: 20, color: ColorSAP.primary[70], marginRight: 16, marginBottom: -10 }} />
            <Typography
                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                size={14}
                color={ColorSAP.neutral[100]}
            >
                {children}
            </Typography>
        </div>
    )
}

const Content1 = props => {
    const { t } = useTranslation();
    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {t("dialogs.twoAdvantages")}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={0}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.templateProcess")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.quickCustom")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.advantageExp")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: HEIGHT_IMG }}>
                        <Illus1 />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const Content2 = props => {
    const { country } = props
    const { t } = useTranslation()
    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {t("dialogs.beforeWeStart")}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={0}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.functionCharac")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.adminProject")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.hardwareLimit")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.beforeWeStartExp")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: HEIGHT_IMG }}>
                        {country === "GERMANY" &&
                            <Illus2 />
                        }
                        {country === "SWITZERLAND" &&
                            <Illus2Switzerland />
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const Content3 = props => {
    const { t } = useTranslation();
    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {t("dialogs.whatHappen90")}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={0}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.workshopSite")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.detailedConcept")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.quickCustom")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.supervisionRollout")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.whatHappen90Exp")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: HEIGHT_IMG }}>
                        <Illus3 />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const Content4 = props => {
    const { t } = useTranslation();
    const { country } = props
    return (
        <div style={{ paddingTop: 40, boxSizing: 'border-box' }}>
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={20}
                color={ColorSAP.neutral[100]}
                style={{ marginBottom: 24 }}
            >
                {t("dialogs.notMakein90")}
            </Typography>

            <Grid style={{ flexGrow: 1 }} container spacing={2}>
                <Grid item md={6}>
                    <CheckmarkText>{t("dialogs.extension30")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.divisionInto2")}</CheckmarkText>
                    <CheckmarkText>{t("dialogs.laterCr")}</CheckmarkText>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 365, marginTop: 24 }}
                    >
                        {t("dialogs.notMakein90Exp")}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <div style={{ height: HEIGHT_IMG }}>
                        {country === "GERMANY" &&
                            <Illus4 />
                        }
                        {country === "SWITZERLAND" &&
                            <Illus4Switzerland />
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const UpAndRunningDialog = props => {
    const { open, onClose, country } = props
    const { t } = useTranslation();

    /** Init nav length */
    const navLength = 4;
    /** Set carousel nav starting index */
    const [navIndex, setNavIndex] = useState(0);

    useEffect(() => {
        setNavIndex(0)
    }, [open])

    /** Set carousel onClick */
    const handleCarouselNavOnClick = index => () => {
        setNavIndex(index)
    };

    const nav = [
        <Content1 />,
        <Content2 country={country} />,
        <Content3 />,
        <Content4 country={country} />
    ];

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            textTitle={t("common.upAndRunning")}
            content={
                <div style={{ width: 750, overflowX: 'hidden', paddingLeft: 24, paddingRight: 24, paddingBottom: 40, boxSizing: 'border-box' }}>
                    {nav[navIndex]}

                    <div style={{ width: '100%', textAlign: 'center', marginTop: 24, marginBottom: 16 }}>
                        <Carousel
                            length={navLength}
                            index={navIndex}
                            onClick={handleCarouselNavOnClick}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", marginTop: 24 }}>
                        <div>
                            {navIndex > 0 && (
                                <TertiaryButton
                                    theme="light"
                                    size="small"
                                    onClick={handleCarouselNavOnClick(navIndex - 1)}
                                >
                                    {t("common.return")}
                                </TertiaryButton>
                            )}
                        </div>
                        <div style={{ display: 'flex' }}>
                            {navIndex !== navLength - 1 ? (
                                <Button
                                    type="primary"
                                    theme="dark"
                                    size="small"
                                    style={{ outline: 'none' }}
                                    onClick={handleCarouselNavOnClick(navIndex + 1)}
                                >
                                    {t("common.continue")}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    theme="dark"
                                    size="small"
                                    style={{ outline: 'none' }}
                                    onClick={onClose}
                                >
                                    {t("common.conclude")}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            }
        />
    )
};

export default UpAndRunningDialog;